import gql from "graphql-tag";

export const ReportListQuery = gql`
    query searchAllReports($search: String!, $offset: Int!) {
        searchAllReports(search: $search, offset: $offset) {
            allReports {
                id
                subject
                seen
                updatedAt
                company {
                    id
                    personal {
                        name
                    }
                }
                issuer {
                    id
                    personal {
                        name
                        surname
                    }

                    role {
                        id
                    }
                }
                complaint_for {
                    id
                    personal {
                        name
                        surname
                    }
                }
            }
            totalCount
        }
    }
`;
