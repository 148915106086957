import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { GetProfileFetch } from "./store/auth/auth_actions";

import ScrollToTop from "./helpers/scrollToTop";

import Dashboard from "./containers/dashboard";
import Authorization from "./components/auth/Authorization";
import requireAuth from "./components/auth/RequireAuth";
import Auth from "./containers/auth";
import Home from "./containers/home";
import CompleteRegistrationRoutes from "./containers/complete-registration/Routes";
import { Loading } from "./components/UI";

// import TP from "./containers/dashboard/Middlewares/TraceChangedProps";
import GlobalModalMiddleware from "./containers/dashboard/Middlewares/GlobalModalMiddleware";
import useLocalStorage from "hooks/useLocalStorage";
import { AUTH_TOKEN } from "consts";

const App = props => {
    const authState = useSelector(s => s.authState);
    const dispatch = useDispatch();
    const [token] = useLocalStorage(AUTH_TOKEN);
    // TP(authState);
    useEffect(() => {
        if (token && !authState.id) {
            dispatch(GetProfileFetch());
        }

        let url = props.location.pathname;
        let lastChar = url.charAt(url.length - 1);
        if (lastChar === "/" && url.length > 1) {
            let newUrl = url.substring(0, url.length - 1);
            props.history.push(newUrl);
        }
    }, [authState.id, dispatch, props.history, props.location.pathname, token]);

    if (token && !authState.id) return <Loading loading />;

    return (
        <ScrollToTop>
            <GlobalModalMiddleware />
            <Switch>
                <Route path="/auth" component={Authorization(Auth)} />
                <Route path="/dashboard" component={requireAuth(Dashboard)} />
                <Route path="/new-user" component={CompleteRegistrationRoutes} />
                <Route path="/" component={Home} />
            </Switch>
        </ScrollToTop>
    );
};

App.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(App);
