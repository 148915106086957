import { client } from "../../../helpers/index";
import { user, singleUser, userStatusChanged } from "../../../store/user/user/user_actions";
import {
  UsersListQuery,
  FetchUser,
  FetchClientActivitiesQuery,
  SearchTrainersForFilters,
} from "../../../resolvers/query/users";
import {
  ToggleUserStatus,
  addClientDashboardMutation,
  editClientDashboard,
  completeProfileDashboard,
  deleteUser,
} from "../../../resolvers/mutation/user";
import {
  toggleLoadingOn,
  toggleLoadingOff,
  showModal,
} from "../../../store/global/global_actions";

export const FetchAllUsersQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: UsersListQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let {
            searchUsers: { totalCount, allUsers },
          } = data;
          dispatch(user({ allUsers: allUsers, totalUsers: totalCount }));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          dispatch(
            user({
              errors: { array: err.graphQLErrors },
              loading: false,
              modal: false,
            })
          );
        } else {
          dispatch(
            user({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const FetchClientActivitiesAction = (variables) => {
  return (dispatch) => {
    return client
      .query({
        query: FetchClientActivitiesQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          return data.fetchClientActivities;
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          dispatch(
            user({
              errors: { array: err.graphQLErrors },
              loading: false,
              modal: false,
            })
          );
        } else {
          dispatch(
            user({ errors: { array: err }, loading: false, modal: false })
          );
        }
      });
  };
};

export const FetchUserByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: FetchUser,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let { fetchUser } = data;
          dispatch(singleUser(fetchUser));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          dispatch(
            user({
              errors: { array: err.graphQLErrors },
              loading: false,
              modal: false,
            })
          );
        } else {
          dispatch(
            user({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const CreateClientMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: addClientDashboardMutation,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          dispatch(singleUser(data.addClientDashboard));
          dispatch(
            showModal({
              type: 1,
              message: "Client created sucessfully.",
              button: "See it",
              link: "/dashboard/users/" + parseInt(data.addClientDashboard.id),
            })
          );
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          dispatch(
            user({
              errors: { array: err.graphQLErrors },
              loading: false,
              modal: false,
            })
          );
        } else {
          dispatch(
            user({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const UpdateClientMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: !variables.complete_profile_token
          ? editClientDashboard
          : completeProfileDashboard,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          if (variables.complete_profile_token) {
            dispatch(showModal({ type: 3, link: "/auth" }));
          } else {
            dispatch(singleUser(data.editClientDashboard));
            dispatch(
              showModal({
                type: 1,
                message: "Client updated sucessfully.",
                button: "See it",
                link:
                  "/dashboard/users/" + parseInt(data.editClientDashboard.id),
              })
            );
          }
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          dispatch(
            user({
              errors: { array: err.graphQLErrors },
              loading: false,
              modal: false,
            })
          );
        } else {
          dispatch(
            user({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const SearchTrainers = (variables) => () =>
  client
    .mutate({
      mutation: SearchTrainersForFilters,
      variables,
    })
    .then(({ data }) =>
      data.searchTrainers.allUsers.map((user) => ({
        ...user,
        name: user.personal.name + " " + user.personal.surname,
        companyName: user.company ? user.company.personal.name : null,
      }))
    )
    .catch((err) => {
      console.log("====================================");
      console.log({ err });
      console.log("====================================");
      return err;
    });

export const DeleteUserMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: deleteUser, variables })
      .then(() => true)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const ToggleUserStatusMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    client
      .mutate({ mutation: ToggleUserStatus, variables })
      .then(() => dispatch(userStatusChanged(variables))) 
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};
