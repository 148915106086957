import gql from "graphql-tag";

export const TrainingPlanListQuery = gql`
  query searchPlans(
    $search: String!
    $trainingPeriods: [Int!]
    $phases: [Int!]
    $bodyParts: [Int!]
    $equipments: [Int!]
    $offset: Int
    $limit: Int
    $creator: Int
    $difficulty: Int
  ) {
    searchPlans(
      search: $search
      trainingPeriods: $trainingPeriods
      phases: $phases
      equipments: $equipments
      bodyParts: $bodyParts
      offset: $offset
      limit: $limit
      difficulty: $difficulty
      creator: $creator
    ) {
      allPlans {
        id
        img
        title
        no_of_exercises
        creator {
          id
          trainerId
          companyId
        }
      }
      totalCount
    }
  }
`;

export const SingleTrainingPlanQuery = gql`
  query fetchPlan($id: Int!) {
    fetchPlan(id: $id) {
      id
      title
      objective
      status
      trainingperiods {
        id
        name
      }
      duration
      phases {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        trainerId
        companyId
        personal {
          name
        }
      }
      img
      no_of_exercises
      exercises {
        id
        title
        img
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const FetchTrainingplanForPDFQuery = gql`
  query fetchPlan($id: Int!) {
    fetchPlan(id: $id) {
      id
      title
      img
      status
      objective
      phases {
        id
        name
      }
      duration
      trainingperiods {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      no_of_exercises
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        trainerId
        companyId
        company {
          id
          img
        }
        img
        personal {
          name
          surname
        }
      }
      exercises {
        id
        title
        img
        reps
        series
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const SingleTakenPlanQuery = gql`
  query fetchTakenPlan($takenPlanId: Int!) {
    fetchTakenPlan(takenPlanId: $takenPlanId) {
      id
      title
      objective
      status
      trainingperiods {
        id
        name
      }
      duration
      phases {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        trainerId
        companyId
        personal {
          name
        }
      }
      img
      no_of_exercises
      exercises {
        id
        title
        img
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const FetchTakenplanForPDFQuery = gql`
  query fetchTakenPlan($takenPlanId: Int!) {
    fetchTakenPlan(takenPlanId: $takenPlanId) {
      id
      title
      img
      status
      objective
      phases {
        id
        name
      }
      duration
      trainingperiods {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      no_of_exercises
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        trainerId
        companyId
        company {
          id
          img
        }
        img
        personal {
          name
          surname
        }
      }
      client {
        id
        img
        personal {
          name
          surname
        }
        private_info {
          website
        }
      }
      exercises {
        id
        title
        img
        reps
        series

        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const IsTitleUnique = gql`
  query isTitleUnique($title: String) {
    isTitleUnique(title: $title)
  }
`;
