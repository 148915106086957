import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import get from "lodash/get";

import placeholder from "../../assets/img/placeholders/400x200.png";
import { useTranslation } from "react-i18next";

const calculateDuration = (time) => {
  let hours = Math.floor(time / 60);
  let minutes = time % 60;

  return `${hours} h ${minutes} min`;
};

const TestsList = (props) => {
  const { t } = useTranslation();
  let [selected, setSelected] = useState(false);
  let handleClick = (props) => {
    if (props.selectUserModal) {
      props.selectUserModal(props.tests.id, selected);
      setSelected(!selected);
    } else {
      let link = !props.userId
        ? `/dashboard/tests/${get(props, "tests.id", "")}`
        : `${props.userId}/takentest/${get(props, "takenTest.id", "")}`;
      props.history.push(link);
    }
  };
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
      <div
        onClick={() => handleClick(props)}
        style={{ cursor: "pointer" }}
        className="excercises-item tests"
      >
        {props.selectUserModal ? (
          <div className="check-item">
            <input
              type="checkbox"
              checked={selected}
              onChange={() => setSelected(!selected)}
            />
            <label htmlFor="Check"></label>
          </div>
        ) : null}
        <div className="thumb">
          <img alt="main" src={get(props, "tests.img", false) || placeholder} />
        </div>
        <div className="content">
          <div className="title">
            <h5>{get(props, "tests.title", "")}</h5>
          </div>
          <div className="icons-text">
            <div>
              <div className="icon">
                <i className="icon-time"></i>
              </div>
              <div className="text">
                <span>{t("duration.label")}</span>
                <p>{calculateDuration(get(props, "tests.duration", ""))}</p>
              </div>
            </div>
            <div>
              <div className="icon">
                <i className="icon-level"></i>
              </div>
              <div className="text">
                <span>{t("difficulty.label")}</span>
                <p>{get(props, "tests.difficulty.name", "")}</p>
              </div>
            </div>
          </div>
          <div className="description">
            <p>{get(props, "tests.description", "")}</p>
          </div>
          {/* <div className="view-details">
					<Link to={`/dashboard/tests/${get(props, "tests", "").id}`}>View details</Link>
				</div> */}
        </div>
      </div>
    </div>
  );
};

TestsList.propTypes = {
  tests: PropTypes.object,
  takenTest: PropTypes.object,
  history: PropTypes.object,
  userId: PropTypes.number,
  selectUserModal: PropTypes.func,
};

export default withRouter(TestsList);
