/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

const multipleSelectHandler = (e, elements) => {
  let items = [];
  let values = e.value;

  elements.map((element) => {
    if (values.includes(element.id)) {
      items.push(element);
    }
  });

  console.log({ items });

  return items;
};

const MultipleSelectComp = (props) => {
  let [state, setState] = useState({
    elements: [],
    selectedItems: [],
  });

  useEffect(() => {
    let elements = [];
    let selectedItems = [];
    if (props.items) {
      elements = [...props.items];
      selectedItems = (props.selectedItems || []).map((el) => el.id);
      setState({
        elements: elements,
        selectedItems: selectedItems,
      });
    }
  }, [props.items, props.selectedItems]);

  let fields = { text: "name", value: "id" };

  return (
    <div className="form-group">
      <MultiSelectComponent
        showClearButton
        id="checkbox"
        dataSource={state.elements}
        fields={fields}
        placeholder={props.text || ""}
        mode="CheckBox"
        change={(e) => props.select(multipleSelectHandler(e, state.elements))}
        value={state.selectedItems}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

MultipleSelectComp.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  text: PropTypes.string,
  select: PropTypes.func,
  required: PropTypes.bool,
};

export default MultipleSelectComp;
