import React, { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Loading } from "../../../components/UI";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import LineChartComponent from "../../../components/Charts/LineChart";
import { GetReportDataForDashboardAction } from "./GraphQLActions";

const LineChartContainer = () => {
    const yearLabels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState(yearLabels);
    const [filter, setFilter] = useState("YEAR");
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        dispatch(GetReportDataForDashboardAction({ period: filter })).then(response => {
            let labels = [];
            let data = [];
            if (response)
                switch (filter) {
                    case "MONTH":
                        let date = moment();
                        let dayCount = date.daysInMonth();
                        for (let i = 1; i < dayCount + 1; i++) {
                            labels.push(i + "");
                            data[i - 1] = 0;
                        }
                        // response.map(res => (data[res.key - 1] = res.count));
                        Object.keys(response).map(key => (data[key - 1] = response[key]));
                        break;
                    case "YEAR":
                        labels = [...yearLabels];
                        labels.map((_, index) => (data[index] = response[index + 1] || 0));
                        // response.map(res => (data[res.key - 1] = res.count));
                        break;
                    case "ALL":
                        let keys = Object.keys(response);

                        for (let i = parseInt(keys[0]); i <= parseInt(keys[keys.length - 1]); i++) {
                            labels.push(i + "");
                            data[i - keys[0]] = 0;
                        }
                        keys.map(key => (data[key - keys[0]] = response[key]));
                        // response.map(res => (data[res.key - response[0].key] = res.count));
                        break;
                }
            setData(data);
            setLabels(labels);
            setLoading(false);
        });
    }, [dispatch, filter]);

    return (
        <div className="chart">
            <div className="chart-head">
                <h4>{t("report.label")}</h4>
                <div className="chart-filter">
                    <form>
                        <div className="select-custom">
                            <select
                                defaultValue={filter}
                                onClick={e => setFilter(e.target.value)}
                                className="form-control"
                            >
                                <option value="MONTH">Month</option>
                                <option value="YEAR">Year</option>
                                <option value="ALL">All</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div className="chart-body report-body">
                {loading ? <Loading loading /> : <LineChartComponent data={data} labels={labels} label="Users" />}
            </div>
        </div>
    );
};

LineChartContainer.propTypes = {
    title: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    backgroundColor: PropTypes.arrayOf(PropTypes.string),
    hoverBackgroundColor: PropTypes.arrayOf(PropTypes.string)
};

export default LineChartContainer;
