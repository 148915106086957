import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useQueryStrings = () => {
    const [state, setState] = useState({});
    const location = useLocation();

    useEffect(() => {
        let query = {};
        let qs = location.search.substr(1);
        qs = qs.split(".");
        qs.map(el => {
            el = el.split("=");
            query = { ...query, [el[0]]: el[1] };
        });
        setState(query);
    }, [location.search]);

    return state;
};

export default useQueryStrings;
