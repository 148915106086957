import React, { useEffect } from "react";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

const DatePickerComponent = props => {
    useEffect(() => {
        console.log({ props });
    }, [props]);

    const onChangeHandler = e => {
        let { value } = e.target;

        props.select(
            moment(value, "YYYY-MM-DD")
                .format("DD-MM-YYYY")
                .toString()
        );
    };

    return (
        <input
            type="date"
            defaultValue={
                props.date
                    ? moment(props.date, "DD-MM-YYYY")
                          .format("YYYY-MM-DD")
                          .toString()
                    : null
            }
            onChange={onChangeHandler}
        />
    );
};
{
    /* <DatePicker
    selected={props.date ? new Date(moment(props.date, "DD-MM-YYYY")) : null}
    onChange={date => {
        console.log({
            "tasj:": "tash",
            date,
            moment: moment(date, "dd-MM-yyyy")
                .format("DD-MM-YYYY")
                .toString()
        });
        if (date)
            props.select(
                moment(date, "dd-MM-yyyy")
                    .format("DD-MM-YYYY")
                    .toString()
            );
    }}
    placeholderText={props.placeholder || "DD-MM-YYYY"}
    dateFormat={"dd-MM-yyyy"}
    showMonthDropdown
    showYearDropdown
/>; */
}
// const DatePickerComponent = props => (
//     <DatePicker
//         selected={props.date ? new Date(moment(props.date, "DD-MM-YYYY")) : null}
//         onChange={date => {
//             console.log({
//                 "tasj:": "tash",
//                 date,
//                 moment: moment(date, "dd-MM-yyyy")
//                     .format("DD-MM-YYYY")
//                     .toString()
//             });
//             if (date)
//                 props.select(
//                     moment(date, "dd-MM-yyyy")
//                         .format("DD-MM-YYYY")
//                         .toString()
//                 );
//         }}
//         placeholderText={props.placeholder || "DD-MM-YYYY"}
//         dateFormat={"dd-MM-yyyy"}
//         showMonthDropdown
//         showYearDropdown

//     />
// );

DatePickerComponent.propTypes = {
    date: PropTypes.string,
    select: PropTypes.func,
    placeholder: PropTypes.string
};

export default DatePickerComponent;
