import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

import SingleTrainingplan from "../../../components/Trainingplan/SingleTrainingplan";
import EditTrainingplan from "./EditTrainingplan";
import {
  FetchTrainingPlanByIdQuery,
  FetchTakenPlanByIdQuery,
  ToggleTrainingPlanStatusMutation,
  DeleteTrainingPlanMutation,
  ToggleTakenPlanStatusMutation,
  DeleteTakenPlanMutation,
} from "./GraphQLActions";
import {
  deleteTrainingPlan,
  plan,
} from "../../../store/trainingplans/trainingplans_actions";

const FetchTrainingplan = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const planState = useSelector((s) => s.planState);
  const singleTrainingPlan = planState.singleTrainingPlan;
  const [error, setError] = useState(null);

  const { userId, planId } = useParams();

  useEffect(() => {
    //See if is a taken paln

    dispatch(plan({ updateFlag: true }));

    if (props.fetch) {
      dispatch(plan({ updateTrainingPlan: {} }));
    }

    if (userId) {
      if (
        parseInt(singleTrainingPlan.id) !== parseInt(planId) ||
        !planState.takenPlanFlag
      ) {
        dispatch(
          FetchTakenPlanByIdQuery({ takenPlanId: parseInt(planId) })
        ).catch((err) => {
          setError(err);
        });
      }
    } else {
      if (
        parseInt(singleTrainingPlan.id) !== parseInt(planId) ||
        planState.takenPlanFlag
      ) {
        dispatch(FetchTrainingPlanByIdQuery({ id: parseInt(planId) })).catch(
          (err) => {
            setError(err);
          }
        );
      }
    }
  }, [
    dispatch,
    singleTrainingPlan.id,
    planState.takenPlanFlag,
    planId,
    userId,
    props.fetch,
  ]);

  /**
   *
   * @param {Event} e
   */
  const toggleTrainingPlanStatus = () => {
    if (userId) {
      dispatch(
        ToggleTakenPlanStatusMutation({
          id: singleTrainingPlan.id,
          status:
            singleTrainingPlan.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        })
      );
    } else {
      dispatch(
        ToggleTrainingPlanStatusMutation({
          id: singleTrainingPlan.id,
          status:
            singleTrainingPlan.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        })
      );
    }
  };

  const deleteTrainingPlanHandler = () => {
    if (userId) {
      dispatch(DeleteTakenPlanMutation({ id: parseInt(planId) })).then(() => {
        history.push(`/dashboard/users/${userId}`);
        dispatch(deleteTrainingPlan());
      });
    } else {
      dispatch(DeleteTrainingPlanMutation({ id: parseInt(planId) })).then(
        () => {
          history.push("/dashboard/trainingplans");
          dispatch(deleteTrainingPlan());
        }
      );
    }
  };

  if (error) throw error;

  if (parseInt(singleTrainingPlan.id) === parseInt(planId)) {
    if (props.fetch) {
      return (
        <section className="main-content">
          <SingleTrainingplan
            onToggleTrainingPlanStatus={toggleTrainingPlanStatus}
            deleteTrainingPlanHandler={deleteTrainingPlanHandler}
          />
        </section>
      );
    } else {
      return <EditTrainingplan update />;
    }
  } else {
    return <div></div>;
  }
};

FetchTrainingplan.propTypes = {
  fetch: PropTypes.bool,
};

export default FetchTrainingplan;
