import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";

import SingleUser from "../../../components/Client/User/SingleUser";
import UserReports from "./UserReports";
import NewUser from "./NewUser";
import {
  FetchUserByIdQuery,
  DeleteUserMutation,
  ToggleUserStatusMutation,
} from "./GraphQLActions";
import { user, deleteUser } from "../../../store/user/user/user_actions";

const FetchUser = ({ event }) => {
  const dispatch = useDispatch();
  const userState = useSelector((s) => s.userState);
  const singleUser = userState.singleUser;

  const { userId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!event) {
      dispatch(user({ updateFlag: true }));
    } else {
      dispatch(user({ updateUser: {} }));
    }

    if (parseInt(singleUser.id) !== parseInt(userId) || userState.forceUpdate) {
      dispatch(FetchUserByIdQuery({ id: parseInt(userId), roleId: 4 }));
    }
  }, [dispatch, event, userId, singleUser, userState.forceUpdate]);

  const deleteUserHandler = () => {
    dispatch(DeleteUserMutation({ id: parseInt(userId) })).then(() => {
      history.push("/dashboard/users");
      dispatch(deleteUser());
    });
  };

  /**
   *
   * @param {Event} e
   */
  const toggleUserStatus = () => {
    dispatch(
      ToggleUserStatusMutation({
        id: singleUser.id,
        status: singleUser.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      })
    );
  };

  if (userState.error) return `Error! ${userState.error}`;

  if (parseInt(singleUser.id) === parseInt(userId)) {
    switch (event) {
      case "fetch":
        return (
          <SingleUser
            deleteUserHandler={deleteUserHandler}
            toggleUserStatus={toggleUserStatus}
          />
        );
      case "reports":
        return <UserReports />;
      default:
        return <NewUser />;
    }
  } else {
    return <div />;
  }
};

FetchUser.propTypes = {
  event: PropTypes.string,
};

export default FetchUser;
