import { client, saveToken } from "../../helpers";
import { auth, authErrors, authLoading } from "../../store/auth/auth_actions";
import { ForgotPasswordMutation, ResetFromToken, LoginMutation } from "../../resolvers/mutation/Auth";
import { toggleLoadingOn, toggleLoadingOff, showModal } from "../../store/global/global_actions";

export const ForgotPasswordAction = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: ForgotPasswordMutation,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    dispatch(
                        showModal({
                            type: 1,
                            message: "A link to reset password was sent to your email",
                            button: "Ok!",
                            link: "/auth"
                        })
                    );
                }
            })
            .catch(err => {
                dispatch(toggleLoadingOff());
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    throw errors;
                } else {
                    throw err;
                }
            });
    };
};

export const ResetPasswordAction = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: ResetFromToken,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    return true;
                }
            })
            .catch(err => {
                dispatch(toggleLoadingOff());
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    throw errors;
                } else {
                    throw err;
                }
            });
    };
};

export const LoginUserAction = variables => {
    return dispatch => {
        // dispatch(auth({ loading: true, errors: [] }));
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: LoginMutation,
                variables: {
                    ...variables,
                    provider: "web"
                }
            })
            .then(({ data }) => {
                if (data.login) {
                    // saveToken(data.login.token, data.login.refreshtoken);
                    return data.login;
                }
            })
            .catch(err => {
                dispatch(toggleLoadingOff());
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    throw errors;
                } else {
                    throw err;
                }
            });
    };
};
