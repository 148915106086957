import { client } from "../../../helpers/index";
import { UsersWithTrainersQuery } from "../../../resolvers/query/users";
import { TrainingPlanListQuery } from "../../../resolvers/query/trainingplan";
import { subscribePlanDashboard } from "../../../resolvers/mutation/trainingplan";
import { subscribeTestDashboard } from "../../../resolvers/mutation/test";
import { TestsListQuery } from "../../../resolvers/query/test";
import { user } from "../../../store/user/user/user_actions";

export const FetchAllClientsQuery = variables => {
    return () => {
        return client
            .query({
                query: UsersWithTrainersQuery,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { searchUsersWithTrainers } = data;
                    return searchUsersWithTrainers;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const FetchAllTrainingPlansQuery = variables => {
    return () => {
        return client
            .query({
                query: TrainingPlanListQuery,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { searchPlans } = data;
                    return searchPlans;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const FetchAllTestsQuery = variables => {
    return () => {
        return client
            .query({
                query: TestsListQuery,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { searchTests } = data;
                    return searchTests;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const SubscribeClientsToPlan = variables => {
    return ds => {
        return client
            .mutate({
                mutation: subscribePlanDashboard,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { subscribePlanDashboard } = data;
                    ds(
                        user({
                            forceUpdate: true
                        })
                    );
                    return subscribePlanDashboard;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const SubscribeClientsToTest = variables => {
    return ds => {
        return client
            .mutate({
                mutation: subscribeTestDashboard,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { subscribeTestDashboard } = data;
                    ds(
                        user({
                            forceUpdate: true
                        })
                    );
                    return subscribeTestDashboard;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};
