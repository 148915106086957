export const USER = `USER`;
export const user = (value) => {
  return { type: USER, value };
};

export const DEEP_STATE_MUTATOR = `DEEP_STATE_MUTATOR`;
export const updateUser = (value) => {
  return { type: DEEP_STATE_MUTATOR, value };
};

export const PREPARE_USER_UPDATE = `PREPARE_USER_UPDATE`;
export const prepareUserUpdate = (value) => {
  return { type: PREPARE_USER_UPDATE, value };
};

export const SINGLE_USER = `SINGLE_USER`;
export const singleUser = (value) => {
  return { type: SINGLE_USER, value };
};

export const DISPLAY_ERRORS = `DISPLAY_ERRORS`;
export const displayErrors = (value) => {
  return { type: DISPLAY_ERRORS, value };
};

export const UPDATE_USER_STATE = `UPDATE_USER_STATE`;
export const updateUserState = (value, isUpdate, final) => {
  return { type: UPDATE_USER_STATE, value, isUpdate, final };
};

export const UPDATE_USER_STATE_ADMINISTRATION = `UPDATE_USER_STATE_ADMINISTRATION`;
export const updateUserStateAdministration = (value) => {
  return { type: UPDATE_USER_STATE_ADMINISTRATION, value };
};

export const UPDATE_USER_STATE_PERSONAL = `UPDATE_USER_STATE_PERSONAL`;
export const updateUserStatePersonal = (value) => {
  return { type: UPDATE_USER_STATE_PERSONAL, value };
};

export const UPDATE_USER_STATE_ADDRESS = `UPDATE_USER_STATE_ADDRESS`;
export const updateUserStateAddress = (value) => {
  return { type: UPDATE_USER_STATE_ADDRESS, value };
};

export const UPDATE_USER_STATE_PRIVATE_INFO = `UPDATE_USER_STATE_PRIVATE_INFO`;
export const updateUserStatePrivateInfo = (value) => {
  return { type: UPDATE_USER_STATE_PRIVATE_INFO, value };
};

export const UPDATE_USER_STATE_FINANCE = `UPDATE_USER_STATE_FINANCE`;
export const updateUserStateFinance = (value) => {
  return { type: UPDATE_USER_STATE_FINANCE, value };
};

export const UPDATE_USER_STATE_AHV = `UPDATE_USER_STATE_AHV`;
export const updateUserStateAHV = (value) => {
  return { type: UPDATE_USER_STATE_AHV, value };
};

export const UPDATE_USER_STATE_WORK = `UPDATE_USER_STATE_WORK`;
export const updateUserStateWork = (value) => {
  return { type: UPDATE_USER_STATE_WORK, value };
};

export const UPDATE_USER_STATE_SPORT = `UPDATE_USER_STATE_SPORT`;
export const updateUserStateSport = (value) => {
  return { type: UPDATE_USER_STATE_SPORT, value };
};

export const UPDATE_USER_STATE_SPORT_SPECIALIST = `UPDATE_USER_STATE_SPORT_SPECIALIST`;
export const updateUserStateSportSpecialist = (value) => {
  return { type: UPDATE_USER_STATE_SPORT_SPECIALIST, value };
};

export const UPDATE_USER_STATE_MEDICINE = `UPDATE_USER_STATE_MEDICINE`;
export const updateUserStateMedicine = (value) => {
  return { type: UPDATE_USER_STATE_MEDICINE, value };
};

export const UPDATE_USER_STATE_MED_EXPERT = `UPDATE_USER_STATE_MED_EXPERT`;
export const updateUserStateMedExpert = (value) => {
  return { type: UPDATE_USER_STATE_MED_EXPERT, value };
};

export const UPDATE_USER_STATE_PROBLEM = `UPDATE_USER_STATE_PROBLEM`;
export const updateUserStateProblem = (value) => {
  return { type: UPDATE_USER_STATE_PROBLEM, value };
};

export const UPDATE_USER_STATE_DIAGNOSE = `UPDATE_USER_STATE_DIAGNOSE`;
export const updateUserStateDiagnose = (value) => {
  return { type: UPDATE_USER_STATE_DIAGNOSE, value };
};

export const OPEN_MODAL = `OPEN_MODAL`;
export const openModal = (value) => {
  return { type: OPEN_MODAL, value };
};

export const USER_FILTERS = `USER_FILTERS`;
export const userFilter = (value) => {
  return { type: USER_FILTERS, value };
};

export const USER_ERRORS = `USER_ERRORS`;
export const userError = (value) => {
  return { type: USER_ERRORS, value };
};

export const USER_LOADING = `USER_LOADING`;
export const userLoading = (value) => {
  return { type: USER_LOADING, value };
};

export const RESET_USER_STATE = `RESET_USER_STATE`;
export const resetUserState = (value) => {
  return { type: RESET_USER_STATE, value };
};

export const RESET_USER_FILTER_STATE = `RESET_USER_FILTER_STATE`;
export const resetUserFilterState = (value) => {
  return { type: RESET_USER_FILTER_STATE, value };
};

export const DELETE_USER = `DELETE_USER`;
export const deleteUser = () => {
  return {
    type: DELETE_USER,
  };
};

export const USER_STATUS_CHANGED = `USER_STATUS_CHANGED`;
export const userStatusChanged = (value) => {
  return {
    type: USER_STATUS_CHANGED,
    value,
  };
};
