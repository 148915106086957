import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";

const TrainerDisclaimer = props => {
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        return () => {
            setChecked(false);
            setError(false);
        };
    }, [props.show]);

    return (
        <Modal visible={props.show} dialogClassName="modal-dialog modal-dialog-centered" onClick={props.close}>
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.close}>
                    <i className="icon-cancel"></i>
                </button>
                <div className="modal-body">
                    <div className="excercises-created disclaimer">
                        <div className="content">
                            Ich nehme zur Kenntnis, dass ich als Trainer und nicht Priosan für die Inhalte
                            (Trainingspläne, Übungen, Tests) und deren Folgen verantwortlich ist.
                        </div>

                        <div className="content">
                            <input
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                                className="checkbox"
                                type="checkbox"
                                name="check"
                                id="check"
                            />{" "}
                            I accept the <a>Terms and Conditions</a>
                            <p style={{ color: "red", display: error ? "block" : "none", fontSize: "12px" }}>
                                Please accept the Terms and Conditions
                            </p>
                        </div>

                        <div className="content">
                            For questions <a>contact</a> admin of company on priosan.
                        </div>

                        <div className="btn-modal">
                            <a
                                href="#"
                                className="add"
                                onClick={e => {
                                    e.preventDefault();
                                    checked ? props.action(e) : setError(true);
                                }}
                            >
                                Confirm
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

TrainerDisclaimer.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired
};

export default TrainerDisclaimer;
