import React from "react";
import { Switch, Route } from "react-router";

import NewTest from "./NewTest";
import FetchTest from "./FetchTest";
import Test from "./index";
import Loading from "../../../components/UI/Loading";
import { useSelector } from "react-redux";

const TestRoutes = () => {
  const globalState = useSelector((s) => s.globalState);
  return (
    <section className="main-content">
      <Loading loading={globalState.loading} />
      <Switch>
        <Route path="/dashboard/tests/new-test" component={NewTest} />
        <Route
          path="/dashboard/tests/:testId(\d+)/edit-test"
          component={FetchTest}
        />
        <Route path="/dashboard/tests/:testId(\d+)">
          <FetchTest fetch />
        </Route>
        <Route path="/dashboard/tests/" component={Test} />
      </Switch>
    </section>
  );
};

export default TestRoutes;
