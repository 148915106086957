import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const QandAQuestions = props => {
    const { t } = useTranslation();

    return (
        <div className="col-md-6">
            <div className="form-group form-question">
                <a onClick={e => props.remove(e)} href="#" className="remove-item">
                    {t("remove.label")} <i className="icon-cancel"></i>
                </a>
                <input
                    onChange={e => props.changeHandler(e)}
                    type="text"
                    defaultValue={props.question.content || ""}
                    placeholder={t("write_your_question.label")}
                    name="content"
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <input
                    onChange={e => props.changeHandler(e)}
                    type="text"
                    defaultValue={props.question.correct_answer || ""}
                    placeholder={""}
                    name="correct_answer"
                    className="form-control"
                />
            </div>
        </div>
    );
};

QandAQuestions.propTypes = {
    changeHandler: PropTypes.func,
    remove: PropTypes.func,
    question: PropTypes.object
};

export default QandAQuestions;
