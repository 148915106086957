import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import TrainingplanList from '../../Trainingplan/TrainingplanList'
// import TestList from '../../Tests/TestsList'

import placeholder from "../../../assets/img/placeholders/200x200.png";

const SingleTrainer = (props) => {
  const { t } = useTranslation();
  const trainerState = useSelector((s) => s.trainerState);
  const authState = useSelector((s) => s.authState);
  const { singleTrainer } = trainerState;
  let birthDate = moment(
    get(singleTrainer, "personal.birthdate", null),
    "DD/MM/YYYY"
  ).format("D MMMM YYYY");
  // let convCreatedAt = moment(get(singleTrainer, "createdAt")).format("D MMMM YYYY");

  let isAdminStyle = {
    color: "red",
  };

  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="white-bg">
          <div className="row">
            <div className="col-md-12">
              <div className="single-detail-head single-head-left-right">
                <div className="single-head-flex">
                  <div className="single-head-left">
                    <div className="form-toggle form-toggle-checked">
                      {/* {hasUpdateAccess(user, exercise) && ( */}
                      <label className="form-toggle-switch" htmlFor="status">
                        <input
                          type="checkbox"
                          id="status"
                          defaultChecked={singleTrainer.status === "ACTIVE"}
                          onChange={props.toggleTrainerStatus}
                        />
                        <div className="form-toggle-switch-slider form-toggle-switch-round"></div>
                      </label>
                      {/* )} */}
                      <span
                        className={
                          singleTrainer.status === "ACTIVE" ? "active" : "inactive"
                        }
                      >
                        {singleTrainer.status === "ACTIVE" ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                  <div className="single-head-right">
                    <div className="single-detail-head">
                      {authState.role && authState.role.id < 3 ? (
                        <a
                          style={singleTrainer.is_admin ? isAdminStyle : {}}
                          onClick={(e) => {
                            e.preventDefault
                              ? e.preventDefault()
                              : (e.returnValue = false);
                            props.toggleAdminPrivilegesHandler();
                          }}
                          href="_"
                          className="btn "
                        >
                          {t("is_admin.label")}
                        </a>
                      ) : null}
                      <Link
                        to={`${get(
                          singleTrainer,
                          "id",
                          placeholder
                        )}/edit-trainer`}
                      >
                        <i className="icon-edit"></i>
                        <span>{t("edit.label")}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 user-flex">
              <div className="user-img">
                <img
                  alt="profile"
                  src={get(singleTrainer, "img", false) || placeholder}
                />
              </div>
              <div className="user-detail">
                <div className="user-row">
                  <div className="user-info col-md-4">
                    <p>{t("first_name.label")}</p>
                    <span>{get(singleTrainer, "personal.name", "-")}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("last_name.label")}</p>
                    <span>{get(singleTrainer, "personal.surname", "-")}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("birthdate.label")}</p>
                    <span>{birthDate || "-"}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("street.label")}</p>
                    <span>{get(singleTrainer, "address.street", "-")}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("postal.label")}</p>
                    <span>{get(singleTrainer, "address.postal", "-")}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("city.label")}</p>
                    <span>{get(singleTrainer, "address.city", "-")}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("country.label")}</p>
                    <span>
                      {get(singleTrainer, "address.country.name", "-")}
                    </span>
                  </div>
                  {/* <div className="user-info">
                                        <p>Date registered</p>
                                        <span>{convCreatedAt || "-"}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>Gender</p>
                                        <span>{get(singleTrainer, "personal.gender.name", "-")}</span>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="white-bg">
          <div className="row"></div>
          <div className="row justify-content-center">
            <div className="col-md-12 user-flex">
              <div className="user-img">
                <img
                  alt="profile"
                  src={get(singleTrainer, "company.img", "")}
                />
              </div>
              <div className="user-detail">
                <div className="user-row">
                  <div className="user-info col-md-4">
                    <p>{t("name.label")}</p>
                    <span>
                      {get(singleTrainer, "company.personal.name", "-")}
                    </span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("address.label")}</p>
                    <span>
                      {get(singleTrainer, "company.address.street", "-")}
                    </span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("email.label")}</p>
                    <span>{get(singleTrainer, "company.email", "-")}</span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("phone.label")}</p>
                    <span>
                      {get(singleTrainer, "company.personal.phoneNumber", "-")}
                    </span>
                  </div>
                  <div className="user-info col-md-4">
                    <p>{t("website.label")}</p>
                    <span>
                      {get(singleTrainer, "company.private_info.website", "-")}
                    </span>
                  </div>
                  {/* <div className="user-info">
                                        <p>Date registered</p>
                                        <span>{convCompanyCreatedAt}</span>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid">
                    <div className="white-bg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="training-head">
                                    <div className="title">
                                        <h5>Training Plans</h5>
                                    </div>
                                    <div className="create-icon">
                                        <a href="#">
                                            <i className="icon-add"></i>
                                            <span>Add Trainingplans</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {
                                trainerState.trainingPlans.map(e => (
                                    <TrainingplanList
                                        key={e.id}
                                        plan={e}
                                    />
                                ))
                            }



                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="training-head">
                                    <div className="title">
                                        <h5>Tests</h5>
                                    </div>
                                    <div className="create-icon">
                                        <a href="#">
                                            <i className="icon-add"></i>
                                            <span>Add Test</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {
                                user.tests.map(e => (
                                    <TestList
                                        key={e.id}
                                        tests={e}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div> */}
    </section>
  );
};

SingleTrainer.propTypes = {
  toggleAdminPrivilegesHandler: PropTypes.func,
};

export default SingleTrainer;
