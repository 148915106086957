import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";
import get from "lodash/get";

const ConfirmModalComponent = ({
    show,
    rejectButton = "Cancel",
    confirmButton,
    rejectHandler,
    confirmHandler,
    message
}) => {
    let closeModalHandler = e => {
        e && e.preventDefault();
        rejectHandler(e);
    };

    let confirmModalHandler = e => {
        e && e.preventDefault();
        confirmHandler(e);
    };

    return (
        <Modal visible={show} dialogClassName="modal-dialog modal-dialog-centered" onClick={closeModalHandler}>
            <div className="modal-content">
                <button type="button" className="close" onClick={closeModalHandler}>
                    <i className="icon-cancel"></i>
                </button>
                <div className="modal-body">
                    <div className="excercises-created">
                        <div className="icon">
                            <i className={"icon-cancel"}></i>
                        </div>
                        <div className="content">
                            <p>{message}</p>
                        </div>
                        <div className="btn-modal">
                            <a href="#" className="add" onClick={closeModalHandler}>
                                {rejectButton}
                            </a>
                            <a href="#" className="add" onClick={confirmModalHandler}>
                                <i className="icon-add"></i>
                                {confirmButton}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ConfirmModalComponent.propTypes = {
    show: PropTypes.object,
    rejectButton: PropTypes.string,
    confirmButton: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    rejectHandler: PropTypes.func.isRequired,
    confirmHandler: PropTypes.func.isRequired
};

export default ConfirmModalComponent;
