import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";

import { AUTH_TOKEN, REFRESH_TOKEN, getApiUrl, getLocale } from "../constants/index";

export const apiUrl = getApiUrl();

const httpLink = createUploadLink({
    uri: apiUrl + "graphql"
});

const authLink = setContext((_, { headers }) => {
    const res = getToken;
    return {
        headers: {
            ...headers,
            locale: getLocale(),
            authorization: res.token ? `Bearer ${res.token}` : "",
            refreshToken: res.refreshToken ? `Bearer ${res.refreshToken}` : ""
        }
    };
});

const link = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        return graphQLErrors;
        // removeToken();
        // window.location.reload();
    }
    return networkError;
    // if (graphQLErrors) return { message: graphQLErrors[0].message, statusCode: graphQLErrors[0].statusCode };
    // graphQLErrors.map(({ message, locations, path }) =>
    //     console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    // );
});

const refreshTokenAfterWare = new ApolloLink((operation, forward) => {
    return forward(operation).map(res => {
        let context = operation.getContext();
        let resp = context.response;
        if (resp.headers.get("token") && resp.headers.get("refreshtoken")) {
            saveToken(resp.headers.get("token"), resp.headers.get("refreshtoken"));
        }
        return res;
    });
});

export const saveToken = (token, refreshToken) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const removeToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
};

export const getToken = {
    token: localStorage.getItem(AUTH_TOKEN),
    refreshToken: localStorage.getItem(REFRESH_TOKEN)
};

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore"
    },
    query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
    }
};

export const client = new ApolloClient({
    link: refreshTokenAfterWare.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

export const url_parser = it => {
    let url = "";
    var keyNames = Object.keys(it);
    keyNames.forEach(element => {
        url += `${element}=${it[element]}&`;
    });
    return url;
};
