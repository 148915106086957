import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import clone from "lodash/cloneDeep";

import Select from "../../../components/UI/Select/Select";

import { SearchCountryQuery } from "../../../resolvers/query/base";
import { company, updateCompany } from "../../../store/user/company/company_actions";
import { CreateCompanyMutation, UpdateCompanyMutation } from "./GraphQLActions";
import { showModal } from "../../../store/global/global_actions";
import { useTranslation } from "react-i18next";
import CompanyDisclaimer from "../../../components/UI/Modal/CompanyDisclaimer";
import { useState } from "react";

const NewCompany = props => {
    const { t } = useTranslation();
    const { companyState } = useSelector(s => s);
    const dispatch = useDispatch();
    const companyProps = companyState.singleCompany;
    const updatedProps = companyState.updateCompany;
    const errors = updatedProps.completeErrors;
    const [modal, toggleModal] = useState(false);

    let countries = useQuery(SearchCountryQuery);

    let uploadMainPhotoHandler = e => {
        dispatch(
            updateCompany({
                ...updatedProps,
                imgFile: e.target.files[0],
                img: URL.createObjectURL(e.target.files[0])
            })
        );
    };

    let formChangeHandler = e => {
        let name = e.target.name.split(".");
        let newState = { ...updatedProps };
        switch (name[0]) {
            case "personal":
                newState = {
                    ...newState,
                    personal: { ...get(newState, "personal", {}), [name[1]]: e.target.value }
                };
                break;
            case "private_info":
                newState = {
                    ...newState,
                    private_info: { ...get(newState, "private_info", {}), [name[1]]: e.target.value }
                };
                break;
            case "address":
                newState = {
                    ...newState,
                    address: { ...get(newState, "address", {}), [name[1]]: e.target.value }
                };
                break;
            case "base":
                newState = { ...newState, [name[1]]: e.target.value };
                break;
            default:
                break;
        }
        dispatch(updateCompany(newState));
    };

    let formSubmitHandler = e => {
        e.preventDefault ? e.preventDefault() : (ccce.returnValue = false);

        let isValid = companyState.updateCompany && companyState.updateCompany.isAllValid;
        if (isValid) {
            let vars = clone(updatedProps);

            let country = get(vars, "address.country", false);
            if (country) vars.address.country = parseInt(country);
            let img = get(vars, "imgFile", false);
            img ? (vars.img = img) : delete vars.img;

            // if (companyState.isValid.isValid) {
            if (!props.update) {
                dispatch(CreateCompanyMutation(vars))
                    .then(id =>
                        dispatch(
                            showModal({
                                type: 1,
                                message: t("co_success_created.message"),
                                button: "See it",
                                link: "/dashboard/companies/" + id
                            })
                        )
                    )
                    .catch(err =>
                        dispatch(
                            showModal({
                                type: 1,
                                message: err.message,
                                button: "Ok"
                            })
                        )
                    );
            } else {
                vars.id = parseInt(companyProps.id);
                dispatch(UpdateCompanyMutation(vars))
                    .then(id =>
                        dispatch(
                            showModal({
                                type: 1,
                                message: t("co_success_updated.message"),
                                button: "See it",
                                link: "/dashboard/companies/" + id
                            })
                        )
                    )
                    .catch(err =>
                        dispatch(
                            showModal({
                                type: 1,
                                message: err.message,
                                button: "Ok"
                            })
                        )
                    );
            }
        } else {
            dispatch(company({ isValid: false }));
        }
    };

    /**
     *
     * @param {Event} e
     */
    const onCliclHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        if (props.update) {
            formSubmitHandler(e);
        } else {
            toggleModal(true);
        }
    };

    return (
        <>
            <section className="main-content">
                <div className="container-fluid">
                    <form onSubmit={e => formSubmitHandler(e)}>
                        <div className="training-form">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="main-file-upload excercises-upload">
                                                <div className="file-upload-title">
                                                    <h5>{t("main_image.label")}</h5>
                                                </div>
                                                <div className="file-upload">
                                                    <div className="image-upload-wrap">
                                                        <input
                                                            maxLength={250}
                                                            id="mainImageInput"
                                                            type="file"
                                                            className="file-upload-input"
                                                            accept="image/*"
                                                            onChange={e => uploadMainPhotoHandler(e)}
                                                            // required={
                                                            //     !get(
                                                            //         updatedProps,
                                                            //         "img",
                                                            //         get(companyProps, "img", false)
                                                            //     )
                                                            // }
                                                        />

                                                        {!get(updatedProps, "img", get(companyProps, "img", false)) ? (
                                                            <div className="drag-text">
                                                                <i className="icon-picture"></i>
                                                                <span>{t("dragndrop.label")}</span>
                                                            </div>
                                                        ) : (
                                                            <img
                                                                alt="uploaded img"
                                                                style={{
                                                                    width: "100%",
                                                                    objectFit: "cover",
                                                                    height: "100%"
                                                                }}
                                                                src={
                                                                    (updatedProps,
                                                                    "img",
                                                                    get(
                                                                        updatedProps,
                                                                        "img",
                                                                        get(companyProps, "img", false)
                                                                    ))
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="button-upload">
                                                    <a
                                                        href="#"
                                                        className="btn btn-upload"
                                                        onClick={e => {
                                                            e.preventDefault
                                                                ? e.preventDefault()
                                                                : (e.returnValue = false);
                                                            document.getElementById("mainImageInput").click();
                                                        }}
                                                    >
                                                        {t("upload_img.label")}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("name.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "personal.name",
                                                                get(companyProps, "personal.name", "")
                                                            )}
                                                            type="text"
                                                            name="personal.name"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                            required
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "name", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("email.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "email",
                                                                get(companyProps, "email", "")
                                                            )}
                                                            required
                                                            type="email"
                                                            name="base.email"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "email", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("street.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "address.street",
                                                                get(companyProps, "address.street", "")
                                                            )}
                                                            type="text"
                                                            required
                                                            name="address.street"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "street", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("postal.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "address.postal",
                                                                get(companyProps, "address.postal", "")
                                                            )}
                                                            required
                                                            type="text"
                                                            name="address.postal"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "postal", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("city.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "address.city",
                                                                get(companyProps, "address.city", "")
                                                            )}
                                                            required
                                                            type="text"
                                                            name="address.city"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "city", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("country.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <Select
                                                            items={get(countries, "data.searchCountry", [])}
                                                            selectedItem={
                                                                get(updatedProps, "address.country") ||
                                                                get(companyProps, "address.country.id", "")
                                                            }
                                                            text={"Select Country"}
                                                            required
                                                            name="address.country"
                                                            select={e =>
                                                                formChangeHandler({
                                                                    target: { name: "address.country", value: e.id }
                                                                })
                                                            }
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "country", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t("website.label")}</label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "private_info.website",
                                                                get(companyProps, "private_info.website", "")
                                                            )}
                                                            type="text"
                                                            name="private_info.website"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "website", "")}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{t("phone.label")}</label>
                                                        <input
                                                            maxLength={250}
                                                            defaultValue={get(
                                                                updatedProps,
                                                                "personal.phoneNumber",
                                                                get(companyProps, "personal.phoneNumber", "")
                                                            )}
                                                            type="text"
                                                            name="personal.phoneNumber"
                                                            className="form-control"
                                                            onChange={e => formChangeHandler(e)}
                                                        />
                                                        {companyState.isValid ? null : (
                                                            <p style={{ color: "red" }}>
                                                                {get(errors, "phoneNumber", "")}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <div className="btn-right">
                                                <Link
                                                    to={
                                                        !props.update
                                                            ? "/dashboard/companies"
                                                            : "/dashboard/companies/" + companyProps.id
                                                    }
                                                    className="btn btn-cancel btn-180"
                                                >
                                                    {t("cancel.label")}
                                                </Link>
                                                <button
                                                    to="#"
                                                    className="btn btn-primary btn-180"
                                                    data-toggle="modal"
                                                    data-target="#modal"
                                                    type="submit"
                                                    onClick={onCliclHandler}
                                                >
                                                    {!props.update ? t("create.label") : t("update.label")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <CompanyDisclaimer
                        show={modal}
                        close={() => toggleModal(false)}
                        action={e => {
                            toggleModal(false);
                            formSubmitHandler(e);
                        }}
                    />

                    {/* <Modal
                        show={companyState.modal}
                        loading={companyState.loading}
                        error={companyState.error}
                        text={`Company ${!props.update ? "created" : "updated"} successfully.`}
                        history={props.history}
                        path={"companies"}
                        id={companyProps.id}
                        item={"company"}
                        closeModal={() => dispatch(company({ modal: false }))}
                    /> */}
                </div>
            </section>
        </>
    );
};

NewCompany.propTypes = {
    update: PropTypes.bool,
    history: PropTypes.object
};

export default NewCompany;
