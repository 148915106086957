import React from "react";
import PropTypes from "prop-types";

import Select from "../UI/Select/Select";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const MeasurementsQuestion = ({ question, changeHandler, remove, units }) => {
    const { t } = useTranslation();

    return (
        <div className="col-md-6">
            <div className="form-group form-question">
                <a onClick={e => remove(e)} href="#" className="remove-item">
                    {t("remove.label")} <i className="icon-cancel"></i>
                </a>
                <input
                    onChange={e => changeHandler(e)}
                    type="text"
                    defaultValue={question.content}
                    placeholder={t("write_your_question.label")}
                    name="content"
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <Select
                    selectedItem={get(question, "unit.id", "").toString()}
                    text={t("select_unit.label")}
                    items={units}
                    select={e => {
                        console.log({ e });
                        changeHandler({ target: { name: "unit", value: e } });
                    }}
                />
            </div>
        </div>
    );
};

MeasurementsQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    changeHandler: PropTypes.func,
    remove: PropTypes.func,
    units: PropTypes.array.isRequired
};

export default MeasurementsQuestion;
