import validator from "validator";
import get from "lodash/get";
// const validator = require("validator");
// const { get } = require("lodash");

export const stateValidator = (state, rules) => {
    let errors = {};
    let valid = true;

    rules.map(rule => {
        //get value of the field
        let fieldValue = get(state, rule.field);
        //get type of the field
        let variableType = rule.variableType;
        //get rules for the field
        let rules = rule.rules;
        //check if has value
        let result;
        let values = [];

        if (fieldValue) {
            //test for the type of the value
            switch (variableType[0]) {
                case "array":
                    if (fieldValue.length > 0) {
                        //in case of array map through values
                        fieldValue.map(value => {
                            if (value) {
                                //Test to see if the values of array are objects so we can take the id
                                switch (variableType[1]) {
                                    case "object":
                                        value = value.id || "";
                                        break;
                                    case "motion":
                                        value = value.motion || "";
                                        break;
                                }
                                //add values to array
                                values.push(value);
                            }
                        });
                    } else {
                        valid = false;
                        // errors.push({ name: rule.field, message: "This field is required" });
                        errors[rule.field] = "This field is required";
                    }
                    break;
                case "object":
                    //in case of object get the id
                    values = [(fieldValue && fieldValue.id) || ""];
                    break;
                default:
                    values = [fieldValue || ""];
                    break;
            }

            //Call the method to test each rule for each value
            result = validatorMethod(rules, values, rule.field, rule.messages);
            //if invalid change global valid to false and add erros
            if (!result.valid) {
                valid = false;
                errors = { ...errors, ...result.errors };
            }

            //if value of the field is null and is required
        } else if (!fieldValue && rule.required) {
            valid = false;
            // errors.push({ name: rule.field, message: "This field is required" });
            errors[rule.field] = "This field is required";
        }
    });

    return { isValid: valid, errors };
};

/**
 * 
 * @param {Array} rules | Array of rules. ex: rules: [{name: "isEmail", params: {}, validWhen: true}, {name: "isNull", params: {}, validWhen: false}],  messages: ["Provide a valid email", "Email cant be null"], required: true,
    variableType: ["array", "Object", "Int"]}]
 * @param {Array} values | Array of values to be tested
 * @param {String} fieldName | Name of the field to test
 * @param {Array} messages | Array of messages for each rule in case of invalidity
 */
let validatorMethod = (rules, values, fieldName, messages) => {
    let valid = true;
    let errors = {};
    //For each value test the rules
    values.map(value => {
        //For each rule test the validity
        rules.map((e, index) => {
            let method = validator[e.name];
            value = e.name === "isInt" || e.name === "isEmpty" ? value + "" : value;
            //test if valid. If not change global valid var to false
            if (!(method(value, e.params) === e.validWhen)) {
                valid = false;
                //add the error to the global error object
                // errors.push({ name: fieldName, message: messages[index] });
                errors[fieldName] = messages[index];
            }
        });
    });

    return { valid, errors };
};
