import React, { useState, Fragment } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ChangePasswordMutation } from "./GraphQLActions";
import { auth } from "../../../store/auth/auth_actions";

const Password = () => {
    const { t } = useTranslation();
    let authState = useSelector(s => s.authState);
    let dispatch = useDispatch();
    let [state, setState] = useState({
        currentPassword: "",
        newPassword: "",
        confrimNewPassword: "",
        regEx: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+-.,#^()<>_+-])[A-Za-z\d@$!%*?&+-.,#^()<>_+-]{8,50}$/,
        loading: false,
        modal: false,
        error: null
    });
    /**
     * @param {Event} e - The event
     */
    let onChangeForm = e => {
        if (e.target.name) {
            setState({ ...state, [e.target.name]: e.target.value });
        }
    };

    /**
     * @param {Event} e - The event
     */
    let submitFormHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        if (!state.currentPassword) {
            setState({ ...state, error: [t("pa_error_1.message")] });
            return;
        }
        if (state.newPassword !== state.confrimNewPassword) {
            setState({ ...state, error: [t("pa_error_2.message")] });
            return;
        }
        if (!state.regEx.test(state.newPassword)) {
            setState({ ...state, error: [t("pa_error_3.message")] });
            return;
        }

        dispatch(
            ChangePasswordMutation({
                oldPassword: state.currentPassword,
                newPassword: state.newPassword,
                confrimNewPassword: state.confrimNewPassword
            })
        );
        setState({ currentPassword: "", newPassword: "", confrimNewPassword: "" });
    };

    return (
        <Fragment>
            <div className="col-md-9">
                <div className="settings-form">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("old_password.label")}</label>
                                        <input
                                            maxLength={250}
                                            value={state.currentPassword}
                                            onChange={e => onChangeForm(e)}
                                            type="password"
                                            name="currentPassword"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("new_password.label")}</label>
                                        <input
                                            maxLength={250}
                                            value={state.newPassword}
                                            onChange={e => onChangeForm(e)}
                                            type="password"
                                            name="newPassword"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("confirm_password.label")}</label>
                                        <input
                                            maxLength={250}
                                            value={state.confrimNewPassword}
                                            onChange={e => onChangeForm(e)}
                                            type="password"
                                            name="confrimNewPassword"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {state.newPassword && !state.regEx.test(state.newPassword) ? (
                                        <p style={{ color: "red" }}>{t("pa_error_3.message")}</p>
                                    ) : null}
                                </div>
                                <div className="col-md-12">
                                    {state.confrimNewPassword && state.confrimNewPassword !== state.newPassword ? (
                                        <p style={{ color: "red" }}>{t("pa_error_2.message")}</p>
                                    ) : null}
                                </div>
                                <div className="col-md-12 d-flex justify-content-end">
                                    <div className="settings-button">
                                        <button
                                            onClick={e => submitFormHandler(e)}
                                            data-toggle="modal"
                                            data-target="#modal"
                                            disabled={
                                                !state.currentPassword ||
                                                !state.regEx.test(state.newPassword) ||
                                                state.confrimNewPassword !== state.newPassword
                                            }
                                            className="btn btn-primary btn-180"
                                        >
                                            {t("update.label")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={authState.modal}
                closeModal={() => dispatch(auth({ modal: false }))}
                text={t("pa_success_updated.message")}
                oneButton
            />
        </Fragment>
    );
};

export default Password;
