import { stateValidator } from "../../../helpers/validator";
import {
    AdministrationInfoRules,
    PersonalInfoRules,
    AddressInfoRules,
    PrivateInfoRules,
    WorkInfoRules,
    SportInfoRules,
    SportSpecialistInfoRules,
    DiagnoseInfoRules,
    MedicineInfoRules,
    MedExpertInfoRules,
    ProblemInfoRules,
    FinanceInfoRules,
    AHVInfoRules,
    BaseInfoRules
} from "./validation-rules";

export let validateState = (state, isUpdate) => {
    let newState = { ...state };

    let isValidBase = stateValidator(
        newState,
        BaseInfoRules(window.location.pathname.includes("complete-registration") ? true : false)
    );
    let isValidAdministration = stateValidator(newState.administration, AdministrationInfoRules(isUpdate));
    let isValidPersonalInfo = stateValidator(newState.personal, PersonalInfoRules(isUpdate));
    let isValidPrivateInfo = stateValidator(newState.private_info, PrivateInfoRules(isUpdate));
    let isValidSport = stateValidator(newState.user_sport, SportInfoRules(isUpdate));
    let isValidSportSpecialist = stateValidator(newState.sport_specialist, SportSpecialistInfoRules(isUpdate));
    let isValidFinance = stateValidator(newState.finance, FinanceInfoRules(isUpdate));
    let isValidAHV = stateValidator(newState.ahv, AHVInfoRules(isUpdate));
    let isValidAddress = stateValidator(newState.address, AddressInfoRules(isUpdate));
    let isValidWorkInfo = stateValidator(newState.work, WorkInfoRules(isUpdate));
    let isValidMedicineInfo = stateValidator(newState.medicine, MedicineInfoRules(isUpdate));
    let isValidMedExpertInfo = stateValidator(newState.med_expert, MedExpertInfoRules(isUpdate));
    let isValidProblemInfo = stateValidator(newState.problem, ProblemInfoRules(isUpdate));
    let isValidDiagnoseInfo = stateValidator(newState.diagnose, DiagnoseInfoRules(isUpdate));

    let isAllValid =
        isValidBase.isValid &&
        isValidAdministration.isValid &&
        isValidPersonalInfo.isValid &&
        isValidPrivateInfo.isValid &&
        isValidSport.isValid &&
        isValidSportSpecialist.isValid &&
        isValidFinance.isValid &&
        isValidAHV.isValid &&
        isValidAddress.isValid &&
        isValidWorkInfo.isValid &&
        isValidMedicineInfo.isValid &&
        isValidMedExpertInfo.isValid &&
        isValidProblemInfo.isValid &&
        isValidDiagnoseInfo.isValid;

    let completeErrors = {
        ...isValidBase.errors,
        ...isValidAdministration.errors,
        ...isValidPersonalInfo.errors,
        ...isValidPrivateInfo.errors,
        ...isValidSport.errors,
        ...isValidSportSpecialist.errors,
        ...isValidFinance.errors,
        ...isValidAHV.errors,
        ...isValidAddress.errors,
        ...isValidWorkInfo.errors,
        ...isValidMedicineInfo.errors,
        ...isValidMedExpertInfo.errors,
        ...isValidProblemInfo.errors,
        ...isValidDiagnoseInfo.errors
    };

    newState = {
        ...newState,
        completeErrors,
        isAllValid,
        administration: { ...newState.administration, ...isValidAdministration, isValidBase },
        personal: { ...newState.personal, ...isValidPersonalInfo },
        private_info: { ...newState.private_info, ...isValidPrivateInfo },
        address: { ...newState.address, ...isValidAddress },
        user_sport: { ...newState.user_sport, ...isValidSport },
        sport_specialist: { ...newState.sport_specialist, ...isValidSportSpecialist },
        finance: { ...newState.finance, ...isValidFinance },
        ahv: { ...newState.ahv, ...isValidAHV },
        work: { ...newState.work, ...isValidWorkInfo },
        medicine: { ...newState.medicine, ...isValidMedicineInfo },
        med_expert: { ...newState.med_expert, ...isValidMedExpertInfo },
        problem: { ...newState.problem, ...isValidProblemInfo },
        diagnose: { ...newState.diagnose, ...isValidDiagnoseInfo }
    };

    return newState;
};
