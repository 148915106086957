import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import exerciseReducers from "./exercise/exerciseState";
import companyReducers from "./user/company/companyState";
import trainerReducers from "./user/trainer/trainerState";
import userReducers from "./user/user/userState";
import trainingplanReducers from "./trainingplans/trainingplanState";
import testReducers from "./test/testState";
import reportReducers from "./reports/reportState";
import authReducer from "./auth/authState";
import globalReducer from "./global/globalStore";

const rootReducers = combineReducers({
    authState: authReducer,
    globalState: globalReducer,
    exerciseState: exerciseReducers,
    companyState: companyReducers,
    trainerState: trainerReducers,
    userState: userReducers,
    planState: trainingplanReducers,
    testState: testReducers,
    reportState: reportReducers
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducers, composeEnhancers(applyMiddleware(thunk)));
