import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Loading } from "../../../components/UI";

import Password from "./Password";
import Company from "./Company";
import Profile from "./Profile";
import Language from "./Language";

const SettingsRoutes = () => {
    const { t } = useTranslation();
    let authState = useSelector(s => s.authState);
    let globalState = useSelector(s => s.globalState);

    const history = useHistory();
    useEffect(() => {
        let location = history.location.pathname.split("/");
        if (location[location.length - 1] === "settings") {
            history.push("/dashboard/settings/profile");
        }
    }, [history]);

    let match = useRouteMatch();
    return (
        <>
            <Loading loading={globalState.loading} />
            <section className="main-content">
                <div className="container-fluid">
                    <form>
                        <div className="settings">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="settings-nav">
                                        <ul>
                                            <li>
                                                <NavLink to={match.url + "/profile"}>{t("edit_profile.label")}</NavLink>
                                            </li>
                                            {authState.role.id === 3 && authState.is_admin ? (
                                                <li>
                                                    <NavLink to={match.url + "/company"}>{t("company.label")}</NavLink>
                                                </li>
                                            ) : null}
                                            <li>
                                                <NavLink to={match.url + "/change-password"}>
                                                    {t("change_password.label")}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={match.url + "/language"}>{t("language.label")}</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <Switch>
                                    <Route path="/dashboard/settings/language" component={Language} />
                                    <Route path="/dashboard/settings/change-password" component={Password} />
                                    <Route path="/dashboard/settings/company" component={Company} />
                                    <Route path="/dashboard/settings" component={Profile} />
                                </Switch>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default SettingsRoutes;
