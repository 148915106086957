import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { testFilter, test } from "../../../store/test/test_actions";
import { searchBaseModels } from "../../../resolvers/query/base";

import AllTests from "./allTests";
import Search from "../../../components/UI/Search/Search";
import Select from "../../../components/UI/Select/Select";
import { useTranslation } from "react-i18next";

const Test = () => {
    const { t } = useTranslation();
    const { testState } = useSelector(s => s);
    const dispatch = useDispatch();
    const filters = testState.filters;

    const { data, loading, error } = useQuery(searchBaseModels, {
        variables: {
            difficulty: true,
            company: "test"
        }
    });

    const BaseModels = () => {
        if (error) return `Error! ${error}`;
        if (loading)
            return (
                <div className="row justify-content-end">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <Select select={difficultySelectHandler} text={t("filter_difficulty.placeholder")} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <Select select={companySelectHandler} text={t("filter_library.placeholder")} />
                    </div>
                </div>
            );
        return (
            <div className="row justify-content-end">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <Select
                        text={t("filter_difficulty.placeholder")}
                        select={difficultySelectHandler || ""}
                        selectedItem={filters.difficulty.id || null}
                        items={data.searchBaseModels.Difficulty}
                    />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <Select
                        text={t("filter_library.placeholder")}
                        selectedItem={filters.creator.id || null}
                        select={companySelectHandler}
                        items={data.searchBaseModels.Company}
                    />
                </div>
            </div>
        );
    };

    let searchHandler = e => {
        dispatch(testFilter({ search: e.target.value }));
    };

    let difficultySelectHandler = e => {
        dispatch(testFilter({ difficulty: e }));
    };

    let companySelectHandler = e => {
        dispatch(testFilter({ creator: e }));
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        <div className="training-result">
                            <p>
                                Total:
                                <span>
                                    {testState.totalTests || 0} {t("tests.label")}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="create-icon">
                            <Link to="/dashboard/tests/new-test/general-info">
                                <i className="icon-add"></i>
                                <span>{t("create_test.label")}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-between training-filter">
                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-3">
                        <div className="row">
                            <Search searchHandler={searchHandler} querySearch={filters.search} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-7 col-xl-9">{BaseModels()}</div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row excercises">
                    <AllTests />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        {testState.totalTests > 0 ? (
                            <ReactPaginate
                                previousLabel={<i className="icon-arrow-left"></i>}
                                nextLabel={<i className="icon-arrow-right"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(testState.totalTests / 8) || 0}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={e => {
                                    dispatch(
                                        testFilter({
                                            offset: e.selected * 8,
                                            page: parseInt(e.selected) + 1
                                        })
                                    );
                                }}
                                forcePage={filters.page - 1}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                activeLinkClassName={"active"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

Test.propTypes = {
    history: PropTypes.object
};

export default Test;
