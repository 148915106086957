import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect, useSelector } from "react-redux";

// import "../../assets/css/style.min.css";

import ErrorBoundary from "../errors/ErrorBoundary";
//Static Components
import Header from "../static/Header";
import SideBar from "../static/SideBar";
//Routes
import Home from "./Home";
import ExerciseRoutes from "./Exercise/Routes";
import CompanyRoutes from "./Company/Routes";
import ReportRoutes from "./Reports/Routes";
import TestRoutes from "./Test/Routes";
import TrainerRoutes from "./Trainer/Routes";
import TrainingplanRoutes from "./Trainingplan/Routes";
import UserRoutes from "./User/Routes";
import SettingsRoutes from "./Settings/Routes";
import CategoriesRoutes from "./Categories/Routes";
import NotFound from "../../components/UI/NotFound/NotFound";

const Layout = () => {
    const authState = useSelector(s => s.authState);
    return (
        <section className="main">
            <Header />
            <SideBar />
            <ErrorBoundary>
                {/* <Loading loading={globalState.loading} /> */}
                <Switch>
                    <Route path="/dashboard/tests/" component={TestRoutes} />

                    <Route path="/dashboard/trainingplans/" component={TrainingplanRoutes} />

                    <Route path="/dashboard/exercises/" component={ExerciseRoutes} />

                    <Route path="/dashboard/users/" component={UserRoutes} />

                    <Route
                        path="/dashboard/reports/"
                        component={
                            parseInt(authState.role.id) < 3 || (authState.role.id === 3 && authState.is_admin)
                                ? ReportRoutes
                                : NotFound
                        }
                    />

                    <Route
                        path="/dashboard/companies/"
                        component={parseInt(authState.role.id) > 1 ? NotFound : CompanyRoutes}
                    />

                    <Route
                        path="/dashboard/trainers/"
                        component={
                            parseInt(authState.role.id) < 3 || (authState.role.id === 3 && authState.is_admin)
                                ? TrainerRoutes
                                : NotFound
                        }
                    />

                    <Route
                        path="/dashboard/categories/"
                        component={parseInt(authState.role.id) !== 1 ? NotFound : CategoriesRoutes}
                    />
                    <Route path="/dashboard/settings/" component={SettingsRoutes} />

                    <Route exact path="/dashboard/" component={Home} />
                </Switch>
            </ErrorBoundary>
        </section>
    );
};

const mapStateToProps = state => ({
    authState: state.authState
});

export default connect(mapStateToProps)(Layout);
