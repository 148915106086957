import React from "react";
import { Switch, Route } from "react-router";

import NewExercise from "./NewExercise";
import FetchExercise from "./FetchExercise";
import Exercise from "./index";
import Loading from "../../../components/UI/Loading";
import { useSelector } from "react-redux";

const ExerciseRoutes = () => {
    const globalState = useSelector(s => s.globalState);

    return (
        <>
            <Loading loading={globalState.loading} />
            <Switch>
                <Route path="/dashboard/exercises/new-exercise" component={NewExercise} />
                <Route path="/dashboard/exercises/:exerciseId(\d+)/edit-exercise" component={FetchExercise} />
                <Route path="/dashboard/exercises/:exerciseId(\d+)">
                    <FetchExercise fetch />
                </Route>
                <Route path="/dashboard/exercises/" component={Exercise} />
            </Switch>
        </>
    );
};

export default ExerciseRoutes;
