import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import ContentModal from "../../../components/UI/Modal/ContentModal";
import { Loading, Search } from "../../../components/UI/";

import {
    FetchAllClientsQuery,
    FetchAllTrainingPlansQuery,
    SubscribeClientsToPlan,
    SubscribeClientsToTest,
    FetchAllTestsQuery
} from "./GraphQLActions";
import { showModal } from "../../../store/global/global_actions";
import { useSelector } from "react-redux";
import get from "lodash/get";

const ContentModalMiddlewareComponent = props => {
    const globalState = useSelector(s => s.globalState);
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const [, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [records, setRecords] = useState({
        total: 0,
        records: []
    });

    useEffect(() => {
        if (props.show) {
            switch (props.content) {
                case "USER_LIST":
                    dispatch(
                        FetchAllClientsQuery({
                            search: search,
                            limit: 10000
                        })
                    )
                        .then(result => {
                            setRecords({
                                total: result.totalCount,
                                records: result.allUsers
                            });
                            setLoading(false);
                        })
                        .catch(err => setErrors(err));
                    break;
                case "PLAN_LIST":
                    dispatch(
                        FetchAllTrainingPlansQuery({
                            search: search,
                            limit: 10000
                        })
                    )
                        .then(result => {
                            setRecords({
                                total: result.totalCount,
                                records: result.allPlans
                            });
                            setLoading(false);
                        })
                        .catch(err => setErrors(err));
                    break;
                case "TEST_LIST":
                    dispatch(
                        FetchAllTestsQuery({
                            search: search,
                            limit: 10000
                        })
                    )
                        .then(result => {
                            setRecords({
                                total: result.totalCount,
                                records: result.allTests
                            });
                            setLoading(false);
                        })
                        .catch(err => setErrors(err));
                    break;
            }

            //On Unmount
            return () => {
                setErrors([]);
                setRecords({ total: 0, records: [] });
                setLoading(true);
            };
        }
    }, [dispatch, props.content, props.show, search]);

    let selectUserModal = (id, selected) => {
        let selectedUsers = [...state];
        if (!selected) {
            selectedUsers.push(parseInt(id));
        } else {
            selectedUsers = selectedUsers.filter(user => user !== parseInt(id));
        }
        setState(selectedUsers);
    };

    let submitHandler = () => {
        setLoading(true);
        switch (props.parent) {
            case "PLAN":
                dispatch(
                    SubscribeClientsToPlan({
                        userIds: state,
                        planIds: [props.parentId]
                    })
                )
                    .then(() => {
                        setLoading(false);
                        dispatch(
                            showModal({
                                message: "Plan succesfully assigned to clients.",
                                button: "Ok.",
                                type: 1,
                                link: get(globalState, "modal.link")
                            })
                        );
                        props.closeModal();
                    })
                    .catch(err => setErrors(err));
                break;
            case "TEST":
                dispatch(
                    SubscribeClientsToTest({
                        userIds: state,
                        testIds: [props.parentId]
                    })
                )
                    .then(() => {
                        setLoading(false);
                        dispatch(
                            showModal({
                                message: "Test succesfully assigned to clients.",
                                button: "Ok.",
                                type: 1,
                                link: get(globalState, "modal.link")
                            })
                        );
                        props.closeModal();
                    })
                    .catch(err => setErrors(err));
                break;
            case "CLIENT":
                switch (props.content) {
                    case "PLAN_LIST":
                        dispatch(
                            SubscribeClientsToPlan({
                                userIds: [props.parentId],
                                planIds: state,
                                parentId: props.parentId
                            })
                        )
                            .then(() => {
                                setLoading(false);
                                dispatch(
                                    showModal({
                                        message: "Plans succesfully assigned to client.",
                                        button: "Ok.",
                                        type: 1,
                                        link: get(globalState, "modal.link")
                                    })
                                );
                                props.closeModal();
                            })
                            .catch(err => setErrors(err));
                        break;
                    case "TEST_LIST":
                        dispatch(
                            SubscribeClientsToTest({
                                testIds: state,
                                userIds: [props.parentId],
                                parentId: props.parentId
                            })
                        )
                            .then(() => {
                                setLoading(false);
                                dispatch(
                                    showModal({
                                        message: "Tests succesfully assigned to client.",
                                        button: "Ok.",
                                        type: 1,
                                        link: get(globalState, "modal.link")
                                    })
                                );
                                props.closeModal();
                            })
                            .catch(err => setErrors(err));
                        break;
                }
        }
        setState([]);
        setSearch("");
    };

    return (
        <Modal
            visible={props.show}
            dialogClassName="modal-dialog modal-xl modal-dialog-centered"
            onClick={() => props.closeModal()}
            className="custom-modal"
        >
            <div>
                <div className="row custom-modal-header">
                    <div className="col-4 modal-flex-start">Add for client</div>
                    <div className="col-4 modal-flex-center">Selected: {state.length}</div>
                    <div className="col-4 modal-flex-end">
                        <Search
                            searchHandler={e => {
                                setSearch(e.target.value);
                            }}
                            querySearch={search}
                        />
                    </div>
                </div>

                <div className="container custom-modal-table">
                    <Loading loading={isLoading} contentModal />
                    <ContentModal  
                        content={props.content}
                        records={records.records}
                        selectUserModal={selectUserModal}
                        selected={state}
                        show={props.show}
                    />
                </div>
                <div className="row custom-modal-button">
                    <div className="col-6">
                        <button onClick={submitHandler} className="btn btn-primary btn-180">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ContentModalMiddlewareComponent.propTypes = {
    history: PropTypes.object,
    content: PropTypes.string,
    parent: PropTypes.string,
    parentId: PropTypes.number,
    show: PropTypes.bool,
    isMutating: PropTypes.bool,
    closeModal: PropTypes.func
};

export default withRouter(ContentModalMiddlewareComponent);
