import React, { Component, Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const CustomClearText = () => "";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div className="wal-arrow-down">{children}</div>
    </div>
  );
};

ClearIndicator.propTypes = {
  children: PropTypes.any,
  getStyles: PropTypes.func,
  innerProps: PropTypes.any,
};

const SearchSelect = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getItemsAsync("");
  }, []);

  const fetchItems = (searchValue = "", cb) => {
    let { searchKey } = props;

    let filters = { ...props.filters };

    filters[searchKey] = searchValue;

    // props.getData(filters, cbItems => cb && cb(setUpOptions(cbItems)));
    props
      .getData(filters)
      .then((res) => {
        console.log("RES", { res });
        let options = setUpOptions(res);
        cb && cb(options);
      })
      .catch((err) => {
        console.log("ERR", { err });
      });
  };

  const getItemsAsync = (searchValue, cb) => {
    if (props.filterable) {
      fetchItems(searchValue, cb);
    }
  };

  const onItemSelected = (options) => {
    if (Array.isArray(options)) {
      props.onChange({
        name: props.name,
        value: options.map((option) => ({
          ...option,
          id: option.value,
          [props.optionKey]: option.label,
        })),
      });
    } else {
      let option = options || {};
      props.onChange({
        name: props.name,
        value: option.value
          ? { ...option, id: option.value, [props.optionKey]: option.label }
          : {},
      });
    }
  };

  const formatOptionLabel = (opt) =>
    props.formatLabels ? props.formatLabels(opt) : opt.label;

  const setUpOptions = (options) => {
    let { optionKey } = props;

    options = options.map((option) => ({
      ...option,
      value: option.id,
      label: option[optionKey],
    }));

    setOptions(options);
    return options;
  };

  const setUpValues = (value) => {
    let { optionKey } = props;

    let options;

    if (Array.isArray(value)) {
      options = value.map((val) => ({
        ...val,
        value: val.id,
        label: val[optionKey],
      }));
    } else {
      options = { ...value, value: value.id, label: value[optionKey] };
    }

    return options;
  };

  return (
    <AsyncSelect
      components={{ ClearIndicator }}
      defaultOptions={options}
      value={setUpValues(props.value)}
      formatOptionLabel={formatOptionLabel}
      loadOptions={getItemsAsync}
      onChange={onItemSelected}
      isMulti={props.isMulti}
      backspaceRemovesValue
      isClearable={true}
      onInputChange={(newValue, actionMeta) => {
        if (actionMeta.action === "input-change" && newValue === "") {
          return getItemsAsync(newValue);
        }
      }}
      isDisabled={props.isDisabled}
      // controlShouldRenderValue={!props.hideSelected}
      cacheOptions
    />
  );
};

SearchSelect.propTypes = {
  getData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formatLabels: PropTypes.func,
  name: PropTypes.string.isRequired,
  searchKey: PropTypes.string,
  filters: PropTypes.object,
  filterable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hideSelected: PropTypes.bool,
  optionKey: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

SearchSelect.defaultProps = {
  searchKey: "name",
  optionKey: "name",
  filters: {},
  isMulti: false,
  isDisabled: false,
  hideSelected: true,
  filterable: true,
};

// class SearchSelectqw extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             options: [],
//             value: []
//         };
//         this.getItemsAsync = this.getItemsAsync.bind(this);
//         this.onItemSelected = this.onItemSelected.bind(this);
//         this.formatOptionLabel = this.formatOptionLabel.bind(this);
//     }

//     componentDidMount() {
//         if (!this.props.isDisabled) this.fetchItems();
//         this.setInitialSelected();
//     }

//     componentDidUpdate(prevProps, prevState) {
//         if (prevProps.options !== this.props.options) {
//             console.log("New Options", { options: this.props.options });
//             this.setUpOptions(this.props.options);
//         }

//         if (!isEqual(prevProps.filters, this.props.filters)) {
//             console.log("test: " + this.props.name, { prev: prevProps.filters, this: this.props.filters });
//             this.fetchItems();
//         }

//         if (prevProps.value !== this.props.value) {
//             this.setInitialSelected();
//         }

//         if (prevProps.isDisabled !== this.props.isDisabled) {
//             this.fetchItems();
//         }

//         if (prevProps.getData !== this.props.getData) {
//             this.fetchItems();
//         }
//     }

//     setUpOptions(options) {
//         let { optionKey } = this.props;

//         options = options.map(option => ({
//             ...option,
//             value: option.id,
//             label: option[optionKey]
//         }));

//         this.setState({ options });
//         return options;
//     }

//     setInitialSelected() {
//         let value = this.props.value;

//         if (Array.isArray(value)) {
//             value = value.map(val => ({ value: val.id, label: val.name, company: val.company }));
//         } else {
//             value = value ? [{ value: value.id, label: value.name, company: value.company }] : [];
//         }

//         this.setState({ value });
//     }

//     fetchItems(searchValue = "", cb) {
//         let { filterKey } = this.props;

//         let filters = { ...this.props.filters };

//         filters[filterKey] = searchValue;

//         console.log({ filters });

//         this.props.getData(filters, cbItems => cb && cb(this.setUpOptions(cbItems)));
//     }

//     getItemsAsync(searchValue, cb) {
//         console.log({ searchValue });
//         if (!this.props.noFilter) {
//             this.fetchItems(searchValue, cb);
//         }
//     }

//     onItemSelected(options) {
//         if (Array.isArray(options)) {
//             this.props.onChange({
//                 target: {
//                     name: this.props.name,
//                     value: options.map(option => ({
//                         ...option,
//                         id: option.value,
//                         [this.props.optionKey]: option.label
//                     }))
//                 }
//             });
//         } else {
//             let option = options || {};
//             this.props.onChange({
//                 target: {
//                     name: this.props.name,
//                     value: option.value ? { ...option, id: option.value, [this.props.optionKey]: option.label } : {}
//                 }
//             });
//         }
//     }

//     formatOptionLabel(opt) {
//         if (opt.company) {
//             return (
//                 <Fragment>
//                     {opt.label} <span style={{ color: "red" }}>({opt.company.name})</span>
//                 </Fragment>
//             );
//         } else {
//             return opt.label;
//         }
//     }

//     render() {
//         return (
//             <AsyncSelect
//                 components={{ ClearIndicator }}
//                 defaultOptions={this.state.options}
//                 value={this.state.value}
//                 formatOptionLabel={this.formatOptionLabel}
//                 loadOptions={this.getItemsAsync}
//                 onChange={this.onItemSelected}
//                 isMulti={this.props.isMulti}
//                 backspaceRemovesValue
//                 isClearable={true}
//                 onInputChange={(newValue, actionMeta) => {
//                     if (actionMeta.action === "input-change" && newValue === "") {
//                         return this.getItemsAsync(newValue);
//                     }
//                 }}
//                 isDisabled={this.props.isDisabled}
//                 controlShouldRenderValue={!this.props.hideSelected}
//                 cacheOptions
//             />
//         );
//     }
// }

export default SearchSelect;
