import get from "lodash/get";

import { CreateClientMutation, UpdateClientMutation } from "./GraphQLActions";
// import { stateValidator } from "../../../helpers/validator";
// import {
//     AdministrationInfoRules,
//     PersonalInfoRules,
//     AddressInfoRules,
//     PrivateInfoRules,
//     WorkInfoRules,
//     SportInfoRules,
//     SportSpecialistInfoRules,
//     DiagnoseInfoRules,
//     MedicineInfoRules,
//     MedExpertInfoRules,
//     ProblemInfoRules,
//     FinanceInfoRules,
//     AHVInfoRules
// } from "./validation-rules";

/**
 *
 * @param {Object} state
 * @param {Function} dispatch
 * @param {Number} userId
 * @param {Boolean} isUpdate
 */
export const submitFormHandler = (state, dispatch, userId, isUpdate, toggleModal) => {
    if (state.isAllValid) {
        let variables = {
            ...state,
            email: get(state, "personal.email", null),
            trainerId: get(state, "trainer.id", null),
            companyId: get(state, "trainer.company.id", null),
            administration: {
                ...get(state, "administration", {}),
                people_group_id: parseInt(get(state, "administration.people_group.id", null)),
                status_id: parseInt(get(state, "administration.status.id", null)),
                contact_channel_id: parseInt(get(state, "administration.contact_channel.id", null)),
                VIP: get(state, "administration.VIP", "false") === "true" ? true : false
            },
            personal: {
                ...get(state, "personal", {}),
                gender: parseInt(get(state, "personal.gender.id", null)),
                nationality: parseInt(get(state, "personal.nationality.id", null))
            },
            address: {
                ...get(state, "address", {}),
                country: parseInt(get(state, "address.country.id", null)),
                canton: parseInt(get(state, "address.canton.id", null))
            },
            private_info: {
                ...get(state, "private_info", {}),
                number_of_children: parseInt(get(state, "private_info.number_of_children.id", null)),
                title: parseInt(get(state, "private_info.title.id", null)),
                titleAddition: parseInt(get(state, "private_info.additional_title.id", null)),
                civil_status_id: parseInt(get(state, "private_info.civil_status.id", null))
            },
            finance: {
                ...get(state, "finance", {}),
                bank_country: parseInt(get(state, "finance.bank_country.id", null))
            },
            ahv: { ...get(state, "ahv", {}) },
            user_sport: {
                ...get(state, "user_sport", {}),
                SOCard_id: parseInt(get(state, "user_sport.SOCard.id", null)),
                training_frequency_id: parseInt(get(state, "user_sport.training_frequency.id", null)),
                training_status_id: parseInt(get(state, "user_sport.training_status.id", null)),
                sport_id: parseInt(get(state, "user_sport.sport.id", null)),
                status_id: parseInt(get(state, "user_sport.sport_status.id", null)),
                years: parseInt(get(state, "user_sport.years", null))
            },
            sport_specialist: {
                ...get(state, "sport_specialist", {}),
                function: parseInt(get(state, "sport_specialist.specialist_function.id", null))
            },
            work: {
                ...get(state, "work", {}),
                country: parseInt(get(state, "work.country.id", null))
            },
            medicine: { ...get(state, "medicine", {}) },
            med_expert: {
                ...get(state, "med_expert", {}),
                function: parseInt(get(state, "med_expert.med_function.id", null))
            },
            problem: { ...get(state, "problem", {}) },
            diagnose: { ...get(state, "diagnose", {}) }
        };

        if (variables.administration) {
            delete variables.administration.id;
            delete variables.administration.people_group;
            delete variables.administration.status;
            delete variables.administration.contact_channel;
            delete variables.administration.isValid;
            delete variables.administration.errors;
            delete variables.administration.isValidBase;
            delete variables.administration.__typename;
            delete variables.administration.displayErrors;
        }

        if (variables.personal) {
            delete variables.personal.id;
            delete variables.personal.email;
            delete variables.personal.communication_channel;
            delete variables.personal.isValid;
            delete variables.personal.errors;
            delete variables.personal.__typename;
            delete variables.personal.your_unique_id;
            delete variables.personal.displayErrors;
        }
        if (variables.address) {
            delete variables.address.id;
            delete variables.address.isValid;
            delete variables.address.errors;
            delete variables.address.__typename;
            delete variables.address.displayErrors;
        }
        //To change
        if (variables.private_info) {
            delete variables.private_info.id;
            delete variables.private_info.country;
            delete variables.private_info.civil_status;
            delete variables.private_info.additional_title;
            delete variables.private_info.isValid;
            delete variables.private_info.errors;
            delete variables.private_info.__typename;
            delete variables.private_info.displayErrors;
        }
        if (variables.med_expert) {
            delete variables.med_expert.id;
            delete variables.med_expert.med_function;
            delete variables.med_expert.isValid;
            delete variables.med_expert.errors;
            delete variables.med_expert.__typename;
            delete variables.med_expert.displayErrors;
        }
        if (variables.ahv) {
            delete variables.ahv.id;
            delete variables.ahv.isValid;
            delete variables.ahv.errors;
            delete variables.ahv.__typename;
            delete variables.ahv.displayErrors;
        }
        if (variables.problem) {
            delete variables.problem.id;
            delete variables.problem.isValid;
            delete variables.problem.errors;
            delete variables.problem.__typename;
            delete variables.problem.displayErrors;
        }
        if (variables.diagnose) {
            delete variables.diagnose.id;
            delete variables.diagnose.isValid;
            delete variables.diagnose.errors;
            delete variables.diagnose.__typename;
            delete variables.diagnose.displayErrors;
        }
        if (variables.medicine) {
            delete variables.medicine.id;
            delete variables.medicine.isValid;
            delete variables.medicine.errors;
            delete variables.medicine.__typename;
            delete variables.medicine.displayErrors;
        }

        if (variables.finance) {
            delete variables.finance.id;
            delete variables.finance.country;
            delete variables.finance.isValid;
            delete variables.finance.errors;
            delete variables.finance.__typename;
            delete variables.finance.displayErrors;
        }

        if (variables.user_sport) {
            delete variables.user_sport.id;
            delete variables.user_sport.SOCard;
            delete variables.user_sport.training_frequency;
            delete variables.user_sport.training_status;
            delete variables.user_sport.sport;
            delete variables.user_sport.sport_status;
            delete variables.user_sport.isValid;
            delete variables.user_sport.errors;
            delete variables.user_sport.__typename;
            delete variables.user_sport.your_unique_id;
            delete variables.user_sport.displayErrors;
        }
        if (variables.sport_specialist) {
            delete variables.sport_specialist.id;
            delete variables.sport_specialist.specialist_function;
            delete variables.sport_specialist.PID;
            delete variables.sport_specialist.isValid;
            delete variables.sport_specialist.errors;
            delete variables.sport_specialist.__typename;
            delete variables.sport_specialist.your_unique_id;
            delete variables.sport_specialist.displayErrors;
        }

        if (variables.work) {
            delete variables.work.id;
            delete variables.work.PID;
            delete variables.work.isValid;
            delete variables.work.errors;
            delete variables.work.__typename;
            delete variables.work.displayErrors;
        }
        //To be removed later
        isUpdate ? dispatch(UpdateClientMutation({ ...variables, userId })) : dispatch(CreateClientMutation(variables));
        toggleModal(false);
    }
};

/**
 *
 * @param {String} name
 * @param {Object || Array} value
 * @param {Object} oldState
 * @param {Boolean} isUpdate
 */
export const formChangeHandler = (name, value, oldState) => {
    let newState = { ...oldState };
    switch (name) {
        case "administration":
            newState = {
                ...newState,
                administration: { ...get(newState, "administration", {}), ...value }
            };
            break;
        case "personal":
            newState = {
                ...newState,
                personal: { ...get(newState, "personal", {}), ...value[0] },
                address: { ...get(newState, "address", {}), ...value[1] }
            };
            break;
        case "private_info":
            newState = {
                ...newState,
                private_info: { ...get(newState, "private_info", {}), ...value }
            };
            break;
        case "finance":
            newState = {
                ...newState,
                finance: { ...get(newState, "finance", {}), ...value[0] },
                ahv: { ...get(newState, "ahv", {}), ...value[1] }
            };
            break;
        case "sport":
            newState = {
                ...newState,
                sport: { ...get(newState, "sport", {}), ...value[0] },
                sport_specialist: { ...get(newState, "sport_specialist", {}), ...value[1] }
            };
            break;
        case "work":
            newState = { ...newState, work: { ...get(newState, "work", {}), ...value } };
            break;
        case "medicine":
            newState = {
                ...newState,
                medicine: { ...get(newState, "medicine", {}), ...value[0] },
                med_expert: { ...get(newState, "med_expert", {}), ...value[1] },
                problem: { ...get(newState, "problem", {}), ...value[2] },
                diagnose: { ...get(newState, "diagnose", {}), ...value[3] },
                ...value[4]
            };
            break;
        case "address":
            newState = { ...newState, address: { ...get(newState, "address", {}), ...value } };
            break;
        case "base":
            newState = { ...newState, ...value };
            break;
        default:
            break;
    }
    return newState;
};

// let validateState = (state, isUpdate) => {
//     let isValidAdministartion = stateValidator(newState.administration, AdministrationInfoRules(isUpdate));
//     let isValidPersonalInfo = stateValidator(newState.personal, PersonalInfoRules(isUpdate));
//     let isValidPrivateInfo = stateValidator(newState.private_info, PrivateInfoRules(isUpdate));
//     let isValidSport = stateValidator(newState.sport, SportInfoRules(isUpdate));
//     let isValidSportSpecialist = stateValidator(newState.sport_specialist, SportSpecialistInfoRules(isUpdate));
//     let isValidFinance = stateValidator(newState.finance, FinanceInfoRules(isUpdate));
//     // AHV Validation missing
//     let isValidAHV = stateValidator(newState.ahv, AHVInfoRules(isUpdate));
//     let isValidAddress = stateValidator(newState.address, AddressInfoRules(isUpdate));
//     let isValidWorkInfo = stateValidator(newState.work, WorkInfoRules(isUpdate));
//     let isValidMedicineInfo = stateValidator(newState.medicine, MedicineInfoRules(isUpdate));
//     let isValidMedExpertInfo = stateValidator(newState.med_expert, MedExpertInfoRules(isUpdate));
//     let isValidProblemInfo = stateValidator(newState.problem, ProblemInfoRules(isUpdate));
//     let isValidDiagnoseInfo = stateValidator(newState.diagnose, DiagnoseInfoRules(isUpdate));

//     let isAllValid =
//         isValidAdministartion.isValid &&
//         isValidPersonalInfo.isValid &&
//         isValidPrivateInfo.isValid &&
//         isValidSport.isValid &&
//         isValidSportSpecialist.isValid &&
//         isValidFinance.isValid &&
//         isValidAHV.isValid &&
//         isValidAddress.isValid &&
//         isValidWorkInfo.isValid &&
//         isValidMedicineInfo.isValid &&
//         isValidMedExpertInfo.isValid &&
//         isValidProblemInfo.isValid &&
//         isValidDiagnoseInfo.isValid;

//     newState = {
//         ...newState,
//         isAllValid,
//         administration: { ...newState.administration, ...isValidAdministartion },
//         personal: { ...newState.personal, ...isValidPersonalInfo },
//         private_info: { ...newState.private_info, ...isValidPrivateInfo },
//         address: { ...newState.address, ...isValidAddress },
//         sport: { ...newState.sport, ...isValidSport },
//         sport_specialist: { ...newState.sport_specialist, ...isValidSportSpecialist },
//         finance: { ...newState.finance, ...isValidFinance },
//         // AHV Validation missing
//         ahv: { ...newState.ahv, ...isValidAHV },
//         work: { ...newState.work, ...isValidWorkInfo },
//         medicine: { ...newState.medicine, ...isValidMedicineInfo },
//         med_expert: { ...newState.med_expert, ...isValidMedExpertInfo },
//         problem: { ...newState.problem, ...isValidProblemInfo },
//         diagnose: { ...newState.diagnose, ...isValidDiagnoseInfo }
//     };

//     return newState;
// };
// export const validateState = (state, )
