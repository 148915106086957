/* eslint-disable default-case */
import * as actionType from "./reports_actions";

const initState = {
    totalReports: null,
    allReports: [],

    //All Reports Filter
    filters: {
        search: "",
        offset: 0,
        page: 1
    }
};

const filterReset = {
    search: "",
    offset: 0,
    page: 1
};

const resetState = {};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.RESET_REPORT_STATE:
            return {
                ...state,
                ...resetState,
                filters: {
                    ...filterReset
                }
            };
        case actionType.REPORT_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.value
                }
            };
            break;
        case actionType.REPORT:
            return {
                ...state,
                ...action.value
            };
        case actionType.RESET_REPORT_FILTER_STATE:
            return {
                ...state,
                filters: {
                    ...filterReset
                }
            };
    }
    return state;
};

export default reducer;
