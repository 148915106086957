/* eslint-disable default-case */
import * as actionType from "./trainer_actions";
import { validateState } from "./validations";

const trainerState = {
  totalTrainers: null,
  allTrainers: [],

  singleTrainer: {},
  updateTrainer: {},
  updateFlag: false,
  isValid: true,
  errors: null,

  filters: {
    search: "",
    offset: 0,
    page: 1,
    status: {},
    //Order
    order: { name: "updatedAt", desc: true },
  },
};

const resetState = {
  singleTrainer: {},
  updateTrainer: {},
  updateFlag: false,
  isValid: true,
};

const filterReset = {
  //All Plans Filter
  search: "",
  offset: 0,
  page: 1,
  status: {},
  //Order
  order: { name: "updatedAt", desc: true },
};

const reducer = (state = trainerState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionType.TRAINER:
      newState = {
        ...newState,
        ...action.value,
      };
      break;
    case actionType.SINGLE_TRAINER:
      newState = {
        ...newState,
        singleTrainer: {
          ...newState.singleTrainer,
          ...action.value,
        },
        // updateTrainer: {},
        modal: false,
        loading: false,
        errors: null,
      };
      break;
    case actionType.UPDATE_TRAINER:
      newState = {
        ...newState,
        updateTrainer: {
          ...newState.updateTrainer,
          ...action.value,
        },
      };
      break;
    case actionType.TRAINER_FILTERS:
      newState = {
        ...newState,
        filters: {
          ...newState.filters,
          order: { ...newState.filters.order },
          ...action.value,
        },
      };
      break;
    case actionType.TRAINER_ERRORS:
      newState = {
        ...newState,
        loading: false,
        modal: false,
        errors: {
          ...action.value,
        },
      };
      break;
    case actionType.TRAINER_SUCCESS:
      newState = {
        ...newState,
        modal: true,
        loading: false,
        errors: null,
      };
      break;
    case actionType.RESET_TRAINER_STATE:
      newState = {
        ...newState,
        ...resetState,
        filters: {
          ...filterReset,
          order: { ...filterReset.order },
        },
      };
      break;
    case actionType.TRAINER_LOADING:
      return {
        ...state,
        loading: true,
        modal: false,
        errors: null,
      };
    case actionType.RESET_TRAINER_FILTER_STATE:
      newState = {
        ...newState,
        filters: {
          ...filterReset,
          order: { ...filterReset.order },
        },
      };
      break;
    case actionType.TRAINER_STATUS_CHANGED:
      newState = {
        ...state,
        singleTrainer: { ...state.singleTrainer, ...action.value },
      };
      break;
  }
  if (Object.keys(newState.updateTrainer).length > 0)
    newState.updateTrainer = validateState(
      newState.updateTrainer,
      newState.updateFlag
    );

  return newState;
};

export default reducer;
