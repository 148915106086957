import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash/get";
import clone from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";

import Select from "../../../components/UI/Select/Select";
import DatePickerComponent from "../../../components/UI/DatePicker/DatePicker";

import { searchBaseModels } from "../../../resolvers/query/base";
import { CreateTrainerMutation, UpdateTrainerMutation } from "./GraphQLActions";
import { showModal } from "../../../store/global/global_actions";
import { updateTrainer, trainer } from "../../../store/user/trainer/trainer_actions";
import TrainerDisclaimer from "../../../components/UI/Modal/TrainerDisclaimer";

const NewTrainer = props => {
    const { t } = useTranslation();
    const authState = useSelector(s => s.authState);
    const trainerState = useSelector(s => s.trainerState);
    const dispatch = useDispatch();
    const [modal, toggleModal] = useState(false);

    const trainerProps = trainerState.singleTrainer;
    const updatedProps = trainerState.updateTrainer;
    const errors = updatedProps.completeErrors;

    const baseModels = useQuery(searchBaseModels, {
        variables: {
            company: "default   ",
            gender: true,
            country: true
        }
    });

    useEffect(() => {
        if (parseInt(authState.role.id) === 2 || (parseInt(authState.role.id) === 3 && authState.is_admin)) {
            dispatch(
                updateTrainer({
                    company: parseInt(authState.role.id) === 2 ? parseInt(authState.id) : parseInt(authState.companyId)
                })
            );
        }
    }, [authState, dispatch]);

    let uploadMainPhotoHandler = e => {
        dispatch(
            updateTrainer({
                ...updatedProps,
                imgFile: e.target.files[0],
                img: URL.createObjectURL(e.target.files[0])
            })
        );
    };

    let genderSelectHandler = e => {
        dispatch(updateTrainer({ personal: { ...get(updatedProps, "personal", {}), gender: e.id } }));
    };

    let countrySelectHandler = e => {
        dispatch(updateTrainer({ address: { ...get(updatedProps, "address", {}), country: e.id } }));
    };

    let companySelectHandler = e => {
        dispatch(updateTrainer({ company: e.id }));
    };

    let formChangeHandler = e => {
        let name = e.target.name.split(".");
        let newState = {};
        switch (name[0]) {
            case "personal":
                newState = { personal: { ...get(updatedProps, "personal", {}), [name[1]]: e.target.value } };
                break;
            case "address":
                newState = { address: { ...get(updatedProps, "address", {}), [name[1]]: e.target.value } };
                break;
            case "base":
                newState = { [name[1]]: e.target.value };
                break;
            default:
                break;
        }
        dispatch(updateTrainer(newState));
    };

    /**
     *
     * @param {Event} e
     */
    let formSubmitHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        let isValid = updatedProps.isAllValid;
        if (isValid) {
            let vars = clone(updatedProps);
            let birthdate = get(vars, "personal.birthdate", null);
            if (birthdate) vars.personal.birthdate = moment(birthdate, "DD-MM-YYYY").format("YYYY-MM-DD");
            let company = get(vars, "company", null);
            if (company) vars.companyId = parseInt(company);
            let gender = get(vars, "personal.gender", null);
            if (gender) vars.personal.gender = parseInt(gender);
            let country = get(vars, "address.country", null);
            if (country) vars.address.country = parseInt(country);
            let img = get(vars, "imgFile", null);
            img ? (vars.img = img) : delete vars.img;

            if (!props.update) {
                dispatch(CreateTrainerMutation(vars))
                    .then(id => {
                        dispatch(
                            showModal({
                                type: 1,
                                message: t("tr_success_created.message"),
                                button: t("see_it.label"),
                                link: "/dashboard/trainers/" + id
                            })
                        );
                    })
                    .catch(err =>
                        dispatch(
                            showModal({
                                message: err.message,
                                button: "Ok"
                            })
                        )
                    );
            } else {
                vars.id = trainerProps.id;
                dispatch(UpdateTrainerMutation(vars))
                    .then(id => {
                        dispatch(
                            showModal({
                                type: 1,
                                message: t("tr_success_updated.message"),
                                button: t("see_it.label"),
                                link: "/dashboard/trainers/" + id
                            })
                        );
                    })
                    .catch(err =>
                        dispatch(
                            showModal({
                                message: err.message,
                                button: "Ok"
                            })
                        )
                    );
            }
        } else {
            dispatch(trainer({ isValid: false }));
        }
    };

    /**
     *
     * @param {Event} e
     */
    const onCliclHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        if (props.update) {
            formSubmitHandler(e);
        } else {
            toggleModal(true);
        }
    };

    return (
        <section className="main-content">
            <div className="container-fluid">
                <form onSubmit={e => formSubmitHandler(e)}>
                    <div className="training-form">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="main-file-upload excercises-upload">
                                            <div className="file-upload-title">
                                                <h5>{t("main_image.label")}</h5>
                                            </div>
                                            <div className="file-upload">
                                                <div className="image-upload-wrap">
                                                    <input
                                                        maxLength={250}
                                                        id="mainImageInput"
                                                        type="file"
                                                        className="file-upload-input"
                                                        accept="image/*"
                                                        onChange={e => uploadMainPhotoHandler(e)}
                                                        // required={get(
                                                        //     updatedProps,
                                                        //     "img",
                                                        //     get(trainerProps, "img", true)
                                                        // )}
                                                    />
                                                    {!get(updatedProps, "img", get(trainerProps, "img")) ? (
                                                        <div className="drag-text">
                                                            <i className="icon-picture"></i>
                                                            <span>{t("dragndrop.label")}</span>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            alt="uploaded img"
                                                            style={{
                                                                width: "100%",
                                                                objectFit: "cover",
                                                                height: "100%"
                                                            }}
                                                            src={get(updatedProps, "img", get(trainerProps, "img"))}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="button-upload">
                                                <a
                                                    href="_blank"
                                                    className="btn btn-upload"
                                                    onClick={e => {
                                                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                                        document.getElementById("mainImageInput").click();
                                                    }}
                                                >
                                                    {t("upload_img.label")}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t("first_name.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <input
                                                        maxLength={250}
                                                        required
                                                        defaultValue={get(
                                                            updatedProps,
                                                            "personal.name",
                                                            get(trainerProps, "personal.name", "")
                                                        )}
                                                        type="text"
                                                        name="personal.name"
                                                        className="form-control"
                                                        onChange={e => formChangeHandler(e)}
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "name", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("last_name.label")}</label>
                                                    <input
                                                        maxLength={250}
                                                        required
                                                        defaultValue={get(
                                                            updatedProps,
                                                            "personal.surname",
                                                            get(trainerProps, "personal.surname", "")
                                                        )}
                                                        type="text"
                                                        name="personal.surname"
                                                        className="form-control"
                                                        onChange={e => formChangeHandler(e)}
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "surname", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t("email.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <input
                                                        maxLength={250}
                                                        required
                                                        defaultValue={get(
                                                            updatedProps,
                                                            "email",
                                                            get(trainerProps, "email", "")
                                                        )}
                                                        type="email"
                                                        name="base.email"
                                                        className="form-control"
                                                        onChange={e => formChangeHandler(e)}
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "email", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group date-custom date-custom-label">
                                                    <label>{t("birthdate.label")}</label>
                                                    <DatePickerComponent
                                                        placeholder={"DD-MM-YYYY"}
                                                        date={get(
                                                            updatedProps,
                                                            "personal.birthdate",
                                                            get(trainerProps, "personal.birthdate", null)
                                                        )}
                                                        select={e =>
                                                            formChangeHandler({
                                                                target: { value: e, name: "personal.birthdate" }
                                                            })
                                                        }
                                                        required
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "birthdate", "")}</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t("gender.label")}</label>
                                                    <Select
                                                        selectedItem={get(
                                                            updatedProps,
                                                            "personal.gender",
                                                            get(trainerProps, "personal.gender.id", "")
                                                        ).toString()}
                                                        text="Select Gender"
                                                        select={genderSelectHandler}
                                                        items={get(baseModels, "data.searchBaseModels.Gender", [])}
                                                        name="personal.gender"
                                                        required
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "gender", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {parseInt(authState.role.id) !== 1 ? null : (
                                                    <div className="form-group">
                                                        <label>
                                                            {t("company.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <Select
                                                            selectedItem={get(
                                                                updatedProps,
                                                                "company",
                                                                get(trainerProps, "company.id", "")
                                                            ).toString()}
                                                            text="Select Company"
                                                            select={companySelectHandler}
                                                            items={get(baseModels, "data.searchBaseModels.Company", [])}
                                                            required
                                                        />
                                                        {trainerState.isValid ? null : (
                                                            <p style={{ color: "red" }}>{get(errors, "company", "")}</p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t("street.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <input
                                                        maxLength={250}
                                                        required
                                                        defaultValue={get(
                                                            updatedProps,
                                                            "address.street",
                                                            get(trainerProps, "address.street", "")
                                                        )}
                                                        type="text"
                                                        name="address.street"
                                                        className="form-control"
                                                        onChange={e => formChangeHandler(e)}
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "street", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t("postal.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <input
                                                        maxLength={250}
                                                        required
                                                        defaultValue={get(
                                                            updatedProps,
                                                            "address.postal",
                                                            get(trainerProps, "address.postal", "")
                                                        )}
                                                        type="text"
                                                        name="address.postal"
                                                        className="form-control"
                                                        onChange={e => formChangeHandler(e)}
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "postal", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t("city.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <input
                                                        maxLength={250}
                                                        required
                                                        defaultValue={get(
                                                            updatedProps,
                                                            "address.city",
                                                            get(trainerProps, "address.city", "")
                                                        )}
                                                        type="text"
                                                        name="address.city"
                                                        className="form-control"
                                                        onChange={e => formChangeHandler(e)}
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "city", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {t("country.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <Select
                                                        selectedItem={get(
                                                            updatedProps,
                                                            "address.country",
                                                            get(trainerProps, "address.country.id", "")
                                                        ).toString()}
                                                        text="Select Country"
                                                        select={countrySelectHandler}
                                                        items={get(baseModels, "data.searchBaseModels.Country", [])}
                                                        required
                                                    />
                                                    {trainerState.isValid ? null : (
                                                        <p style={{ color: "red" }}>{get(errors, "country", "")}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/**Listat */}
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <div className="btn-right">
                                            <Link
                                                to={
                                                    !props.update
                                                        ? "/dashboard/trainers"
                                                        : "/dashboard/trainers/" + trainerProps.id
                                                }
                                                className="btn btn-cancel btn-180"
                                            >
                                                {t("cancel.label")}
                                            </Link>
                                            <button
                                                to="#"
                                                className="btn btn-primary btn-180"
                                                data-toggle="modal"
                                                data-target="#modal"
                                                type="submit"
                                                onClick={onCliclHandler}
                                                // disabled={!(Object.entries(updatedProps).length > 0)}
                                            >
                                                {!props.update ? t("create.label") : t("update.label")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <TrainerDisclaimer
                    show={modal}
                    close={() => toggleModal(false)}
                    action={e => {
                        toggleModal(false);
                        formSubmitHandler(e);
                    }}
                />
            </div>
        </section>
    );
};

NewTrainer.propTypes = {
    update: PropTypes.bool,
    history: PropTypes.object
};

export default NewTrainer;
