/* eslint-disable default-case */
import * as actionType from "./exercise_actions";
import { stateValidator } from "../../helpers/validator";
import deepStateMutator from "../../helpers/deepStateMutator";
import {
  InformationValidations,
  UploadFileValidations,
} from "./validation-rules";

const initState = {
  totalExercises: null,
  allExercises: [],

  singleExercise: {},
  updateExercise: {},
  isValid: true,
  updateFlag: false,

  firstStepValidity: false,
  secondStepValidity: false,
  displayUploadFileErrors: false,
  dipslayInformationErrors: false,

  //All Exercises Filter
  filters: {
    search: "",
    offset: 0,
    page: 1,
    bodyparts: [],
    groups: [],
    difficulty: {},
    creator: {},
  },
  loading: false,
  loadingFilters: false,
};

/**
 *  search={exerciseState.searchFilter}
    difficulty={exerciseState.difficultyFilter}
    equipments={exerciseState.equipmentsFilter}
    bodyparts={exerciseState.bodypartsFilter}
    creator={exerciseState.creatorFilter}
    offset={exerciseState.offsetFilter}
 */
const filterReset = {
  search: "",
  offset: 0,
  page: 1,
  bodyparts: [],
  groups: [],
  difficulty: {},
  creator: {},
};

const resetState = {
  singleExercise: {},
  updateExercise: {},
  isValid: true,
  updateFlag: false,

  firstStepValidity: false,
  secondStepValidity: false,
  displayUploadFileErrors: false,
  dipslayInformationErrors: false,

  loading: false,
  loadingFilters: false,
};

const reducer = (state = initState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionType.EXERCISE:
      newState = {
        ...state,
        ...action.value,
      };
      break;
    case actionType.SINGLE_EXERCISE:
      newState = {
        ...state,
        singleExercise: {
          ...action.value,
        },
      };
      break;
    case actionType.PREPARE_EXERCISE_UPDATE:
      newState = {
        ...state,
        updateExercise: {
          ...newState.singleExercise,
        },
      };
      break;
    case actionType.UPDATE_EXERCISE:
      console.log({ action: action.value });
      newState = {
        ...state,
        // updateExercise: {
        //   ...newState.updateExercise,
        //   ...action.value,
        // },
        updateExercise: {
          ...deepStateMutator(state.updateExercise, action.value),
        },
      };
      break;
    case actionType.EXERCISE_FILTERS:
      newState = {
        ...state,
        filters: {
          ...newState.filters,
          ...action.value,
        },
      };
      break;
    case actionType.EXERCISE_ERRORS:
      newState = {
        ...newState,
        loading: false,
        modal: false,
        errors: {
          ...action.value,
        },
      };
      break;
    case actionType.EXERCISE_LOADING:
      return {
        ...state,
        loading: true,
        modal: false,
        errors: { array: [] },
      };
    case actionType.RESET_EXERCISE_STATE:
      newState = {
        ...state,
        ...resetState,
        filters: { ...filterReset },
      };
      break;
    case actionType.RESET_EXERCISE_FILTER_STATE:
      newState = {
        ...state,
        filters: { ...filterReset },
      };
      break;

    case actionType.EXERCISE_STATUS_CHANGED:
      newState = {
        ...state,
        singleExercise: { ...state.singleExercise, ...action.value },
      };
      break;
    case actionType.DELETE_EXERCISE:
      newState = { ...state, singleExercise: {} };
      break;
  }

  newState.firstStepValidity = stateValidator(
    newState.updateExercise,
    UploadFileValidations()
  );
  newState.secondStepValidity = stateValidator(
    newState.updateExercise,
    InformationValidations(false)
  );
  return newState;
};

export default reducer;
