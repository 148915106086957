/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import get from "lodash/get";

import TrainingplanList from "../../Trainingplan/TrainingplanList";
import TestList from "../../Tests/TestsList";
import ConfirmModal from "../../UI/Modal/ConfirmModal";
import ContentModalMiddleware from "../../../containers/dashboard/Middlewares/ContentModalMiddleware";

import placeholder from "../../../assets/img/placeholders/200x200.png";
import ListModal from "../../UI/Modal/ListModal";

const SingleUser = ({ deleteUserHandler, toggleUserStatus }) => {
  const [modal, toggleModal] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalContent, setModalContent] = useState("PLAN_LIST");

  const userState = useSelector((s) => s.userState);
  const auth = useSelector((s) => s.authState);
  const { singleUser } = userState;

  const { t } = useTranslation();

  let convCreatedAt = moment(get(singleUser, "createdAt", "")).format(
    "D MMMM YYYY"
  );

  const hasAccessToDelete = () => {
    if (
      (auth.roleId === 2 && singleUser.companyId !== auth.id) || //company
      (auth.is_admin && singleUser.companyId === auth.companyId) || //trainer as company admin
      (auth.roleId === 3 && singleUser.trainerId !== auth.id) //trainer
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <section className="main-content">
        <ContentModalMiddleware
          parent={"CLIENT"}
          parentId={parseInt(singleUser.id)}
          content={modalContent}
          show={modal}
          closeModal={() => toggleModal(false)}
        />
        {/* <ListModal show type="USER_LIST" /> */}
        <div className="container-fluid">
          <div className="white-bg">
            <div className="row">
              <div className="col-md-12">
                <div className="single-detail-head single-head-left-right">
                  <div className="single-head-flex">
                    <div className="single-head-left">
                      <div className="form-toggle form-toggle-checked">
                        {/* {hasUpdateAccess(user, exercise) && ( */}
                        <label className="form-toggle-switch" htmlFor="status">
                          <input
                            type="checkbox"
                            id="status"
                            defaultChecked={singleUser.status === "ACTIVE"}
                            onChange={toggleUserStatus}
                          />
                          <div className="form-toggle-switch-slider form-toggle-switch-round"></div>
                        </label>
                        {/* )} */}
                        <span
                          className={
                            singleUser.status === "ACTIVE"
                              ? "active"
                              : "inactive"
                          }
                        >
                          {singleUser.status === "ACTIVE"
                            ? "Active"
                            : "Inactive"}
                        </span>
                      </div>
                    </div>
                    <div className="single-head-right">
                      {/* <div className="create-icon">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalContent("PLAN_LIST");
                            toggleModal(true);
                          }}
                        >
                          <i className="icon-add"></i>
                          <span>Assign trainer</span>
                        </a>
                      </div> */}
                      <Link
                        to={
                          get(singleUser, "id", null) +
                          "/edit-user/administration"
                        }
                      >
                        <i className="icon-edit"></i>
                        <span>{t("edit.label")}</span>
                      </Link>
                      {hasAccessToDelete() && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeleteModal(true);
                          }}
                        >
                          <i className="icon-cancel"></i>
                          <span>Delete</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-4">
                <div className="user-img">
                  <img
                    alt="profile"
                    src={get(singleUser, "img", false) || placeholder}
                  />
                </div>
                <div className="user-results">
                  <Link
                    to={(location) => ({
                      ...location,
                      pathname: location.pathname + "/reports",
                      search: "?date=" + moment().format("DD-MM-YYYY"),
                    })}
                  >
                    <i className="icon-reports"></i>
                    {t("results.label")}
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-lg-8 user-flex">
                <div className="user-detail">
                  <div className="user-row">
                    <div className="user-info">
                      <p>{t("first_name.label")}</p>
                      <span>{get(singleUser, "personal.name", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("last_name.label")}</p>
                      <span>{get(singleUser, "personal.surname", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("birthdate.label")}</p>
                      <span>{get(singleUser, "personal.birthdate", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("street.label")}</p>
                      <span>{get(singleUser, "address.street", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("postal.label")}</p>
                      <span>{get(singleUser, "address.postal", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("city.label")}</p>
                      <span>{get(singleUser, "address.city", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("country.label")}</p>
                      <span>
                        {get(singleUser, "address.country.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("date_registered.label")}</p>
                      <span>{convCreatedAt}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("gender.label")}</p>
                      <span>
                        {get(singleUser, "personal.gender.name", "-")}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("values.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("height.label")}</p>
                      <span>
                        {get(singleUser, "measurement.height", "0")} cm
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("weight.label")}</p>
                      <span>
                        {get(singleUser, "measurement.weight", "0")} kg
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("body_fat_pt.label")}</p>
                      <span>
                        {get(singleUser, "measurement.bodyFatPt", "0")}%
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("waist.label")}</p>
                      <span>
                        {get(singleUser, "measurement.belly", "0")} cm
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("resting_heart_rate.label")}</p>
                      <span>
                        {get(singleUser, "measurement.restingHeartRate", "0")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="white-bg">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 user-flex">
                <div className="user-detail custom-user-detail">
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("administration.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("pid.label")}</p>
                      <span>{get(singleUser, "administration.PID", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("people_group.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "administration.people_group.name",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("status.label")}</p>
                      <span>
                        {get(singleUser, "administration.status.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("contact_date.label")}</p>
                      <span>
                        {get(singleUser, "administration.contact_date", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("contact_channel.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "administration.contact_channel.name",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("found_from.label")}</p>
                      <span>
                        {get(singleUser, "administration.found_from", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("referenced_by.label")}</p>
                      <span>
                        {get(singleUser, "administration.referenced_by", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_contact.label")}</p>
                      <span>
                        {get(singleUser, "administration.remark_contact", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("notify_on.label")}</p>
                      <span>
                        {get(singleUser, "administration.notify_on", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("first_visit.label")}</p>
                      <span>
                        {get(singleUser, "administration.first_visit", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("death_date.label")}</p>
                      <span>
                        {get(singleUser, "administration.death_date", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_date.label")}</p>
                      <span>
                        {get(singleUser, "administration.remark_death", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("vip.label")}</p>
                      <span>
                        {get(singleUser, "administration.VIP", false)
                          ? "VIP"
                          : "-"}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_administration.label")}</p>
                      <span>
                        {get(singleUser, "administration.comment", "-")}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("personal_info.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("first_name.label")}</p>
                      <span>{get(singleUser, "personal.name", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("last_name.label")}</p>
                      <span>{get(singleUser, "personal.surname", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("business_name.label")}</p>
                      <span>
                        {get(singleUser, "personal.bussiness_name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("gender.label")}</p>
                      <span>
                        {get(singleUser, "personal.gender.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("birthdate.label")}</p>
                      <span>{get(singleUser, "personal.birthdate", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("address.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("street.label")}</p>
                      <span>{get(singleUser, "address.street", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("postal.label")}</p>
                      <span>{get(singleUser, "address.postal", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("city.label")}</p>
                      <span>{get(singleUser, "address.city", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("country.label")}</p>
                      <span>
                        {get(singleUser, "address.country.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("canton.label")}</p>
                      <span>{get(singleUser, "address.canton.name", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("additional_address.label")}</p>
                      <span>
                        {get(singleUser, "address.additionalAddress", "-")}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("contact.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("email.label")}</p>
                      <span>{get(singleUser, "email", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("phone.label")}</p>
                      <span>
                        {get(singleUser, "personal.phoneNumber", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("landline.label")}</p>
                      <span>{get(singleUser, "personal.landline", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("desired_contact_channel.label")}</p>
                      <span>
                        {get(singleUser, "personal.communication_channel", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_contact_form.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "personal.remark_communication_channel",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("nationality.label")}</p>
                      <span>
                        {get(singleUser, "personal.nationality.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_personal_info.label")}</p>
                      <span>{get(singleUser, "personal.comments", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("private_info.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("title.label")}</p>
                      <span>
                        {get(singleUser, "private_info.title.id", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("additional_title.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.additional_title.id",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("website.label")}</p>
                      <span>
                        {get(singleUser, "private_info.website", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("landline.label")}</p>
                      <span>
                        {get(singleUser, "private_info.landline_alt", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("email_alternative.label")}</p>
                      <span>
                        {get(singleUser, "private_info.mail_alt", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("phone_alternative.label")}</p>
                      <span>
                        {get(singleUser, "private_info.tel_alt", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("fax.label")}</p>
                      <span>{get(singleUser, "private_info.fax", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("civil_status.label")}</p>
                      <span>
                        {get(singleUser, "private_info.civil_status.id", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("hometown.label")}</p>
                      <span>
                        {get(singleUser, "private_info.hometown", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("childhood_surname.label")}</p>
                      <span>
                        {get(singleUser, "private_info.name_unmarried", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("no_of_children.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.number_of_children",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("emergency_contact_name.label")}</p>
                      <span>
                        {get(singleUser, "private_info.emergency_contact", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("pid_emergency_contact.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.emergency_contact_PID",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_emergency_contact.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.remark_emergency_contact",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("emergency_tel.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.emergency_telephone1",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_emergency_tel.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.remark_emergency_telephone1",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("emergency_tel.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.emergency_telephone2",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_emergency_tel.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.remark_emergency_telephone2",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_private_info.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "private_info.remark_private_info",
                          "-"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("finance.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("iban.label")}</p>
                      <span>{get(singleUser, "finance.IBAN", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("account.label")}</p>
                      <span>{get(singleUser, "finance.account_no", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("clearing_nr.label")}</p>
                      <span>{get(singleUser, "finance.clearing_no", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("bic.label")}</p>
                      <span>{get(singleUser, "finance.BIC", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("bank_name.label")}</p>
                      <span>{get(singleUser, "finance.bank_name", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("bank_street.label")}</p>
                      <span>{get(singleUser, "finance.bank_street", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("bank_postal.label")}</p>
                      <span>{get(singleUser, "finance.bank_postal", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("bank_city.label")}</p>
                      <span>{get(singleUser, "finance.bank_city", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("bank_country.label")}</p>
                      <span>
                        {get(singleUser, "finance.bank_country.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("bank_additional_address.label")}</p>
                      <span>
                        {get(singleUser, "finance.bank_address_addition", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_account.label")}</p>
                      <span>{get(singleUser, "finance.comment", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("insurance.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("ahv_nr.label")}</p>
                      <span>{get(singleUser, "ahv.AHV", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark_ahv_nr.label")}</p>
                      <span>{get(singleUser, "ahv.remark_ahv", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("other_comments.label")}</p>
                      <span>{get(singleUser, "ahv.comment", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("sport.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("sport.label")}</p>
                      <span>
                        {get(singleUser, "user_sport.sport.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("status.label")}</p>
                      <span>
                        {get(singleUser, "user_sport.sport_status.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("years.label")}</p>
                      <span>{get(singleUser, "user_sport.years", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("training_frequency.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "user_sport.training_frequency.name",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("training_status.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "user_sport.training_status.name",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("discipline.label")}</p>
                      <span>
                        {get(singleUser, "user_sport.discipline", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("suqad.label")}</p>
                      <span>{get(singleUser, "user_sport.squad", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("training_group.label")}</p>
                      <span>
                        {get(singleUser, "user_sport.training_group", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("so_card.label")}</p>
                      <span>
                        {get(singleUser, "user_sport.SOCard.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("association.label")}</p>
                      <span>
                        {get(singleUser, "user_sport.association", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark.label")}</p>
                      <span>{get(singleUser, "user_sport.comment", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("sport_specialist.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("pid.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "sport_specialist.specialist_PID",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("name.label")}</p>
                      <span>
                        {get(singleUser, "sport_specialist.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("function.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "sport_specialist.specialist_function.name",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("cooperation_since.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "sport_specialist.cooperation_since",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("email.label")}</p>
                      <span>
                        {get(singleUser, "sport_specialist.email", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("phone.label")}</p>
                      <span>
                        {get(singleUser, "sport_specialist.mobile", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("phone_alternative.label")}</p>
                      <span>
                        {get(
                          singleUser,
                          "sport_specialist.mobile_alternative",
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("remark.label")}</p>
                      <span>
                        {get(singleUser, "sport_specialist.comments", "-")}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("work.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("pid.label")}</p>
                      <span>{get(singleUser, "work.PIDcompany", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("job_name.label")}</p>
                      <span>{get(singleUser, "work.job_name", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("company.label")}</p>
                      <span>{get(singleUser, "work.company_name", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("department.label")}</p>
                      <span>{get(singleUser, "work.department", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("position.label")}</p>
                      <span>{get(singleUser, "work.position", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("street.label")}</p>
                      <span>{get(singleUser, "work.street", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("additional_address.label")}</p>
                      <span>
                        {get(singleUser, "work.additional_address", "-")}
                      </span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("postal.label")}</p>
                      <span>{get(singleUser, "work.postal", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("city.label")}</p>
                      <span>{get(singleUser, "work.city", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("country.label")}</p>
                      <span>{get(singleUser, "work.country.name", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("phone.label")}</p>
                      <span>{get(singleUser, "work.mobile", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("landline.label")}</p>
                      <span>{get(singleUser, "work.landline", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("email.label")}</p>
                      <span>{get(singleUser, "work.email", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("website.label")}</p>
                      <span>{get(singleUser, "work.website", "-")}</span>
                    </div>{" "}
                    <div className="user-info">
                      <p>{t("fax.label")}</p>
                      <span>{get(singleUser, "work.fax", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("health_insurance.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("kk_kvg.label")}</p>
                      <span>{get(singleUser, "medicine.KK_KVG", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("kk_addition.label")}</p>
                      <span>{get(singleUser, "medicine.KK_zusatz", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("police_nr.label")}</p>
                      <span>{get(singleUser, "medicine.police_no", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("health_insurance.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("accident_insurance.label")}</p>
                      <span>
                        {get(singleUser, "medicine.accident_insurance", "-")}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("add_medical_proffesional.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("type.label")}</p>
                      <span>
                        {get(singleUser, "med_expert.function.name", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("first_name.label")}</p>
                      <span>{get(singleUser, "med_expert.name", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("last_name.label")}</p>
                      <span>{get(singleUser, "med_expert.surname", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("salutation.label")}</p>
                      <span>
                        {get(singleUser, "med_expert.salutation", "-")}
                      </span>
                    </div>
                    <div className="user-info">
                      <p>{t("address.label")}</p>
                      <span>{get(singleUser, "med_expert.street", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("postal.label")}</p>
                      <span>{get(singleUser, "med_expert.postal", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("city.label")}</p>
                      <span>{get(singleUser, "med_expert.city", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("phone.label")}</p>
                      <span>{get(singleUser, "med_expert.mobile", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("email.label")}</p>
                      <span>{get(singleUser, "med_expert.email", "-")}</span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("add_diagnosis.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("type.label")}</p>
                      <span>{get(singleUser, "diagnose.typ", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("description.label")}</p>
                      <span>
                        {get(singleUser, "diagnose.description", "-")}
                      </span>
                    </div>
                  </div>
                  <div className="user-row">
                    <div className="title">
                      <h4>{t("add_problem.label")}</h4>
                    </div>
                    <div className="user-info">
                      <p>{t("type.label")}</p>
                      <span>{get(singleUser, "problem.typ", "-")}</span>
                    </div>
                    <div className="user-info">
                      <p>{t("description.label")}</p>
                      <span>{get(singleUser, "problem.description", "-")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {!singleUser.trainerId ? (
            <div className="white-bg">
              <p>{t("no_trainet_assigned.label")}</p>
            </div>
          ) : (
            <>
              <div className="white-bg">
                <div className="row">
                  <div className="col-md-12">
                    <div className="training-head">
                      <div className="title">
                        <h5>
                          {t("trainingplans.label")} -{" "}
                          {get(singleUser, "taken_plans_count", "0")}
                        </h5>
                      </div>
                      <div className="create-icon">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalContent("PLAN_LIST");
                            toggleModal(true);
                          }}
                        >
                          <i className="icon-add"></i>
                          <span>{t("add_trainingplans.label")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ maxHeight: "680px", overflowY: "auto" }}
                >
                  {get(singleUser, "taken_plans", []).map((e) => (
                    <TrainingplanList
                      key={e.id}
                      plan={e}
                      userId={get(singleUser, "id")}
                    />
                  ))}
                </div>
              </div>
              <div className="white-bg">
                <div className="row">
                  <div className="col-md-12">
                    <div className="training-head">
                      <div className="title">
                        <h5>
                          {t("tests.label")} -{" "}
                          {get(singleUser, "taken_tests_count", 0)}
                        </h5>
                      </div>
                      <div className="create-icon">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalContent("TEST_LIST");
                            toggleModal(true);
                          }}
                        >
                          <i className="icon-add"></i>
                          <span>{t("add_tests.label")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ maxHeight: "680px", overflowY: "auto" }}
                >
                  {get(singleUser, "taken_tests", []).map((e) => (
                    <TestList
                      key={e.id}
                      userId={get(singleUser, "id")}
                      tests={e.test}
                      takenTest={e}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <ConfirmModal
          show={deleteModal}
          confirmButton="Delete"
          rejectHandler={() => setDeleteModal(false)}
          confirmHandler={deleteUserHandler}
          message="Are you sure you want to delete this user?"
        />
      </section>
    </>
  );
};

export default SingleUser;
