/* eslint-disable default-case */
import * as actionType from "./test_actions";
import { stateValidator } from "../../helpers/validator";
import { GeneralInfoValidations } from "./validation-rules";
import questionValidator from "./question_validations";

const testState = {
    totalTests: null,
    allTests: [],

    singleTest: {},
    updateTest: {},
    isValid: true,
    updateFlag: false,

    firstStepValidity: false,
    secondStepValidity: false,
    displayGenerealInfoErrors: false,
    dipslayQuestionErrors: false,

    //All Test Filter
    filters: {
        search: "",
        offset: 0,
        page: 1,
        creator: {},
        difficulty: {}
    }
};

const resetState = {
    firstStepValidity: false,
    secondStepValidity: false,
    displayGenerealInfoErrors: false,
    dipslayQuestionErrors: false,

    singleTest: {},
    updateTest: {},
    isValid: true,
    updateFlag: false
};

const filterReset = {
    //All Test Filter
    search: "",
    offset: 0,
    page: 1,
    creator: {},
    difficulty: {}
};

const reducer = (state = testState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case actionType.TEST:
            newState = {
                ...state,
                ...action.value
            };
            break;
        case actionType.SINGLE_TEST:
            newState = {
                ...state,
                singleTest: { ...action.value }
                // updateTest: {}
            };
            break;
        case actionType.UPDATE_TEST:
            newState = {
                ...state,
                updateTest: { ...state.updateTest, ...action.value }
            };
            break;
        case actionType.TEST_FILTERS:
            newState = {
                ...state,
                filters: {
                    ...newState.filters,
                    offset: 0,
                    page: 1,
                    ...action.value
                }
            };
            break;
        case actionType.TEST_ERRORS:
            newState = {
                ...newState,
                loading: false,
                modal: false,
                errors: {
                    ...action.value
                }
            };
            break;
        case actionType.TEST_LOADING:
            return {
                ...state,
                loading: true,
                modal: false,
                errors: { array: [] }
            };
        case actionType.RESET_TEST_STATE:
            newState = {
                ...state,
                ...resetState,
                filters: { ...filterReset }
            };
            break;
        case actionType.RESET_TEST_FILTER_STATE:
            newState = {
                ...state,
                filters: { ...filterReset }
            };
            break;
        case actionType.TEST_STATUS_CHANGED:
            newState = { ...state, singleTest: { ...state.singleTest, ...action.value } };
            break;
        case actionType.DELETE_TEST:
            newState = { ...state, singleTest: {} };
            break;
    }
    newState.firstStepValidity = stateValidator(newState.updateTest, GeneralInfoValidations(newState.updateFlag));
    newState.secondStepValidity = questionValidator(newState.updateTest);

    return newState;
};

export default reducer;
