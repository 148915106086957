/* eslint-disable default-case */
import * as actionType from "./company_actions";
import { validateState } from "./validations";

const companyState = {
    totalCompanies: null,
    allCompanies: [],

    singleCompany: {},
    updateCompany: {},
    updateFlag: false,
    isValid: true,
    errors: {
        array: []
    },

    //All Plans Filter
    filters: {
        search: "",
        offset: 0,
        page: 1,
        status: {},

        //Order
        order: { name: "updatedAt", desc: true }
    }
};

const resetState = {
    singleCompany: {},
    updateCompany: {},
    updateFlag: false,
    isValid: true,
    errors: { array: [] }
};

const filterReset = {
    //All Plans
    search: "",
    offset: 0,
    page: 1,
    status: {},
    //Order
    order: { name: "updatedAt", desc: true }
};

const reducer = (state = companyState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case actionType.RESET_COMPANY_STATE:
            newState = {
                ...newState,
                ...resetState,
                filters: {
                    ...filterReset
                }
            };
            break;
        case actionType.COMPANY:
            newState = {
                ...newState,
                ...action.value
            };
            break;
        case actionType.COMPANY_PERSONAL:
            newState = {
                ...newState,
                singleCompany: {
                    ...newState.singleCompany,
                    personal: {
                        ...newState.singleCompany.personal,
                        ...action.value
                    }
                }
            };
            break;
        case actionType.COMPANY_PRIVATE:
            newState = {
                ...newState,
                singleCompany: {
                    ...newState.singleCompany,
                    private_info: {
                        ...newState.singleCompany.private_info,
                        ...action.value
                    }
                }
            };
            break;
        case actionType.COMPANY_ADDRESS:
            newState = {
                ...newState,
                singleCompany: {
                    ...newState.singleCompany,
                    address: {
                        ...newState.singleCompany.address,
                        ...action.value
                    }
                }
            };
            break;
        case actionType.SINGLE_COMPANY:
            newState = {
                ...newState,
                singleCompany: {
                    ...newState.singleCompany,
                    ...action.value
                },
                // updateCompany: {},
                modal: false,
                loading: false,
                errors: { array: [] }
            };
            break;
        case actionType.UPDATE_COMPANY:
            newState = {
                ...newState,
                updateCompany: {
                    ...newState.updateCompany,
                    ...action.value
                }
            };
            break;
        case actionType.COMPANY_FILTER:
            newState = {
                ...newState,
                filters: {
                    ...newState.filters,
                    order: { ...newState.filters.order },
                    ...action.value
                }
            };
            break;
        case actionType.COMPANY_LOADING:
            return {
                ...state,
                loading: true,
                modal: false,
                errors: { array: [] }
            };
        case actionType.COMPANY_ERRORS:
            newState = {
                ...newState,
                loading: false,
                modal: false,
                errors: { ...action.value }
            };
            break;
        case actionType.COMPANY_SUCCESS:
            newState = {
                ...newState,
                loading: false,
                modal: true,
                errors: { array: [] }
            };
            break;
        case actionType.COMPANY_LOADING:
            newState = {
                ...newState,
                loading: true,
                errors: {
                    array: []
                }
            };
            break;
        case actionType.RESET_COMPANY_FILTER_STATE:
            newState = {
                ...newState,
                filters: {
                    ...filterReset,
                    order: { ...filterReset.order }
                }
            };
            break;
    }
    if (Object.keys(newState.updateCompany).length > 0)
        newState.updateCompany = validateState(newState.updateCompany, newState.updateFlag);

    // newState.valid = validation.validate(newState);

    return {
        ...newState
    };
};

export default reducer;
