import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import WizardNav from "components/UI/WizardNav/WizardNav";
import { UpdateTestAction, CreateTestAction } from "../GraphQLActions";
import { showModal, HIDE_CONTENT_MODAL } from "store/global/global_actions";
import ContentModalMiddleware from "../../Middlewares/ContentModalMiddleware";

import SingleTest from "../../../../components/Tests/SingleTest";
import { constructTestRequest } from "./helpers";

const Overview = props => {
    const { t } = useTranslation();
    const testState = useSelector(s => s.testState);
    const reduxState = testState.singleTest;
    const updateState = testState.updateTest;
    const globalState = useSelector(s => s.globalState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!testState.firstStepValidity.isValid) {
            props.history.push("general-info");
        }
        if (!testState.secondStepValidity.isValid) {
            props.history.push("questions");
        }
    }, []);

    const submitHandler = e => {
        e.preventDefault();
        let mutation = updateState.id ? UpdateTestAction : CreateTestAction;
        dispatch(mutation(constructTestRequest(updateState)))
            .then(id =>
                dispatch(
                    showModal({
                        type: 2,
                        message: `Test ${updateState.id ? "updated" : "created"} sucessfully.`,
                        button: "Add for clients",
                        link: "/dashboard/tests/" + id
                    })
                )
            )
            .catch(err => dispatch(showModal({ message: err.message, button: "Ok" })));
    };

    return (
        <>
            <WizardNav
                first={t("general_info.label")}
                firstLink="general-info"
                second={t("questions.label")}
                secondLink="questions"
                active1={testState.firstStepValidity.isValid}
                active2={testState.firstStepValidity.isValid && testState.secondStepValidity.isValid}
                third={t("overview.label")}
                thirdLink="overview"
                active={3}
            />
            <ContentModalMiddleware
                parent={"TEST"}
                parentId={parseInt(reduxState.id)}
                content={"USER_LIST"}
                isMutating
                show={globalState.contentModal}
                closeModal={() => dispatch({ type: HIDE_CONTENT_MODAL })}
            />
            <SingleTest test={updateState} isUpdate submitHandler={e => submitHandler(e)} />
        </>
    );
};

Overview.propTypes = {
    history: PropTypes.object
};

export default Overview;
