import gql from "graphql-tag";

export const addClientDashboardMutation = gql`
  mutation addClientDashboard(
    $email: String!
    $trainerId: Int
    $companyId: Int
    $administration: CreateAdministrationInputType
    $personal: CreatePersonalInfoInputType!
    $address: CreateAddressInputType!
    $private_info: CreatePrivateInputType!
    $finance: CreateFinanceInputType
    $user_sport: CreateUserSportInputType!
    $sport_specialist: CreateSportSpecialistInputType!
    $work: CreateWorkInputType!
    $medicine: CreateMedicineInputType!
    $med_expert: CreateMedExpertInputType
    $problem: CreateProblemInputType
    $diagnose: CreateDiagnoseInputType
    $ahv: CreateAhvInputType
  ) {
    addClientDashboard(
      trainerId: $trainerId
      companyId: $companyId
      email: $email
      personal: $personal
      address: $address
      administration: $administration
      private_info: $private_info
      finance: $finance
      user_sport: $user_sport
      sport_specialist: $sport_specialist
      work: $work
      medicine: $medicine
      med_expert: $med_expert
      problem: $problem
      diagnose: $diagnose
      ahv: $ahv
    ) {
      id
      trainerId
      trainer {
        id
        personal {
          name
          surname
        }
        company {
          id
          personal {
            name
            surname
          }
        }
      }
      company {
        id
        personal {
          name
          surname
        }
      }
      administration {
        id
        PID
        contact_date
        found_from
        referenced_by
        remark_contact
        notify_on
        first_visit
        death_date
        remark_death
        VIP
        comment
        people_group {
          id
          name
        }
        status {
          id
          name
        }
        contact_channel {
          id
          name
        }
      }
      personal {
        name
        surname
        phoneNumber
        birthdate
        gender {
          id
          name
        }
        bussiness_name
        comments
        landline
        remark_communication_channel
        nationality {
          id
          name
        }
      }
      address {
        street
        city
        postal
        country {
          id
          name
        }
        canton {
          id
          name
        }
        additionalAddress
      }
      measurement {
        weight
        height
        bodyFatPt
        belly
        restingHeartRate
      }
      private_info {
        website
        landline_alt
        mail_alt
        tel_alt
        fax
        hometown
        name_unmarried
        number_of_children
        emergency_contact
        emergency_contact_PID
        remark_emergency_contact
        emergency_telephone1
        remark_emergency_telephone1
        emergency_telephone2
        remark_emergency_telephone2
        remark_private_info
        title {
          id
          name
        }
        additional_title {
          id
          name
        }
        civil_status {
          id
          name
        }
      }
      finance {
        id
        IBAN
        account_no
        clearing_no
        BIC
        bank_name
        bank_city
        bank_street
        bank_postal
        bank_country {
          id
          name
        }
        bank_address_addition
        comment
      }
      ahv {
        id
        AHV
        remark_ahv
        comment
      }
      user_sport {
        id
        years
        discipline
        squad
        training_group
        association
        comment
        sport {
          id
          name
        }
        sport_status {
          id
          name
        }
        training_frequency {
          id
          name
        }
        training_status {
          id
          name
        }
        SOCard {
          id
          name
        }
      }
      sport_specialist {
        id
        specialist_PID
        name
        cooperation_since
        email
        mobile
        mobile_alternative
        comments
        specialist_function {
          id
          name
        }
      }
      work {
        id
        PIDcompany
        job_name
        company_name
        department
        position
        street
        roadNo
        additional_address
        postal
        city
        mobile
        landline
        email
        website
        fax
        country {
          id
          name
        }
      }
      medicine {
        id
        KK_KVG
        KK_zusatz
        accident_insurance
        police_no
      }
      med_expert {
        id
        name
        surname
        salutation
        street
        additionalAddress
        postal
        city
        email
        mobile
        med_function {
          id
          name
        }
      }
      problem {
        id
        typ
        description
      }
      diagnose {
        id
        typ
        description
      }
      email
      img
      createdAt
      taken_plans {
        id
        master_plan {
          id
          title
          img
        }
        no_of_exercises
      }
      taken_plans_count
      taken_tests_count
      taken_tests {
        id
        test {
          id
          title
          img
          duration
          difficulty {
            id
            name
          }
          description
        }
        answered
        favourite
        status
      }
    }
  }
`;

export const editClientDashboard = gql`
  mutation editClientDashboard(
    $userId: Int!
    $email: String
    $trainerId: Int
    $companyId: Int
    $complete_profile_token: String
    $administration: UpdateAdministrationInputType
    $personal: UpdatePersonalInfoInputType!
    $address: UpdateAddressInputType!
    $private_info: UpdatePrivateInputType!
    $finance: UpdateFinanceInputType
    $user_sport: UpdateUserSportInputType!
    $sport_specialist: UpdateSportSpecialistInputType
    $work: UpdateWorkInputType!
    $medicine: UpdateMedicineInputType!
    $med_expert: UpdateMedExpertInputType
    $problem: UpdateProblemInputType
    $diagnose: UpdateDiagnoseInputType
    $ahv: UpdateAhvInputType
  ) {
    editClientDashboard(
      userId: $userId
      trainerId: $trainerId
      companyId: $companyId
      complete_profile_token: $complete_profile_token
      email: $email
      personal: $personal
      address: $address
      administration: $administration
      private_info: $private_info
      finance: $finance
      user_sport: $user_sport
      sport_specialist: $sport_specialist
      work: $work
      medicine: $medicine
      med_expert: $med_expert
      problem: $problem
      diagnose: $diagnose
      ahv: $ahv
    ) {
      id
      trainerId
      trainer {
        id
        personal {
          name
          surname
        }
        company {
          id
          personal {
            name
            surname
          }
        }
      }
      company {
        id
        personal {
          name
          surname
        }
      }
      administration {
        id
        PID
        contact_date
        found_from
        referenced_by
        remark_contact
        notify_on
        first_visit
        death_date
        remark_death
        VIP
        comment
        people_group {
          id
          name
        }
        status {
          id
          name
        }
        contact_channel {
          id
          name
        }
      }
      personal {
        name
        surname
        phoneNumber
        birthdate
        gender {
          id
          name
        }
        bussiness_name
        comments
        landline
        remark_communication_channel
        nationality {
          id
          name
        }
      }
      address {
        street
        city
        postal
        country {
          id
          name
        }
        canton {
          id
          name
        }
        additionalAddress
      }
      measurement {
        weight
        height
        bodyFatPt
        belly
        restingHeartRate
      }
      private_info {
        website
        landline_alt
        mail_alt
        tel_alt
        fax
        hometown
        name_unmarried
        number_of_children
        emergency_contact
        emergency_contact_PID
        remark_emergency_contact
        emergency_telephone1
        remark_emergency_telephone1
        emergency_telephone2
        remark_emergency_telephone2
        remark_private_info
        title {
          id
          name
        }
        additional_title {
          id
          name
        }
        civil_status {
          id
          name
        }
      }
      finance {
        id
        IBAN
        account_no
        clearing_no
        BIC
        bank_name
        bank_city
        bank_street
        bank_postal
        bank_country {
          id
          name
        }
        bank_address_addition
        comment
      }
      ahv {
        id
        AHV
        remark_ahv
        comment
      }
      user_sport {
        id
        years
        discipline
        squad
        training_group
        association
        comment
        sport {
          id
          name
        }
        sport_status {
          id
          name
        }
        training_frequency {
          id
          name
        }
        training_status {
          id
          name
        }
        SOCard {
          id
          name
        }
      }
      sport_specialist {
        id
        specialist_PID
        name
        cooperation_since
        email
        mobile
        mobile_alternative
        comments
        specialist_function {
          id
          name
        }
      }
      work {
        id
        PIDcompany
        job_name
        company_name
        department
        position
        street
        roadNo
        additional_address
        postal
        city
        mobile
        landline
        email
        website
        fax
        country {
          id
          name
        }
      }
      medicine {
        id
        KK_KVG
        KK_zusatz
        accident_insurance
        police_no
      }
      med_expert {
        id
        name
        surname
        salutation
        street
        additionalAddress
        postal
        city
        email
        mobile
        med_function {
          id
          name
        }
      }
      problem {
        id
        typ
        description
      }
      diagnose {
        id
        typ
        description
      }
      email
      img
      createdAt
      taken_plans {
        id
        master_plan {
          id
          title
          img
        }
        no_of_exercises
      }
      taken_plans_count
      taken_tests_count
      taken_tests {
        id
        test {
          id
          title
          img
          duration
          difficulty {
            id
            name
          }
          description
        }
        answered
        favourite
        status
      }
    }
  }
`;

export const completeProfileDashboard = gql`
  mutation editClientDashboard(
    $userId: Int!
    $email: String
    $trainerId: Int
    $complete_profile_token: String
    $administration: UpdateAdministrationInputType
    $personal: UpdatePersonalInfoInputType!
    $address: UpdateAddressInputType!
    $private_info: UpdatePrivateInputType!
    $finance: UpdateFinanceInputType
    $user_sport: UpdateUserSportInputType!
    $work: UpdateWorkInputType!
    $medicine: UpdateMedicineInputType!
    $med_expert: UpdateMedExpertInputType
    $problem: UpdateProblemInputType
    $diagnose: UpdateDiagnoseInputType
    $ahv: UpdateAhvInputType
  ) {
    editClientDashboard(
      userId: $userId
      trainerId: $trainerId
      complete_profile_token: $complete_profile_token
      email: $email
      personal: $personal
      address: $address
      administration: $administration
      private_info: $private_info
      finance: $finance
      user_sport: $user_sport
      work: $work
      medicine: $medicine
      med_expert: $med_expert
      problem: $problem
      diagnose: $diagnose
      ahv: $ahv
    ) {
      id
    }
  }
`;

export const addTrainerMutation = gql`
  mutation addTrainer(
    $companyId: Int!
    $email: String!
    $img: Upload
    $address: addressInput
    $personal: personalInput
  ) {
    addTrainer(
      companyId: $companyId
      email: $email
      img: $img
      addressInput: $address
      personalInput: $personal
    ) {
      id
      personal {
        name
        surname
        birthdate
        gender {
          id
          name
        }
      }
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }
      email
      img
      createdAt

      company {
        id
        personal {
          phoneNumber
          name
        }
        address {
          street
        }
        img
        email
        private_info {
          website
        }
        createdAt
      }
    }
  }
`;

export const addCompanyMutation = gql`
  mutation addCompany(
    $personal: personalInput
    $address: addressInput
    $private_info: privateInput
    $email: String!
    $img: Upload
  ) {
    addCompany(
      personalInput: $personal
      email: $email
      addressInput: $address
      img: $img
      privateInput: $private_info
    ) {
      id
      personal {
        name
        phoneNumber
      }
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }
      email
      private_info {
        website
      }
      createdAt
      img
    }
  }
`;

export const editUserMutation = gql`
  mutation editUser(
    $id: Int!
    $name: String
    $surname: String
    $email: String
    $gender: Int
    $birthdate: Date
    $street: String
    $city: String
    $country: String
    $zip: String
    $trainerId: Int
    $img: Upload
    $height: Int
    $weight: Int
    $bodyFatPt: Int
    $belly: Int
    $restingHeartrate: Int
    $web: String
    $phoneNumber: String
  ) {
    editUser(
      id: $id
      name: $name
      surname: $surname
      email: $email
      gender: $gender
      birthdate: $birthdate
      street: $street
      city: $city
      country: $country
      zip: $zip
      trainerId: $trainerId
      img: $img
      height: $height
      weight: $weight
      bodyFatPt: $bodyFatPt
      belly: $belly
      restingHeartrate: $restingHeartrate
      web: $web
      phoneNumber: $phoneNumber
    ) {
      id
      name
      surname
      birthdate
      street
      zip
      city
      email
      img
      country
      createdAt
      weight
      height
      bodyFatPt
      belly
      restingHeartrate
      gender {
        id
        name
      }
      taken_plans {
        id
        master_plan {
          id
          title
          img
        }
        no_of_exercises
      }
      taken_plans_count
      taken_tests_count
      taken_tests {
        id
        test {
          id
          title
          img
          duration
          difficulty {
            id
            name
          }
          description
        }
        answered
        favourite
        status
      }
    }
  }
`;

export const editCompanyMutation = gql`
  mutation editUser(
    $id: Int!
    $personal: personalInput
    $address: addressInput
    $private_info: privateInput
    $email: String
    $img: Upload
  ) {
    editUser(
      id: $id
      personalInput: $personal
      email: $email
      addressInput: $address
      img: $img
      privateInput: $private_info
    ) {
      id
      personal {
        name
        phoneNumber
      }
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }
      email
      private_info {
        website
      }
      createdAt
      img
    }
  }
`;

export const editTrainerMutation = gql`
  mutation editUser(
    $id: Int!
    $email: String
    $companyId: Int
    $img: Upload
    $personal: personalInput
    $address: addressInput
  ) {
    editUser(
      id: $id
      email: $email
      companyId: $companyId
      img: $img
      personalInput: $personal
      addressInput: $address
    ) {
      id
      personal {
        name
        surname
        birthdate
        gender {
          id
          name
        }
      }
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }
      email
      img
      createdAt

      company {
        id
        personal {
          phoneNumber
          name
        }
        address {
          street
        }
        img
        email
        private_info {
          website
        }
        createdAt
      }
    }
  }
`;

export const editProfileMutation = gql`
  mutation editUser(
    $id: Int!
    $email: String
    $personal: personalInput
    $address: addressInput
    $locale: LOCALE
  ) {
    editUser(
      id: $id
      personalInput: $personal
      addressInput: $address
      email: $email
      locale: $locale
    ) {
      id
      locale
      personal {
        name
        surname
        phoneNumber
      }
      email
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }

      role {
        id
        name
      }
      img
      is_admin
      company {
        id
        personal {
          phoneNumber
          name
        }
        address {
          street
        }
        img
        private_info {
          website
        }
      }
    }
  }
`;

export const editUser = gql`
  mutation editUser(
    $id: Int!
    $name: String
    $surname: String
    $gender: Int
    $birthdate: Date
    $email: String
    $street: String
    $city: String
    $country: String
    $zip: String
    $height: Int
    $weight: Int
    $bodyFatPt: Int
    $belly: Int
    $restingHeartrate: Int
    $img: Upload
  ) {
    editUser(
      id: $id
      name: $name
      surname: $surname
      email: $email
      gender: $gender
      birthdate: $birthdate
      img: $img
      height: $height
      weight: $weight
      bodyFatPt: $bodyFatPt
      belly: $belly
      street: $street
      city: $city
      country: $country
      zip: $zip
      restingHeartrate: $restingHeartrate
    ) {
      id
      name
      surname
      birthdate
      street
      zip
      city
      email
      img
      country
      createdAt
      weight
      height
      bodyFatPt
      belly
      restingHeartrate
      gender {
        id
        name
      }
      taken_plans {
        id
        master_plan {
          id
          title
          img
        }
        no_of_exercises
      }
      taken_plans_count
      taken_tests_count
      taken_tests {
        id
        test {
          id
          titlecompany {
            id
            personal {
              name
              surname
            }
          }
          img
          duration
          difficulty {
            id
            name
          }
          description
        }
        answered
        favourite
        status
      }
    }
  }
`;

export const changePasswordMutation = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $confrimNewPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      repeatPassword: $confrimNewPassword
    )
  }
`;

export const toggleCompanyAdminPrivileges = gql`
  mutation toggleCompanyAdminPrivileges($userId: Int!) {
    toggleCompanyAdminPrivileges(userId: $userId)
  }
`;

export const UpdateCompanyProfileAsTrainer = gql`
  mutation updateCompanyProfileAsTrainer(
    $img: Upload
    $name: String
    $phoneNumber: String
    $street: String
    $website: String
  ) {
    updateCompanyProfileAsTrainer(
      img: $img
      personal: { name: $name, phoneNumber: $phoneNumber }
      address: { street: $street }
      private_info: { website: $website }
    ) {
      id
      personal {
        phoneNumber
        name
      }
      address {
        street
      }
      img
      private_info {
        website
      }
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id)
  }
`;

export const ToggleUserStatus = gql`
  mutation ToggleUserStatus($id: Int!, $status: STATUS!) {
    toggleUserStatus(id: $id, status: $status)
  }
`;

export const ToggleTrainerStatus = gql`
  mutation ToggleTrainerStatus($id: Int!, $status: STATUS!) {
    toggleTrainerStatus(id: $id, status: $status)
  }
`;