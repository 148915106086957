/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Select,
  FiltersMultipleSelect,
  DatePicker,
} from "../../../../components/UI";
import { searchBaseModels } from "../../../../resolvers/query/base";
import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import { valueHandler } from "./helpers";
import { updateUser as updateUserAction } from "../../../../store/user/user/user_actions";

const Personal = (props) => {
  const { t } = useTranslation();
  const { singleUser, updateUser } = useSelector((s) => s.userState);
  const dispatch = useDispatch();

  //Redux state
  const personalInfoState = updateUser.personal;
  const addressInfoState = updateUser.address;
  const emailReduxState = singleUser.email;

  //Errors for component
  let { displayErrors } = updateUser.personal;
  displayErrors = displayErrors || updateUser.displayErrors;
  let personalComponentErrors = get(personalInfoState, "errors", {});
  let addressComponentErrors = get(addressInfoState, "errors", {});
  /**
   *
   * @param {Event} e
   */
  const displayErrorsFunc = (e) => {
    let keys = [
      ...Object.keys(personalComponentErrors),
      ...Object.keys(addressComponentErrors),
    ];
    if (keys.length > 0) {
      e.preventDefault();
      dispatch(
        updateUserAction({ name: "personal.displayErrors", value: true })
      );
    }
  };

  let selectItems = useQuery(searchBaseModels, {
    variables: {
      gender: true,
      country: true,
      canton: true,
      contact_channel: true,
      nationality: true,
    },
  });

  return (
    <section className="main-content">
      <ClientMenu
        componentNumber={2}
        componentName={"personal"}
        {...props}
        clickHandler={displayErrorsFunc}
      />
      <div className="container-fluid">
        <form
          onChange={(e) => {
            let req = valueHandler(e);
            dispatch(
              updateUserAction({
                name: "personal." + req.name,
                value: req.value,
              })
            );
          }}
        >
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="client-form-title">
                  <h1>{t("personal_info.label")}</h1>
                </div>
              </div>
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("personal_details.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("first_name.label")}
                    <span className="form-required">*</span>
                  </label>
                  <input
                    defaultValue={get(personalInfoState, "name", "")}
                    type="text"
                    name="name"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.name}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("last_name.label")}
                    <span className="form-required">*</span>
                  </label>
                  <input
                    defaultValue={get(personalInfoState, "surname", "")}
                    type="text"
                    name="surname"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.surname}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("business_name.label")}</label>
                  <input
                    defaultValue={get(personalInfoState, "bussiness_name", "")}
                    type="text"
                    name="bussiness_name"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.bussiness_name}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("gender.label")}
                    {/* <span className="form-required">*</span> */}
                  </label>
                  <Select
                    name={"gender"}
                    selectedItem={get(
                      personalInfoState,
                      "gender.id",
                      ""
                    ).toString()}
                    select={(e) => e}
                    items={get(selectItems, "data.searchBaseModels.Gender", [])}
                    // text={"Select gender"}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.gender}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group date-custom date-custom-label">
                  <label>
                    {t("birthdate.label")}
                    <span className="form-required">*</span>
                  </label>
                  <DatePicker
                    placeholder={"DD-MM-YYYY"}
                    date={get(personalInfoState, "birthdate", "")}
                    select={(e) =>
                      dispatch(
                        updateUserAction({
                          name: "personal.birthdate",
                          value: e,
                        })
                      )
                    }
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.birthdate}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </form>
        <form
          onChange={(e) => {
            let req = valueHandler(e);
            dispatch(
              updateUserAction({
                name: "address." + req.name,
                value: req.value,
              })
            );
          }}
        >
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("address.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("street.label")}
                    <span className="form-required">*</span>
                  </label>
                  <input
                    defaultValue={get(addressInfoState, "street", "")}
                    type="text"
                    name="street"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {addressComponentErrors.street}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("postal.label")}
                    <span className="form-required">*</span>
                  </label>
                  <input
                    defaultValue={get(addressInfoState, "postal", "")}
                    type="text"
                    name="postal"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {addressComponentErrors.postal}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("city.label")}
                    <span className="form-required">*</span>
                  </label>
                  <input
                    defaultValue={get(addressInfoState, "city", "")}
                    type="text"
                    name="city"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {addressComponentErrors.city}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("country.label")}
                    <span className="form-required">*</span>
                  </label>
                  <Select
                    name={"country"}
                    selectedItem={get(
                      addressInfoState,
                      "country.id",
                      ""
                    ).toString()}
                    select={(e) => e}
                    items={get(
                      selectItems,
                      "data.searchBaseModels.Country",
                      []
                    )}
                    // text={"Select country"}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {addressComponentErrors.country}
                    </span>
                  ) : null}
                </div>
              </div>
              {get(addressInfoState, "country.name", "") === "Switzerland" ? (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("canton.label")}</label>
                    <Select
                      name={"canton"}
                      selectedItem={get(
                        addressInfoState,
                        "canton.id",
                        ""
                      ).toString()}
                      select={(e) => e}
                      items={get(
                        selectItems,
                        "data.searchBaseModels.Canton",
                        []
                      )}
                      text={"Select canton"}
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {personalComponentErrors.canton}
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("additional_address.label")}</label>
                  <input
                    defaultValue={get(
                      addressInfoState,
                      "additionalAddress",
                      ""
                    )}
                    type="text"
                    name="additionalAddress"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.additionalAddress}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </form>
        <form
          onChange={(e) => {
            let req = valueHandler(e);
            dispatch(
              updateUserAction({
                name: "personal." + req.name,
                value: req.value,
              })
            );
          }}
        >
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("contact.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("email.label")}
                    <span className="form-required">*</span>
                  </label>
                  <input
                    defaultValue={get(
                      personalInfoState,
                      "email",
                      emailReduxState || ""
                    )}
                    type="text"
                    name="email"
                    // onChange={e => {
                    //     dispatch(updateUserAction({ name: "email", value: e.target.value }));
                    // }}
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.email}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("phone.label")}</label>
                  <input
                    defaultValue={get(personalInfoState, "phoneNumber", "")}
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.phoneNumber}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("landline.label")}</label>
                  <input
                    defaultValue={get(personalInfoState, "landline", "")}
                    type="text"
                    name="landline"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.landline}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("desired_contact_channel.label")}</label>
                  <FiltersMultipleSelect
                    name={"communication_channel"}
                    selectedItems={get(
                      personalInfoState,
                      "communication_channel",
                      []
                    )}
                    select={(e) =>
                      dispatch(
                        updateUserAction({
                          name: "personal.communication_channel",
                          value: e,
                        })
                      )
                    }
                    items={get(
                      selectItems,
                      "data.searchBaseModels.ContactChannel",
                      []
                    )}
                    // text={"Select communication channel"}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.communication_channel}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("remark_contact_form.label")}</label>
                  <input
                    defaultValue={get(
                      personalInfoState,
                      "remark_communication_channel",
                      ""
                    )}
                    type="text"
                    name="remark_communication_channel"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.remark_communication_channel}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("nationality.label")}</label>
                  <Select
                    name={"nationality"}
                    selectedItem={get(
                      personalInfoState,
                      "nationality.id",
                      ""
                    ).toString()}
                    select={(e) => e}
                    items={get(
                      selectItems,
                      "data.searchBaseModels.Nationality",
                      []
                    )}
                    // text={"Select nationality"}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.nationality}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t("remark_personal_info.label")}</label>
                  <textarea
                    defaultValue={get(personalInfoState, "comments", "")}
                    name="comments"
                    className="form-control"
                    rows="5"
                  ></textarea>
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {personalComponentErrors.comments}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <div className="btn-right">
                  <Link to="administration" className="btn btn-cancel btn-180">
                    {t("back.label")}
                  </Link>
                  <Link
                    onClick={displayErrorsFunc}
                    to="private"
                    className="btn btn-primary btn-180"
                  >
                    {t("next.label")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

Personal.propTypes = {
  changeMasterStateHandler: PropTypes.func,
  formChangeHandler: PropTypes.func,
  addressState: PropTypes.object,
  personalState: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(Personal);
