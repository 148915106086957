import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Search = props => {
    let [value, setValue] = useState(undefined);
    // let [timer, setTimer] = useState(null);
    let timer = null;
    let { t } = useTranslation();

    useEffect(() => {
        if (value === undefined) setValue(props.querySearch);
    }, [props.querySearch, value]);

    let changeHandler = event => {
        event.persist();
        clearTimeout(timer);
        timer = setTimeout(() => props.searchHandler(event), 200);
    };

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group search">
                <input
                    onChange={changeHandler}
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder={t("search.placeholder")}
                    defaultValue={value}
                />
            </div>
        </div>
    );
};

Search.propTypes = {
    querySearch: PropTypes.string,
    searchHandler: PropTypes.func
};

export default Search;
