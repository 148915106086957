export const hasUpdateAccess = (user, item) => {
  switch (user.role.id) {
    case 1:
      return true;
    case 2:
      if (!item.creator) return false;
      if (item.creator.id === user.id) return true;
      if (item.creator.companyId === user.id) return true;
      return false;
    case 3:
      if (!item.creator) return false;
      if (item.creator.id === user.id) return true;
      if (user.is_admin && item.creator.id === user.companyId) return true;
      return false;
    default:
      return false;
  }
};
