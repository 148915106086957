import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clone from "lodash/cloneDeep";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";

import Modal from "../../../components/UI/Modal/Modal";
import { EditUserMutation } from "./GraphQLActions";
import { Select } from "../../../components/UI";
import { searchBaseModels } from "../../../resolvers/query/base";
import { auth } from "../../../store/auth/auth_actions";

const Profile = () => {
    const { t } = useTranslation();
    let authState = useSelector(e => e.authState);
    let dispatch = useDispatch();
    let [state, setState] = useState({});

    // useTraceUpdate(state);

    // useEffect(() => {}, [authState]);

    let selectItems = useQuery(searchBaseModels, { variables: { country: true } });

    /**
     * @param {Event} e - The event
     */
    let onChangeForm = e => {
        if (e.target.name) {
            let name = e.target.name.split(".");
            let vars = { ...state };
            switch (name[0]) {
                case "personal":
                    vars = { ...vars, personal: { ...vars.personal, [name[1]]: e.target.value } };
                    break;
                case "address":
                    vars = { ...vars, address: { ...vars.address, [name[1]]: e.target.value } };
                    break;
                case "select":
                    vars = { ...vars, address: { ...vars.address, country: e.target.value } };
                    break;
                default:
                    vars = { ...vars, [name[0]]: e.target.value };
            }
            setState({ ...vars });
        }
    };

    /**
     * @param {Event} e - The event
     */
    // astate.id = parseInt(state.id);

    let submitFormHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        let variables = clone(state);
        variables.id = parseInt(authState.id);
        if (get(variables, "address.country", false)) {
            variables.address.country = parseInt(variables.address.country.id);
        }
        dispatch(EditUserMutation(variables));
    };
    return (
        <Fragment>
            <div className="col-md-9">
                <div className="settings-form">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("first_name.label")}</label>
                                        <input
                                            maxLength={250}
                                            onChange={e => onChangeForm(e)}
                                            value={get(state, "personal.name", get(authState, "personal.name", ""))}
                                            type="text"
                                            name="personal.name"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                {authState.role.id === 2 ? null : (
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t("last_name.label")}</label>
                                            <input
                                                maxLength={250}
                                                onChange={e => onChangeForm(e)}
                                                value={get(
                                                    state,
                                                    "personal.surname",
                                                    get(authState, "personal.surname", "")
                                                )}
                                                type="text"
                                                name="personal.surname"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("phone.label")}</label>
                                        <input
                                            maxLength={250}
                                            onChange={e => onChangeForm(e)}
                                            value={get(
                                                state,
                                                "personal.phoneNumber",
                                                get(authState, "personal.phoneNumber", "")
                                            )}
                                            type="text"
                                            name="personal.phoneNumber"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("email.label")}</label>
                                        <input
                                            maxLength={250}
                                            onChange={e => onChangeForm(e)}
                                            value={get(state, "email", get(authState, "email", ""))}
                                            type="email"
                                            name="email"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("postal.label")}</label>
                                        <input
                                            maxLength={250}
                                            onChange={e => onChangeForm(e)}
                                            value={get(state, "address.postal", get(authState, "address.postal", ""))}
                                            type="text"
                                            name="address.postal"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("street.label")}</label>
                                        <input
                                            maxLength={250}
                                            onChange={e => onChangeForm(e)}
                                            value={get(state, "address.street", get(authState, "address.street", ""))}
                                            type="text"
                                            name="address.street"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("city.label")}</label>
                                        <input
                                            maxLength={250}
                                            onChange={e => onChangeForm(e)}
                                            value={get(state, "address.city", get(authState, "address.city", ""))}
                                            type="text"
                                            name="address.city"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("country.label")}</label>
                                        <Select
                                            name="country"
                                            items={get(selectItems, "data.searchBaseModels.Country", [])}
                                            selectedItem={get(
                                                state,
                                                "address.country.id",
                                                get(authState, "address.country.id", "")
                                            ).toString()}
                                            text={"Select country"}
                                            select={e => onChangeForm({ target: { name: "select", value: e } })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-end">
                                    <div className="settings-button">
                                        <button
                                            // disabled={isEqual(state, authState)}
                                            onClick={e => submitFormHandler(e)}
                                            className="btn btn-primary btn-180"
                                        >
                                            {t("update.label")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={authState.modal}
                    closeModal={() => dispatch(auth({ modal: false }))}
                    text={t("pr_success_updated.message")}
                    oneButton
                />
            </div>
        </Fragment>
    );
};

export default Profile;
