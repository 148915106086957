import gql from "graphql-tag";

export const UserTicketQuery = gql`
    query UserTicketQuery($period: TICKET_TIME_PERIOD) {
        userTicketQuery(period: $period)
    }
`;

export const LibraryTicketQuery = gql`
    query LibraryTicketQuery($period: TICKET_TIME_PERIOD) {
        libraryTicketQuery(period: $period)
    }
`;

export const LatestRecordTicketQuery = gql`
    query LatestRecordTicketQuery {
        latestRecordTicketQuery
    }
`;

export const GetReportDataForDashboard = gql`
    query getReportDataForDashboard($period: TICKET_TIME_PERIOD) {
        getReportDataForDashboard(period: $period)
    }
`;

export const UserStatusChartDataQuery = gql`
    query userStatusChartDataQuery($role: Int) {
        userStatusChartDataQuery(role: $role)
    }
`;

export const NewTrainersChartDataQuery = gql`
    query newTrainersChartDataQuery {
        newTrainersChartDataQuery
    }
`;
