/* eslint-disable no-throw-literal */
/* eslint-disable no-mixed-spaces-and-tabs */
import { client } from "../../../helpers/index";
import {
  ExerciseListQuery,
  SingleExerciseQuery,
  SingleCustomExerciseQuery,
} from "../../../resolvers/query/exercises";
import {
  createExerciseMutation,
  DeleteExercise,
  editExerciseMutation,
  ToggleExerciseStatus,
} from "../../../resolvers/mutation/exercises";
import {
  exercise,
  exerciseErrors,
  singleExercise,
  exerciseStatusChanged,
} from "../../../store/exercise/exercise_actions";
import { plan } from "../../../store/trainingplans/trainingplans_actions";
import {
  toggleLoadingOn,
  toggleLoadingOff,
} from "../../../store/global/global_actions";

export const FetchAllExercisesQuery = (variables, forPlan = false) => {
  return (dispatch) => {
    // forPlan ? dispatch(planLoading()) : dispatch(exerciseLoading());
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: ExerciseListQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let {
            searchExercise: { totalCount, allExercises },
          } = data;
          forPlan
            ? dispatch(
                plan({
                  allExercises: allExercises,
                  totalExercise: totalCount,
                  loading: false,
                })
              )
            : dispatch(
                exercise({
                  allExercises: allExercises,
                  totalExercises: totalCount,
                  loading: false,
                })
              );
          // forPlan ? dispatch(plan({ loading: false })) : dispatch(exercise({ loading: false }));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const FetchExerciseByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(exercise({ customExerciseFlag: false }));
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: SingleExerciseQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let { fetchExercise } = data;
          dispatch(singleExercise(fetchExercise));
          // dispatch(updateExercise({ photos: fetchExercise.photos }));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const FetchCustomExerciseByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(exercise({ customExerciseFlag: true }));
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: SingleCustomExerciseQuery,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          let err = [];
          errors.map((mes) => err.push(mes.message));
          dispatch(exerciseErrors({ array: err }));
        }
        if (data) {
          let { fetchCostumExercise } = data;
          let bodypartsIds = [];
          let groupsIds = [];
          let equipmentsIds = [];
          fetchCostumExercise.bodyparts.map((e) =>
            bodypartsIds.push(parseInt(e.id))
          );
          fetchCostumExercise.groups.map((e) => groupsIds.push(parseInt(e.id)));
          fetchCostumExercise.equipments.map((e) =>
            equipmentsIds.push(parseInt(e.id))
          );
          dispatch(
            singleExercise({
              ...fetchCostumExercise,
              id: parseInt(fetchCostumExercise.id),
              bodypartsIds,
              equipmentsIds,
              groupsIds,
              loading: false,
              modal: false,
              errors: { array: [] },
            })
          );
          // dispatch(updateExercise({ photos: fetchCostumExercise.photos }));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const CreateExerciseMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: createExerciseMutation,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          dispatch(
            singleExercise({
              ...data.createExercise,
              id: parseInt(data.createExercise.id),
              imgFile: null,
              photosToUploadFiles: [],
              photosToUpload: [],
              photosToDelete: [],
            })
          );
          // dispatch(updateExercise({ photos: data.createExercise.photos }));
        }
        return parseInt(data.createExercise.id);
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const UpdateExerciseMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: editExerciseMutation,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          dispatch(
            singleExercise({
              ...data.editExercise,
              id: parseInt(data.editExercise.id),
              photosToUploadFiles: [],
              photosToUpload: [],
              imgFile: null,
              photosToDelete: [],
            })
          );
        }
        // dispatch(updateExercise({ photos: data.editExercise.photos }));
        return parseInt(data.editExercise.id);
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const ToggleExerciseStatusMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    client
      .mutate({ mutation: ToggleExerciseStatus, variables })
      .then(() => dispatch(exerciseStatusChanged(variables)))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const DeleteExerciseMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: DeleteExercise, variables })
      .then(() => true)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};
