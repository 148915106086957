import gql from "graphql-tag";

export const ExerciseListQuery = gql`
  query ExercisesList(
    $search: String!
    $difficulty: Int
    $groups: [Int!]
    $bodyparts: [Int!]
    $offset: Int!
    $creator: Int
  ) {
    searchExercise(
      search: $search
      offset: $offset
      bodyparts: $bodyparts
      groups: $groups
      difficulty: $difficulty
      creator: $creator
    ) {
      allExercises {
        id
        title
        img
        status
        creator {
          id
          trainerId
          companyId
        }
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const SingleExerciseQuery = gql`
  query fetchExercise($id: Int!) {
    fetchExercise(id: $id) {
      id
      title
      description
      note
      recommendations
      device_settings
      resistance
      cave
      series
      video
      img
      goal
      createdAt
      status
      termination_condition_value
      termination_condition {
        id
        name
      }
      difficulty {
        id
        name
      }
      groups {
        id
        name
      }
      photos {
        id
        path
      }
      equipments {
        id
        name
      }
      bodyparts {
        id
        name
      }
      motions {
        id
        motion
      }
      creator {
        id
        trainerId
        companyId
      }
    }
  }
`;

export const FetchExerciseForPDFQuery = gql`
  query fetchExercise($id: Int!) {
    fetchExercise(id: $id) {
      id
      title
      img
      status
      difficulty {
        id
        name
      }
      bodyparts {
        id
        name
      }
      groups {
        id
        name
      }
      equipments {
        id
        name
      }
      photos {
        id
        path
      }
      device_settings
      resistance
      goal
      cave
      series
      termination_condition_value
      termination_condition {
        id
        name
      }
      reps
      motions {
        id
        motion
      }
      note
      description
      recommendations
      creator {
        id
        trainerId
        companyId
        personal {
          name
          surname
        }
      }
    }
  }
`;

export const SingleCustomExerciseQuery = gql`
  query fetchCostumExercise($id: Int!) {
    fetchCostumExercise(id: $id) {
      id
      title
      description
      note
      recommendations
      device_settings
      resistance
      cave
      series
      reps
      video
      img
      goal
      status
      createdAt
      termination_condition_value
      termination_condition {
        id
        name
      }
      difficulty {
        id
        name
      }
      groups {
        id
        name
      }
      photos {
        id
        path
      }
      equipments {
        id
        name
      }
      bodyparts {
        id
        name
      }
      motions {
        id
        motion
      }
    }
  }
`;

export const FetchCustomExerciseForPDFQuery = gql`
  query fetchCostumExercise($id: Int!) {
    fetchCostumExercise(id: $id) {
      id
      title
      img
      status
      difficulty {
        id
        name
      }
      bodyparts {
        id
        name
      }
      groups {
        id
        name
      }
      equipments {
        id
        name
      }
      photos {
        id
        path
      }
      device_settings
      resistance
      goal
      cave
      series
      termination_condition_value
      termination_condition {
        id
        name
      }
      reps
      motions {
        id
        motion
      }
      note
      description
      recommendations
      creator {
        id
        trainerId
        companyId
        personal {
          name
          surname
        }
      }
      taken_plan {
        client {
          id
          personal {
            name
            surname
          }
        }
      }
    }
  }
`;

export const ExerciseCount = gql`
  query CountExercises {
    countExercise
  }
`;
