import React from "react";
import PropTypes from "prop-types";
import { NotFound } from "../../components/UI";

//Stop react halt or exit
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null
        };
    }
    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            info: info
        });
    }
    render() {
        if (this.state.hasError) {
            console.log("====================================");
            console.log({ state: this.state });
            console.log("====================================");
            if (this.state.error.statusCode === 404) {
                return <NotFound />;
            }
            return (
                <section className="main-content">
                    <div className="container-fluid">
                        <div>
                            <h1>Oops, something went wrong :(</h1>
                            <p>The error: {this.state.error.toString()}</p>
                            <p>Where it occured: {this.state.info.componentStack}</p>
                        </div>
                    </div>{" "}
                </section>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};

export default ErrorBoundary;
