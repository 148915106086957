import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../store/global/global_actions";
import { withRouter } from "react-router-dom";

const SingleButtonModalComponent = props => {
    const globalState = useSelector(s => s.globalState);
    const dispatch = useDispatch();

    let closeModal = () => {
        dispatch(hideModal());
        if (get(globalState, "modal.link", false)) props.history.push(globalState.modal.link);
    };

    return (
        <Modal
            visible={get(globalState, "modal.show", false)}
            dialogClassName="modal-dialog modal-dialog-centered"
            onClick={closeModal}
        >
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                    <i className="icon-cancel"></i>
                </button>
                <div className="modal-body">
                    <div className="excercises-created">
                        <div className="icon">
                            <i className={get(globalState, "modal.isError", false) ? "icon-cancel" : "icon-check"}></i>
                        </div>
                        <div className="content">
                            <p style={{ fontSize: "20px" }}>{get(globalState, "modal.message", "")}</p>
                        </div>
                        <div className="btn-modal">
                            <a
                                href="#"
                                className="add"
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    closeModal();
                                }}
                            >
                                {get(globalState, "modal.button", "")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

SingleButtonModalComponent.propTypes = {
    history: PropTypes.object
};

export default withRouter(SingleButtonModalComponent);
