import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TrainingplanList from "../../../components/Trainingplan/TrainingplanList";
import { FetchAllTrainingPlansQuery } from "./GraphQLActions";

const AllTrainingplans = () => {
    const dispatch = useDispatch();
    const { planState } = useSelector(s => s);
    const filters = planState.filters;
    const [error, setError] = useState(null);

    useEffect(() => {
        dispatch(
            FetchAllTrainingPlansQuery({
                ...filters,
                creator: filters.creator.id || null,
                difficulty: filters.difficulty.id || null,
                trainingPeriods: filters.trainingPeriods.map(e => e.id),
                phases: filters.phases.map(e => e.id),
                bodyParts: filters.bodyParts.map(e => e.id),
                equipments: filters.equipments.map(e => e.id)
            })
        ).catch(err => {
            setError(err);
        });
    }, [dispatch, filters]);

    if (error) throw error;
    return (
        <div className="container-fluid">
            <div className="row">
                {planState.allPlans.map(plan => {
                    return <TrainingplanList key={plan.id} plan={plan} />;
                })}
            </div>
        </div>
    );
};

export default AllTrainingplans;
