const updateObject = (object, data) => {
  if (!data.name) return { ...object };

  console.log({ data, object });

  let name = data.name.split(".");
  let firstKey = name[0];
  if (Array.isArray(name) && name.length > 1) {
    name.shift();
    name = name.join(".");
    return {
      ...object,
      [firstKey]: {
        ...updateObject(object[firstKey] || {}, { name, value: data.value }),
      },
    };
  }

  return { ...object, [firstKey]: data.value };
};

export default updateObject;
