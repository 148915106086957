import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import { userFilter } from "../../../store/user/user/user_actions";
import UsersList from "../../../components/Client/User/UsersList";
import { FetchAllUsersQuery } from "./GraphQLActions";
import {
  orderHandler,
  classNameGenerator,
} from "../../../helpers/OrderHandlers";

const AllUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userState = useSelector((s) => s.userState);
  const filters = userState.filters;

  useEffect(() => {
    dispatch(
      FetchAllUsersQuery({
        ...filters,
        status: filters.status.id || null,
        assigned: filters.assigned.id || "ALL",
      })
    );
  }, [dispatch, filters]);

  if (userState.error) return `Error! ${userState.error}`;

  return (
    <>
      <table className="table table-priosan">
        <thead>
          <tr>
            <th>
              <div className="head-table">
                <p>{t("image.label")}</p>
              </div>
            </th>
            <th>
              <div className="head-table">
                <a
                  onClick={(e) =>
                    orderHandler(e, filters.order, "name", dispatch, userFilter)
                  }
                  href="#"
                >
                  <p>{t("name.label")}</p>
                  <i className={classNameGenerator(filters.order, "name")} />
                </a>
              </div>
            </th>
            {/* <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "phoneNumber", dispatch, userFilter)}
                                    href="#"
                                >
                                    <p>{t("phone.label")}</p>
                                    <i className={classNameGenerator(filters.order, "phoneNumber")} />
                                </a>
                            </div>
                        </th> */}
            <th>
              <div className="head-table">
                <a
                  onClick={(e) =>
                    orderHandler(
                      e,
                      filters.order,
                      "email",
                      dispatch,
                      userFilter
                    )
                  }
                  href="#"
                >
                  <p>{t("email.label")}</p>
                  <i className={classNameGenerator(filters.order, "email")} />
                </a>
              </div>
            </th>
            {/* <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "website", dispatch, userFilter)}
                                    href="#"
                                >
                                    <p>{t("website.label")}</p>
                                    <i className={classNameGenerator(filters.order, "website")} />
                                </a>
                            </div>
                        </th> */}
            <th>
              <div className="head-table">
                <a
                  onClick={(e) =>
                    orderHandler(e, filters.order, "city", dispatch, userFilter)
                  }
                  href="#"
                >
                  <p>{t("city.label")}</p>
                  <i className={classNameGenerator(filters.order, "city")} />
                </a>
              </div>
            </th>
            <th>
              <div className="head-table">
                <a
                  onClick={(e) =>
                    orderHandler(
                      e,
                      filters.order,
                      "updatedAt",
                      dispatch,
                      userFilter
                    )
                  }
                  href="#"
                >
                  <p>{t("updated_at.label")}</p>
                  <i
                    className={classNameGenerator(filters.order, "updatedAt")}
                  />
                </a>
              </div>
            </th>
            <th>
              <div className="head-table">
                <a
                  onClick={(e) =>
                    orderHandler(
                      e,
                      filters.order,
                      "status",
                      dispatch,
                      userFilter
                    )
                  }
                  href="#"
                >
                  <p>{t("status.label")}</p>
                  <i className={classNameGenerator(filters.order, "status")} />
                </a>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {get(userState, "allUsers", []).map((user) => {
            return <UsersList key={user.id} users={user} />;
          })}
        </tbody>
      </table>
    </>
  );
};

AllUsers.propTypes = {
  history: PropTypes.object,
  search: PropTypes.string,
  status: PropTypes.number,
  offset: PropTypes.number,
};

export default withRouter(AllUsers);
