import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";

import AllExercises from "./allExercises";
import Search from "../../../components/UI/Search/Search";
import Select from "../../../components/UI/Select/Select";
import { searchBaseModels } from "../../../resolvers/query/base";
import { resetExerState, exerciseFilter } from "../../../store/exercise/exercise_actions";
import FilterMultipleSelectComp from "../../../components/UI/Select/FiltersMultipleSelect";
import { useTranslation } from "react-i18next";

const Exercise = () => {
    const { t } = useTranslation();
    const exerciseState = useSelector(s => s.exerciseState);
    const dispatch = useDispatch();
    const filters = exerciseState.filters;

    let { data, loading, error } = useQuery(searchBaseModels, {
        variables: {
            group: true,
            bodypart: true,
            difficulty: true,
            company: "exercise"
        }
    });

    const BaseModels = () => {
        if (loading) {
            return (
                <Fragment>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <FilterMultipleSelectComp text={t("filter_bodypart.placeholder")} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <FilterMultipleSelectComp text={t("filter_group.placeholder")} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Select text={t("filter_difficulty.placeholder")} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Select text={t("filter_library.placeholder")} />
                    </div>
                </Fragment>
            );
        }
        if (error) return `Error! ${error}`;
        return (
            <Fragment>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <FilterMultipleSelectComp
                        text={t("filter_bodypart.placeholder")}
                        select={e => bodypartSelectHandler(e)}
                        selectedItems={filters.bodyparts}
                        items={data.searchBaseModels.BodyPart}
                    />
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <FilterMultipleSelectComp
                        text={t("filter_group.placeholder")}
                        select={e => groupSelectHandler(e)}
                        selectedItems={filters.groups}
                        items={data.searchBaseModels.Group}
                    />
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <Select
                        text={t("filter_difficulty.placeholder")}
                        selectedItem={filters.difficulty.id || ""}
                        select={e => difficultySelectHandler(e)}
                        items={data.searchBaseModels.Difficulty}
                    />
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <Select
                        text={t("filter_library.placeholder")}
                        selectedItem={filters.creator.id || ""}
                        select={e => creatorSelectHandler(e)}
                        items={data.searchBaseModels.Company}
                    />
                </div>
            </Fragment>
        );
    };

    let searchHandler = e => {
        dispatch(exerciseFilter({ search: e.target.value, offset: 0, page: 1 }));
    };

    let bodypartSelectHandler = e => {
        dispatch(exerciseFilter({ bodyparts: e ? e : [], offset: 0, page: 1 }));
    };

    let groupSelectHandler = e => {
        dispatch(exerciseFilter({ groups: e ? e : [], offset: 0, page: 1 }));
    };

    let difficultySelectHandler = e => {
        dispatch(exerciseFilter({ difficulty: e ? e : null, offset: 0, page: 1 }));
    };

    let creatorSelectHandler = e => {
        dispatch(exerciseFilter({ creator: e ? e : null, offset: 0, page: 1 }));
    };

    return (
        <section className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        <div className="training-result">
                            <p>
                                {t("total.label")}:
                                <span>
                                    {" "}
                                    {exerciseState.totalExercises || 0} {t("exercises.label")}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="create-icon">
                            <Link onClick={() => dispatch(resetExerState())} to="exercises/new-exercise/upload-file">
                                <i className="icon-add"></i>
                                <span>{t("create_exercise.label")}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-between training-filter">
                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-3">
                        <div className="row">
                            <Search searchHandler={e => searchHandler(e)} querySearch={filters.search || ""} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                        <div className="row">{BaseModels()}</div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row excercises">
                    <AllExercises />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        {exerciseState.totalExercises > 0 ? (
                            <ReactPaginate
                                previousLabel={<i className="icon-arrow-left"></i>}
                                nextLabel={<i className="icon-arrow-right"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(exerciseState.totalExercises / 8) || 0}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={e => {
                                    dispatch(
                                        exerciseFilter({
                                            offset: e.selected * 8,
                                            page: parseInt(e.selected) + 1
                                        })
                                    );
                                }}
                                forcePage={filters.page - 1}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                activeLinkClassName={"active"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

Exercise.propTypes = {
    history: PropTypes.object
};

export default Exercise;
