import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import get from "lodash/get";
// import validator from 'validator'

import { searchBaseModels } from "../../../../resolvers/query/base";
import { plan, updateTrainingPlan } from "../../../../store/trainingplans/trainingplans_actions";

import WizardNav from "../../../../components/UI/WizardNav/WizardNav";
import Select from "../../../../components/UI/Select/Select";
import FiltersMultipleSelect from "../../../../components/UI/Select/FiltersMultipleSelect";

import { IsTitleUniqueQuery } from "../GraphQLActions";
import { useTranslation } from "react-i18next";
// import FormValidator from '../../../../helpers/FormValidation'

const GeneralInfo = props => {
    const { t } = useTranslation();
    const planState = useSelector(s => s.planState);
    const reduxState = planState.singleTrainingPlan;
    const updateState = planState.updateTrainingPlan;
    const errors = planState.isValidGeneralInfo.errors;

    const dispatch = useDispatch();
    const [isTitleUnique, setTitleUnique] = useState(true);

    let uploadMainPhotoHandler = e => {
        dispatch(
            updateTrainingPlan({
                imgFile: e.target.files[0],
                img: URL.createObjectURL(e.target.files[0])
            })
        );
    };

    let formChangeHandler = (e, num = false) => {
        if (e.target.name) {
            if (num) {
                dispatch(updateTrainingPlan({ [e.target.name]: parseInt(e.target.value) || 0 }));
            } else {
                dispatch(updateTrainingPlan({ [e.target.name]: e.target.value }));
            }
        }
    };

    let submitHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        if (planState.isValidGeneralInfo.isValid) {
            props.history.push(
                reduxState.id
                    ? "/dashboard/trainingplans/" + reduxState.id + "/edit-trainingplan/select-exercises"
                    : "/dashboard/trainingplans/new-trainingplan/select-exercises"
            );
        } else {
            dispatch(plan({ displayGenerealInfoErrors: true }));
        }
    };

    return (
        <section className="main-content">
            <WizardNav
                first={t("general_info.label")}
                firstLink="general-info"
                second={t("select_exercises.label")}
                secondLink="select-exercises"
                third={t("overview.label")}
                thirdLink="overview"
                active1={planState.isValidGeneralInfo.isValid}
                active2={planState.isValidGeneralInfo.isValid && planState.isValidSelectExercises}
                active={1}
            />
            <div className="container-fluid">
                <form onSubmit={e => submitHandler(e)}>
                    <div className="training-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-file-upload">
                                    <div className="file-upload">
                                        <div className="image-upload-wrap">
                                            <input
                                                maxLength={250}
                                                id="mainImageInput"
                                                type="file"
                                                className="file-upload-input"
                                                accept="image/*"
                                                onChange={e => uploadMainPhotoHandler(e)}
                                            />
                                            {get(updateState, "img", get(reduxState, "img", null)) ? (
                                                <img
                                                    alt="uploaded img"
                                                    style={{
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        height: "100%"
                                                    }}
                                                    src={get(updateState, "img", get(reduxState, "img", null))}
                                                />
                                            ) : (
                                                <div className="drag-text">
                                                    <i className="icon-picture"></i>
                                                    <span>{t("dragndrop.label")}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="button-upload">
                                        <button
                                            onClick={e => {
                                                e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                                document.getElementById("mainImageInput").click();
                                            }}
                                            className="btn btn-upload"
                                        >
                                            {t("upload_img.label")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>
                                        {t("title.label")}
                                        <span className="form-required">*</span>
                                    </label>
                                    <input
                                        maxLength={250}
                                        // required
                                        onChange={e => {
                                            formChangeHandler(e);
                                        }}
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        defaultValue={get(updateState, "title", get(reduxState, "title", ""))}
                                        onBlur={e => {
                                            if (!props.match.path.includes("edit-trainingplan"))
                                                dispatch(IsTitleUniqueQuery({ title: e.target.value })).then(res =>
                                                    setTitleUnique(res)
                                                );
                                        }}
                                    />
                                    {isTitleUnique ? null : (
                                        <p style={{ color: "red", fontSize: "15px" }}>
                                            Plan with this title is alredy on your library
                                        </p>
                                    )}
                                    {!planState.displayGenerealInfoErrors ? null : (
                                        <p style={{ color: "red", fontSize: "15px" }}>{errors.title}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("objective.label")}</label>
                                    <input
                                        maxLength={250}
                                        onChange={e => formChangeHandler(e)}
                                        defaultValue={get(updateState, "objective", get(reduxState, "objective", ""))}
                                        type="text"
                                        name="objective"
                                        className="form-control"
                                    />
                                    {!planState.displayGenerealInfoErrors ? null : (
                                        <p style={{ color: "red", fontSize: "15px" }}>{errors.objective}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>
                                        {t("duration.label")}
                                        <span className="form-required">*</span>
                                    </label>
                                    <input
                                        maxLength={250}
                                        onChange={e => formChangeHandler(e, true)}
                                        type="text"
                                        name="duration"
                                        className="form-control"
                                        value={get(updateState, "duration", get(reduxState, "duration", ""))}
                                    />
                                    {!planState.displayGenerealInfoErrors ? null : (
                                        <p style={{ color: "red", fontSize: "15px" }}>{errors.duration}</p>
                                    )}
                                </div>
                            </div>
                            <Query
                                query={searchBaseModels}
                                variables={{
                                    trainingperiod: true,
                                    phase: true,
                                    bodypart: true,
                                    equipment: true,
                                    difficulty: true
                                }}
                            >
                                {({ data, loading, error }) => {
                                    if (error) return `Error ${error}`;
                                    if (loading) {
                                        return (
                                            <Fragment>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("trainingperiods.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <FiltersMultipleSelect
                                                            text={t("select_trainingperiods.placeholder")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("trainingphases.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <FiltersMultipleSelect
                                                            text={t("select_trainingphases.placeholder")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("bodyparts.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <FiltersMultipleSelect
                                                            text={t("select_bodyparts.placeholder")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("equipment.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <FiltersMultipleSelect
                                                            text={t("select_euqipments.placeholder")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>
                                                            {t("difficulty.label")}
                                                            <span className="form-required">*</span>
                                                        </label>
                                                        <Select text={t("select_difficulty.placeholder")} />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        );
                                    }
                                    data = data.searchBaseModels;
                                    return (
                                        <Fragment>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("trainingperiods.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <FiltersMultipleSelect
                                                        text={t("select_trainingperiods.placeholder")}
                                                        select={e =>
                                                            dispatch(updateTrainingPlan({ trainingperiods: e }))
                                                        }
                                                        selectedItems={get(
                                                            updateState,
                                                            "trainingperiods",
                                                            get(reduxState, "trainingperiods", [])
                                                        )}
                                                        items={data.TrainingPeriod}
                                                        required
                                                    />
                                                    {!planState.displayGenerealInfoErrors ? null : (
                                                        <p style={{ color: "red", fontSize: "15px" }}>
                                                            {errors.trainingperiods}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("trainingphases.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <FiltersMultipleSelect
                                                        text={t("select_trainingphases.placeholder")}
                                                        select={e => dispatch(updateTrainingPlan({ phases: e }))}
                                                        selectedItems={get(
                                                            updateState,
                                                            "phases",
                                                            get(reduxState, "phases", [])
                                                        )}
                                                        items={data.Phase}
                                                    />
                                                    {!planState.displayGenerealInfoErrors ? null : (
                                                        <p style={{ color: "red", fontSize: "15px" }}>
                                                            {errors.phases}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("bodyparts.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <FiltersMultipleSelect
                                                        text={t("select_bodyparts.placeholder")}
                                                        select={e => dispatch(updateTrainingPlan({ bodyparts: e }))}
                                                        selectedItems={get(
                                                            updateState,
                                                            "bodyparts",
                                                            get(reduxState, "bodyparts", [])
                                                        )}
                                                        items={data.BodyPart}
                                                    />
                                                    {!planState.displayGenerealInfoErrors ? null : (
                                                        <p style={{ color: "red", fontSize: "15px" }}>
                                                            {errors.bodyparts}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("equipment.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <FiltersMultipleSelect
                                                        text={t("select_euqipments.placeholder")}
                                                        select={e => dispatch(updateTrainingPlan({ equipments: e }))}
                                                        selectedItems={get(
                                                            updateState,
                                                            "equipments",
                                                            get(reduxState, "equipments", [])
                                                        )}
                                                        items={data.Equipment}
                                                    />
                                                    {!planState.displayGenerealInfoErrors ? null : (
                                                        <p style={{ color: "red", fontSize: "15px" }}>
                                                            {errors.equipments}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("difficulty.label")}
                                                        <span className="form-required">*</span>
                                                    </label>
                                                    <Select
                                                        text="Select the difficulty"
                                                        select={e => dispatch(updateTrainingPlan({ difficulty: e }))}
                                                        selectedItem={get(
                                                            updateState,
                                                            "difficulty.id",
                                                            get(reduxState, "difficulty.id", "")
                                                        )}
                                                        items={data.Difficulty}
                                                    />
                                                    {!planState.displayGenerealInfoErrors ? null : (
                                                        <p style={{ color: "red", fontSize: "15px" }}>
                                                            {errors.difficulty}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                }}
                            </Query>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t("description.label")}</label>
                                    <textarea
                                        maxLength={250}
                                        name="description"
                                        defaultValue={get(
                                            updateState,
                                            "description",
                                            get(reduxState, "description", "")
                                        )}
                                        onChange={e => formChangeHandler(e)}
                                        className="form-control"
                                        rows="3"
                                    ></textarea>
                                    {!planState.displayGenerealInfoErrors ? null : (
                                        <p style={{ color: "red", fontSize: "15px" }}>{errors.description}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t("notes.label")}</label>
                                    <textarea
                                        maxLength={250}
                                        defaultValue={get(updateState, "notes", get(reduxState, "notes", ""))}
                                        onChange={e => formChangeHandler(e)}
                                        name="notes"
                                        className="form-control"
                                        rows="3"
                                    ></textarea>
                                    {!planState.displayGenerealInfoErrors ? null : (
                                        <p style={{ color: "red", fontSize: "15px" }}>{errors.notes}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                                <div className="btn-right">
                                    <Link to="/dashboard/trainingplans/" className="btn btn-cancel btn-180">
                                        {t("cancel.label")}
                                    </Link>
                                    <button
                                        // disabled={!planState.isValidGeneralInfo.isValid}
                                        type="submit"
                                        className="btn btn-primary btn-180"
                                    >
                                        {t("next.label")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

GeneralInfo.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object
};

export default GeneralInfo;
