import React from "react";

// import "../../assets/css/style.min.css";

import Login from "./Login";

import ResetPassword from "./ResetPassword";

import ForgotPassword from "./ForgotPassword";

import { Route } from "react-router-dom";
import Loading from "../../components/UI/Loading";
import { useSelector } from "react-redux";

const Auth = () => {
    let { globalState } = useSelector(s => s);
    return (
        <section className="auth">
            <Loading loading={globalState.loading} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                        <Route exact path="/auth/forgetpassword/:token" component={ResetPassword} />
                        <Route exact path="/auth/forgetpassword" component={ForgotPassword} />
                        <Route exact path="/auth" component={Login} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Auth;
