import React from "react";
import { useSelector } from "react-redux";

const NotFound = () => {
    const globalState = useSelector(s => s.globalState);
    if (globalState.loading) {
        return <section></section>;
    }
    return (
        <section className="main-content">
            <h1 style={{ fontSize: "250%", fontWeight: 800 }}>404 Not Found</h1>
        </section>
    );
};
export default NotFound;
