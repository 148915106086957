import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import SingleCompany from "../../../components/Client/Company/SingleCompany";
import NewCompany from "./NewCompany";
import Loading from "../../../components/UI/Loading";
import { FetchCompanyByIdQuery } from "./GraphQLActions";
import { useParams } from "react-router-dom";
import { company } from "../../../store/user/company/company_actions";

const FetchCompany = props => {
    const dispatch = useDispatch();
    const companyState = useSelector(s => s.companyState);
    const { singleCompany } = companyState;

    const { companyId } = useParams();

    useEffect(() => {
        if (!props.fetch) {
            dispatch(company({ updateFlag: true }));
        } else {
            dispatch(company({ updateCompany: {} }));
        }
        dispatch(company({ updateCompany: {} }));
        if (singleCompany.id !== parseInt(companyId))
            dispatch(FetchCompanyByIdQuery({ id: parseInt(companyId), roleId: 2 }));
    }, [singleCompany.id, companyId, dispatch, props.fetch]);

    if (companyState.error) return `Error! ${companyState.error}`;

    if (singleCompany.id === parseInt(companyId))
        if (props.fetch) {
            return <SingleCompany />;
        } else {
            return <NewCompany update {...props} />;
        }

    return <Loading />;
};

FetchCompany.propTypes = {
    fetch: PropTypes.bool
};

export default FetchCompany;
