import React, { useEffect } from "react";
import { Switch, Route } from "react-router";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import GeneralInfo from "./CreateTestv1/GeneralInfo";
import Questions from "./CreateTestv1/Questions";
import Overview from "./CreateTestv1/Overview";
import { test } from "../../../store/test/test_actions";
import { useDispatch } from "react-redux";

const NewTest = () => {
    const { id } = useSelector(s => s.testState.updateTest);
    const dispatch = useDispatch();
    useEffect(() => {
        if (id) dispatch(test({ updateTest: {} }));
    }, [dispatch, id]);

    return (
        <Switch>
            <Route path="/dashboard/tests/new-test/overview" component={Overview} />
            <Route path="/dashboard/tests/new-test/questions" component={Questions} />
            <Route path="/dashboard/tests/new-test/" component={GeneralInfo} />
        </Switch>
    );
};

NewTest.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
};

export default NewTest;
