import React from "react";
import { Switch, Route } from "react-router";
import { useSelector } from "react-redux";

import Company from "./index";
import FetchCompany from "./fetchCompany";
import NewCompany from "./NewCompany";
import Loading from "../../../components/UI/Loading";

const CompanyRoutes = () => {
    const globalState = useSelector(s => s.globalState);
    return (
        <>
            <Loading loading={globalState.loading} />
            <Switch>
                <Route path="/dashboard/companies/new-company" component={NewCompany} />
                <Route path="/dashboard/companies/:companyId(\d+)/edit-company" component={FetchCompany} />
                <Route path="/dashboard/companies/:companyId(\d+)">
                    <FetchCompany fetch />
                </Route>
                <Route path="/dashboard/companies/" component={Company} />
            </Switch>
        </>
    );
};

export default CompanyRoutes;
