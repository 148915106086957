import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import Modal from "../../../components/UI/Modal/Modal";

import { EditCompanyProfile } from "./GraphQLActions";
import { auth } from "../../../store/auth/auth_actions";
import { showModal } from "../../../store/global/global_actions";

const Company = props => {
    const { t } = useTranslation();
    const authState = useSelector(s => s.authState);
    const companyState = authState.company;
    const dispatch = useDispatch();
    const [state, setState] = useState({});

    /**
     * @param {Event} e - The event
     */
    let onChangeForm = e => {
        let newState = { ...state };
        newState[e.target.name] = e.target.value;
        setState(newState);
    };

    /**
     * @param {Event} e - The event
     */
    let uploadMainPhotoHandler = e => {
        setState({
            ...state,
            imgFile: e.target.files[0],
            img: URL.createObjectURL(e.target.files[0])
        });
    };

    useEffect(() => {
        if (authState.role.id !== 3 || !authState.is_admin) {
            props.history.push("/dashboard/settings");
        }
    }, [authState.is_admin, authState.role.id, props.history]);

    /**
     * @param {Event} e - The event
     */
    let submitFormHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        if (!isEmpty(state))
            dispatch(EditCompanyProfile({ ...state, img: state.imgFile })).then(() => {
                dispatch(showModal({ message: t("co_success_updated.message"), button: "Ok." }));
            });
    };

    return (
        <Fragment>
            <div className="col-md-9">
                <div className="settings-form">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="settings-company-img">
                                        <img
                                            style={{
                                                width: "100%",
                                                objectFit: "cover",
                                                height: "100%"
                                            }}
                                            src={get(state, "img", get(companyState, "img", ""))}
                                            alt="logo"
                                        />
                                        <div className="settings-company-file">
                                            <input
                                                maxLength={255}
                                                onChange={e => uploadMainPhotoHandler(e)}
                                                type="file"
                                                className="settings-company-file-upload"
                                            />
                                            <span>{t("upload_img.label")}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t("name.label")}</label>
                                                <input
                                                    maxLength={255}
                                                    onChange={e => onChangeForm(e)}
                                                    defaultValue={get(
                                                        state,
                                                        "name",
                                                        get(companyState, "personal.name", "")
                                                    )}
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t("address.label")}</label>
                                                <input
                                                    maxLength={255}
                                                    onChange={e => onChangeForm(e)}
                                                    defaultValue={get(
                                                        state,
                                                        "street",
                                                        get(companyState, "address.street", "")
                                                    )}
                                                    type="text"
                                                    name="street"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t("website.label")}</label>
                                                <input
                                                    maxLength={255}
                                                    onChange={e => onChangeForm(e)}
                                                    defaultValue={get(
                                                        state,
                                                        "website",
                                                        get(companyState, "private_info.website", "")
                                                    )}
                                                    type="text"
                                                    name="website"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t("phone.label")}</label>
                                                <input
                                                    maxLength={255}
                                                    onChange={e => onChangeForm(e)}
                                                    defaultValue={get(
                                                        state,
                                                        "phoneNumber",
                                                        get(companyState, "personal.phoneNumber", "")
                                                    )}
                                                    type="text"
                                                    name="phoneNumber"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-end">
                                    <div className="settings-button">
                                        <button
                                            disabled={isEmpty(state)}
                                            onClick={e => submitFormHandler(e)}
                                            className="btn btn-primary btn-180"
                                        >
                                            {t("update.label")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={authState.modal}
                closeModal={() => dispatch(auth({ modal: false }))}
                text="Company profile updated successfully."
                oneButton
            />
        </Fragment>
    );
};

Company.propTypes = {
    history: PropTypes.object
};

export default Company;
