export const BaseInfoRules = (isUpdate) => [
  {
    field: "trainer",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Trainer got invalid value."],
    required: !isUpdate,
    variableType: ["object"],
  },
];

export const AdministrationInfoRules = (isUpdate) => [
  {
    field: "PID",
    rules: [{ name: "isUUID", params: "all", validWhen: true }],
    messages: ["PID is wrong formatted."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "people_group",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["People group is required."],
    variableType: ["object"],
  },
  {
    field: "contact_channel",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Contact channel is required."],
    variableType: ["object"],
  },
  {
    field: "found_from",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Input too long."],
    variableType: [],
  },
  {
    field: "referenced_by",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Input too long."],
    variableType: [],
  },
  {
    field: "remark_contact",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Remark contact field too long."],
    variableType: [],
  },
  {
    field: "remark_death",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Remark death field too long."],
    variableType: [],
  },
];

export const PersonalInfoRules = (isUpdate) => [
  {
    field: "email",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isEmail", params: {}, validWhen: true },
    ],
    messages: ["Email is required.", "Email is not valid."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "name",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 100 }, validWhen: true },
    ],
    messages: ["Name is required.", "Name input too long."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "surname",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 100 }, validWhen: true },
    ],
    messages: ["Surname is required.", "Surname input too long."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "bussiness_name",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Business name input too long."],
    variableType: [],
  },
  {
    field: "gender",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Gender is required."],
    variableType: ["object"],
  },
  {
    field: "birthdate",
    rules: [{ name: "isEmpty", params: {}, validWhen: false }],
    messages: ["Birthdate is required."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "phoneNumber",
    rules: [{ name: "isLength", params: { max: 50 }, validWhen: true }],
    messages: ["Phone number too long."],
    variableType: [],
  },
  {
    field: "landline",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Landline too long."],
    variableType: [],
  },
  {
    field: "communication_channel",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Communication channel is required"],
    variableType: ["array", "object"],
  },
  {
    field: "remark_communication_channel",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Remark communication channel field to long"],
    variableType: [],
  },
  {
    field: "comments",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Communication channel field to long"],
    variableType: [],
  },
];

export const AddressInfoRules = (isUpdate) => [
  {
    field: "street",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 150 }, validWhen: true },
    ],
    messages: ["Street is required.", "Street input too long."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "postal",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 100 }, validWhen: true },
    ],
    messages: ["Postal is required.", "Postal input too long."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "city",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 100 }, validWhen: true },
    ],
    messages: ["City name is required.", "City input too long."],
    required: !isUpdate,
    variableType: [],
  },
  {
    field: "country",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Country is required."],
    required: !isUpdate,
    variableType: ["object"],
  },
  {
    field: "canton",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Invalid value."],
    variableType: ["object"],
  },
  {
    field: "additionalAddress",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Additional address field too long"],
    variableType: [],
  },
];

export const PrivateInfoRules = (isUpdate) => [
  {
    field: "website",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Website input too long."],
    variableType: [],
  },
  {
    field: "landline_alt",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Landline alternative input too long."],
    variableType: [],
  },
  {
    field: "mail_alt",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Mail alternative input too long."],
    variableType: [],
  },
  {
    field: "tel_alt",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Mobile alternative input too long."],
    variableType: [],
  },
  {
    field: "fax",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Fax input too long."],
    variableType: [],
  },
  {
    field: "hometown",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Hometown input too long."],
    variableType: [],
  },
  {
    field: "name_unmarried",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Name unmaried input too long."],
    variableType: [],
  },
  {
    field: "emergency_contact",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Emergency contact input too long."],
    variableType: [],
  },
  {
    field: "remark_emergency_contact",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Remark emergency contact input too long."],
    variableType: [],
  },
  {
    field: "emergency_telephone1",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Emergency telephone input too long."],
    variableType: [],
  },
  {
    field: "remark_emergency_telephone1",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Remark emergency contact number 1 input too long."],
    variableType: [],
  },
  {
    field: "emergency_telephone2",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Emergency telephone 2 input too long."],
    variableType: [],
  },
  {
    field: "remark_emergency_telephone2",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Remark emergency contact number 2 input too long."],
    variableType: [],
  },
  {
    field: "remark_private_info",
    rules: [{ name: "isLength", params: { max: 150 }, validWhen: true }],
    messages: ["Remark private info input too long."],
    variableType: [],
  },
];

export const FinanceInfoRules = (isUpdate) => [
  {
    field: "IBAN",
    rules: [{ name: "isLength", params: { max: 35 }, validWhen: true }],
    messages: ["IBAN input too long."],
    variableType: [],
  },
  {
    field: "account_no",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Account number input too long."],
    variableType: [],
  },
  {
    field: "clearing_no",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Clearing number input too long."],
    variableType: [],
  },
  {
    field: "BIC",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["BIC input too long."],
    variableType: [],
  },
  {
    field: "bank_name",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Bank name input too long."],
    variableType: [],
  },
  {
    field: "bank_street",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Bank street input too long."],
    variableType: [],
  },
  {
    field: "bank_postal",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Bank postal input too long."],
    variableType: [],
  },
  {
    field: "bank_city",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Bank city input too long."],
    variableType: [],
  },
  {
    field: "bank_address_addition",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Bank additional address input too long."],
    variableType: [],
  },
  {
    field: "comment",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Other comments input too long."],
    variableType: [],
  },
];

export const AHVInfoRules = (isUpdate) => [
  {
    field: "AHV",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["AHV-nr input too long."],
    variableType: [],
  },
  {
    field: "remark_ahv",
    rules: [{ name: "isLength", params: { max: 200 }, validWhen: true }],
    messages: ["Remark ahv input too long."],
    variableType: [],
  },
  {
    field: "comment",
    rules: [{ name: "isLength", params: { max: 400 }, validWhen: true }],
    messages: ["Other comment input too long."],
    variableType: [],
  },
];

export const SportInfoRules = (isUpdate) => [
  {
    field: "sport",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Sport is required."],
    variableType: ["object"],
  },
  {
    field: "sport_status",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Sport status is required."],
    variableType: ["object"],
  },
  {
    field: "years",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Years god invalid value."],
    variableType: [],
  },
  {
    field: "training_frequency",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Training frequency is required."],
    variableType: ["object"],
  },
  {
    field: "training_status",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Training status is required."],
    variableType: ["object"],
  },
  {
    field: "discipline",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Discipline input too long."],
    variableType: [],
  },
  {
    field: "squad",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Squad input too long."],
    variableType: [],
  },
  {
    field: "training_group",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Training group input too long."],
    variableType: [],
  },
  {
    field: "association",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Association input too long."],
    variableType: [],
  },
  {
    field: "comment",
    rules: [{ name: "isLength", params: { max: 400 }, validWhen: true }],
    messages: ["Comment input too long. Max 400 char"],
    variableType: [],
  },
];

export const SportSpecialistInfoRules = (isUpdate) => [
  {
    field: "specialist_PID",
    rules: [{ name: "isUUID", params: "all", validWhen: true }],
    messages: ["Specialist PID got invalid value."],
    variableType: [],
  },
  {
    field: "name",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Name input too long."],
    variableType: [],
  },
  {
    field: "specialist_function",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Function is required."],
    variableType: ["object"],
  },
  {
    field: "email",
    rules: [
      { name: "isLength", params: { max: 250 }, validWhen: true },
      { name: "isEmail", params: {}, validWhen: true },
    ],
    messages: ["Email input too long.", "Please provide a valid email."],
    variableType: [],
  },
  {
    field: "mobile",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Mobile input too long."],
    variableType: [],
  },
  {
    field: "mobile_alternative",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Mobile alternative input too long."],
    variableType: [],
  },
  {
    field: "comments",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Comments input too long."],
    variableType: [],
  },
];

export const WorkInfoRules = (isUpdate) => [
  {
    field: "PIDcompany",
    rules: [{ name: "isUUID", params: "all", validWhen: true }],
    messages: ["Company PID is required.", "Street input too long."],
    variableType: [],
  },
  {
    field: "job_name",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Job name input too long."],
    variableType: [],
  },
  {
    field: "company_name",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Company name input too long."],
    variableType: [],
  },
  {
    field: "department",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Department input too long."],
    variableType: [],
  },
  {
    field: "position",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Position input too long."],
    variableType: [],
  },
  {
    field: "street",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Street input too long."],
    variableType: [],
  },
  {
    field: "additional_address",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Additional address input too long."],
    variableType: [],
  },
  {
    field: "postal",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Postal input too long."],
    variableType: [],
  },
  {
    field: "city",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["City input too long."],
    variableType: [],
  },
  {
    field: "mobile",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Mobile input too long."],
    variableType: [],
  },
  {
    field: "landline",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Landline input too long."],
    variableType: [],
  },
  {
    field: "email",
    rules: [
      { name: "isEmail", params: {}, validWhen: true },
      { name: "isLength", params: { max: 100 }, validWhen: true },
    ],
    messages: ["Email got invalid value.", "Email input too long."],
    variableType: [],
  },
  {
    field: "website",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Website input too long."],
    variableType: [],
  },
  {
    field: "fax",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Fax input too long."],
    variableType: [],
  },
];

export const MedicineInfoRules = () => [
  {
    field: "KK_KVG",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["KK KVG input too long."],
    variableType: [],
  },
  {
    field: "KK_zusatz",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["KK Zusatz input too long."],
    variableType: [],
  },
  {
    field: "police_no",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Police number input too long."],
    variableType: [],
  },
  {
    field: "accident_insurance",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Accident insurance input too long."],
    variableType: [],
  },
];

export const MedExpertInfoRules = (isUpdate) => [
  {
    field: "med_function",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Medical expert function got invalid value."],
    variableType: ["object"],
  },
  {
    field: "name",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Name input too long."],
    variableType: [],
  },
  {
    field: "surname",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Surname input too long."],
    variableType: [],
  },
  {
    field: "salutation",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Salutation input too long."],
    variableType: [],
  },
  {
    field: "street",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Street input too long."],
    variableType: [],
  },
  {
    field: "postal",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Postal input too long."],
    variableType: [],
  },
  {
    field: "city",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["City input too long."],
    variableType: [],
  },
  {
    field: "mobile",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Mobile input too long."],
    variableType: [],
  },
  {
    field: "email",
    rules: [
      { name: "isEmail", params: {}, validWhen: true },
      { name: "isLength", params: { max: 100 }, validWhen: true },
    ],
    messages: ["Email got invalid value.", "Email input too long."],
    variableType: [],
  },
];

export const DiagnoseInfoRules = () => [
  {
    field: "typ",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Typ input too long."],
    variableType: [],
  },
  {
    field: "description",
    rules: [{ name: "isLength", params: { max: 400 }, validWhen: true }],
    messages: ["Description input too long."],
    variableType: [],
  },
];

export const ProblemInfoRules = () => [
  {
    field: "typ",
    rules: [{ name: "isLength", params: { max: 100 }, validWhen: true }],
    messages: ["Typ input too long."],
    variableType: [],
  },
  {
    field: "description",
    rules: [{ name: "isLength", params: { max: 400 }, validWhen: true }],
    messages: ["Description input too long."],
    variableType: [],
  },
];
