import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";

import placeholder from "../../assets/img/placeholders/400x200.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// const calculateDuration = (time) => {
//     let hours = Math.floor(time / 60);
//     let minutes = time % 60;

//     return `${hours} h ${minutes} min`
// }

/**
 * Props.plan *
    id
    img
    title
    no_of_exercises
 */

const TrainingplanList = props => {
    let [selected, setSelected] = useState(false);
    const { t } = useTranslation();

    let handleClick = () => {
        if (props.selectUserModal) {
            props.selectUserModal(props.plan.id, selected);
            setSelected(!selected);
        } else {
            props.history.push(
                !props.userId
                    ? `trainingplans/${get(props, "plan.id", "-")}`
                    : `${props.userId}/takenplan/${get(props, "plan.id", "-")}`
            );
        }
    };

    return (
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <div onClick={handleClick} style={{ cursor: "pointer" }} className="training-item training-item-border">
                {props.selectUserModal ? (
                    <div className="check-item">
                        <input type="checkbox" checked={selected} onChange={() => setSelected(!selected)} />
                        <label htmlFor="Check"></label>
                    </div>
                ) : null}
                <div className="thumb">
                    <img alt="Main" src={get(props, "plan.img", false) || placeholder} />
                </div>
                <div className="content">
                    <div className="title">
                        <h5>{get(props, "plan.title", "-")}</h5>
                    </div>
                    <div className="time">
                        {/* <span>
                        <i className="icon-time"></i>
                        {calculateDuration(get(props, "plan.time", "-"))}
                    </span> */}
                        <span>
                            <i className="icon-folder"></i>
                            {get(props, "plan.no_of_exercises", "0")} {t("exercises.label")}
                        </span>
                        {props.selectUserModal ? null : (
                            <div className="view-details">
                                <Link
                                    onClick={e => e.preventDefault ? e.preventDefault() : (e.returnValue = false)}
                                    to={
                                        !props.userId
                                            ? `trainingplans/${get(props, "plan.id", "-")}`
                                            : `takenplan/${get(props, "plan.id", "-")}`
                                    }
                                >
                                    {t("view_details.label")}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

TrainingplanList.propTypes = {
    plan: PropTypes.object,
    userId: PropTypes.number,
    selectUserModal: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(TrainingplanList);
