import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

import EditExercise from "./EditExercise";
import SingleExercise from "../../../components/Exercises/SingleExercise";
import {
  FetchExerciseByIdQuery,
  FetchCustomExerciseByIdQuery,
  ToggleExerciseStatusMutation,
  DeleteExerciseMutation,
} from "./GraphQLActions";
import {
  exercise,
  deleteExercise,
} from "../../../store/exercise/exercise_actions";

const FetchExercise = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let exerciseState = useSelector((s) => s.exerciseState);
  let singleExercise = exerciseState.singleExercise;

  let { userId, exerciseId } = useParams();

  /**
   *
   * @param {Event} e
   */
  const toggleExerciseStatus = () => {
    dispatch(
      ToggleExerciseStatusMutation({
        id: singleExercise.id,
        status: singleExercise.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      })
    );
  };

  const deleteExerciseHandler = () => {
    dispatch(DeleteExerciseMutation({ id: parseInt(exerciseId) })).then(() => {
      history.push("/dashboard/exercises");
      dispatch(deleteExercise());
    });
  };

  useEffect(() => {
    dispatch(exercise({ updateFlag: true }));
    if (props.fetch) {
      dispatch(exercise({ updateExercise: {} }));
    }
    if (userId) {
      if (
        singleExercise.id !== parseInt(exerciseId) ||
        singleExercise.__typename !== "CostumExercise"
      ) {
        dispatch(FetchCustomExerciseByIdQuery({ id: parseInt(exerciseId) }));
      }
    } else {
      if (
        singleExercise.id !== parseInt(exerciseId) ||
        singleExercise.__typename !== "Exercise"
      ) {
        dispatch(FetchExerciseByIdQuery({ id: parseInt(exerciseId) }));
      }
    }
  }, [
    singleExercise.id,
    exerciseId,
    userId,
    singleExercise.__typename,
    dispatch,
  ]);

  if (singleExercise.error) return `Error! ${singleExercise.error}`;

  if (singleExercise.id === parseInt(exerciseId))
    if (props.fetch) {
      return (
        <SingleExercise
          onToggleExerciseStatus={toggleExerciseStatus}
          deleteExerciseHandler={deleteExerciseHandler}
        />
      );
    } else {
      return <EditExercise update {...props} />;
    }

  return <div />;
};

FetchExercise.propTypes = {
  match: PropTypes.object,
  fetch: PropTypes.bool,
};

export default FetchExercise;
