import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SideBar = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    return (
        <ul>
            <li>
                <NavLink to={match.url + "/activities"}>{t("activities.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/additional-title"}>{t("additional_title.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/administration-status"}>{t("administration_status.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/body-part"}>{t("body_part.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/body-type"}>{t("body_type.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/canton"}>{t("canton.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/civil-status"}>{t("civil_status.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/contact-channel"}>{t("contact_channel.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/country"}>{t("country.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/difficulty"}>{t("difficulty.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/equipment"}>{t("equipment.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/gender"}>{t("gender.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/goal"}>{t("goal.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/group"}>{t("group.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/medical-function"}>{t("medical_function.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/nationality"}>{t("nationality.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/people-group"}>{t("people_group.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/phase"}>{t("phase.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/so-card"}>{t("so_card.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/sport-function"}>{t("sport_function.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/sport-status"}>{t("sport_status.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/sport"}>{t("sport.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/status"}>{t("status.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/termination-conditions"}>{t("termination_condition.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/test-type"}>{t("test_type.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/title"}>{t("title.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/training-frequency"}>{t("training_frequency.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/training-status"}>{t("training_status.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/training-period"}>{t("training_period.label")}</NavLink>
            </li>
            <li>
                <NavLink to={match.url + "/unit"}>{t("unit.label")}</NavLink>
            </li>
        </ul>
    );
};

export default SideBar;
