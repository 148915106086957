import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { hideModal, SHOW_CONTENT_MODAL } from "../../../store/global/global_actions";
import { withRouter } from "react-router-dom";

const ConfirmationModalComponent = props => {
    let closeModal = () => {};

    return (
        <Modal visible={props.show} dialogClassName="modal-dialog modal-dialog-centered" onClick={props.closeModal}>
            <div className="modal-content">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={props.closeModal}
                >
                    <i className="icon-cancel"></i>
                </button>
                <div className="modal-body">
                    <div className="excercises-created">
                        <div className="icon">
                            <i className={"icon-check"}></i>
                        </div>
                        <div className="content">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="btn-modal">
                            <a
                                href="#"
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    props.closeModal();
                                }}
                            >
                                Cancel
                            </a>
                            <a
                                href="#"
                                className="add"
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    props.closeModal();
                                    props.remove();
                                }}
                            >
                                Delete
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ConfirmationModalComponent.propTypes = {
    history: PropTypes.object,
    remove: PropTypes.func,
    closeModal: PropTypes.func,
    show: PropTypes.bool
};

export default withRouter(ConfirmationModalComponent);
