import React from "react";
import PropTypes from "prop-types";

import Logo from "../../assets/img/logo_favicon/logo-w.svg";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "./GraphQLActions";
import { useState } from "react";
import { showModal } from "../../store/global/global_actions";
import { useTranslation } from "react-i18next";

const ResetPassword = props => {
    const { t } = useTranslation();
    const { singleUser } = useSelector(s => s.userState);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        password: "",
        confirmPassword: ""
    });
    const [errors, setErrors] = useState([]);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+-.,#^()<>_+-])[A-Za-z\d@$!%*?&+-.,#^()<>_+-]{8,50}$/;

    let submitHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        let { password, confirmPassword } = state;

        if (!password || !confirmPassword) {
            return setErrors(["Password fields cannot be null"]);
        }

        if (password !== confirmPassword) {
            return setErrors(["Password and Confirm Password should be the same"]);
        }

        if (passwordRegex.test(password))
            dispatch(
                ResetPasswordAction({
                    newPassword: password,
                    confirmPassword: confirmPassword,
                    token: props.match.params.token
                })
            )
                .then(() => {
                    let link = "/auth";
                    let message = "Password successfully changed.";
                    if (singleUser.id) {
                        link = "/new-user/complete-registration/personal";
                        message += " Fulfill your profile.";
                    }
                    dispatch(
                        showModal({
                            type: 1,
                            button: "Ok!",
                            message,
                            link
                        })
                    );
                })
                .catch(err => {
                    setErrors(err);
                });
    };

    return (
        <>
            <div className="content">
                <div className="logo">
                    <img alt="" src={Logo} />
                </div>
                <div className="description">
                    <h4>New Password</h4>
                    <p>Change your password</p>
                </div>
                <form>
                    {errors.map((e, i) => (
                        <p key={i} className="LoginErrors" style={{ color: "red", marginBottom: 10, fontSize: "15px" }}>
                            {e}
                        </p>
                    ))}
                    <div className="form-group">
                        <input
                            required
                            onChange={e => {
                                setState({ ...state, password: e.target.value });
                            }}
                            value={state.password || ""}
                            type="password"
                            name="newpassword"
                            className="form-control"
                            placeholder="New Password"
                        />
                        {state.password && !passwordRegex.test(state.password) ? (
                            <p style={{ color: "red" }}>{t("pa_error_3.message")}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <input
                            required
                            onChange={e => {
                                setState({ ...state, confirmPassword: e.target.value });
                            }}
                            value={state.confirmPassword || ""}
                            type="password"
                            name="confirmpassword"
                            className="form-control"
                            placeholder="Confirm Password"
                        />
                        {state.confirmPassword && state.confirmPassword !== state.password ? (
                            <p style={{ color: "red" }}>{t("pa_error_2.message")}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <button
                            onClick={e => submitHandler(e)}
                            type="submit"
                            className="btn btn-primary btn-full-width"
                        >
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

ResetPassword.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

export default ResetPassword;
