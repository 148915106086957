import React from "react";
import { Switch, Route } from "react-router";
import { useSelector } from "react-redux";

import NewTrainingplan from "./NewTrainingplan";
import FetchTrainingplan from "./FetchTrainingplan";
import Trainingplan from "./index";
import { Loading } from "../../../components/UI";
import FetchExercise from "../Exercise/FetchExercise";

const TrainingplanRoutes = () => {
    const globalState = useSelector(s => s.globalState);
    return (
        <>
            <Loading loading={globalState.loading} />
            <Switch>
                <Route path="/dashboard/trainingplans/new-trainingplan" component={NewTrainingplan} />
                <Route path="/dashboard/trainingplans/:planId(\d+)/edit-trainingplan" component={FetchTrainingplan} />
                <Route path="/dashboard/trainingplans/:planId(\d+)/custom-exercise/:exerciseId(\d+)">
                    <FetchExercise fetch />
                </Route>
                <Route path="/dashboard/trainingplans/:planId(\d+)">
                    <FetchTrainingplan fetch />
                </Route>
                <Route path="/dashboard/trainingplans/" component={Trainingplan} />
            </Switch>
        </>
    );
};

export default TrainingplanRoutes;
