module.exports.GeneralInfoValidations = isUpdate => [
    {
        field: "title",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 200 }, validWhen: true }
        ],
        messages: ["Title can't be empty.", "Input too long. Max 200 characters."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "duration",
        rules: [{ name: "isInt", params: { min: 1, max: 500 }, validWhen: true }],
        messages: ["Please enter a value between 1 and 500."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "difficulty",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value for difficulty."],
        required: !isUpdate,
        variableType: ["object"]
    },
    {
        field: "description",
        rules: [{ name: "isLength", params: { max: 500 }, validWhen: true }],
        messages: ["Input too long. Max 500 characters."],
        variableType: []
    }
];
