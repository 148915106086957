import React from "react";
import PropTypes from "prop-types";

import ClipLoader from "react-spinners/ClipLoader";
import "./index.css";

const Loading = props => {
    return (
        <section className={"" + (props.contentModal ? "" : " ") + (props.loading ? "holder" : "hide")}>
            <div className={"override"}>
                <ClipLoader css={"override"} sizeUnit={"px"} size={100} color={"#e24049"} loading={props.loading} />
            </div>
        </section>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    contentModal: PropTypes.bool
};

export default Loading;
