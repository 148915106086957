module.exports.TrainerInfoRules = isUpdate => [
    {
        field: "email",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isEmail", params: {}, validWhen: true }
        ],
        messages: ["Email can't be null.", "Please provide a valid email."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "company",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Company is required."],
        required: !isUpdate,
        variableType: []
    }
];

module.exports.TrainerPersonalInfoRules = isUpdate => [
    {
        field: "name",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 100 }, validWhen: true }
        ],
        messages: ["First name is required.", "First name input too long."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "surname",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 100 }, validWhen: true }
        ],
        messages: ["Last name is required.", "Last name input too long."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "birthdate",
        rules: [{ name: "isLength", params: { max: 10 }, validWhen: true }],
        messages: ["Wrong format for birthdate."],
        variableType: []
    },
    {
        field: "gender",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Gender got invalid value."],
        variableType: []
    }
];

module.exports.TrainerAddressInfoRules = isUpdate => [
    {
        field: "street",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 150 }, validWhen: true }
        ],
        messages: ["Street is required.", "Street input too long."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "postal",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 100 }, validWhen: true }
        ],
        messages: ["Postal is required.", "Postal input too long."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "city",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 100 }, validWhen: true }
        ],
        messages: ["City name is required.", "City input too long."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "country",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Country is required."],
        required: !isUpdate,
        variableType: []
    }
];
