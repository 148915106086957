import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { searchBaseModels } from "../../../resolvers/query/base";
import { resetPlanState, planFilter } from "../../../store/trainingplans/trainingplans_actions";

import Select from "../../../components/UI/Select/Select";
import FilterMultipleSelectComp from "../../../components/UI/Select/FiltersMultipleSelect";
import Search from "../../../components/UI/Search/Search";
import AllTriningplans from "./allTriningplans";
import { useTranslation } from "react-i18next";

const Trainingplan = () => {
    const { planState } = useSelector(s => s);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filters = planState.filters;

    const { data, loading, error } = useQuery(searchBaseModels, {
        variables: {
            company: "plan",
            difficulty: true,
            trainingperiod: true,
            phase: true,
            bodypart: true,
            equipment: true
        }
    });

    let searchHandler = e => {
        dispatch(
            planFilter({
                search: e.target.value,
                offset: 0,
                page: 1
            })
        );
    };

    let filterHandler = (name, value) => {
        dispatch(planFilter({ [name]: value, offset: 0, page: 1 }));
    };

    if (error) return "Error! " + error;
    if (loading) return null;

    return (
        <section className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        <div className="training-result">
                            <p>
                                {t("total.label")}:
                                <span>
                                    {" "}
                                    {planState.totalPlans || 0} {t("trainingplans.label")}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="create-icon">
                            <Link
                                onClick={() => dispatch(resetPlanState())}
                                to="/dashboard/trainingplans/new-trainingplan/general-info"
                            >
                                <i className="icon-add"></i>
                                <span>{t("create_trainingplan.label")}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-between training-filter">
                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-3">
                        <div className="row">
                            <Search searchHandler={searchHandler} querySearch={filters.search} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                                <Select
                                    text={t("filter_library.placeholder")}
                                    select={e => filterHandler("creator", e)}
                                    selectedItem={filters.creator.id || null}
                                    items={data.searchBaseModels.Company}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                                <Select
                                    text={t("filter_difficulty.placeholder")}
                                    select={e => filterHandler("difficulty", e)}
                                    items={data.searchBaseModels.Difficulty}
                                    selectedItem={filters.difficulty.id || null}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                                <FilterMultipleSelectComp
                                    text={t("trainingperiods.label")}
                                    select={e => filterHandler("trainingPeriods", e)}
                                    selectedItems={filters.trainingPeriods}
                                    items={data.searchBaseModels.TrainingPeriod}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                                <FilterMultipleSelectComp
                                    text={t("trainingphases.label")}
                                    select={e => filterHandler("phases", e)}
                                    selectedItems={filters.phases}
                                    items={data.searchBaseModels.Phase}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                                <FilterMultipleSelectComp
                                    text={t("bodypart.label")}
                                    select={e => filterHandler("bodyParts", e)}
                                    selectedItems={filters.bodyParts}
                                    items={data.searchBaseModels.BodyPart}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                                <FilterMultipleSelectComp
                                    text={t("equipment.label")}
                                    select={e => filterHandler("equipments", e)}
                                    selectedItems={filters.equipments}
                                    items={data.searchBaseModels.Equipment}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AllTriningplans />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        {planState.totalPlans > 0 ? (
                            <ReactPaginate
                                previousLabel={<i className="icon-arrow-left"></i>}
                                nextLabel={<i className="icon-arrow-right"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(planState.totalPlans / 8) || 0}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={e => {
                                    console.log({
                                        offset: e.selected * 8,
                                        page: parseInt(e.selected) + 1
                                    });
                                    dispatch(
                                        planFilter({
                                            offset: e.selected * 8,
                                            page: parseInt(e.selected) + 1
                                        })
                                    );
                                }}
                                forcePage={filters.page - 1}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                activeLinkClassName={"active"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

Trainingplan.propTypes = {
    history: PropTypes.object
};

export default Trainingplan;
