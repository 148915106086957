import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import UserList from "../../Client/User/UsersList";
import TrainingplanList from "../../Trainingplan/TrainingplanList";
import TestsList from "../../Tests/TestsList";

const ContentModalComponent = props => {
    if (props.show)
        switch (props.content) {
            case "USER_LIST":
                return (
                    <>
                        <table className="table table-priosan">
                            <tbody>
                                {props.records.map(user => {
                                    return (
                                        <UserList
                                            selected={props.selected}
                                            selectUserModal={props.selectUserModal}
                                            key={user.id}
                                            users={user}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                );
            case "PLAN_LIST":
                return (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                {props.records.map(plan => (
                                    <TrainingplanList
                                        selected={props.selected}
                                        selectUserModal={props.selectUserModal}
                                        key={plan.id}
                                        plan={plan}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                );
            case "TEST_LIST":
                return (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                {props.records.map(test => (
                                    <TestsList
                                        selected={props.selected}
                                        selectUserModal={props.selectUserModal}
                                        key={test.id}
                                        tests={test}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                );
        }
    return null;
    // return (
    //     <Modal visible={props.show} dialogClassName="modal-dialog modal-xl modal-dialog-centered" onClick={closeModal}>
    //         {displayContent()}
    //     </Modal>
    // );
};

ContentModalComponent.propTypes = {
    history: PropTypes.object,
    content: PropTypes.string,
    show: PropTypes.bool,
    selectUserModal: PropTypes.func,
    records: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.arrayOf(PropTypes.number)
};

export default withRouter(ContentModalComponent);
