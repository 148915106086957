import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import SideBar from "./SideBar";
import CategorySwitch from "./CategorySwitch";

const SettingsRoutes = () => {
    const history = useHistory();
    useEffect(() => {
        let location = history.location.pathname.split("/");
        if (location[location.length - 1] === "categories") {
            history.push("/dashboard/categories/activities");
        }
    }, [history]);

    return (
        <>
            <section className="main-content">
                <div className="container-fluid">
                    <form>
                        <div className="settings">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="settings-nav">
                                        <SideBar />
                                    </div>
                                </div>
                                <CategorySwitch />
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default SettingsRoutes;
