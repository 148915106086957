import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DatePickerComponent from "../../UI/DatePicker/DatePicker";

const ReportCalendar = ({ date, dayChangeHandler, monthChangeHandler }) => {
    let currentNumberOfDays = moment(date, "DD-MM-YYYY").daysInMonth();
    let startDayOfMonth =
        moment(date, "DD-MM-YYYY")
            .startOf("month")
            .day() - 1;
    startDayOfMonth = startDayOfMonth === -1 ? 6 : startDayOfMonth;
    // let lastMonthNumberOfDays = moment(date, "DD-MM-YYYY")
    //     .subtract(1, "month")
    //     .daysInMonth();

    let daysArray = [
        ...Array(currentNumberOfDays + startDayOfMonth + (7 - ((currentNumberOfDays + startDayOfMonth) % 7)))
    ];
    let todaysDayCount = moment(date, "DD-MM-YYYY").date();
    let todaysMonth = moment(date, "DD-MM-YYYY").month() + 1;
    let todaysYear = moment(date, "DD-MM-YYYY").year();

    return (
        <div className="col-md-12 col-lg-12 col-xl-5">
            <div style={{ marginBottom: 0 }} className="form-group date-custom">
                <DatePickerComponent
                    date={date}
                    select={e => {
                        dayChangeHandler(e);
                        window.history.pushState(null, "", "?date=" + e);
                    }}
                    required
                />
            </div>
            <div className="calendar">
                <div className="month">
                    <ul>
                        <li onClick={() => monthChangeHandler(false)} className="prev">
                            <i className="icon-arrow-left"></i>
                        </li>
                        <li onClick={() => monthChangeHandler(true)} className="next">
                            <i className="icon-arrow-right"></i>
                        </li>
                        <li> {moment(date, "DD-MM-YYYY").format("MMMM YYYY")}</li>
                    </ul>
                </div>
                <ul className="weekdays">
                    <li>Mon</li>
                    <li>Tue</li>
                    <li>Wed</li>
                    <li>Thu</li>
                    <li>Fri</li>
                    <li>Sat</li>
                    <li>Sun</li>
                </ul>
                <ul className="days">
                    {daysArray.map((el, index) => {
                        if (index < startDayOfMonth) {
                            return (
                                <li key={index} className="inactive">
                                    &nbsp;
                                    {/* {lastMonthNumberOfDays - (startDayOfMonth - index - 1)} */}
                                </li>
                            );
                        } else if (index - startDayOfMonth < currentNumberOfDays) {
                            if (index - startDayOfMonth + 1 === todaysDayCount) {
                                return (
                                    <li key={index}>
                                        <span className="active">{index - startDayOfMonth + 1}</span>
                                    </li>
                                );
                            } else {
                                return (
                                    <li
                                        onClick={() =>
                                            dayChangeHandler(
                                                index - startDayOfMonth + 1 + "-" + todaysMonth + "-" + todaysYear
                                            )
                                        }
                                        key={index}
                                    >
                                        {index - startDayOfMonth + 1}
                                    </li>
                                );
                            }
                        } else {
                            return (
                                <li className="inactive">
                                    {/* {index - startDayOfMonth - currentNumberOfDays + 1} */}
                                    &nbsp;
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        </div>
    );
};

ReportCalendar.propTypes = {
    date: PropTypes.string,
    dayChangeHandler: PropTypes.func,
    monthChangeHandler: PropTypes.func
};

export default ReportCalendar;
