import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as HooksApolloProvider } from "@apollo/react-hooks";
import { Provider } from "react-redux";

import "react-dates/initialize";

import "./assets/css/style.min.css";
import "./assets/css/custom.css";
import "./i18n";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { store as ReduxStore } from "./store";
import { client as ApolloClient } from "./helpers/index";
import { Loading } from "./components/UI";

ReactDOM.render(
    <Provider store={ReduxStore}>
        <BrowserRouter>
            <ApolloProvider client={ApolloClient}>
                <HooksApolloProvider client={ApolloClient}>
                    <Suspense fallback={<Loading loading />}>
                        <App />
                    </Suspense>
                </HooksApolloProvider>
            </ApolloProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
