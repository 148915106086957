import React from "react";
import { Switch, Route } from "react-router";

import NewUser from "./NewUser";
import FetchUser from "./FetchUser";
import User from "./index";
import FetchPlan from "../Trainingplan/FetchTrainingplan";
import FetchExercise from "../Exercise/FetchExercise";

import Loading from "../../../components/UI/Loading";
import { useSelector } from "react-redux";
import FetchTest from "../Test/FetchTest";

const UserRoutes = () => {
  const globalState = useSelector((s) => s.globalState);
  return (
    <>
      <Loading loading={globalState.loading} />
      <Switch>
        <Route path="/dashboard/users/new-user" component={NewUser} />

        <Route path="/dashboard/users/:userId(\d+)/takentest/:testId(\d+)">
          <section className="main-content">
            <FetchTest fetch />
          </section>
        </Route>

        <Route path="/dashboard/users/:userId(\d+)/takenplan/:planId(\d+)/custom-exercise/:exerciseId(\d+)">
          <FetchExercise fetch />
        </Route>

        <Route path="/dashboard/users/:userId(\d+)/takenplan/:planId(\d+)">
          <FetchPlan fetch />
        </Route>

        <Route
          path="/dashboard/users/:userId(\d+)/edit-user"
          component={FetchUser}
        />

        <Route path="/dashboard/users/:userId(\d+)/reports">
          <FetchUser event="reports" />
        </Route>

        <Route path="/dashboard/users/:userId(\d+)/reports?date=:date">
          <FetchUser event="reports" />
        </Route>

        <Route path="/dashboard/users/:userId(\d+)">
          <FetchUser event="fetch" />
        </Route>

        <Route path="/dashboard/users/" component={User} />
      </Switch>
    </>
  );
};

export default UserRoutes;
