import { stateValidator } from "../../../helpers/validator";
import {
    CompanyInfoRules,
    CompanyAddressInfoRules,
    CompanyPersonalInfoRules,
    CompanyPrivateInfoRules
} from "./validation-rules";

export let validateState = (state, isUpdate) => {
    let newState = { ...state };

    let isValidBaseInfo = stateValidator(newState, CompanyInfoRules(isUpdate));
    let isValidPersonalInfo = stateValidator(newState.personal, CompanyPersonalInfoRules(isUpdate));
    let isValidPrivateInfo = stateValidator(newState.private_info, CompanyPrivateInfoRules(isUpdate));
    let isValidAddress = stateValidator(newState.address, CompanyAddressInfoRules(isUpdate));

    let isAllValid =
        isValidPersonalInfo.isValid && isValidPrivateInfo.isValid && isValidAddress.isValid && isValidBaseInfo.isValid;

    let completeErrors = {
        ...isValidBaseInfo.errors,
        ...isValidPersonalInfo.errors,
        ...isValidPrivateInfo.errors,
        ...isValidAddress.errors
    };

    newState = {
        ...newState,
        completeErrors,
        isAllValid
    };

    return newState;
};
