import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import CompanyList from "../../../components/Client/Company/CompanyList";
import { FetchAllComapniesQuery } from "./GraphQLActions";
import { companyFilter } from "../../../store/user/company/company_actions";
import { orderHandler, classNameGenerator } from "../../../helpers/OrderHandlers";
import { useTranslation } from "react-i18next";

const AllCompanies = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companyState } = useSelector(s => s);
    const filters = companyState.filters;

    useEffect(() => {
        dispatch(
            FetchAllComapniesQuery({
                ...filters,
                status: filters.status.id || null
            })
        );
    }, [dispatch, filters]);

    if (companyState.error) return `Error! ${companyState.error}`;

    return (
        <>
            <table className="table table-priosan">
                <thead>
                    <tr>
                        <th>
                            <div className="head-table">
                                <p>{t("image.label")}</p>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "name", dispatch, companyFilter)}
                                    href="#"
                                >
                                    <p>{t("name.label")}</p>
                                    <i className={classNameGenerator(filters.order, "name")} />
                                </a>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <a
                                    onClick={e => {
                                        return;
                                        orderHandler(e, filters.order, "employee_count", dispatch, companyFilter);
                                    }}
                                    href="#"
                                >
                                    <p>{t("no_of_trainers.label")}</p>
                                    <i className={classNameGenerator(filters.order, "employee_count")} />
                                </a>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "email", dispatch, companyFilter)}
                                    href="#"
                                >
                                    <p>{t("email.label")}</p>
                                    <i className={classNameGenerator(filters.order, "email")} />
                                </a>
                            </div>
                        </th>
                        {/* <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "website", dispatch, companyFilter)}
                                    href="#"
                                >
                                    <p>{t("website.label")}</p>
                                    <i className={classNameGenerator(filters.order, "website")} />
                                </a>
                            </div>
                        </th> */}
                        <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "city", dispatch, companyFilter)}
                                    href="#"
                                >
                                    <p>{t("city.label")}</p>
                                    <i className={classNameGenerator(filters.order, "city")} />
                                </a>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "updatedAt", dispatch, companyFilter)}
                                    href="#"
                                >
                                    <p>{t("updated_at.label")}</p>
                                    <i className={classNameGenerator(filters.order, "updatedAt")} />
                                </a>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <a
                                    onClick={e => orderHandler(e, filters.order, "status", dispatch, companyFilter)}
                                    href="#"
                                >
                                    <p>{t("status.label")}</p>
                                    <i className={classNameGenerator(filters.order, "status")} />
                                </a>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {companyState.allCompanies.map(user => {
                        return <CompanyList history={props.history} key={user.id} users={user} />;
                    })}
                </tbody>
            </table>
        </>
    );
};

AllCompanies.propTypes = {
    history: PropTypes.object,
    search: PropTypes.string,
    status: PropTypes.number,
    offset: PropTypes.number
};

export default withRouter(AllCompanies);
