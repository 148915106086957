import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { useQuery } from "@apollo/react-hooks";

import WizardNav from "components/UI/WizardNav/WizardNav";
import { updateTest, test } from "store/test/test_actions";
import Select from "components/UI/Select/Select";
import { searchBaseModels } from "resolvers/query/base/";

const GeneralInfo = props => {
    const { t } = useTranslation();
    const testState = useSelector(s => s.testState);
    const updateState = testState.updateTest;
    const dispatch = useDispatch();
    const errors = testState.firstStepValidity.errors;

    const { loading, data } = useQuery(searchBaseModels, { variables: { difficulty: true } });

    let uploadMainPhotoHandler = e => {
        if (e.target.files[0]) {
            dispatch(
                updateTest({
                    imgFile: e.target.files[0],
                    img: URL.createObjectURL(e.target.files[0])
                })
            );
        }
    };

    let uploadMainVideoHandler = e => {
        if (e.target.files[0]) {
            dispatch(
                updateTest({
                    videoFile: e.target.files[0],
                    video: URL.createObjectURL(e.target.files[0])
                })
            );
        }
    };

    /**
     *
     * @param {Event} e
     */
    let submitHandler = (e, link) => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        if (testState.firstStepValidity.isValid) {
            props.history.push(link);
        } else {
            dispatch(test({ displayGenerealInfoErrors: true }));
        }
    };

    return (
        <>
            <WizardNav
                first={t("general_info.label")}
                firstLink="general-info"
                second={t("questions.label")}
                secondLink="questions"
                active1={testState.firstStepValidity.isValid}
                active2={testState.firstStepValidity.isValid && testState.secondStepValidity.isValid}
                third={t("overview.label")}
                thirdLink="overview"
                active={1}
            />

            <div className="container-fluid">
                <form>
                    <div className="training-form">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="main-file-upload excercises-upload">
                                            <div className="file-upload-title">
                                                <h5>{t("main_image.label")}</h5>
                                            </div>
                                            <div className="file-upload">
                                                <div className="image-upload-wrap">
                                                    <input
                                                        maxLength={255}
                                                        onChange={e => uploadMainPhotoHandler(e)}
                                                        id="main-image"
                                                        type="file"
                                                        className="file-upload-input"
                                                        accept="image/*"
                                                    />
                                                    {!get(updateState, "img", false) ? (
                                                        <div className="drag-text">
                                                            <i className="icon-picture"></i>
                                                            <span>{t("dragndrop.label")}</span>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            alt="uploaded img"
                                                            style={{
                                                                width: "100%",
                                                                objectFit: "cover",
                                                                height: "100%"
                                                            }}
                                                            src={get(updateState, "img", false)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="button-upload">
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("main-image").click();
                                                    }}
                                                    type="button"
                                                    className="btn btn-upload"
                                                >
                                                    {t("upload_img.label")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label>
                                                {t("title.label")}
                                                <span className="form-required">*</span>
                                            </label>
                                            <input
                                                maxLength={255}
                                                value={get(updateState, "title", "")}
                                                onChange={e => dispatch(updateTest({ title: e.target.value }))}
                                                type="text"
                                                name="title"
                                                className="form-control"
                                            />
                                            {!testState.displayGenerealInfoErrors ? null : (
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.title}</p>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                {t("difficulty.label")}
                                                <span className="form-required">*</span>
                                            </label>
                                            <Select
                                                selectedItem={get(updateState, "difficulty.id", "").toString()}
                                                text={"Select the level"}
                                                select={e => dispatch(updateTest({ difficulty: e }))}
                                                items={loading ? [] : data.searchBaseModels.Difficulty}
                                            />
                                            {!testState.displayGenerealInfoErrors ? null : (
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.difficulty}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group form-group-title">
                                            <label>
                                                {t("duration.label")}
                                                <span className="form-required">*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input
                                                maxLength={255}
                                                value={get(updateState, "duration", 0)}
                                                onChange={e =>
                                                    !parseInt(e.target.value)
                                                        ? dispatch(updateTest({ duration: 0 }))
                                                        : dispatch(updateTest({ duration: parseInt(e.target.value) }))
                                                }
                                                type="text"
                                                name="series"
                                                className="form-control"
                                            />
                                            {!testState.displayGenerealInfoErrors ? null : (
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.duration}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-file-upload excercises-upload">
                                            <div className="file-upload-title">
                                                <h5>{t("video.label")}</h5>
                                            </div>
                                            <div className="file-upload">
                                                <div className="image-upload-wrap">
                                                    <input
                                                        maxLength={255}
                                                        id="video-upload"
                                                        type="file"
                                                        className="file-upload-input"
                                                        accept="video/*"
                                                        onChange={e => uploadMainVideoHandler(e)}
                                                    />
                                                    {!get(updateState, "video", null) ? (
                                                        <div className="drag-text">
                                                            <i className="icon-video"></i>
                                                            <span>Drag and drop here</span>
                                                        </div>
                                                    ) : (
                                                        <video height="100%" controls>
                                                            <i className="icon-video"></i>
                                                            <source
                                                                src={get(updateState, "video", null)}
                                                                type="video/mp4"
                                                            />
                                                            <source
                                                                src={get(updateState, "video", null)}
                                                                type="video/ogg"
                                                            />
                                                            Your browser does not support HTML5 video.
                                                        </video>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="button-upload">
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("video-upload").click();
                                                    }}
                                                    type="button"
                                                    className="btn btn-upload"
                                                >
                                                    {t("upload_video.label")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{t("description.label")}</label>
                                            <textarea
                                                maxLength={500}
                                                value={get(updateState, "description", "")}
                                                onChange={e => dispatch(updateTest({ description: e.target.value }))}
                                                className="form-control"
                                                rows="5"
                                            ></textarea>
                                            {!testState.displayGenerealInfoErrors ? null : (
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.description}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <div className="btn-right">
                                            <Link to="/dashboard/tests" className="btn btn-cancel btn-180">
                                                {t("cancel.label")}
                                            </Link>
                                            <button
                                                // disabled={!testState.firstStepValidity.isValid}
                                                className="btn btn-primary btn-180"
                                                onClick={e => submitHandler(e, "questions")}
                                            >
                                                {t("next.label")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

GeneralInfo.propTypes = {
    history: PropTypes.object
};

export default GeneralInfo;
