import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const TrainingPlanReportList = ({ data }) => {
    const { t } = useTranslation();

    const displayStatus = status => {
        switch (status) {
            case "Done":
                return (
                    <div className="completed">
                        <span>
                            <i className="icon-tick"></i>
                        </span>
                        <p>{t("finished.label")}</p>
                    </div>
                );
            // case "In Progress":
            //     return (
            //         <div className="completed">
            //             <span>
            //                 <i className="icon-tick"></i>
            //             </span>
            //             <p>Finished</p>
            //         </div>
            //     );
            default:
                return null;
        }
    };

    const displayExercises = (completed_exercises, exercises) => {
        let completedExerciseIds = completed_exercises.map(exercise => parseInt(exercise.id));
        exercises = exercises.map(exercise => {
            return { ...exercise, finished: completedExerciseIds.includes(parseInt(exercise.id)) };
        });

        return (
            <div className="user-detail-list">
                {exercises.map(exercise => (
                    <div key={exercise.id} className="user-detail-list-item">
                        <div className="img">
                            <img src={get(exercise, "img", null)} alt="" />
                        </div>
                        <div className="content">
                            <h2>{get(exercise, "title", "")}</h2>
                            <p className={exercise.finished ? "finished" : "unfinished"}>
                                <span>
                                    <i className="icon-tick"></i>
                                </span>
                                {exercise.finished ? t("finished.label") : t("unfinished.label")}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="user-detail-item">
            <div className="user-detail-item-l">
                <div className="user-detail-img">
                    <img src={get(data, "taken_plan.img", null)} alt="" />
                    {displayStatus(get(data, "status", null))}
                </div>
                <div className="user-detail-title">
                    <h2>{get(data, "taken_plan.title", "")}</h2>
                </div>
            </div>
            <div className="user-detail-item-r">
                {displayExercises(get(data, "completed_exercises", []), get(data, "taken_plan.exercises", []))}
            </div>
        </div>
    );
};

TrainingPlanReportList.propTypes = {
    data: PropTypes.object.isRequired
};

export default TrainingPlanReportList;
