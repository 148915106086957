import { client } from "../../../helpers/index";
import {
  TestsListQuery,
  SingleTestQuery,
  FetchTakenTest,
} from "../../../resolvers/query/test";
import {
  test,
  singleTest,
  updateTest,
  testStatusChanged,
} from "../../../store/test/test_actions";
import {
  CreateTestMutation,
  DeleteTest,
  ToggleTestStatus,
  DeleteTakenTest,
  ToggleTakenTestStatus,
  UpdateTestMutation,
} from "../../../resolvers/mutation/test";
import {
  toggleLoadingOn,
  toggleLoadingOff,
} from "../../../store/global/global_actions";

export const FetchAllTestsQuery = (variables) => {
  return (dispatch) => {
    // dispatch(test({ loading: true, errors: { array: [] } }));
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: TestsListQuery,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          let err = [];
          errors.map((mes) => err.push(mes.message));
          dispatch(
            test({ errors: { array: err }, loading: false, modal: false })
          );
        }
        if (data) {
          let {
            searchTests: { totalCount, allTests },
          } = data;
          dispatch(
            test({ allTests: allTests, totalTests: totalCount, loading: false })
          );
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          const errors = err.graphQLErrors.map((mes) => mes.message);
          dispatch(
            test({ errors: { array: errors }, loading: false, modal: false })
          );
        } else {
          dispatch(
            test({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => {
        dispatch(toggleLoadingOff());
      });
  };
};

export const FetchTestByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: SingleTestQuery,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          let err = [];
          errors.map((mes) => err.push(mes.message));
          dispatch(
            test({ errors: { array: err }, loading: false, modal: false })
          );
        }
        if (data) {
          let { fetchTest } = data;
          dispatch(singleTest(fetchTest));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          const errors = err.graphQLErrors.map((mes) => mes.message);
          dispatch(
            test({ errors: { array: errors }, loading: false, modal: false })
          );
        } else {
          dispatch(
            test({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => {
        dispatch(toggleLoadingOff());
      });
  };
};

export const FetchTakenTestByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: FetchTakenTest,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          let err = [];
          errors.map((mes) => err.push(mes.message));
          dispatch(
            test({ errors: { array: err }, loading: false, modal: false })
          );
        }
        if (data) {
          let { test, ...other } = data.fetchTakenTest;
          console.log({ other, test });
          dispatch(singleTest({ ...test, ...other }));
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          const errors = err.graphQLErrors.map((mes) => mes.message);
          dispatch(
            test({ errors: { array: errors }, loading: false, modal: false })
          );
        } else {
          dispatch(
            test({ errors: { array: err }, loading: false, modal: false })
          );
        }
      })
      .finally(() => {
        dispatch(toggleLoadingOff());
      });
  };
};

export const CreateTestAction = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: CreateTestMutation, variables })
      .then(({ data }) => {
        if (data) {
          dispatch(singleTest(data.createTest_v1));
          return parseInt(data.createTest_v1.id);
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => {
        dispatch(toggleLoadingOff());
      });
  };
};

export const UpdateTestAction = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: UpdateTestMutation, variables })
      .then(({ data }) => {
        if (data) {
          dispatch(singleTest(data.updateTest_v1));
          return parseInt(data.updateTest_v1.id);
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => {
        dispatch(toggleLoadingOff());
      });
  };
};

export const ToggleTestStatusMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    client
      .mutate({ mutation: ToggleTestStatus, variables })
      .then(() => dispatch(testStatusChanged(variables)))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const DeleteTestMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: DeleteTest, variables })
      .then(() => true)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const ToggleTakenTestStatusMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    client
      .mutate({ mutation: ToggleTakenTestStatus, variables })
      .then(() => dispatch(testStatusChanged(variables)))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const DeleteTakenTestMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: DeleteTakenTest, variables })
      .then(() => true)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};
