import gql from "graphql-tag";

export const SearchActivitiesQuery = gql`
    query SearchActivities($search: String) {
        searchActivities(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchAdditionalTitleQuery = gql`
    query SearchAdditionalTitle($search: String) {
        searchAdditionalTitle(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchAdministrationStatusQuery = gql`
    query SearchAdministrationStatus($search: String) {
        searchAdministrationStatus(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchBodyPartQuery = gql`
    query SearchBodyPart($search: String) {
        searchBodyPart(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchBodyTypeQuery = gql`
    query SearchBodyType($search: String) {
        searchBodyType(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchCantonQuery = gql`
    query SearchCanton($search: String) {
        searchCanton(search: $search) {
            id
            name
        }
    }
`;
export const SearchCivilStatusQuery = gql`
    query SearchCivilStatus($search: String) {
        searchCivilStatus(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchContactChannelQuery = gql`
    query SearchContactChannel($search: String) {
        searchContactChannel(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchCountryQuery = gql`
    query SearchCountry($search: String) {
        searchCountry(search: $search) {
            id
            name
        }
    }
`;
export const SearchDifficultyQuery = gql`
    query SearchDifficulty($search: String) {
        searchDifficulty(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchEquipmentQuery = gql`
    query SearchEquipment($search: String) {
        searchEquipment(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchGenderQuery = gql`
    query SearchGender($search: String) {
        searchGender(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchGoalQuery = gql`
    query SearchGoal($search: String) {
        searchGoal(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchGroupQuery = gql`
    query SearchGroup($search: String) {
        searchGroup(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchMedicalFunctionQuery = gql`
    query SearchMedicalFunction($search: String) {
        searchMedicalFunction(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchNationalityQuery = gql`
    query SearchNationality($search: String) {
        searchNationality(search: $search) {
            id
            name
        }
    }
`;
export const SearchPeopleGroupQuery = gql`
    query SearchPeopleGroup($search: String) {
        searchPeopleGroup(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchPhaseQuery = gql`
    query SearchPhase($search: String) {
        searchPhase(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchSOCardQuery = gql`
    query SearchSOCard($search: String) {
        searchSOCard(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchSportFunctionQuery = gql`
    query SearchSportFunction($search: String) {
        searchSportFunction(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchSportStatusQuery = gql`
    query SearchSportStatus($search: String) {
        searchSportStatus(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchSportQuery = gql`
    query SearchSport($search: String) {
        searchSport(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchStatusQuery = gql`
    query SearchStatus($search: String) {
        searchStatus(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchTerminationConditionsQuery = gql`
    query SearchTerminationConditions($search: String) {
        searchTerminationConditions(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchTestTypeQuery = gql`
    query SearchTestType($search: String) {
        searchTestType(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchTitleQuery = gql`
    query SearchTitle($search: String) {
        searchTitle(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchTrainingFrequencyQuery = gql`
    query SearchTrainingFrequency($search: String) {
        searchTrainingFrequency(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchTrainingStatusQuery = gql`
    query SearchTrainingStatus($search: String) {
        searchTrainingStatus(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchTrainingPeriodQuery = gql`
    query SearchTrainingPeriod($search: String) {
        searchTrainingPeriod(search: $search) {
            id
            name
            name_de
        }
    }
`;
export const SearchUnitQuery = gql`
    query SearchUnit($search: String) {
        searchUnit(search: $search) {
            id
            name
            name_de
        }
    }
`;

export default {
    SearchActivitiesQuery,
    SearchAdditionalTitleQuery,
    SearchAdministrationStatusQuery,
    SearchBodyPartQuery,
    SearchBodyTypeQuery,
    SearchCantonQuery,
    SearchCivilStatusQuery,
    SearchContactChannelQuery,
    SearchCountryQuery,
    SearchDifficultyQuery,
    SearchEquipmentQuery,
    SearchGenderQuery,
    SearchGoalQuery,
    SearchGroupQuery,
    SearchMedicalFunctionQuery,
    SearchNationalityQuery,
    SearchPeopleGroupQuery,
    SearchPhaseQuery,
    SearchSOCardQuery,
    SearchSportFunctionQuery,
    SearchSportStatusQuery,
    SearchSportQuery,
    SearchStatusQuery,
    SearchTerminationConditionsQuery,
    SearchTestTypeQuery,
    SearchTitleQuery,
    SearchTrainingFrequencyQuery,
    SearchTrainingStatusQuery,
    SearchTrainingPeriodQuery,
    SearchUnitQuery
};

export const RegionListQuery = gql`
    query SearchGroup($search: String!) {
        searchGroup(search: $search) {
            id
            name
        }
    }
`;

export const DifficultyListQuery = gql`
    query SearchDifficulty($search: String!) {
        searchDifficulty(search: $search) {
            id
            name
        }
    }
`;

export const EquipmentListQuery = gql`
    query SearchEquipment($search: String!) {
        searchEquipment(search: $search) {
            id
            name
        }
    }
`;

export const MusculeListQuery = gql`
    query SearchMuscle($search: String!) {
        searchMuscle(search: $search) {
            id
            name
        }
    }
`;

export const fetchDifficulty = gql`
    query fetchDifficulty($id: Int!) {
        fetchDifficulty(id: $id) {
            id
            name
        }
    }
`;
export const fetchGroup = gql`
    query fetchGroup($id: Int!) {
        fetchGroup(id: $id) {
            id
            name
        }
    }
`;

export const fetchEquipment = gql`
    query fetchEquipment($id: Int!) {
        fetchEquipment(id: $id) {
            id
            name
        }
    }
`;

export const searchCountries = gql`
    query searchCountries {
        searchCountries {
            id
            name
        }
    }
`;

export const searchBaseModels = gql`
    query seachBaseModels(
        $phase: Boolean
        $difficulty: Boolean
        $equipment: Boolean
        $testtype: Boolean
        $bodytype: Boolean
        $goal: Boolean
        $group: Boolean
        $gender: Boolean
        $unit: Boolean
        $trainingperiod: Boolean
        $status: Boolean
        $company: String
        $trainer: Int
        $bodypart: Boolean
        $country: Boolean
        $nationality: Boolean
        $canton: Boolean
        $terminationconditions: Boolean
        $people_group: Boolean
        $administration_status: Boolean
        $contact_channel: Boolean
        $civil_status: Boolean
        $title: Boolean
        $additional_title: Boolean
        $sport: Boolean
        $so_card: Boolean
        $sport_status: Boolean
        $training_frequency: Boolean
        $training_status: Boolean
        $sport_function: Boolean
        $medical_function: Boolean
    ) {
        searchBaseModels(
            phase: $phase
            difficulty: $difficulty
            equipment: $equipment
            testtype: $testtype
            goal: $goal
            gender: $gender
            trainingperiod: $trainingperiod
            bodytype: $bodytype
            unit: $unit
            so_card: $so_card
            nationality: $nationality
            status: $status
            company: $company
            trainer: $trainer
            group: $group
            bodypart: $bodypart
            country: $country
            canton: $canton
            civil_status: $civil_status
            title: $title
            additional_title: $additional_title
            terminationconditions: $terminationconditions
            people_group: $people_group
            administration_status: $administration_status
            contact_channel: $contact_channel
            sport: $sport
            sport_status: $sport_status
            training_frequency: $training_frequency
            training_status: $training_status
            sport_function: $sport_function
            medical_function: $medical_function
        ) {
            MedicalFunction {
                id
                name
            }
            Sport {
                id
                name
            }
            SportFunction {
                id
                name
            }
            SOCard {
                id
                name
            }
            SportStatus {
                id
                name
            }
            TrainingFrequency {
                id
                name
            }
            TrainingStatus {
                id
                name
            }
            Phase {
                id
                name
            }
            AdministrationStatus {
                id
                name
            }
            ContactChannel {
                id
                name
            }
            PeopleGroup {
                id
                name
            }
            Difficulty {
                id
                name
            }
            Group {
                id
                name
            }
            CivilStatus {
                id
                name
            }
            AdditionalTitle {
                id
                name
            }
            Title {
                id
                name
            }
            Gender {
                id
                name
            }
            Equipment {
                id
                name
            }
            BodyPart {
                id
                name
            }
            TestType {
                id
                name
            }
            BodyType {
                id
                name
            }
            Country {
                id
                name
            }
            Nationality {
                id
                name
            }
            Canton {
                id
                name
            }
            Goal {
                id
                name
            }
            Unit {
                id
                name
            }
            Status {
                id
                name
            }
            Company
            Trainer
            TrainingPeriod {
                id
                name
            }
            TerminationConditions {
                id
                name
            }
        }
    }
`;
