import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AnswerList = ({ type, questions, userAnswers, userOptionAnswers }) => {
  const { t } = useTranslation();

  const displayAnswers = () => {
    console.log({ type });
    switch (+type.id) {
      case 1:
      case 2:
        return userAnswers.map((answer) => (
          <li key={answer.id}>
            <span className="question">{answer.Question.content}</span>
            <span className="answer answer-strong">{answer.content}</span>
          </li>
        ));

      case 3:
        const answers = userOptionAnswers.map((answer) => answer.optionId);

        return questions.map((question, qIndex) => {
          return (
            <li key={qIndex}>
              <span className="question">{question.content}</span>
              {question.options.map((option, oIndex) => (
                <>
                  <span
                    key={qIndex + "." + oIndex}
                    className={`answer ${answers.includes(option.id) &&
                      "answer-strong"}`}
                  >
                    {oIndex + 1 + "). " + option.content}
                  </span>
                  {option.sub_options.map((sub_option, sIndex) => {
                    return (
                      <span
                        style={{ paddingLeft: "35px" }}
                        key={qIndex + "." + oIndex + "." + sIndex}
                        className={`answer ${answers.includes(sub_option.id) &&
                          "answer-strong"}`}
                      >
                        {sub_option.content}
                      </span>
                    );
                  })}
                </>
              ))}
            </li>
          );
        });

      case 4:
        return userAnswers.map((answer) => (
          <li key={answer.id}>
            <span className="question">{answer.Question.content}</span>
            <span className="answer answer-strong">{`${answer.content} ${answer.Question.unit.name}`}</span>
          </li>
        ));
    }
  };

  return (
    <div className="col-md-12">
      <div className="questions-answers">
        <div className="title">
          <h3>Client Answers</h3>
        </div>
        <ul>{displayAnswers()}</ul>
      </div>
    </div>
  );

  switch (parseInt(type.id)) {
    case 1:
      return (
        <div className="col-md-12">
          <div className="questions-answers">
            <div className="title">
              <h3>{type.name}</h3>
            </div>
            <ul>
              {questions.map((question, index) => {
                return (
                  <li key={index}>
                    <span className="question">{question.content}</span>
                    <span className="answer">{question.correct_answer}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="col-md-12">
          <div className="questions-answers">
            <div className="title">
              <h3>{type.name}</h3>
            </div>
            <ul>
              {questions.map((question, index) => {
                return (
                  <li key={index}>
                    <span className="question">{question.content}</span>
                    <span className="answer">{question.correct_answer}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="col-md-12">
          <div className="questions-answers">
            <div className="title">
              <h3>{type.name}</h3>
            </div>
            <ul>
              {questions.map((question, qIndex) => {
                return (
                  <li key={qIndex}>
                    <span className="question">{question.content}</span>
                    {question.options.map((option, oIndex) => (
                      <>
                        <span key={qIndex + "." + oIndex} className="answer">
                          {oIndex + 1 + "). " + option.content}
                        </span>
                        {option.sub_options.map((sub_option, sIndex) => {
                          return (
                            <span
                              style={{ paddingLeft: "35px" }}
                              key={qIndex + "." + oIndex + "." + sIndex}
                              className="answer"
                            >
                              {sub_option.content}
                            </span>
                          );
                        })}
                      </>
                    ))}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    case 4:
      return (
        <div className="col-md-12">
          <div className="questions-answers">
            <div className="title">
              <h3>{type.name}</h3>
            </div>
            <ul>
              {questions.map((question, index) => {
                return (
                  <li key={index}>
                    <span className="question">{question.content}</span>
                    <span className="answer">
                      {t("unit.label")}: {question.unit.name}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
  }

  return null;
};

AnswerList.propTypes = {
  type: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AnswerList;
