import React from "react";
import { Switch, Route } from "react-router";
import GeneralInfo from "./CreateTrainingplan/GeneralInfo";
import SelectExercises from "./CreateTrainingplan/SelectExercises";
import Overview from "./CreateTrainingplan/Overview";

const NewTrainingplan = () => {
    return (
        <Switch>
            <Route path="/dashboard/trainingplans/new-trainingplan/overview" component={Overview} />
            <Route path="/dashboard/trainingplans/new-trainingplan/select-exercises" component={SelectExercises} />
            <Route path="/dashboard/trainingplans/new-trainingplan" component={GeneralInfo} />
        </Switch>
    );
};

export default NewTrainingplan;
