import React from "react";
import { Switch, Route } from "react-router";

import Reports from "./index";
import Loading from "../../../components/UI/Loading";
import { useSelector } from "react-redux";

const ReportRoutes = () => {
    let reportState = useSelector(s => s.reportState);
    return (
        <>
            <Loading loading={reportState.loading || reportState.loadingFilters} />
            <Switch>
                <Route path="/dashboard/reports/" component={Reports} />
            </Switch>
        </>
    );
};

export default ReportRoutes;
