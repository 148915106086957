import { client } from "../../../helpers/index";

import queries from "../../../resolvers/query/base";
import mutations from "../../../resolvers/mutation/base";

export const SearchActivitiesAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchActivitiesQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchActivities;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchAdditionalTitleAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchAdditionalTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchAdditionalTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchAdministrationStatusAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchAdministrationStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchAdministrationStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchBodyPartAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchBodyPartQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchBodyPart;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchBodyTypeAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchBodyTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchBodyType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchCantonAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchCantonQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchCanton;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchCivilStatusAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchCivilStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchCivilStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchContactChannelAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchContactChannelQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchContactChannel;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchCountryAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchCountryQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchCountry;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchDifficultyAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchDifficultyQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchDifficulty;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchEquipmentAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchEquipmentQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchEquipment;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchGenderAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchGenderQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchGender;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchGoalAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchGoalQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchGoal;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchGroupAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchMedicalFunctionAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchMedicalFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchMedicalFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchNationalityAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchNationalityQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchNationality;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchPeopleGroupAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchPeopleGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchPeopleGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchPhaseAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchPhaseQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchPhase;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchSOCardAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchSOCardQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchSOCard;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchSportFunctionAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchSportFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchSportFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchSportStatusAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchSportStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchSportStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchSportAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchSportQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchSport;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchStatusAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchTerminationConditionsAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchTerminationConditionsQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchTerminationConditions;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchTestTypeAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchTestTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchTestType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchTitleAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchTrainingFrequencyAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchTrainingFrequencyQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchTrainingFrequency;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchTrainingStatusAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchTrainingStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchTrainingStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchTrainingPeriodAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchTrainingPeriodQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchTrainingPeriod;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const SearchUnitAction = variables => {
    return () => {
        return client
            .query({ query: queries.SearchUnitQuery, variables })
            .then(({ data }) => {
                if (data) return data.searchUnit;
            })
            .catch(err => {
                throw err;
            });
    };
};

export const AddActivitiesAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddActivitiesQuery, variables })
            .then(({ data }) => {
                if (data) return data.addActivities;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddAdditionalTitleAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddAdditionalTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.addAdditionalTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddAdministrationStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddAdministrationStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.addAdministrationStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddBodyPartAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddBodyPartQuery, variables })
            .then(({ data }) => {
                if (data) return data.addBodyPart;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddBodyTypeAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddBodyTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.addBodyType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddCantonAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddCantonQuery, variables })
            .then(({ data }) => {
                if (data) return data.addCanton;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddCivilStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddCivilStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.addCivilStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddContactChannelAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddContactChannelQuery, variables })
            .then(({ data }) => {
                if (data) return data.addContactChannel;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddCountryAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddCountryQuery, variables })
            .then(({ data }) => {
                if (data) return data.addCountry;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddDifficultyAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddDifficultyQuery, variables })
            .then(({ data }) => {
                if (data) return data.addDifficulty;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddEquipmentAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddEquipmentQuery, variables })
            .then(({ data }) => {
                if (data) return data.addEquipment;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddGenderAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddGenderQuery, variables })
            .then(({ data }) => {
                if (data) return data.addGender;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddGoalAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddGoalQuery, variables })
            .then(({ data }) => {
                if (data) return data.addGoal;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddGroupAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.addGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddMedicalFunctionAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddMedicalFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.addMedicalFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddNationalityAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddNationalityQuery, variables })
            .then(({ data }) => {
                if (data) return data.addNationality;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddPeopleGroupAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddPeopleGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.addPeopleGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddPhaseAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddPhaseQuery, variables })
            .then(({ data }) => {
                if (data) return data.addPhase;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddSOCardAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddSOCardQuery, variables })
            .then(({ data }) => {
                if (data) return data.addSOCard;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddSportFunctionAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddSportFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.addSportFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddSportStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddSportStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.addSportStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddSportAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddSportQuery, variables })
            .then(({ data }) => {
                if (data) return data.addSport;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.addStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddTerminationConditionsAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddTerminationConditionsQuery, variables })
            .then(({ data }) => {
                if (data) return data.addTerminationConditions;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddTestTypeAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddTestTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.addTestType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddTitleAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.addTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddTrainingFrequencyAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddTrainingFrequencyQuery, variables })
            .then(({ data }) => {
                if (data) return data.addTrainingFrequency;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddTrainingStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddTrainingStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.addTrainingStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddTrainingPeriodAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddTrainingPeriodQuery, variables })
            .then(({ data }) => {
                if (data) return data.addTrainingPeriod;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const AddUnitAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.AddUnitQuery, variables })
            .then(({ data }) => {
                if (data) return data.addUnit;
            })
            .catch(err => {
                throw err;
            });
    };
};

export const EditActivityAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditActivityQuery, variables })
            .then(({ data }) => {
                if (data) return data.editActivity;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditAdditionalTitleAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditAdditionalTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.editAdditionalTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditAdministrationStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditAdministrationStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.editAdministrationStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditBodyPartAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditBodyPartQuery, variables })
            .then(({ data }) => {
                if (data) return data.editBodyPart;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditBodyTypeAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditBodyTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.editBodyType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditCantonAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditCantonQuery, variables })
            .then(({ data }) => {
                if (data) return data.editCanton;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditCivilStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditCivilStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.editCivilStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditContactChannelAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditContactChannelQuery, variables })
            .then(({ data }) => {
                if (data) return data.editContactChannel;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditCountryAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditCountryQuery, variables })
            .then(({ data }) => {
                if (data) return data.editCountry;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditDifficultyAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditDifficultyQuery, variables })
            .then(({ data }) => {
                if (data) return data.editDifficulty;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditEquipmentAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditEquipmentQuery, variables })
            .then(({ data }) => {
                if (data) return data.editEquipment;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditGenderAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditGenderQuery, variables })
            .then(({ data }) => {
                if (data) return data.editGender;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditGoalAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditGoalQuery, variables })
            .then(({ data }) => {
                if (data) return data.editGoal;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditGroupAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.editGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditMedicalFunctionAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditMedicalFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.editMedicalFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditNationalityAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditNationalityQuery, variables })
            .then(({ data }) => {
                if (data) return data.editNationality;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditPeopleGroupAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditPeopleGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.editPeopleGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditPhaseAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditPhaseQuery, variables })
            .then(({ data }) => {
                if (data) return data.editPhase;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditSOCardAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditSOCardQuery, variables })
            .then(({ data }) => {
                if (data) return data.editSOCard;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditSportFunctionAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditSportFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.editSportFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditSportStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditSportStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.editSportStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditSportAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditSportQuery, variables })
            .then(({ data }) => {
                if (data) return data.editSport;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.editStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditTerminationConditionsAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditTerminationConditionsQuery, variables })
            .then(({ data }) => {
                if (data) return data.editTerminationConditions;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditTestTypeAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditTestTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.editTestType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditTitleAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.editTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditTrainingFrequencyAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditTrainingFrequencyQuery, variables })
            .then(({ data }) => {
                if (data) return data.editTrainingFrequency;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditTrainingStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditTrainingStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.editTrainingStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditTrainingPeriodAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditTrainingPeriodQuery, variables })
            .then(({ data }) => {
                if (data) return data.editTrainingPeriod;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const EditUnitAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.EditUnitQuery, variables })
            .then(({ data }) => {
                if (data) return data.editUnit;
            })
            .catch(err => {
                throw err;
            });
    };
};

export const DeleteActivityAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteActivityQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteActivity;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteAdditionalTitleAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteAdditionalTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteAdditionalTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteAdministrationStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteAdministrationStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteAdministrationStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteBodyPartAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteBodyPartQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteBodyPart;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteBodyTypeAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteBodyTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteBodyType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteCantonAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteCantonQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteCanton;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteCivilStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteCivilStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteCivilStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteContactChannelAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteContactChannelQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteContactChannel;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteCountryAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteCountryQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteCountry;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteDifficultyAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteDifficultyQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteDifficulty;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteEquipmentAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteEquipmentQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteEquipment;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteGenderAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteGenderQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteGender;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteGoalAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteGoalQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteGoal;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteGroupAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteMedicalFunctionAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteMedicalFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteMedicalFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteNationalityAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteNationalityQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteNationality;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeletePeopleGroupAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeletePeopleGroupQuery, variables })
            .then(({ data }) => {
                if (data) return data.deletePeopleGroup;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeletePhaseAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeletePhaseQuery, variables })
            .then(({ data }) => {
                if (data) return data.deletePhase;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteSOCardAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteSOCardQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteSOCard;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteSportFunctionAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteSportFunctionQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteSportFunction;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteSportStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteSportStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteSportStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteSportAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteSportQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteSport;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteTerminationConditionsAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteTerminationConditionsQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteTerminationConditions;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteTestTypeAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteTestTypeQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteTestType;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteTitleAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteTitleQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteTitle;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteTrainingFrequencyAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteTrainingFrequencyQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteTrainingFrequency;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteTrainingStatusAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteTrainingStatusQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteTrainingStatus;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteTrainingPeriodAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteTrainingPeriodQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteTrainingPeriod;
            })
            .catch(err => {
                throw err;
            });
    };
};
export const DeleteUnitAction = variables => {
    return () => {
        return client
            .mutate({ mutation: mutations.DeleteUnitQuery, variables })
            .then(({ data }) => {
                if (data) return data.deleteUnit;
            })
            .catch(err => {
                throw err;
            });
    };
};

export default {
    SearchActivitiesAction,
    SearchAdditionalTitleAction,
    SearchAdministrationStatusAction,
    SearchBodyPartAction,
    SearchBodyTypeAction,
    SearchCantonAction,
    SearchCivilStatusAction,
    SearchContactChannelAction,
    SearchCountryAction,
    SearchDifficultyAction,
    SearchEquipmentAction,
    SearchGenderAction,
    SearchGoalAction,
    SearchGroupAction,
    SearchMedicalFunctionAction,
    SearchNationalityAction,
    SearchPeopleGroupAction,
    SearchPhaseAction,
    SearchSOCardAction,
    SearchSportFunctionAction,
    SearchSportStatusAction,
    SearchSportAction,
    SearchStatusAction,
    SearchTerminationConditionsAction,
    SearchTestTypeAction,
    SearchTitleAction,
    SearchTrainingFrequencyAction,
    SearchTrainingStatusAction,
    SearchTrainingPeriodAction,
    SearchUnitAction,
    AddActivitiesAction,
    AddAdditionalTitleAction,
    AddAdministrationStatusAction,
    AddBodyPartAction,
    AddBodyTypeAction,
    AddCantonAction,
    AddCivilStatusAction,
    AddContactChannelAction,
    AddCountryAction,
    AddDifficultyAction,
    AddEquipmentAction,
    AddGenderAction,
    AddGoalAction,
    AddGroupAction,
    AddMedicalFunctionAction,
    AddNationalityAction,
    AddPeopleGroupAction,
    AddPhaseAction,
    AddSOCardAction,
    AddSportFunctionAction,
    AddSportStatusAction,
    AddSportAction,
    AddStatusAction,
    AddTerminationConditionsAction,
    AddTestTypeAction,
    AddTitleAction,
    AddTrainingFrequencyAction,
    AddTrainingStatusAction,
    AddTrainingPeriodAction,
    AddUnitAction,
    EditActivityAction,
    EditAdditionalTitleAction,
    EditAdministrationStatusAction,
    EditBodyPartAction,
    EditBodyTypeAction,
    EditCantonAction,
    EditCivilStatusAction,
    EditContactChannelAction,
    EditCountryAction,
    EditDifficultyAction,
    EditEquipmentAction,
    EditGenderAction,
    EditGoalAction,
    EditGroupAction,
    EditMedicalFunctionAction,
    EditNationalityAction,
    EditPeopleGroupAction,
    EditPhaseAction,
    EditSOCardAction,
    EditSportFunctionAction,
    EditSportStatusAction,
    EditSportAction,
    EditStatusAction,
    EditTerminationConditionsAction,
    EditTestTypeAction,
    EditTitleAction,
    EditTrainingFrequencyAction,
    EditTrainingStatusAction,
    EditTrainingPeriodAction,
    EditUnitAction,
    DeleteActivityAction,
    DeleteAdditionalTitleAction,
    DeleteAdministrationStatusAction,
    DeleteBodyPartAction,
    DeleteBodyTypeAction,
    DeleteCantonAction,
    DeleteCivilStatusAction,
    DeleteContactChannelAction,
    DeleteCountryAction,
    DeleteDifficultyAction,
    DeleteEquipmentAction,
    DeleteGenderAction,
    DeleteGoalAction,
    DeleteGroupAction,
    DeleteMedicalFunctionAction,
    DeleteNationalityAction,
    DeletePeopleGroupAction,
    DeletePhaseAction,
    DeleteSOCardAction,
    DeleteSportFunctionAction,
    DeleteSportStatusAction,
    DeleteSportAction,
    DeleteStatusAction,
    DeleteTerminationConditionsAction,
    DeleteTestTypeAction,
    DeleteTitleAction,
    DeleteTrainingFrequencyAction,
    DeleteTrainingStatusAction,
    DeleteTrainingPeriodAction,
    DeleteUnitAction
};
