export const DEFAULT_LOCALE = "DE";

export const LOCALE = "locale";
export const AUTH_TOKEN = "token";
export const REFRESH_TOKEN = "refreshtoken";

export const LIMIT = 8;

export const getLocale = () => {
  const locale = localStorage.getItem("locale") || "";
  switch (locale.toUpperCase()) {
    case "EN":
      return "EN";
    case "DE":
      return "DE";
    default:
      return DEFAULT_LOCALE;
  }
};

export const getApiUrl = () => {
  // if (!window.location.hostname.includes("priosan")) {
  //     return "http://localhost:4000/";
  // }
  // return "https://dev-api.priosan.app/";
  switch (window.location.hostname) {
    case "admin.priosan.app":
      return "https://api.priosan.app/";
    case "dev-admin.priosan.app":
      return "https://dev-api.priosan.app/";
    default:
      return "http://localhost:4000/";
  }
};
