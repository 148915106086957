import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import moment from "moment";

import placeholder from "../../../assets/img/placeholders/200x200.png";
import checkIcon from "../../../assets/img/icons/check.png";
import { useState } from "react";

const UserList = (props) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (props.selected && props.selected.includes(props.users.id)) {
      setSelected(true);
    }
  }, [props.selected]);

  let onClickHandler = () => {
    if (props.selectUserModal) {
      if (!selected) {
        props.selectUserModal(props.users.id, selected);
        setSelected(true);
      } else {
        props.selectUserModal(props.users.id, selected);
        setSelected(false);
      }
    } else {
      props.history.push(`/dashboard/users/${get(props, "users.id", "-")}`);
    }
  };

  return (
    <tr style={{ cursor: "pointer" }} onClick={onClickHandler}>
      <td>
        <div className="profile-img">
          <img
            alt="profile"
            src={
              selected
                ? checkIcon
                : get(props, "users.img", false) || placeholder
            }
          />
        </div>
      </td>
      <td>
        <div className="info">
          <p>{`${get(props, "users.personal.name", "-")} ${get(
            props,
            "users.personal.surname",
            ""
          ) || "-"}`}</p>
        </div>
      </td>
      {/* <td>
                <div className="info">
                    <p>{get(props, "users.personal.phoneNumber", "-")}</p>
                </div>
            </td> */}
      <td>
        <div className="info">
          <p>{get(props, "users.email", "-")}</p>
        </div>
      </td>
      <td>
        <div className="info">
          <p>{get(props, "users.address.city", "-")}</p>
        </div>
      </td>
      <td>
        <div className="info">
          <p>
            {moment(get(props, "users.updatedAt", "-")).format(
              "YYYY-MM-DD hh:mm"
            )}
          </p>
        </div>
      </td>
      <td>
        <div className={props.users.status === "ACTIVE" ? "active" : "inactive"}>
          <span className="circle"></span>
          <p>{props.users.status === "ACTIVE" ? "Active" : "Inactive"}</p>
        </div>
      </td>
    </tr>
  );
};

UserList.propTypes = {
  users: PropTypes.object,
  history: PropTypes.object,
  selectUserModal: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.number),
};

export default withRouter(UserList);
