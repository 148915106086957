import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import TrainerList from "../../../components/Client/Trainer/TrainerList";
import { withRouter } from "react-router";
import { FetchAllTrainersQuery } from "./GraphQLActions";
import { orderHandler, classNameGenerator } from "../../../helpers/OrderHandlers";
import { trainerFilter } from "../../../store/user/trainer/trainer_actions";
import { Loading } from "../../../components/UI";
import { useTranslation } from "react-i18next";

let AllTrainers = () => {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let trainerState = useSelector(s => s.trainerState);
    const filters = trainerState.filters;
    const [error, setError] = useState(null);

    useEffect(() => {
        dispatch(
            FetchAllTrainersQuery({
                ...filters,
                status: filters.status.id || null
            })
        ).catch(err => {
            setError(err);
        });
    }, [dispatch, filters]);

    if (error) throw error;
    if (trainerState.totalTrainers > 0) {
        return (
            <>
                <table className="table table-priosan">
                    <thead>
                        <tr>
                            <th>
                                <div className="head-table">
                                    <p>{t("image.label")}</p>
                                </div>
                            </th>
                            <th>
                                <div className="head-table">
                                    <a
                                        onClick={e => orderHandler(e, filters.order, "name", dispatch, trainerFilter)}
                                        href="#"
                                    >
                                        <p>{t("name.label")}</p>
                                        <i className={classNameGenerator(filters.order, "name")} />
                                    </a>
                                </div>
                            </th>
                            {/* <th>
                                <div className="head-table">
                                    <a
                                        onClick={e =>
                                            orderHandler(e, filters.order, "phoneNumber", dispatch, trainerFilter)
                                        }
                                        href="#"
                                    >
                                        <p>{t("phone.label")}</p>
                                        <i className={classNameGenerator(filters.order, "phoneNumber")} />
                                    </a>
                                </div>
                            </th> */}
                            <th>
                                <div className="head-table">
                                    <a
                                        onClick={e => orderHandler(e, filters.order, "email", dispatch, trainerFilter)}
                                        href="#"
                                    >
                                        <p>{t("email.label")}</p>
                                        <i className={classNameGenerator(filters.order, "email")} />
                                    </a>
                                </div>
                            </th>
                            {/* <th>
                                <div className="head-table">
                                    <a
                                        onClick={e =>
                                            orderHandler(e, filters.order, "website", dispatch, trainerFilter)
                                        }
                                        href="#"
                                    >
                                        <p>{t("website.label")}</p>
                                        <i className={classNameGenerator(filters.order, "website")} />
                                    </a>
                                </div>
                            </th> */}
                            <th>
                                <div className="head-table">
                                    <a
                                        onClick={e => orderHandler(e, filters.order, "city", dispatch, trainerFilter)}
                                        href="#"
                                    >
                                        <p>{t("city.label")}</p>
                                        <i className={classNameGenerator(filters.order, "city")} />
                                    </a>
                                </div>
                            </th>
                            <th>
                                <div className="head-table">
                                    <a
                                        onClick={e =>
                                            orderHandler(e, filters.order, "updatedAt", dispatch, trainerFilter)
                                        }
                                        href="#"
                                    >
                                        <p>{t("updated_at.label")}</p>
                                        <i className={classNameGenerator(filters.order, "updatedAt")} />
                                    </a>
                                </div>
                            </th>
                            <th>
                                <div className="head-table">
                                    <a
                                        onClick={e => orderHandler(e, filters.order, "status", dispatch, trainerFilter)}
                                        href="#"
                                    >
                                        <p>{t("status.label")}</p>
                                        <i className={classNameGenerator(filters.order, "status")} />
                                    </a>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {trainerState.allTrainers.map(trainer => {
                            return <TrainerList key={trainer.id} users={trainer} />;
                        })}
                    </tbody>
                </table>
            </>
        );
    } else {
        return <div></div>;
    }
};

AllTrainers.propTypes = {
    history: PropTypes.object,
    search: PropTypes.string,
    status: PropTypes.number,
    offset: PropTypes.number
};

export default withRouter(AllTrainers);
