import React, { useState, Fragment, useEffect } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { ChangeLocaleAction } from "./GraphQLActions";
import { auth } from "../../../store/auth/auth_actions";
import { changeLocale } from "../../../helpers/changeLocale";

const Language = () => {
    const { t, i18n } = useTranslation();
    let authState = useSelector(s => s.authState);
    let dispatch = useDispatch();
    let [locale, setLocale] = useState(null);
    let [modal, setModal] = useState(false);

    useEffect(() => {}, [locale]);

    /**
     * @param {Event} e - The event
     */
    let submitFormHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        dispatch(ChangeLocaleAction({ locale })).then(() => {
            i18n.changeLanguage(locale);
            setModal(true);
        });
    };

    return (
        <Fragment>
            <div className="col-md-9">
                <div className="settings-form">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("language.label")}</label>
                                        <div className="select-custom select-max-width">
                                            <select
                                                defaultValue={locale || get(authState, "locale", "DE")}
                                                onChange={e => setLocale(e.target.value)}
                                                className="form-control"
                                            >
                                                <option value={"EN"}>{t("english.label")}</option>
                                                <option value={"DE"}>{t("german.label")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-end">
                                    <div className="settings-button">
                                        <button
                                            onClick={e => submitFormHandler(e)}
                                            data-toggle="modal"
                                            data-target="#modal"
                                            disabled={!locale || locale === authState.locale}
                                            className="btn btn-primary btn-180"
                                        >
                                            {t("update.label")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={modal}
                closeModal={() => {
                    setModal(false);
                    dispatch(auth({ locale }));
                    changeLocale(locale);
                }}
                text={t("lang_success_updated.message")}
                oneButton
            />
        </Fragment>
    );
};

export default Language;
