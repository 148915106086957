import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { LoginUserAction } from "./GraphQLActions";
import { useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { LOCALE, AUTH_TOKEN, REFRESH_TOKEN } from "consts";

const Login = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [errors, setErrors] = useState([]);
  const [, setLocale] = useLocalStorage(LOCALE);
  const [, setToken] = useLocalStorage(AUTH_TOKEN);
  const [, setRefreshtoken] = useLocalStorage(REFRESH_TOKEN);

  let handleLogin = (e) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let { email, password } = state;
    if (email && password) {
      dispatch(LoginUserAction({ email, password }))
        .then((data) => {
          console.log("LOGIN:", { data });

          setLocale(data.locale);
          setToken(data.token);
          setRefreshtoken(data.refreshtoken);
          window.location.reload();
          // history.push("/");
        })
        .catch((err) => {
          console.log("LOGIN:", { err });
          setErrors(err);
        });
    }
  };

  return (
    <div className="content">
      <div className="logo">
        <img alt="" src="/img/logo_favicon/logo-w.svg" />
      </div>
      <div className="description">
        <h4>Login</h4>
        <p>Welcome back, Please log in to your account.</p>
      </div>
      <form>
        <div className="form-group">
          {errors.length > 0
            ? errors.map((e, i) => (
                <p
                  key={i}
                  className="LoginErrors"
                  style={{ color: "red", marginBottom: 10, fontSize: "15px " }}
                >
                  {e}
                </p>
              ))
            : null}

          <input
            required
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            defaultValue={state.email}
            onChange={(e) => {
              setState({ ...state, email: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <input
            required
            type="password"
            name="password"
            className="form-control"
            placeholder="Password"
            defaultValue={state.password}
            onChange={(e) => {
              setState({ ...state, password: e.target.value });
            }}
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary btn-full-width"
            onClick={(e) => handleLogin(e)}
          >
            Login
          </button>
        </div>
        <div className="forgot-password">
          <Link to="/auth/forgetpassword">Forgot your password?</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
