import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";

import Search from "../../../components/UI/Search/Search";
import Select from "../../../components/UI/Select/Select";

import AllTrainers from "./allTrainers";

import {
  resetTrainerState,
  trainerFilter,
} from "../../../store/user/trainer/trainer_actions";
import { searchBaseModels } from "../../../resolvers/query/base";
import { useTranslation } from "react-i18next";

const Trainer = () => {
  const { t } = useTranslation();
  const trainerState = useSelector((s) => s.trainerState);
  const dispatch = useDispatch();
  const filters = trainerState.filters;


  let searchHandler = (e) => {
    dispatch(
      trainerFilter({
        search: e.target.value,
        offset: 0,
        page: 1,
      })
    );
  };

  let statusHandler = (e) => {
    dispatch(
      trainerFilter({
        status: e,
        offset: 0,
        page: 1,
      })
    );
  };

  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-6">
            <div className="training-result">
              <p>
                {t("total.label")}:
                <span>
                  {trainerState.totalTrainers || 0} {t("trainers.label")}
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="create-icon">
              <Link
                onClick={() => dispatch(resetTrainerState())}
                to="trainers/new-trainer"
              >
                <i className="icon-add"></i>
                <span> {t("create_trainer.label")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-between training-filter">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <Search
                searchHandler={searchHandler}
                querySearch={filters.searchFilter}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="row justify-content-end">
              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-4">
                <Select
                  text={t("filter_status.placeholder")}
                  select={statusHandler}
                  selectedItem={filters.status.id || ""}
                  items={[
                    { id: "ACTIVE", name: "Active" },
                    { id: "INACTIVE", name: "Inactive" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <AllTrainers
              search={filters.search}
              offset={filters.offset}
              status={filters.status ? filters.status.id : null}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            {trainerState.totalTrainers > 0 ? (
              <ReactPaginate
                previousLabel={<i className="icon-arrow-left"></i>}
                nextLabel={<i className="icon-arrow-right"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(trainerState.totalTrainers / 20) || 0}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  dispatch(
                    trainerFilter({
                      offset: e.selected * 20,
                      page: parseInt(e.selected) + 1,
                    })
                  );
                }}
                forcePage={filters.page - 1}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                containerClassName={"pagination"}
                activeClassName={"active"}
                activeLinkClassName={"active"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

Trainer.propTypes = {
  history: PropTypes.object,
};

export default Trainer;
