import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import moment from "moment";

import placeholder from "../../../assets/img/placeholders/200x200.png";

const TrainerList = (props) => (
  <tr
    style={{ cursor: "pointer" }}
    onClick={() => {
      props.history.push(`/dashboard/trainers/${get(props, "users.id", "")}`);
    }}
  >
    <td>
      <div className="profile-img">
        <img
          alt="profile"
          src={get(props, "users.img", false) || placeholder}
        />
      </div>
    </td>
    <td>
      <div className="info">
        <p>{`${get(props, "users.personal.name", null)} ${get(
          props,
          "users.personal.surname",
          null
        )}`}</p>
      </div>
    </td>
    {/* <td>
            <div className="info">
                <p>{get(props, "users.personal.phoneNumber") || "-"}</p>
            </div>
        </td> */}
    <td>
      <div className="info">
        <p>{get(props, "users.email", "-")}</p>
      </div>
    </td>
    <td>
      <div className="info">
        <p>{get(props, "users.address.city", "-")}</p>
      </div>
    </td>
    <td>
      <div className="info">
        <p>
          {moment(get(props, "users.updatedAt", "-")).format(
            "YYYY-MM-DD hh:mm"
          )}
        </p>
      </div>
    </td>
    <td>
      <div className={props.users.status === "ACTIVE" ? "active" : "inactive"}>
        <span className="circle"></span>
        <p>{props.users.status === "ACTIVE" ? "Active" : "Inactive"}</p>
      </div>
    </td>
  </tr>
);

TrainerList.propTypes = {
  users: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(TrainerList);
