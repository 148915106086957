import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import List from "./List";
import actions from "./GraphQLActions";
import { useTranslation } from "react-i18next";

const CategorySwitch = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    return (
        <Switch>
            <Route path={match.url + "/activities"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchActivitiesAction}
                    edit={actions.EditActivityAction}
                    create={actions.AddActivitiesAction}
                    delete={actions.DeleteActivityAction}
                />
            </Route>
            <Route path={match.url + "/additional-title"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchAdditionalTitleAction}
                    edit={actions.EditAdditionalTitleAction}
                    create={actions.AddAdditionalTitleAction}
                    delete={actions.DeleteAdditionalTitleAction}
                />
            </Route>
            <Route path={match.url + "/administration-status"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchAdministrationStatusAction}
                    edit={actions.EditAdministrationStatusAction}
                    create={actions.AddAdministrationStatusAction}
                    delete={actions.DeleteAdministrationStatusAction}
                />
            </Route>
            <Route path={match.url + "/body-part"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchBodyPartAction}
                    edit={actions.EditBodyPartAction}
                    create={actions.AddBodyPartAction}
                    delete={actions.DeleteBodyPartAction}
                />
            </Route>
            <Route path={match.url + "/body-type"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchBodyTypeAction}
                    edit={actions.EditBodyTypeAction}
                    create={actions.AddBodyTypeAction}
                    delete={actions.DeleteBodyTypeAction}
                />
            </Route>
            <Route path={match.url + "/canton"}>
                <List
                    locales={{ [t("english.label")]: "name" }}
                    fetch={actions.SearchCantonAction}
                    edit={actions.EditCantonAction}
                    create={actions.AddCantonAction}
                    delete={actions.DeleteCantonAction}
                />
            </Route>
            <Route path={match.url + "/civil-status"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchCivilStatusAction}
                    edit={actions.EditCivilStatusAction}
                    create={actions.AddCivilStatusAction}
                    delete={actions.DeleteCivilStatusAction}
                />
            </Route>
            <Route path={match.url + "/contact-channel"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchContactChannelAction}
                    edit={actions.EditContactChannelAction}
                    create={actions.AddContactChannelAction}
                    delete={actions.DeleteContactChannelAction}
                />
            </Route>
            <Route path={match.url + "/country"}>
                <List
                    locales={{ [t("english.label")]: "name" }}
                    fetch={actions.SearchCountryAction}
                    edit={actions.EditCountryAction}
                    create={actions.AddCountryAction}
                    delete={actions.DeleteCountryAction}
                />
            </Route>
            <Route path={match.url + "/difficulty"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchDifficultyAction}
                    edit={actions.EditDifficultyAction}
                    create={actions.AddDifficultyAction}
                    delete={actions.DeleteDifficultyAction}
                />
            </Route>
            <Route path={match.url + "/equipment"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchEquipmentAction}
                    edit={actions.EditEquipmentAction}
                    create={actions.AddEquipmentAction}
                    delete={actions.DeleteEquipmentAction}
                />
            </Route>
            <Route path={match.url + "/gender"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchGenderAction}
                    edit={actions.EditGenderAction}
                    create={actions.AddGenderAction}
                    delete={actions.DeleteGenderAction}
                />
            </Route>
            <Route path={match.url + "/goal"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchGoalAction}
                    edit={actions.EditGoalAction}
                    // create={actions.AddGoalAction}
                    // delete={actions.DeleteGoalAction}
                />
            </Route>
            <Route path={match.url + "/group"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchGroupAction}
                    edit={actions.EditGroupAction}
                    create={actions.AddGroupAction}
                    delete={actions.DeleteGroupAction}
                />
            </Route>
            <Route path={match.url + "/medical-function"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchMedicalFunctionAction}
                    edit={actions.EditMedicalFunctionAction}
                    create={actions.AddMedicalFunctionAction}
                    delete={actions.DeleteMedicalFunctionAction}
                />
            </Route>
            <Route path={match.url + "/nationality"}>
                <List
                    locales={{ [t("english.label")]: "name" }}
                    fetch={actions.SearchNationalityAction}
                    edit={actions.EditNationalityAction}
                    create={actions.AddNationalityAction}
                    delete={actions.DeleteNationalityAction}
                />
            </Route>
            <Route path={match.url + "/people-group"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchPeopleGroupAction}
                    edit={actions.EditPeopleGroupAction}
                    create={actions.AddPeopleGroupAction}
                    delete={actions.DeletePeopleGroupAction}
                />
            </Route>
            <Route path={match.url + "/phase"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchPhaseAction}
                    edit={actions.EditPhaseAction}
                    create={actions.AddPhaseAction}
                    delete={actions.DeletePhaseAction}
                />
            </Route>
            <Route path={match.url + "/so-card"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchSOCardAction}
                    edit={actions.EditSOCardAction}
                    create={actions.AddSOCardAction}
                    delete={actions.DeleteSOCardAction}
                />
            </Route>
            <Route path={match.url + "/sport-function"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchSportFunctionAction}
                    edit={actions.EditSportFunctionAction}
                    create={actions.AddSportFunctionAction}
                    delete={actions.DeleteSportFunctionAction}
                />
            </Route>
            <Route path={match.url + "/sport-status"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchSportStatusAction}
                    edit={actions.EditSportStatusAction}
                    create={actions.AddSportStatusAction}
                    delete={actions.DeleteSportStatusAction}
                />
            </Route>
            <Route path={match.url + "/sport"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchSportAction}
                    edit={actions.EditSportAction}
                    create={actions.AddSportAction}
                    delete={actions.DeleteSportAction}
                />
            </Route>
            <Route path={match.url + "/status"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchStatusAction}
                    edit={actions.EditStatusAction}
                    create={actions.AddStatusAction}
                    delete={actions.DeleteStatusAction}
                />
            </Route>
            <Route path={match.url + "/termination-conditions"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchTerminationConditionsAction}
                    edit={actions.EditTerminationConditionsAction}
                />
            </Route>
            <Route path={match.url + "/test-type"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchTestTypeAction}
                    edit={actions.EditTestTypeAction}
                    create={actions.AddTestTypeAction}
                    delete={actions.DeleteTestTypeAction}
                />
            </Route>
            <Route path={match.url + "/title"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchTitleAction}
                    edit={actions.EditTitleAction}
                    create={actions.AddTitleAction}
                    delete={actions.DeleteTitleAction}
                />
            </Route>
            <Route path={match.url + "/training-frequency"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchTrainingFrequencyAction}
                    edit={actions.EditTrainingFrequencyAction}
                    create={actions.AddTrainingFrequencyAction}
                    delete={actions.DeleteTrainingFrequencyAction}
                />
            </Route>
            <Route path={match.url + "/training-status"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchTrainingStatusAction}
                    edit={actions.EditTrainingStatusAction}
                    create={actions.AddTrainingStatusAction}
                    delete={actions.DeleteTrainingStatusAction}
                />
            </Route>
            <Route path={match.url + "/training-period"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchTrainingPeriodAction}
                    edit={actions.EditTrainingPeriodAction}
                    create={actions.AddTrainingPeriodAction}
                    delete={actions.DeleteTrainingPeriodAction}
                />
            </Route>
            <Route exact path={match.url + "/unit"}>
                <List
                    locales={{ [t("english.label")]: "name", [t("german.label")]: "name_de" }}
                    fetch={actions.SearchUnitAction}
                    edit={actions.EditUnitAction}
                    create={actions.AddUnitAction}
                    delete={actions.DeleteUnitAction}
                />
            </Route>
        </Switch>
    );
};

export default CategorySwitch;
