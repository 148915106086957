import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import get from "lodash/get";

import placeholder from "../../../assets/img/placeholders/200x200.png";
import { useTranslation } from "react-i18next";

const SingleCompany = () => {
    const { t } = useTranslation();
    const { companyState } = useSelector(s => s);
    const companyProps = companyState.singleCompany;

    let convCreatedAt = moment(get(companyProps, "createdAt", "")).format("D MMMM YYYY");
    return (
        <section className="main-content">
            <div className="container-fluid">
                <div className="white-bg">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-detail-head">
                                <Link to={`${get(companyProps, "id", "")}/edit-company`}>
                                    <i className="icon-edit"></i>
                                    <span>{t("edit.label")}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 user-flex">
                            <div className="user-img">
                                <img alt="profile" src={get(companyProps, "img", false) || placeholder} />
                            </div>
                            <div className="user-detail">
                                <div className="user-row">
                                    <div className="user-info">
                                        <p>{t("name.label")}</p>
                                        <span>{get(companyProps, "personal.name", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("email.label")}</p>
                                        <span>{get(companyProps, "email", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("street.label")}</p>
                                        <span>{get(companyProps, "address.street", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("postal.label")}</p>
                                        <span>{get(companyProps, "address.postal", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("city.label")}</p>
                                        <span>{get(companyProps, "address.city", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("country.label")}</p>
                                        <span>{get(companyProps, "address.country.name", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("website.label")}</p>
                                        <span>{get(companyProps, "private_info.website", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("phone.label")}</p>
                                        <span>{get(companyProps, "personal.phoneNumber", "-")}</span>
                                    </div>
                                    <div className="user-info">
                                        <p>{t("date_registered.label")}</p>
                                        <span>{convCreatedAt || "-"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid">
                    <div className="white-bg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="training-head">
                                    <div className="title">
                                        <h5>Training Plans</h5>
                                    </div>
                                    <div className="create-icon">
                                        <a href="#">
                                            <i className="icon-add"></i>
                                            <span>Add Trainingplans</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {
                                company.trainingPlans.map(e => (
                                    <TrainingplanList
                                        key={e.id}
                                        plan={e}
                                    />
                                ))
                            }



                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="training-head">
                                    <div className="title">
                                        <h5>Tests</h5>
                                    </div>
                                    <div className="create-icon">
                                        <a href="#">
                                            <i className="icon-add"></i>
                                            <span>Add Test</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {
                                user.tests.map(e => (
                                    <TestList
                                        key={e.id}
                                        tests={e}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div> */}
        </section>
    );
};

export default SingleCompany;
