/* eslint-disable default-case */
import * as actionType from "./auth_actions";

const authState = {
    password: "",
    confirmPassword: "",
    is_admin: false,
    errors: [],
    loading: false,
    modal: false,
    id: null,
    personal: {
        name: "",
        phoneNumber: "",
        surname: ""
    },
    email: "",
    address: {
        street: ""
    },
    address: {
        street: "",
        postal: "",
        country: {
            id: "",
            name: ""
        },
        city: ""
    },
    role: {
        id: "",
        name: ""
    },
    img: "",
    is_admin: "",
    company: {
        id: "",
        personal: {
            phoneNumber: "",
            name: ""
        },
        img: "null",
        address: {
            street: ""
        },
        private_info: {
            website: ""
        }
    }
};

const resetState = {
    password: "",
    confirmPassword: "",
    is_admin: false,
    errors: [],
    loading: false,
    modal: false,
    id: null,
    personal: {
        name: "",
        phoneNumber: "",
        surname: ""
    },
    email: "",
    address: {
        street: ""
    },
    address: {
        street: "",
        postal: "",
        country: {
            id: "",
            name: ""
        },
        city: ""
    },
    role: {
        id: "",
        name: ""
    },
    img: "",
    is_admin: "",
    company: {
        id: "",
        personal: {
            phoneNumber: "",
            name: ""
        },
        img: "null",
        address: {
            street: ""
        },
        private_info: {
            website: ""
        }
    }
};

const reducer = (state = authState, action) => {
    switch (action.type) {
        case actionType.AUTH:
            return {
                ...state,
                ...action.value
            };
        case actionType.AUTH_ADDRESS:
            return {
                ...state,
                address: {
                    ...state.address,
                    ...action.value
                }
            };
        case actionType.AUTH_PERSONAL:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    ...action.value
                }
            };
        case actionType.AUTH:
            return {
                ...state,
                ...action.value
            };
        case actionType.AUTH_ERRORS:
            return {
                ...state,
                loading: false,
                modal: false,
                errors: action.value
            };
        case actionType.AUTH_LOADING:
            return {
                ...state,
                loading: true,
                modal: false,
                errors: []
            };
        case actionType.RESET_AUTH_STATE:
            return {
                ...state,
                ...resetState
            };
    }
    return state;
};

export default reducer;
