import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import PropTypes from "prop-types";

import Logo from "../../assets/img/logo_favicon/logo-w.svg";
import { auth } from "../../store/auth/auth_actions";
import { ForgotPasswordAction } from "./GraphQLActions";

import Modal from "../../components/UI/Modal/Modal";
import { toggleLoadingOn } from "../../store/global/global_actions";

const Login = props => {
    let { authState } = useSelector(s => s);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]);

    let submitHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        if (!validator.isEmail(email)) {
            return setErrors(["Please provide a valid email"]);
        }
        dispatch(ForgotPasswordAction({ email })).catch(err => setErrors(err));
    };
    return (
        <>
            <div className="content">
                <div className="logo">
                    <img alt="" src={Logo} />
                </div>
                <div className="description">
                    <h4>Forgot Password</h4>
                    <p>Please enter your email.</p>
                </div>
                <form>
                    <div className="form-group">
                        {errors.map((e, i) => (
                            <p
                                key={i}
                                className="LoginErrors"
                                style={{ color: "red", marginBottom: 10, fontSize: "15px" }}
                            >
                                {e}
                            </p>
                        ))}
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn btn-primary btn-full-width"
                            onClick={e => submitHandler(e)}
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                oneButton
                text={authState.errors.success}
                closeModal={() => {
                    dispatch(auth({ modal: false }));
                    props.history.push("/");
                }}
                show={authState.modal}
            />
        </>
    );
};

Login.propTypes = {
    history: PropTypes.object
};

export default Login;
