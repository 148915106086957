module.exports.GeneralInfoValidations = isUpdate => [
    {
        field: "title",
        rules: [
            { name: "isEmpty", params: {}, validWhen: false },
            { name: "isLength", params: { max: 200 }, validWhen: true }
        ],
        messages: ["Title can't be empty.", "Input too long. Max 200 characters."],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "objective",
        rules: [{ name: "isLength", params: { max: 200 }, validWhen: true }],
        messages: ["Input too long. Max 200 characters."],
        variableType: []
    },
    {
        field: "trainingperiods",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value"],
        required: !isUpdate,
        variableType: ["array", "object"]
    },
    {
        field: "duration",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value"],
        required: !isUpdate,
        variableType: []
    },
    {
        field: "phases",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value"],
        required: !isUpdate,
        variableType: ["array", "object"]
    },
    {
        field: "equipments",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value"],
        required: !isUpdate,
        variableType: ["array", "object"]
    },
    {
        field: "difficulty",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value"],
        required: !isUpdate,
        variableType: ["object"]
    },
    {
        field: "notes",
        rules: [{ name: "isLength", params: { max: 200 }, validWhen: true }],
        messages: ["Input too long. Max 200 characters."],
        variableType: []
    },
    {
        field: "description",
        rules: [{ name: "isLength", params: { max: 200 }, validWhen: true }],
        messages: ["Input too long. Max 200 characters."],
        variableType: []
    },
    {
        field: "bodyparts",
        rules: [{ name: "isInt", params: {}, validWhen: true }],
        messages: ["Invalid value"],
        required: !isUpdate,
        variableType: ["array", "object"]
    }
];
