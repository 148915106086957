import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import MeasurementsQuestion from "components/Tests/MeasurementsQuestion";
import QandAQuestions from "components/Tests/QandAQuestions";
import ScalaQuestions from "components/Tests/ScalaQuestions";
import VariantQuestions from "components/Tests/VariantQuestions";

import WizardNav from "components/UI/WizardNav/WizardNav";
import Select from "components/UI/Select/Select";

import { updateTest, test } from "store/test/test_actions";
import { searchBaseModels as SearchBaseModels } from "resolvers/query/base";

const Questions = props => {
    const { t } = useTranslation();
    const testState = useSelector(s => s.testState);
    const updateState = testState.updateTest;
    const dispatch = useDispatch();
    // const history = useHistory();

    // useEffect(() => {
    //     // if (!testState.firstStepValidity.isValid) {
    //     //     history.push("general-info");
    //     // }
    //     // if (get(updateState, "questions", []).length === 0) {
    //     //     dispatch(
    //     //         updateTest({ questions: [{ correct_answer: get(updateState, "type.id", null) === 2 ? 1 : null }] })
    //     //     );
    //     // }
    // }, [dispatch, history, testState.firstStepValidity.isValid, updateState]);

    let addQuestion = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        let questions = [...get(updateState, "questions", [])];
        questions.push({ correct_answer: get(updateState, "type.id", null) === 2 ? 1 : null });
        dispatch(updateTest({ questions }));
    };

    let removeQuestion = (event, index) => {
        event.preventDefault();
        let questions = [];
        questions = [...get(updateState, "questions", [])];
        if (questions.length > 1) questions.splice(index, 1);
        dispatch(updateTest({ questions }));
    };

    let changeUnitForQuestion = (unit, index) => {
        let questions = [...get(updateState, "questions", [])];
        questions[index].unit = unit;
        dispatch(updateTest({ questions }));
    };

    let changeQuestionContent = (content, index) => {
        let questions = [];
        questions = [...get(updateState, "questions", [])];
        questions[index].content = content;
        dispatch(updateTest({ questions }));
    };

    /**
     *
     * @param {Event} event
     * @param {Number} index
     */
    const onChangeHandler = (event, index) => {
        const { name, value } = event.target;
        let questions = [];
        questions = [...get(updateState, "questions", [])];
        questions[index][name] = value;

        dispatch(updateTest({ questions }));
    };

    const { loading, data } = useQuery(SearchBaseModels, { variables: { unit: true, testtype: true } });

    const displayQuestions = () => {
        switch (parseInt(get(updateState, "type.id", ""))) {
            case 1:
                return get(updateState, "questions", []).map((e, i) => {
                    return (
                        <QandAQuestions
                            key={i}
                            question={e}
                            changeHandler={e => onChangeHandler(e, i)}
                            remove={e => removeQuestion(e, i)}
                        />
                    );
                });
            case 2:
                return get(updateState, "questions", []).map((e, i) => {
                    return (
                        <ScalaQuestions
                            key={i}
                            question={e}
                            changeHandler={e => onChangeHandler(e, i)}
                            remove={e => removeQuestion(e, i)}
                        />
                    );
                });
            case 3:
                return get(updateState, "questions", []).map((e, i) => {
                    return (
                        <VariantQuestions
                            key={i}
                            order={i}
                            question={e}
                            changeHandler={e => onChangeHandler(e, i)}
                            remove={e => removeQuestion(e, i)}
                        />
                    );
                });
            case 4:
                return get(updateState, "questions", []).map((e, i) => {
                    return (
                        <MeasurementsQuestion
                            key={i}
                            units={loading ? [] : data.searchBaseModels.Unit}
                            question={e}
                            changeHandler={e => onChangeHandler(e, i)}
                            remove={e => removeQuestion(e, i)}
                        />
                    );
                });
            default:
                return null;
        }
    };

    /**
     *
     * @param {Event} e
     */
    let submitHandler = (e, link) => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        if (testState.firstStepValidity.isValid && testState.secondStepValidity.isValid) {
            props.history.push(link);
        } else {
            dispatch(test({ displayQuestionErrors: true }));
        }
    };

    useEffect(() => {
        if (!testState.firstStepValidity.isValid) {
            props.history.push("general-info");
        }
    }, []);

    return (
        <>
            <WizardNav
                first={t("general_info.label")}
                firstLink="general-info"
                second={t("questions.label")}
                secondLink="questions"
                active1={testState.firstStepValidity.isValid}
                active2={testState.firstStepValidity.isValid && testState.secondStepValidity.isValid}
                third={t("overview.label")}
                thirdLink="overview"
                active={2}
            />
            <div className="container-fluid">
                <form>
                    <div className="training-form">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="row">
                                    {testState.displayQuestionErrors ? (
                                        <p style={{ color: "red", marginBottom: "12px" }}>
                                            {testState.secondStepValidity.error}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t("chose_the_type.label")}</label>
                                            <Select
                                                items={loading ? [] : data.searchBaseModels.TestType}
                                                selectedItem={get(updateState, "type.id", "")}
                                                select={e => dispatch(updateTest({ type: e, questions: [] }))}
                                                disable={!!updateState.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div id="question-row" className="row">
                                    {displayQuestions()}
                                </div>
                                {get(updateState, "type.id", false) ? (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <a
                                                onClick={e => addQuestion(e)}
                                                href="#"
                                                className="add-item"
                                            >
                                                {t("add_question.label")}
                                                <i className="icon-add"></i>
                                            </a>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <div className="btn-right">
                                            <Link to="general-info" className="btn btn-cancel btn-180">
                                                {t("back.label")}
                                            </Link>
                                            <button
                                                onClick={e => submitHandler(e, "overview")}
                                                className="btn btn-primary btn-180"
                                            >
                                                {t("next.label")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

Questions.propTypes = {
    history: PropTypes.object
};

export default Questions;
