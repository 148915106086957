import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import get from "lodash/get";

const VariantQuestions = ({ remove, changeHandler, question, order }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({});

    useEffect(() => {
        setState(question);
    }, [question]);

    /**
     *
     * @param {Event} e
     * @param {Number} index
     */
    const changeOptionHandler = (e, index, isCheckbox = false) => {
        let options = [...(question.options || [])];
        options[index][e.target.name] = e.target.value;
        if (isCheckbox) {
            switch (e.target.name) {
                case "isCorrect":
                    (options[index].sub_options || []).map(subOption => {
                        subOption.isCorrect = e.target.value;
                    });
                    break;
                case "sub_options":
                    let allChecked = true;
                    let allUnchecked = false;
                    (options[index].sub_options || []).map(subOption => {
                        allChecked = allChecked && subOption.isCorrect;
                        allUnchecked = allUnchecked || subOption.isCorrect;
                    });
                    if (allChecked) options[index].isCorrect = allChecked;
                    if (!allUnchecked) options[index].isCorrect = allUnchecked;
                    if (!allChecked && allUnchecked) options[index].isCorrect = false;
                    break;
            }
        }
        changeHandler({ target: { name: "options", value: options } });
    };

    /**
     *
     * @param {Event} e
     * @param {Number} oIndex
     * @param {Number} sIndex
     */
    const changeSubOptionHandler = (e, oIndex, sIndex, isCheckbox = false) => {
        let subOptions = [...get(question, `options[${oIndex}].sub_options`, [])];
        subOptions[sIndex][e.target.name] = e.target.value;
        changeOptionHandler({ target: { name: "sub_options", value: subOptions } }, oIndex, isCheckbox);
    };

    /**
     *
     * @param {Event} e
     * @param {Number} index
     */
    const removeOptionHandler = (e, index) => {
        e.preventDefault();
        let options = [...(question.options || [])];
        options.splice(index, 1);
        changeHandler({ target: { name: "options", value: options } });
    };

    /**
     *
     * @param {Event} e
     */
    const addOptionHandler = e => {
        e.preventDefault();
        let options = [...(question.options || [])];
        options.push({ content: "", isCorrect: false, sub_options: [] });
        changeHandler({ target: { name: "options", value: options } });
    };

    /**
     *
     * @param {Event} e
     * @param {Number} oIndex
     * @param {Number} sIndex
     */
    const removeSubOptionHandler = (e, oIndex, sIndex) => {
        e.preventDefault();
        let subOptions = [...get(question, `options[${oIndex}].sub_options`, [])];
        subOptions.splice(sIndex, 1);
        changeOptionHandler({ target: { name: "sub_options", value: subOptions } }, oIndex);
    };

    /**
     *
     * @param {Event} e
     * @param {Number} oIndex
     */
    const addSubOptionHandler = (e, oIndex) => {
        e.preventDefault();
        let subOptions = [...get(question, `options[${oIndex}].sub_options`, [])];
        subOptions.push({ content: "", isCorrect: get(question, `options[${oIndex}].isCorrect`, false) });
        changeOptionHandler({ target: { name: "sub_options", value: subOptions } }, oIndex);
    };

    return (
        <div className="col-md-6">
            <div className="form-group form-question">
                <a onClick={e => remove(e)} href="#" className="remove-item">
                    Remove <i className="icon-cancel"></i>
                </a>
                <input
                    type="text"
                    placeholder={t("write_your_question.label")}
                    name="content"
                    className="form-control"
                    onChange={e => changeHandler(e)}
                    defaultValue={state.content || ""}
                />
            </div>
            <div className="form-group checkbox-wrapper">
                {(state.options || []).map((option, oIndex) => {
                    return (
                        <div key={oIndex} className="checkbox-group">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id={order + "." + oIndex}
                                    name="isCorrect"
                                    onChange={e =>
                                        changeOptionHandler(
                                            { target: { name: e.target.name, value: e.target.checked } },
                                            oIndex,
                                            true
                                        )
                                    }
                                    checked={option.isCorrect}
                                />
                                <label htmlFor={order + "." + oIndex}></label>
                                <input
                                    type="text"
                                    name="content"
                                    placeholder="option..."
                                    onChange={e => changeOptionHandler(e, oIndex)}
                                    defaultValue={option.content || ""}
                                    className="form-checkbox"
                                />
                                <a href="#" className="close-checkbox" onClick={e => removeOptionHandler(e, oIndex)}>
                                    <i className="icon-cancel"></i>
                                </a>
                            </div>
                            {(option.sub_options || []).map((subOption, sIndex) => {
                                return (
                                    <div key={oIndex + "." + sIndex} className="checkbox checkbox-inner">
                                        <input
                                            type="checkbox"
                                            id={order + "." + oIndex + "." + sIndex}
                                            name="isCorrect"
                                            onChange={e =>
                                                changeSubOptionHandler(
                                                    { target: { name: e.target.name, value: e.target.checked } },
                                                    oIndex,
                                                    sIndex,
                                                    true
                                                )
                                            }
                                            checked={subOption.isCorrect}
                                        />
                                        <label htmlFor={order + "." + oIndex + "." + sIndex}></label>
                                        <input
                                            type="text"
                                            name="content"
                                            defaultValue={subOption.content || ""}
                                            placeholder="sub option..."
                                            onChange={e => changeSubOptionHandler(e, oIndex, sIndex)}
                                            className="form-checkbox"
                                        />
                                        <a
                                            onClick={e => removeSubOptionHandler(e, oIndex, sIndex)}
                                            href="#"
                                            className="close-checkbox"
                                        >
                                            <i className="icon-cancel"></i>
                                        </a>
                                    </div>
                                );
                            })}

                            <a onClick={e => addSubOptionHandler(e, oIndex)} href="#" className="add-suboptions">
                                <i className="icon-add"></i>
                                <span>Add suboptions</span>
                            </a>
                        </div>
                    );
                })}
            </div>
            <div className="form-group add-options-wrapper">
                <a onClick={e => addOptionHandler(e)} href="#" className="add-options">
                    <i className="icon-add"></i>
                    <span>Add options</span>
                </a>
            </div>
        </div>
    );
};

VariantQuestions.propTypes = {
    changeHandler: PropTypes.func,
    remove: PropTypes.func,
    question: PropTypes.object,
    order: PropTypes.number
};

export default VariantQuestions;
