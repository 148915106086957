import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import get from "lodash/get";
import Slider from "react-slick";

import WizardNav from "../../../../components/UI/WizardNav/WizardNav";
import AllExercises from "../../Exercise/allExercises";
import Search from "../../../../components/UI/Search/Search";
import Select from "../../../../components/UI/Select/Select";
import { searchBaseModels } from "../../../../resolvers/query/base";
import {
    planFilter,
    updateTrainingPlan,
    singleTrainingPlan
} from "../../../../store/trainingplans/trainingplans_actions";
import ExerciseList from "../../../../components/Exercises/ExerciseList";
import FiltersMultipleSelect from "../../../../components/UI/Select/FiltersMultipleSelect";
import { useTranslation } from "react-i18next";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        { breakpoint: 768, settings: { slidesToShow: 1 } },
        { breakpoint: 990, settings: { slidesToShow: 2 } },
        { breakpoint: 1200, settings: { slidesToShow: 3 } },
        { breakpoint: 1600, settings: { slidesToShow: 4 } }
    ]
};

const SelectExercises = props => {
    const { t } = useTranslation();
    const planState = useSelector(s => s.planState);
    const authState = useSelector(s => s.authState);
    const reduxState = planState.singleTrainingPlan;
    const updateState = planState.updateTrainingPlan;

    const dispatch = useDispatch();
    const filters = planState.filters;

    useEffect(() => {
        if (authState.role.id === 1) {
            dispatch(planFilter({ creatorExercise: { id: -1, name: "Global" }, offsetExercise: 0, pageExercise: 1 }));
        }
    }, [authState.role.id, dispatch]);

    let addExerciseToTrainingPlan = (checked, exercise, isNew) => {
        if (isNew) {
            let exercises = [...get(updateState, "exercises", [])];
            let exercisesId = [...get(updateState, "exercisesId", [])];
            if (!checked) {
                exercises.push({ ...exercise, master: parseInt(exercise.id) });
                exercisesId.push(parseInt(exercise.id));
                dispatch(updateTrainingPlan({ exercises, exercisesId }));
            } else {
                let filtered = exercises.filter(e => e !== exercise);
                let filteredId = exercisesId.filter(e => parseInt(e) !== parseInt(exercise.id));
                dispatch(updateTrainingPlan({ exercises: filtered, exercisesId: filteredId }));
            }
        } else {
            let exercises = [...get(reduxState, "exercises", [])];
            let exercisesId = [...get(reduxState, "exercisesId", [])];
            let filtered = exercises.filter(e => e !== exercise);
            let filteredId = exercisesId.filter(e => parseInt(e) !== parseInt(exercise.id));
            dispatch(singleTrainingPlan({ exercises: filtered, exercisesId: filteredId }));
        }
    };

    let { data, loading, error } = useQuery(searchBaseModels, {
        variables: { group: true, bodypart: true, difficulty: true, company: "exercise" }
    });

    let bodypartSelectHandler = e => {
        dispatch(planFilter({ bodypartsExercise: e ? e : [], offsetExercise: 0, pageExercise: 1 }));
    };

    let groupSelectHandler = e => {
        dispatch(planFilter({ groupsExercise: e ? e : [], offsetExercise: 0, pageExercise: 1 }));
    };

    let difficultySelectHandler = e => {
        dispatch(planFilter({ difficultyExercise: e ? e : null, offsetExercise: 0, pageExercise: 1 }));
    };

    let creatorSelectHandler = e => {
        dispatch(planFilter({ creatorExercise: e ? e : null, offsetExercise: 0, pageExercise: 1 }));
    };

    const BaseModels = () => {
        if (loading) {
            return (
                <Fragment>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <FiltersMultipleSelect text={t("filter_bodypart.placeholder")} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <FiltersMultipleSelect text={t("filter_group.placeholder")} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Select text={t("filter_difficulty.placeholder")} />
                    </div>
                    {authState.role.id === 1 ? null : (
                        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <Select text={t("filter_library.placeholder")} />
                        </div>
                    )}
                </Fragment>
            );
        }
        if (error) return `Error! ${error}`;
        return (
            <Fragment>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <FiltersMultipleSelect
                        text={t("filter_bodypart.placeholder")}
                        select={e => bodypartSelectHandler(e)}
                        selectedItems={filters.bodypartsExercise}
                        items={data.searchBaseModels.BodyPart}
                    />
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <FiltersMultipleSelect
                        text={t("filter_group.placeholder")}
                        select={e => groupSelectHandler(e)}
                        selectedItems={filters.groupsExercise}
                        items={data.searchBaseModels.Group}
                    />
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <Select
                        text={t("filter_difficulty.placeholder")}
                        selectedItem={filters.difficultyExercise.id || ""}
                        select={e => difficultySelectHandler(e)}
                        items={data.searchBaseModels.Difficulty}
                    />
                </div>
                {authState.role.id === 1 ? null : (
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Select
                            text={t("filter_library.placeholder")}
                            selectedItem={filters.creatorExercise.id || ""}
                            select={e => creatorSelectHandler(e)}
                            items={data.searchBaseModels.Company}
                        />
                    </div>
                )}
            </Fragment>
        );
    };

    if (!planState.isValidGeneralInfo.isValid) {
        props.history.push("general-info");
        return null;
    }

    return (
        <section className="main-content">
            <WizardNav
                first={t("general_info.label")}
                firstLink="general-info"
                second={t("select_exercises.label")}
                secondLink="select-exercises"
                third={t("overview.label")}
                thirdLink="overview"
                active1={planState.isValidGeneralInfo.isValid}
                active2={planState.isValidGeneralInfo.isValid && planState.isValidSelectExercises}
                active={2}
            />
            <div className="container-fluid">
                <div className="row justify-content-between training-filter">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div className="row">
                            <Search
                                searchHandler={e => dispatch(planFilter({ searchExercise: e.target.value }))}
                                querySearch={filters.searchExercise}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                        <div className="row">{BaseModels()}</div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="training-slider m-b-30">
                    <div className="row excercises justify-content-center">
                        <div className="col-md-12">
                            <div className="excercises-title">
                                <p>
                                    {t("exercises_in_tp.label")} (
                                    <span>
                                        {get(reduxState, "exercises", []).length +
                                            get(updateState, "exercises", []).length}
                                    </span>
                                    )
                                </p>
                            </div>
                        </div>
                        {!(get(reduxState, "exercises", []).length + get(updateState, "exercises", []).length > 0) ? (
                            <div className="col-md-12">
                                <div className="excercises-title">
                                    <span className="select">{t("please_select_exercises.label")}</span>
                                </div>
                                <div className=""></div>
                            </div>
                        ) : (
                            <div className="col-md-12">
                                <div>
                                    <Slider {...settings}>
                                        {get(reduxState, "exercises", []).map(exercise => (
                                            <ExerciseList
                                                isChecked={true}
                                                forPlan={true}
                                                changeClassForPlans={true}
                                                selectExerciseHandler={addExerciseToTrainingPlan}
                                                key={exercise.id}
                                                exercises={exercise}
                                            />
                                        ))}
                                        {get(updateState, "exercises", []).map(exercise => (
                                            <ExerciseList
                                                isChecked={true}
                                                forPlan={true}
                                                changeClassForPlans={true}
                                                selectExerciseHandler={addExerciseToTrainingPlan}
                                                key={exercise.id}
                                                exercises={exercise}
                                                isNew
                                            />
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="training-slider">
                    <div className="row excercises">
                        <div className="col-md-12">
                            <div className="excercises-title">
                                <p>{t("select_exercises.label")}</p>
                            </div>
                        </div>
                        <AllExercises
                            forPlan={true}
                            selectExerciseHandler={addExerciseToTrainingPlan}
                            isNew
                            selectedExercises={get(updateState, "exercisesId", [])}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="icon-arrow-left"></i>}
                            nextLabel={<i className="icon-arrow-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(planState.totalExercise / 8) || 0}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={e =>
                                dispatch(
                                    planFilter({
                                        offsetExercise: e.selected * 8,
                                        pageExercise: parseInt(e.selected) + 1
                                    })
                                )
                            }
                            pageClassName={"page-item"}
                            forcePage={filters.page - 1}
                            pageLinkClassName={"page-link"}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            activeLinkClassName={"active"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                        />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                        <div className="btn-right">
                            <Link
                                to={
                                    planState.id
                                        ? "/dashboard/trainingplans/" + planState.id + "/edit-trainingplan/general-info"
                                        : "/dashboard/trainingplans/new-trainingplan/general-info"
                                }
                                className="btn btn-cancel btn-180"
                            >
                                {t("back.label")}
                            </Link>
                            <button
                                disabled={!planState.isValidSelectExercises}
                                onClick={() =>
                                    props.history.push(
                                        reduxState.id
                                            ? "/dashboard/trainingplans/" +
                                                  reduxState.id +
                                                  "/edit-trainingplan/overview"
                                            : "/dashboard/trainingplans/new-trainingplan/overview"
                                    )
                                }
                                className="btn btn-primary btn-180"
                            >
                                {t("next.label")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

SelectExercises.propTypes = {
    history: PropTypes.object
};

export default SelectExercises;
