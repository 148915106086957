export const RESET_REPORT_STATE = `RESET_REPORT_STATE`;
export const resetReportState = value => {
    return {
        type: RESET_REPORT_STATE,
        value
    };
};

export const REPORT = `REPORT`;
export const report = value => {
    return {
        type: REPORT,
        value
    };
};

export const REPORT_FILTERS = `REPORT_FILTERS`;
export const reportFilter = value => {
    return {
        type: REPORT_FILTERS,
        value
    };
};

export const RESET_REPORT_FILTER_STATE = `RESET_REPORT_FILTER_STATE`;
export const resetReportFilterState = value => {
    return {
        type: RESET_REPORT_FILTER_STATE,
        value
    };
};
