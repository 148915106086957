import React, { useState } from "react";
import { useEffect } from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

const LineChartComponent = props => {
    return (
        <Line
            data={{
                labels: props.labels,
                datasets: [
                    {
                        label: props.label,
                        borderColor: "#9c282d",
                        pointBorderColor: "#9c282d",
                        pointBackgroundColor: "#ffffff",
                        pointBorderWidth: 4,
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 5,
                        pointRadius: 0,
                        fill: false,
                        borderWidth: 5,
                        data: props.data
                    }
                ]
            }}
            options={{
                responsive: true,
                animation: { duration: 1000, easing: "linear" },
                maintainAspectRatio: false,
                legend: { display: false },
                tooltips: { mode: "index", intersect: false },
                hover: { mode: "index", intersect: false },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true,
                                color: "rgba(208, 212, 223, 0.5)",
                                zeroLineColor: "rgba(208, 212, 223, 0.5)",
                                drawBorder: false,
                                lineWidth: 1,
                                zeroLineWidth: 1
                            },
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                stepSize: 50
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawTicks: false,
                                display: false,
                                color: "rgba(208, 212, 223, 0.7)"
                            },
                            ticks: {
                                padding: 30,
                                fontColor: "#9c9c9c",
                                fontStyle: "normal",
                                fontSize: 16
                            }
                        }
                    ]
                }
            }}
        />
    );
};

LineChartComponent.propTypes = {
    label: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.number)
};

export default LineChartComponent;
