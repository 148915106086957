import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ClientMenu = props => {
    const { t } = useTranslation();
    const userState = useSelector(s => s.userState);
    const singleUser = userState.singleUser;
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="client-menu">
                    <ul>
                        {singleUser.completeProfileFlag ? null : (
                            <li className={props.componentNumber >= 1 ? "active" : null}>
                                <Link
                                    onClick={e => {
                                        if (props.componentNumber < 1) {
                                            props.clickHandler(e);
                                        }
                                    }}
                                    to="administration"
                                >
                                    <span className="line"></span>
                                    <span className="icon">1</span>
                                    <span className="title">{t("administration.label")}</span>
                                </Link>
                            </li>
                        )}
                        <li className={props.componentNumber >= 2 ? "active" : null}>
                            <Link
                                onClick={e => {
                                    if (props.componentNumber < 2) {
                                        props.clickHandler(e);
                                    }
                                }}
                                to="personal"
                            >
                                <span className="line"></span>
                                <span className="icon">{!singleUser.completeProfileFlag ? 2 : 1}</span>
                                <span className="title">{t("personal_info.label")}</span>
                            </Link>
                        </li>
                        <li className={props.componentNumber >= 3 ? "active" : null}>
                            <Link
                                onClick={e => {
                                    if (props.componentNumber < 3) {
                                        props.clickHandler(e);
                                    }
                                }}
                                to="private"
                            >
                                <span className="line"></span>
                                <span className="icon">{!singleUser.completeProfileFlag ? 3 : 2}</span>
                                <span className="title">{t("private_info.label")}</span>
                            </Link>
                        </li>
                        {singleUser.completeProfileFlag ? null : (
                            <li className={props.componentNumber >= 4 ? "active" : null}>
                                <Link
                                    onClick={e => {
                                        if (props.componentNumber < 4) {
                                            props.clickHandler(e);
                                        }
                                    }}
                                    to="finance"
                                >
                                    <span className="line"></span>
                                    <span className="icon">4</span>
                                    <span className="title">{t("finance.label")}</span>
                                </Link>
                            </li>
                        )}

                        <li className={props.componentNumber >= 5 ? "active" : null}>
                            <Link
                                onClick={e => {
                                    if (props.componentNumber < 5) {
                                        props.clickHandler(e);
                                    }
                                }}
                                to="sport"
                            >
                                <span className="line"></span>
                                <span className="icon">{!singleUser.completeProfileFlag ? 5 : 3}</span>
                                <span className="title">{t("sport.label")}</span>
                            </Link>
                        </li>
                        <li className={props.componentNumber >= 6 ? "active" : null}>
                            <Link
                                onClick={e => {
                                    if (props.componentNumber < 6) {
                                        props.clickHandler(e);
                                    }
                                }}
                                to="work"
                            >
                                <span className="line"></span>
                                <span className="icon">{!singleUser.completeProfileFlag ? 6 : 4}</span>
                                <span className="title">{t("work.label")}</span>
                            </Link>
                        </li>
                        <li className={props.componentNumber >= 7 ? "active" : null}>
                            <Link
                                onClick={e => {
                                    if (props.componentNumber < 7) {
                                        props.clickHandler(e);
                                    }
                                }}
                                to="medicine"
                            >
                                <span className="line"></span>
                                <span className="icon">{!singleUser.completeProfileFlag ? 7 : 5}</span>
                                <span className="title">{t("medicine.label")}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

ClientMenu.propTypes = {
    clickHandler: PropTypes.func,
    componentName: PropTypes.string,
    componentNumber: PropTypes.number,
    componentState: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ClientMenu;
