import gql from "graphql-tag";

export const AddActivitiesQuery = gql`
    mutation AddActivities($name: String!, $name_de: String!) {
        addActivities(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddAdditionalTitleQuery = gql`
    mutation AddAdditionalTitle($name: String!, $name_de: String!) {
        addAdditionalTitle(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddAdministrationStatusQuery = gql`
    mutation AddAdministrationStatus($name: String!, $name_de: String!) {
        addAdministrationStatus(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddBodyPartQuery = gql`
    mutation AddBodyPart($name: String!, $name_de: String!) {
        addBodyPart(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddBodyTypeQuery = gql`
    mutation AddBodyType($name: String!, $name_de: String!) {
        addBodyType(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddCantonQuery = gql`
    mutation AddCanton($name: String!, $name_de: String!) {
        addCanton(name: $name, name_de: $name_de) {
            id
            name
        }
    }
`;

export const AddCivilStatusQuery = gql`
    mutation AddCivilStatus($name: String!, $name_de: String!) {
        addCivilStatus(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddContactChannelQuery = gql`
    mutation AddContactChannel($name: String!, $name_de: String!) {
        addContactChannel(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddCountryQuery = gql`
    mutation AddCountry($name: String!, $name_de: String!) {
        addCountry(name: $name, name_de: $name_de) {
            id
            name
        }
    }
`;

export const AddDifficultyQuery = gql`
    mutation AddDifficulty($name: String!, $name_de: String!) {
        addDifficulty(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddEquipmentQuery = gql`
    mutation AddEquipment($name: String!, $name_de: String!) {
        addEquipment(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddGenderQuery = gql`
    mutation AddGender($name: String!, $name_de: String!) {
        addGender(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddGoalQuery = gql`
    mutation AddGoal($name: String!, $name_de: String!) {
        addGoal(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddGroupQuery = gql`
    mutation AddGroup($name: String!, $name_de: String!) {
        addGroup(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddMedicalFunctionQuery = gql`
    mutation AddMedicalFunction($name: String!, $name_de: String!) {
        addMedicalFunction(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddNationalityQuery = gql`
    mutation AddNationality($name: String!, $name_de: String!) {
        addNationality(name: $name, name_de: $name_de) {
            id
            name
        }
    }
`;

export const AddPeopleGroupQuery = gql`
    mutation AddPeopleGroup($name: String!, $name_de: String!) {
        addPeopleGroup(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddPhaseQuery = gql`
    mutation AddPhase($name: String!, $name_de: String!) {
        addPhase(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddSOCardQuery = gql`
    mutation AddSOCard($name: String!, $name_de: String!) {
        addSOCard(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddSportFunctionQuery = gql`
    mutation AddSportFunction($name: String!, $name_de: String!) {
        addSportFunction(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddSportStatusQuery = gql`
    mutation AddSportStatus($name: String!, $name_de: String!) {
        addSportStatus(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddSportQuery = gql`
    mutation AddSport($name: String!, $name_de: String!) {
        addSport(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddStatusQuery = gql`
    mutation AddStatus($name: String!, $name_de: String!) {
        addStatus(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddTerminationConditionsQuery = gql`
    mutation AddTerminationConditions($name: String!, $name_de: String!) {
        addTerminationConditions(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddTestTypeQuery = gql`
    mutation AddTestType($name: String!, $name_de: String!) {
        addTestType(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddTitleQuery = gql`
    mutation AddTitle($name: String!, $name_de: String!) {
        addTitle(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddTrainingFrequencyQuery = gql`
    mutation AddTrainingFrequency($name: String!, $name_de: String!) {
        addTrainingFrequency(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddTrainingStatusQuery = gql`
    mutation AddTrainingStatus($name: String!, $name_de: String!) {
        addTrainingStatus(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddTrainingPeriodQuery = gql`
    mutation AddTrainingPeriod($name: String!, $name_de: String!) {
        addTrainingPeriod(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const AddUnitQuery = gql`
    mutation AddUnit($name: String!, $name_de: String!) {
        addUnit(name: $name, name_de: $name_de) {
            id
            name
            name_de
        }
    }
`;

export const EditActivityQuery = gql`
    mutation EditActivity($name: String!, $name_de: String!, $id: Int!) {
        editActivity(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditAdditionalTitleQuery = gql`
    mutation EditAdditionalTitle($name: String!, $name_de: String!, $id: Int!) {
        editAdditionalTitle(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditAdministrationStatusQuery = gql`
    mutation EditAdministrationStatus($name: String!, $name_de: String!, $id: Int!) {
        editAdministrationStatus(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditBodyPartQuery = gql`
    mutation EditBodyPart($name: String!, $name_de: String!, $id: Int!) {
        editBodyPart(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditBodyTypeQuery = gql`
    mutation EditBodyType($name: String!, $name_de: String!, $id: Int!) {
        editBodyType(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditCantonQuery = gql`
    mutation EditCanton($name: String!, $id: Int!) {
        editCanton(name: $name, id: $id) {
            id
            name
        }
    }
`;

export const EditCivilStatusQuery = gql`
    mutation EditCivilStatus($name: String!, $name_de: String!, $id: Int!) {
        editCivilStatus(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditContactChannelQuery = gql`
    mutation EditContactChannel($name: String!, $name_de: String!, $id: Int!) {
        editContactChannel(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditCountryQuery = gql`
    mutation EditCountry($name: String!, $id: Int!) {
        editCountry(name: $name, id: $id) {
            id
            name
        }
    }
`;

export const EditDifficultyQuery = gql`
    mutation EditDifficulty($name: String!, $name_de: String!, $id: Int!) {
        editDifficulty(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditEquipmentQuery = gql`
    mutation EditEquipment($name: String!, $name_de: String!, $id: Int!) {
        editEquipment(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditGenderQuery = gql`
    mutation EditGender($name: String!, $name_de: String!, $id: Int!) {
        editGender(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditGoalQuery = gql`
    mutation EditGoal($name: String!, $name_de: String!, $id: Int!) {
        editGoal(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditGroupQuery = gql`
    mutation EditGroup($name: String!, $name_de: String!, $id: Int!) {
        editGroup(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditMedicalFunctionQuery = gql`
    mutation EditMedicalFunction($name: String!, $name_de: String!, $id: Int!) {
        editMedicalFunction(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditNationalityQuery = gql`
    mutation EditNationality($name: String!, $id: Int!) {
        editNationality(name: $name, id: $id) {
            id
            name
        }
    }
`;

export const EditPeopleGroupQuery = gql`
    mutation EditPeopleGroup($name: String!, $name_de: String!, $id: Int!) {
        editPeopleGroup(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditPhaseQuery = gql`
    mutation EditPhase($name: String!, $name_de: String!, $id: Int!) {
        editPhase(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditSOCardQuery = gql`
    mutation EditSOCard($name: String!, $name_de: String!, $id: Int!) {
        editSOCard(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditSportFunctionQuery = gql`
    mutation EditSportFunction($name: String!, $name_de: String!, $id: Int!) {
        editSportFunction(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditSportStatusQuery = gql`
    mutation EditSportStatus($name: String!, $name_de: String!, $id: Int!) {
        editSportStatus(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditSportQuery = gql`
    mutation EditSport($name: String!, $name_de: String!, $id: Int!) {
        editSport(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditStatusQuery = gql`
    mutation EditStatus($name: String!, $name_de: String!, $id: Int!) {
        editStatus(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditTerminationConditionsQuery = gql`
    mutation EditTerminationConditions($name: String!, $name_de: String!, $id: Int!) {
        editTerminationConditions(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditTestTypeQuery = gql`
    mutation EditTestType($name: String!, $name_de: String!, $id: Int!) {
        editTestType(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditTitleQuery = gql`
    mutation EditTitle($name: String!, $name_de: String!, $id: Int!) {
        editTitle(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditTrainingFrequencyQuery = gql`
    mutation EditTrainingFrequency($name: String!, $name_de: String!, $id: Int!) {
        editTrainingFrequency(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditTrainingStatusQuery = gql`
    mutation EditTrainingStatus($name: String!, $name_de: String!, $id: Int!) {
        editTrainingStatus(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditTrainingPeriodQuery = gql`
    mutation EditTrainingPeriod($name: String!, $name_de: String!, $id: Int!) {
        editTrainingPeriod(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const EditUnitQuery = gql`
    mutation EditUnit($name: String!, $name_de: String!, $id: Int!) {
        editUnit(name: $name, name_de: $name_de, id: $id) {
            id
            name
            name_de
        }
    }
`;

export const DeleteActivityQuery = gql`
    mutation DeleteActivity($id: Int!) {
        deleteActivity(id: $id)
    }
`;

export const DeleteAdditionalTitleQuery = gql`
    mutation DeleteAdditionalTitle($id: Int!) {
        deleteAdditionalTitle(id: $id)
    }
`;

export const DeleteAdministrationStatusQuery = gql`
    mutation DeleteAdministrationStatus($id: Int!) {
        deleteAdministrationStatus(id: $id)
    }
`;

export const DeleteBodyPartQuery = gql`
    mutation DeleteBodyPart($id: Int!) {
        deleteBodyPart(id: $id)
    }
`;

export const DeleteBodyTypeQuery = gql`
    mutation DeleteBodyType($id: Int!) {
        deleteBodyType(id: $id)
    }
`;

export const DeleteCantonQuery = gql`
    mutation DeleteCanton($id: Int!) {
        deleteCanton(id: $id)
    }
`;

export const DeleteCivilStatusQuery = gql`
    mutation DeleteCivilStatus($id: Int!) {
        deleteCivilStatus(id: $id)
    }
`;

export const DeleteContactChannelQuery = gql`
    mutation DeleteContactChannel($id: Int!) {
        deleteContactChannel(id: $id)
    }
`;

export const DeleteCountryQuery = gql`
    mutation DeleteCountry($id: Int!) {
        deleteCountry(id: $id)
    }
`;

export const DeleteDifficultyQuery = gql`
    mutation DeleteDifficulty($id: Int!) {
        deleteDifficulty(id: $id)
    }
`;

export const DeleteEquipmentQuery = gql`
    mutation DeleteEquipment($id: Int!) {
        deleteEquipment(id: $id)
    }
`;

export const DeleteGenderQuery = gql`
    mutation DeleteGender($id: Int!) {
        deleteGender(id: $id)
    }
`;

export const DeleteGoalQuery = gql`
    mutation DeleteGoal($id: Int!) {
        deleteGoal(id: $id)
    }
`;

export const DeleteGroupQuery = gql`
    mutation DeleteGroup($id: Int!) {
        deleteGroup(id: $id)
    }
`;

export const DeleteMedicalFunctionQuery = gql`
    mutation DeleteMedicalFunction($id: Int!) {
        deleteMedicalFunction(id: $id)
    }
`;

export const DeleteNationalityQuery = gql`
    mutation DeleteNationality($id: Int!) {
        deleteNationality(id: $id)
    }
`;

export const DeletePeopleGroupQuery = gql`
    mutation DeletePeopleGroup($id: Int!) {
        deletePeopleGroup(id: $id)
    }
`;

export const DeletePhaseQuery = gql`
    mutation DeletePhase($id: Int!) {
        deletePhase(id: $id)
    }
`;

export const DeleteSOCardQuery = gql`
    mutation DeleteSOCard($id: Int!) {
        deleteSOCard(id: $id)
    }
`;

export const DeleteSportFunctionQuery = gql`
    mutation DeleteSportFunction($id: Int!) {
        deleteSportFunction(id: $id)
    }
`;

export const DeleteSportStatusQuery = gql`
    mutation DeleteSportStatus($id: Int!) {
        deleteSportStatus(id: $id)
    }
`;

export const DeleteSportQuery = gql`
    mutation DeleteSport($id: Int!) {
        deleteSport(id: $id)
    }
`;

export const DeleteStatusQuery = gql`
    mutation DeleteStatus($id: Int!) {
        deleteStatus(id: $id)
    }
`;

export const DeleteTerminationConditionsQuery = gql`
    mutation DeleteTerminationConditions($id: Int!) {
        deleteTerminationConditions(id: $id)
    }
`;

export const DeleteTestTypeQuery = gql`
    mutation DeleteTestType($id: Int!) {
        deleteTestType(id: $id)
    }
`;

export const DeleteTitleQuery = gql`
    mutation DeleteTitle($id: Int!) {
        deleteTitle(id: $id)
    }
`;

export const DeleteTrainingFrequencyQuery = gql`
    mutation DeleteTrainingFrequency($id: Int!) {
        deleteTrainingFrequency(id: $id)
    }
`;

export const DeleteTrainingStatusQuery = gql`
    mutation DeleteTrainingStatus($id: Int!) {
        deleteTrainingStatus(id: $id)
    }
`;

export const DeleteTrainingPeriodQuery = gql`
    mutation DeleteTrainingPeriod($id: Int!) {
        deleteTrainingPeriod(id: $id)
    }
`;

export const DeleteUnitQuery = gql`
    mutation DeleteUnit($id: Int!) {
        deleteUnit(id: $id)
    }
`;

export default {
    AddBodyPartQuery,
    AddActivitiesQuery,
    AddAdditionalTitleQuery,
    AddAdministrationStatusQuery,
    AddBodyTypeQuery,
    AddCantonQuery,
    AddCivilStatusQuery,
    AddContactChannelQuery,
    AddCountryQuery,
    AddDifficultyQuery,
    AddEquipmentQuery,
    AddGenderQuery,
    AddGoalQuery,
    AddGroupQuery,
    AddMedicalFunctionQuery,
    AddNationalityQuery,
    AddPeopleGroupQuery,
    AddPhaseQuery,
    AddSOCardQuery,
    AddSportFunctionQuery,
    AddSportStatusQuery,
    AddSportQuery,
    AddStatusQuery,
    AddTerminationConditionsQuery,
    AddTestTypeQuery,
    AddTitleQuery,
    AddTrainingFrequencyQuery,
    AddTrainingStatusQuery,
    AddTrainingPeriodQuery,
    AddUnitQuery,
    EditBodyPartQuery,
    EditActivityQuery,
    EditAdditionalTitleQuery,
    EditAdministrationStatusQuery,
    EditBodyTypeQuery,
    EditCantonQuery,
    EditCivilStatusQuery,
    EditContactChannelQuery,
    EditCountryQuery,
    EditDifficultyQuery,
    EditEquipmentQuery,
    EditGenderQuery,
    EditGoalQuery,
    EditGroupQuery,
    EditMedicalFunctionQuery,
    EditNationalityQuery,
    EditPeopleGroupQuery,
    EditPhaseQuery,
    EditSOCardQuery,
    EditSportFunctionQuery,
    EditSportStatusQuery,
    EditSportQuery,
    EditStatusQuery,
    EditTerminationConditionsQuery,
    EditTestTypeQuery,
    EditTitleQuery,
    EditTrainingFrequencyQuery,
    EditTrainingStatusQuery,
    EditTrainingPeriodQuery,
    EditUnitQuery,
    DeleteBodyPartQuery,
    DeleteActivityQuery,
    DeleteAdditionalTitleQuery,
    DeleteAdministrationStatusQuery,
    DeleteBodyTypeQuery,
    DeleteCantonQuery,
    DeleteCivilStatusQuery,
    DeleteContactChannelQuery,
    DeleteCountryQuery,
    DeleteDifficultyQuery,
    DeleteEquipmentQuery,
    DeleteGenderQuery,
    DeleteGoalQuery,
    DeleteGroupQuery,
    DeleteMedicalFunctionQuery,
    DeleteNationalityQuery,
    DeletePeopleGroupQuery,
    DeletePhaseQuery,
    DeleteSOCardQuery,
    DeleteSportFunctionQuery,
    DeleteSportStatusQuery,
    DeleteSportQuery,
    DeleteStatusQuery,
    DeleteTerminationConditionsQuery,
    DeleteTestTypeQuery,
    DeleteTitleQuery,
    DeleteTrainingFrequencyQuery,
    DeleteTrainingStatusQuery,
    DeleteTrainingPeriodQuery,
    DeleteUnitQuery
};
