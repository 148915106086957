import React from "react";
import { Switch, Route } from "react-router";
import UploadFile from "./CreateExercise/UploadFile";
import Information from "./CreateExercise/Information";
import Overview from "./CreateExercise/Overview";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../../../components/UI";

const NewExercise = () => {
    const exerciseState = useSelector(s => s.exerciseState);
    useEffect(() => {}, [exerciseState.loading]);

    return (
        <>
            <Loading loading={exerciseState.loading || exerciseState.loadingFilters} />
            <Switch>
                <Route path="/dashboard/exercises/new-exercise/information" component={Information} />
                <Route path="/dashboard/exercises/new-exercise/overview" component={Overview} />
                <Route path="/dashboard/exercises/new-exercise/" component={UploadFile} />
            </Switch>
        </>
    );
};

export default NewExercise;
