import React, { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

const RowComponent = props => {
    const { data, locales } = props;
    const [state, setstate] = useState({ ...data });

    const isValid = () => {
        let valid = true;
        valid = valid && !isEqual(data, state);
        Object.keys(locales).map(key => {
            valid = valid && state[locales[key]] !== "";
        });
        return valid;
    };

    return (
        <div className="row">
            {Object.keys(locales).map((key, index) => (
                <div key={index} className="col-md-5">
                    <div className="form-group">
                        <label>{key}</label>
                        <input
                            onChange={e => setstate({ ...state, [locales[key]]: e.target.value })}
                            defaultValue={state[locales[key]]}
                            type="text"
                            name="english"
                            className="form-control"
                        />
                    </div>
                </div>
            ))}
            <div className="col-md-2 d-flex align-items-center justify-content-end">
                <div className="add-remove-btn">
                    <a
                        className={!isValid() ? "" : "active"}
                        href="#"
                        onClick={e =>
                            !isValid()
                                ? e.preventDefault
                                    ? e.preventDefault()
                                    : (e.returnValue = false)
                                : props.mutate(e, { ...state, id: parseInt(state.id) })
                        }
                    >
                        <i className="icon-check"></i>
                    </a>
                    {!props.remove ? null : (
                        <a href="#" onClick={e => props.remove(e)}>
                            <i className="icon-cancel"></i>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

RowComponent.propTypes = {
    data: PropTypes.object.isRequired,
    locales: PropTypes.object.isRequired,
    remove: PropTypes.func,
    mutate: PropTypes.func
};

export default RowComponent;
