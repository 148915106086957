import gql from "graphql-tag";

export const TestsListQuery = gql`
  query searchTests(
    $search: String!
    $difficulty: Int
    $offset: Int
    $creator: Int
    $limit: Int
  ) {
    searchTests(
      search: $search
      difficulty: $difficulty
      offset: $offset
      creator: $creator
      limit: $limit
    ) {
      allTests {
        id
        title
        img
        difficulty {
          id
          name
        }
        description
        duration
        creator {
          id
          trainerId
          companyId
        }
      }
      totalCount
    }
  }
`;

export const SingleTestQuery = gql`
  query fetchTest($id: Int!) {
    fetchTest(id: $id) {
      id
      title
      duration
      description
      img
      video
      no_of_questions
      status
      difficulty {
        id
        name
      }
      type {
        id
        name
      }
      questions {
        id
        content
        order
        correct_answer
        unit {
          id
          name
        }
        options {
          id
          content
          isCorrect
          order
          sub_options {
            id
            content
            isCorrect
            order
          }
        }
      }
      creator {
        id
        trainerId
        companyId
        personal {
          name
        }
      }
    }
  }
`;

export const FetchTestForPDFQuery = gql`
  query fetchTest($id: Int!) {
    fetchTest(id: $id) {
      id
      title
      img
      duration
      status
      difficulty {
        id
        name
      }
      description
      type {
        id
        name
      }
      questions {
        id
        content
        unit {
          id
          name
        }
      }
      creator {
        id
        trainerId
        companyId
        img
        personal {
          name
          surname
        }
      }
    }
  }
`;

export const FetchTakenTestForPDFQuery = gql`
  query fetchTakenTest($takenTestId: Int!) {
    fetchTakenTest(takenTestId: $takenTestId) {
      id
      answered
      status
      favourite
      test {
        id
        title
        duration
        description
        img
        video
        no_of_questions
        difficulty {
          id
          name
        }
        type {
          id
          name
        }
        questions {
          id
          content
          unit {
            id
            name
          }
        }
        creator {
          id
          trainerId
          companyId
          personal {
            name
          }
        }
      }
      user {
        id
        personal {
          name
          surname
        }
      }
      user_answers {
        id
        content
        Question {
          id
          content
        }
      }
    }
  }
`;

export const FetchTakenTest = gql`
  query FetchTakenTest($takenTestId: Int!) {
    fetchTakenTest(takenTestId: $takenTestId) {
      id
      answered
      favourite
      status
      creator {
        id
        trainerId
        companyId
      }
      user {
        id
        email
      }
      test {
        id
        title
        duration
        description
        img
        video
        no_of_questions
        status
        type {
          id
          name
        }
        difficulty {
          id
          name
        }
        type {
          id
          name
        }
        questions {
          id
          content
          order
          correct_answer
          unit {
            id
            name
          }
          options {
            id
            content
            isCorrect
            order
            sub_options {
              id
              content
              isCorrect
              order
            }
          }
        }
        creator {
          id
          trainerId
          companyId
          personal {
            name
          }
        }
        __typename
      }

      user_answers {
        id
        content
        Question {
          id
          content
          correct_answer
          unit {
            id
            name
          }
          type {
            id
            name
          }
        }
      }
      user_option_answers {
        id
        questionId
        optionId
      }
      __typename
    }
  }
`;
