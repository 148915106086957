import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { SingleButtonModal, DoubleButtonsModal, DownloadAppModal } from "../../../components/UI";
import { useEffect } from "react";

const GlobalModalMiddlewareComponent = () => {
    const globalState = useSelector(s => s.globalState);

    useEffect(() => {}, [globalState.modal]);

    if (globalState.modal) {
        switch (globalState.modal.type) {
            case 1:
                return <SingleButtonModal />;
            case 2:
                return <DoubleButtonsModal />;
            case 3:
                return <DownloadAppModal />;
            default:
                return <SingleButtonModal />;
        }
    }

    return null;
};

export default withRouter(GlobalModalMiddlewareComponent);
