import React from "react";
import PropTypes from "prop-types";

import { getToken } from "../../helpers/index";

const Home = props => {
    if (getToken) {
        props.history.push("/dashboard");
    } else {
        props.history.push("/auth");
    }

    return <div />;
};

Home.propTypes = {
    history: PropTypes.object
};

export default Home;
