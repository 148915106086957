import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../store/global/global_actions";
import { withRouter } from "react-router-dom";

/**
 * 
 * <div className="modal fade" id="createexcercises" tabindex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="icon-cancel"></i>
            </button>
            <div className="modal-body">
                <div className="excercises-created">
                    <div className="icon">
                        <i className="icon-check"></i>
                    </div>
                    <div className="content">
                        <p>Successful created</p>
                    </div>
                    <div className="btn-modal">
                        <a href="#">Cancel</a>
                        <a href="#" className="add"><i className="icon-add"></i>Add for client</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 */

const DownloadAppModalComponent = props => {
    const globalState = useSelector(s => s.globalState);
    const dispatch = useDispatch();

    let closeModal = () => {
        dispatch(hideModal());
        if (get(globalState, "modal.link", false)) props.history.push(globalState.modal.link);
    };

    return (
        <Modal
            visible={get(globalState, "modal.show", false)}
            dialogClassName="modal-dialog modal-dialog-centered"
            onClick={closeModal}
        >
            <div className="modal-content">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => closeModal()}
                >
                    <i className="icon-cancel"></i>
                </button>
                <div className="modal-body">
                    <div className="download-app">
                        <div className="content">
                            <h2>Download Priosan Mobile App</h2>
                            <p>Available on</p>
                        </div>
                        <div className="badge">
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="163" height="50" viewBox="0 0 163 50">
                                    <g fill="#404040" fillRule="evenodd">
                                        <rect width="163" height="50" fillOpacity=".08" rx="6" />
                                        <g fillRule="nonzero">
                                            <path d="M28.032 22.079c-2.069 3.461-.754 8.714 1.534 11.9C30.708 35.57 31.865 37 33.448 37c.03 0 .06 0 .09-.002.745-.028 1.283-.25 1.803-.463.583-.24 1.187-.487 2.134-.487.9 0 1.475.24 2.031.471.548.229 1.112.465 1.946.45 1.784-.032 2.88-1.574 3.846-2.935a12.81 12.81 0 0 0 1.685-3.327l.007-.021a.191.191 0 0 0-.107-.237l-.015-.006c-.314-.124-3.07-1.303-3.098-4.515-.027-2.61 2.067-3.992 2.487-4.242l.02-.012a.193.193 0 0 0 .087-.124.188.188 0 0 0-.03-.148c-1.446-2.039-3.662-2.346-4.553-2.383a4.164 4.164 0 0 0-.398-.019c-1.047 0-2.05.381-2.857.688-.557.212-1.037.394-1.369.394-.372 0-.856-.185-1.416-.399-.748-.286-1.597-.61-2.495-.61a3.4 3.4 0 0 0-.064 0c-2.088.03-4.062 1.181-5.15 3.006zM40.779 13c-1.198.052-2.635.836-3.494 1.906-.73.899-1.442 2.398-1.255 3.909a.195.195 0 0 0 .174.175c.08.007.163.01.246.01 1.17 0 2.434-.689 3.297-1.798.908-1.172 1.367-2.675 1.229-4.022a.195.195 0 0 0-.197-.18z" />
                                        </g>
                                        <text
                                            fontFamily="TitilliumWeb-Regular, Titillium Web"
                                            fontSize="16"
                                            letterSpacing=".218"
                                        >
                                            <tspan x="67" y="31">
                                                App Store
                                            </tspan>
                                        </text>
                                    </g>
                                </svg>
                            </a>
                            <a href="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="163" height="50" viewBox="0 0 163 50">
                                    <g fill="#404040" fillRule="evenodd">
                                        <rect width="163" height="50" fillOpacity=".08" rx="6" />
                                        <path
                                            fillRule="nonzero"
                                            d="M45.65 22.71l-16.64-9.362a2.718 2.718 0 0 0-2.675.008A2.639 2.639 0 0 0 25 15.638v18.724c0 .937.512 1.811 1.335 2.282a2.715 2.715 0 0 0 2.675.008l16.64-9.362A2.637 2.637 0 0 0 47 25c0-.944-.517-1.822-1.35-2.29zm-7.21-2.214l-2.285 3.132-5.465-7.492 7.75 4.36zM27.576 35.39a1.039 1.039 0 0 1-.95-1.03V15.639a1.038 1.038 0 0 1 .948-1.031L35.154 25l-7.578 10.39zm3.114-1.527l5.465-7.492 2.285 3.132-7.75 4.36zm14.153-7.963l-4.985 2.805L37.155 25l2.703-3.706 4.985 2.805c.328.184.532.53.532.9 0 .372-.204.718-.532.902z"
                                        />
                                        <text
                                            fontFamily="TitilliumWeb-Regular, Titillium Web"
                                            fontSize="16"
                                            letterSpacing=".218"
                                        >
                                            <tspan x="67" y="31">
                                                Play Store
                                            </tspan>
                                        </text>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

DownloadAppModalComponent.propTypes = {
    history: PropTypes.object
};

export default withRouter(DownloadAppModalComponent);
