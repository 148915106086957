import { client } from "../../../helpers/index";
import {
    editProfileMutation,
    changePasswordMutation,
    UpdateCompanyProfileAsTrainer
} from "../../../resolvers/mutation/user";
import { ChangeLocaleMutation } from "../../../resolvers/mutation/Auth";
import { auth, authErrors } from "../../../store/auth/auth_actions";
import { toggleLoadingOn, toggleLoadingOff } from "../../../store/global/global_actions";

export const EditUserMutation = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: editProfileMutation,
                variables
            })
            .then(({ data, errors }) => {
                if (data) {
                    dispatch(
                        auth({
                            ...data.editUser,
                            role: {
                                ...data.editUser.role,
                                id: parseInt(data.editUser.role.id)
                            },
                            id: parseInt(data.editUser.id),
                            loading: false,
                            modal: true
                        })
                    );
                    return data.editUser;
                }
                if (errors) {
                    let err = [];
                    errors.map(mes => err.push(mes.message));
                    dispatch(authErrors({ array: err }));
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    dispatch(authErrors({ array: errors }));
                } else {
                    dispatch(authErrors({ array: err }));
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const ChangePasswordMutation = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: changePasswordMutation,
                variables
            })
            .then(({ data, errors }) => {
                if (errors) {
                    let err = [];
                    errors.map(mes => err.push(mes.message));
                    dispatch(authErrors({ array: err }));
                }
                dispatch(auth({ loading: false, modal: true }));
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    dispatch(authErrors({ array: errors }));
                } else {
                    dispatch(authErrors({ array: err }));
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const EditCompanyProfile = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: UpdateCompanyProfileAsTrainer,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    dispatch(auth({ company: { ...data.updateCompanyProfileAsTrainer } }));
                    dispatch(toggleLoadingOff());
                    return true;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    dispatch(authErrors({ array: errors }));
                } else {
                    dispatch(authErrors({ array: err }));
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const ChangeLocaleAction = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({ mutation: ChangeLocaleMutation, variables })
            .then(({ data }) => {
                if (data) return true;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    dispatch(authErrors({ array: errors }));
                } else {
                    dispatch(authErrors({ array: err }));
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};
