export const TRAINER = `TRAINER`;
export const trainer = value => {
    return {
        type: TRAINER,
        value
    };
};
export const TRAINER_PERSONAL = `TRAINER_PERSONAL`;
export const trainerPersonal = value => {
    return {
        type: TRAINER_PERSONAL,
        value
    };
};
export const TRAINER_ADDRESS = `TRAINER_ADDRESS`;
export const trainerAddress = value => {
    return {
        type: TRAINER_ADDRESS,
        value
    };
};

export const SINGLE_TRAINER = `SINGLE_TRAINER`;
export const singleTrainer = value => {
    return {
        type: SINGLE_TRAINER,
        value
    };
};

export const UPDATE_TRAINER = `UPDATE_TRAINER`;
export const updateTrainer = value => {
    return {
        type: UPDATE_TRAINER,
        value
    };
};

export const TRAINER_FILTERS = `TRAINER_FILTERS`;
export const trainerFilter = value => {
    return {
        type: TRAINER_FILTERS,
        value
    };
};

export const TRAINER_ERRORS = `TRAINER_ERRORS`;
export const trainerError = value => {
    return {
        type: TRAINER_ERRORS,
        value
    };
};

export const TRAINER_SUCCESS = `TRAINER_SUCCESS`;
export const trainerSuccess = value => {
    return {
        type: TRAINER_SUCCESS,
        value
    };
};

export const TRAINER_LOADING = `TRAINER_LOADING`;
export const trainerLoading = value => {
    return {
        type: TRAINER_LOADING,
        value
    };
};

export const RESET_TRAINER_STATE = `RESET_TRAINER_STATE`;
export const resetTrainerState = value => {
    return {
        type: RESET_TRAINER_STATE,
        value
    };
};

export const RESET_TRAINER_FILTER_STATE = `RESET_TRAINER_FILTER_STATE`;
export const resetTrainerFilterState = value => {
    return {
        type: RESET_TRAINER_FILTER_STATE,
        value
    };
};

export const TRAINER_STATUS_CHANGED = `TRAINER_STATUS_CHANGED`;
export const trainerStatusChanged = (value) => {
  return {
    type: TRAINER_STATUS_CHANGED,
    value,
  };
};