import { removeToken } from "../../helpers/index";
import { FetchCurrentUser } from "../../resolvers/query/users";
import { client } from "../../helpers";
import { toggleLoadingOn, toggleLoadingOff } from "../global/global_actions";
import { changeLocale } from "../../helpers/changeLocale";

export const AUTH = `AUTH`;
export const auth = value => {
    return {
        type: AUTH,
        value
    };
};
export const AUTH_ADDRESS = `AUTH_ADDRESS`;
export const authAddress = value => {
    return {
        type: AUTH_ADDRESS,
        value
    };
};
export const AUTH_PERSONAL = `AUTH_PERSONAL`;
export const authPersonal = value => {
    return {
        type: AUTH_PERSONAL,
        value
    };
};

export const AUTH_ERRORS = `AUTH_ERRORS`;
export const authErrors = value => {
    return {
        type: AUTH_ERRORS,
        value
    };
};

export const AUTH_LOADING = `AUTH_LOADING`;
export const authLoading = value => {
    return {
        type: AUTH_LOADING,
        value
    };
};

export const RESET_AUTH_STATE = `RESET_AUTH_STATE`;
export const resetAuthState = value => {
    return {
        type: RESET_AUTH_STATE,
        value
    };
};

export const GetProfileFetch = () => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        const { token, refreshtoken } = localStorage;
        if (token && refreshtoken) {
            return client
                .query({
                    query: FetchCurrentUser
                })
                .then(({ data, errors }) => {
                    if (errors) throw errors;
                    if (data) {
                        const { me } = data;
                        dispatch(
                            auth({
                                ...me,
                                id: parseInt(me.id),
                                role: {
                                    ...me.role,
                                    id: parseInt(me.role.id)
                                }
                            })
                        );
                        changeLocale(me.locale);
                        dispatch(toggleLoadingOff());
                    }
                })
                .catch(err => {
                    dispatch(auth({ autherrors: err }));
                    removeToken();
                    dispatch(resetAuthState());
                    window.location.reload();
                });
        }
    };
};
