import get from "lodash/get";

/**
 *
 * @param {Object} state
 * @returns Object
 */
export const constructTestRequest = state => {
    return {
        id: +state.id,
        type: +state.type.id,
        duration: +state.duration,
        difficulty: +get(state, "difficulty.id", null),
        title: state.title,
        description: state.description,
        img: state.imgFile,
        video: state.videoFile,
        ...arrangeQuestions(+state.type.id, state.questions)
    };
};

/**
 *
 * @param {Integer} type
 * @param {Array} questions
 * @returns Array
 */
export const arrangeQuestions = (type, questions) => {
    switch (type) {
        case 1:
            return {
                QuestionAndAnswerInput: questions.map((question, index) => {
                    return {
                        id: +question.id || null,
                        content: question.content,
                        correct_answer: question.correct_answer,
                        order: index + 1
                    };
                })
            };
        case 2:
            return {
                ScalaInput: questions.map((question, index) => {
                    return {
                        id: +question.id || null,
                        content: question.content,
                        correct_answer: +question.correct_answer,
                        order: index + 1
                    };
                })
            };
        case 3:
            return {
                VariantsInput: questions.map((question, qIndex) => {
                    return {
                        id: +question.id || null,
                        content: question.content,
                        order: qIndex + 1,
                        options: (question.options || []).map((option, oIndex) => {
                            return {
                                id: +option.id,
                                content: option.content,
                                isCorrect: option.isCorrect,
                                order: oIndex + 1,
                                sub_options: (option.sub_options || []).map((sub_option, sIndex) => {
                                    return {
                                        id: +sub_option.id,
                                        order: sIndex + 1,
                                        isCorrect: sub_option.isCorrect,
                                        content: sub_option.content
                                    };
                                })
                            };
                        })
                    };
                })
            };
        case 4:
            return {
                MeasurementsInput: questions.map((question, index) => {
                    return {
                        id: +question.id || null,
                        content: question.content,
                        unit: +question.unit.id,
                        order: index + 1
                    };
                })
            };
    }
};
