export const LOGIN = `LOGIN`;
export const login = value => {
    return {
        type: LOGIN,
        value
    };
};

export const COMPANY = `COMPANY`;
export const company = value => {
    return {
        type: COMPANY,
        value
    };
};

export const COMPANY_PERSONAL = `COMPANY_PERSONAL`;
export const companyPersonal = value => {
    return {
        type: COMPANY_PERSONAL,
        value
    };
};

export const COMPANY_ADDRESS = `COMPANY_ADDRESS`;
export const companyAddress = value => {
    return {
        type: COMPANY_ADDRESS,
        value
    };
};

export const COMPANY_PRIVATE = `COMPANY_PRIVATE`;
export const companyPrivate = value => {
    return {
        type: COMPANY_PRIVATE,
        value
    };
};

export const SINGLE_COMPANY = `SINGLE_COMPANY`;
export const singleCompany = value => {
    return {
        type: SINGLE_COMPANY,
        value
    };
};

export const UPDATE_COMPANY = `UPDATE_COMPANY`;
export const updateCompany = value => {
    return {
        type: UPDATE_COMPANY,
        value
    };
};

export const COMPANY_FILTER = `COMPANY_FILTER`;
export const companyFilter = value => {
    return {
        type: COMPANY_FILTER,
        value
    };
};

export const COMPANY_SUCCESS = `COMPANY_SUCCESS`;
export const companySuccess = value => {
    return {
        type: COMPANY_SUCCESS,
        value
    };
};

export const COMPANY_ERRORS = `COMPANY_ERRORS`;
export const companyError = value => {
    return {
        type: COMPANY_ERRORS,
        value
    };
};

export const COMPANY_LOADING = `COMPANY_LOADING`;
export const companyLoading = value => {
    return {
        type: COMPANY_LOADING,
        value
    };
};

export const RESET_COMPANY_STATE = `RESET_COMPANY_STATE`;
export const resetCompanyState = value => {
    return {
        type: RESET_COMPANY_STATE,
        value
    };
};

export const RESET_COMPANY_FILTER_STATE = `RESET_COMPANY_FILTER_STATE`;
export const resetCompanyFilterState = value => {
    return {
        type: RESET_COMPANY_FILTER_STATE,
        value
    };
};
