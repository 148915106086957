import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ExerciseList from "../Exercises/ExerciseList";
import get from "lodash/get";

import placeholder from "../../assets/img/placeholders/400x200.png";
import { getApiUrl } from "../../constants";
import ContentModalMiddleware from "../../containers/dashboard/Middlewares/ContentModalMiddleware";
import { getToken } from "../../helpers";
import { useTranslation } from "react-i18next";

import ConfirmModal from "../UI/Modal/ConfirmModal";
import { hasUpdateAccess } from "../../helpers/hasUpdateAccess";

const SingleTrainingplan = (props) => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const planState = useSelector((s) => s.planState);
  const user = useSelector((state) => state.authState);
  const reduxState = planState.singleTrainingPlan;
  const updateState = planState.updateTrainingPlan;

  const [modal, toggleModal] = useState();
  let calculateDuration = (time) => {
    let hours = Math.floor(time / 60);
    let minutes = time % 60;

    return `${hours} h ${minutes} min`;
  };

  let apiUrl = getApiUrl();
  let pdfUrl = !planState.takenPlanFlag
    ? "plan/" + reduxState.id
    : "taken-plan/" + reduxState.id;
  pdfUrl += "?token=" + getToken.token;

  return (
    <Fragment>
      <ContentModalMiddleware
        parent={"PLAN"}
        parentId={parseInt(reduxState.id)}
        content={"USER_LIST"}
        show={modal}
        closeModal={() => toggleModal(false)}
      />
      <div className="container-fluid">
        <div className="white-bg">
          <div className="row">
            <div className="col-md-12">
              {props.forOverview ? null : (
                <div className="single-detail-head single-head-left-right">
                  <div className="single-head-flex">
                    <div className="single-head-left">
                      <div className="form-toggle form-toggle-checked">
                        {hasUpdateAccess(user, reduxState) && (
                          <label
                            className="form-toggle-switch"
                            htmlFor="status"
                          >
                            <input
                              type="checkbox"
                              id="status"
                              defaultChecked={reduxState.status === "ACTIVE"}
                              onChange={props.onToggleTrainingPlanStatus}
                            />
                            <div className="form-toggle-switch-slider form-toggle-switch-round"></div>
                          </label>
                        )}
                        <span
                          className={
                            reduxState.status === "ACTIVE"
                              ? "active"
                              : "inactive"
                          }
                        >
                          {reduxState.status === "ACTIVE"
                            ? "Active"
                            : "Inactive"}
                        </span>
                      </div>
                    </div>
                    <div className="single-head-right">
                      {planState.takenPlanFlag ? null : user.role.id !== 1 &&
                        !reduxState.creator ? null : (
                        <Link
                          to={
                            !planState.takenPlanFlag
                              ? `${get(
                                  updateState,
                                  "id",
                                  get(reduxState, "id", "")
                                )}/edit-trainingplan/general-info`
                              : `${get(
                                  updateState,
                                  "id",
                                  get(reduxState, "id", "")
                                )}/edit-takenplan/general-info`
                          }
                        >
                          <i className="icon-edit"></i>
                          <span>{t("edit.label")}</span>
                        </Link>
                      )}
                      <a
                        href={apiUrl + pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-print"></i>
                        <span>{t("print.label")}</span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleModal(true);
                        }}
                        className="add"
                      >
                        <i className="icon-add"></i>
                        <span>{t("add_for_client.label")}</span>
                      </a>
                      {user.role.id !== 1 && !reduxState.creator ? null : (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeleteModal(true);
                          }}
                        >
                          <i className="icon-cancel"></i>
                          <span>Delete</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="single-detail">
                <div className="img">
                  <img
                    alt="Main"
                    src={
                      get(updateState, "img", get(reduxState, "img", false)) ||
                      placeholder
                    }
                  />
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <h3>
                          {get(
                            updateState,
                            "title",
                            get(reduxState, "title", "")
                          )}
                        </h3>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("difficulty.label")}</span>
                        <p>
                          {get(
                            updateState,
                            "difficulty.name",
                            get(reduxState, "difficulty.name", "-")
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("duration.label")}</span>
                        <p>
                          {calculateDuration(
                            get(
                              updateState,
                              "duration",
                              get(reduxState, "duration", "0")
                            )
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("trainingperiods.label")}</span>
                        {get(
                          updateState,
                          "trainingperiods",
                          get(reduxState, "trainingperiods", [])
                        ).length > 0 ? (
                          get(
                            updateState,
                            "trainingperiods",
                            get(reduxState, "trainingperiods", [])
                          ).map((e, i) => <p key={i}>{e.name}</p>)
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("trainingphases.label")}</span>
                        {get(
                          updateState,
                          "phases",
                          get(reduxState, "phases", [])
                        ).length > 0 ? (
                          get(
                            updateState,
                            "phases",
                            get(reduxState, "phases", [])
                          ).map((e, i) => <p key={i}>{e.name}</p>)
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("bodyparts.label")}</span>
                        {get(
                          updateState,
                          "bodyparts",
                          get(reduxState, "bodyparts", [])
                        ).length > 0 ? (
                          get(
                            updateState,
                            "bodyparts",
                            get(reduxState, "bodyparts", [])
                          ).map((e, i) => <p key={i}>{e.name}</p>)
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("equipment.label")}</span>
                        {get(
                          updateState,
                          "equipments",
                          get(reduxState, "equipments", [])
                        ).length > 0 ? (
                          get(
                            updateState,
                            "equipments",
                            get(reduxState, "equipments", [])
                          ).map((e, i) => <p key={i}>{e.name}</p>)
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("objective.label")}</span>
                        <p>
                          {get(
                            updateState,
                            "objective",
                            get(reduxState, "objective", "-")
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("notes.label")}</span>
                        <p>
                          {get(
                            updateState,
                            "notes",
                            get(reduxState, "notes", "-")
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("description.label")}</span>
                        <p>
                          {get(
                            updateState,
                            "description",
                            get(reduxState, "description", "-")
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="white-bg">
          <div className="row excercises">
            <div className="col-md-12">
              <div className="excercises-title">
                <p>
                  {t("exercises_in_tp.label")} (
                  <span>
                    {get(
                      updateState,
                      "no_of_exercises",
                      get(reduxState, "no_of_exercises", "0")
                    )}
                  </span>
                  )
                </p>
              </div>
            </div>
            {get(reduxState, "exercises", []).map((e) => {
              return (
                <ExerciseList
                  custom={
                    e.__typename === "CostumExercise" ? reduxState.id : false
                  }
                  key={e.id}
                  exercises={e}
                />
              );
            })}
            {get(updateState, "exercises", []).map((e) => {
              return (
                <ExerciseList
                  custom={
                    e.__typename === "CostumExercise" ? reduxState.id : false
                  }
                  key={e.id}
                  exercises={e}
                />
              );
            })}
          </div>
          {props.forOverview ? (
            <Fragment>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-end">
                  <div className="btn-right">
                    <Link
                      to={
                        props.update
                          ? "/dashboard/trainingplans/" +
                            get(updateState, "id", get(reduxState, "id", "")) +
                            "/edit-trainingplan/select-exercises"
                          : "/dashboard/trainingplans/new-trainingplan/select-exercises"
                      }
                      className="btn btn-cancel btn-180"
                    >
                      Back
                    </Link>
                    <a
                      onClick={(e) => props.submitHandler(e)}
                      href="_"
                      className="btn btn-primary btn-180"
                      data-toggle="modal"
                      data-target="#createexcercises"
                    >
                      {props.update ? t("update.label") : t("create.label")}
                    </a>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
      <ConfirmModal
        show={deleteModal}
        confirmButton="Delete"
        rejectHandler={() => setDeleteModal(false)}
        confirmHandler={props.deleteTrainingPlanHandler}
        message="Are you sure you want to delete this training plan?"
      />
    </Fragment>
  );
};

SingleTrainingplan.propTypes = {
  forOverview: PropTypes.bool,
  update: PropTypes.bool,
  submitHandler: PropTypes.func,
  loading: PropTypes.bool,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  onToggleTrainingPlanStatus: PropTypes.func,
  deleteTrainingPlanHandler: PropTypes.func,
};

export default SingleTrainingplan;
