import React, { useState } from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";

import { Loading, Search } from "../../../components/UI/";

import UserList from "../../Client/User/UsersList";
import TrainingPlanList from "../../Trainingplan/TrainingplanList";
import TestsList from "../../Tests/TestsList";

const ListModal = ({ show, close, type }) => {
  const [data, setData] = useState([
    {
      img: null,
      personal: { name: "Jetmir", surname: "Avdullahu" },
      email: "jetmir@gmail.com",
      address: { city: "San Francisco" },
      status: "ACTIVE",
    },
  ]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (e) => {};

  const selectHandler = (id, isSelected) => {
    let selectedItems = [...selected];
    console.log({ id, selectedItems });
    if (!isSelected) {
      selectedItems.push(parseInt(id));
    } else {
      selectedItems = selectedItems.filter((item) => item !== parseInt(id));
    }
    setSelected(selectedItems);
  };

  return (
    <Modal
      visible={show}
      dialogClassName="modal-dialog modal-xl modal-dialog-centered"
      onClick={() => close()}
      className="custom-modal"
    >
      <div>
        <div className="row custom-modal-header">
          <div className="col-4 modal-flex-start">
            Selected: {selected.length}
          </div>
          <div className="col-4 modal-flex-center"></div>
          <div className="col-4 modal-flex-end">
            <Search
              searchHandler={(e) => {
                setSearch(e.target.value);
              }}
              querySearch={search}
            />
          </div>
        </div>

        <div className="container custom-modal-table">
          <Loading loading={isLoading} contentModal />
          <ListElements
            type={type}
            data={data}
            selected={selected}
            select={selectHandler}
            show={show}
          />
        </div>
        <div className="row custom-modal-button">
          <div className="col-6">
            <button onClick={submitHandler} className="btn btn-primary btn-180">
              Add
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ListElements = ({ type, data, selected, select }) => {
  switch (type) {
    case "USER_LIST":
      return (
        <table className="table table-priosan">
          <tbody>
            {data.map((user) => {
              return (
                <UserList
                  selected={selected}
                  selectUserModal={select}
                  key={user.id}
                  users={user}
                />
              );
            })}
          </tbody>
        </table>
      );
    case "PLAN_LIST":
      return (
        <div className="container-fluid">
          <div className="row">
            {data.map((plan) => (
              <TrainingPlanList
                selected={selected}
                selectUserModal={select}
                key={plan.id}
                plan={plan}
              />
            ))}
          </div>
        </div>
      );
    case "TEST_LIST":
      return (
        <div className="container-fluid">
          <div className="row">
            {data.map((test) => (
              <TestsList
                selected={selected}
                selectUserModal={select}
                key={test.id}
                tests={test}
              />
            ))}
          </div>
        </div>
      );
  }
};

ListModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.oneOf(["USER_LIST", "PLAN_LIST", "TEST_LIST"]).isRequired,
  close: PropTypes.func.isRequired,
};

export default ListModal;
