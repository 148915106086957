import gql from "graphql-tag";

export const FetchUserQuery = gql`
  query GetUser($id: Int!, $roleId: Int) {
    fetchUser(id: $id, roleId: $roleId) {
      id
      name
      surname
      email
      birthdate
      street
      city
      country
      zip
      bodyTypeId
      phoneNumber
      height
      weight
      bodyFatPt
      belly
      restingHeartrate
      maxHeartRate
      web
      img
      status
      createdAt
      role {
        id
        name
      }
      body_type {
        id
        name
      }
      goal {
        id
        name
      }
      activity {
        id
        name
      }
      trainee_count
      trainee {
        id
        name
        email
        img
      }
      trainer {
        id
        name
        surname
        email
        img
      }
      employee {
        id
        name
        surname
        email
        img
      }
      employee_count
      company {
        id
        name
        email
        street
        phoneNumber
        web
        createdAt
        img
      }
      gender {
        id
        name
      }
      taken_plans {
        id
        master_plan {
          id
          title
          img
        }
        time
        no_of_exercises
      }
      taken_plans_count
      taken_tests_count
      taken_tests {
        id
        test {
          id
          title
          img
          duration
          difficulty {
            id
            name
          }
          description
        }
        answered
        favourite
        status
      }
      recent_activities {
        id
        plan {
          id
          title
          img
        }
        test {
          id
          title
          img
        }
        type
      }
      trainer_plans {
        id
        title
        duration
        img
        no_of_exercises
      }
      trainer_plans_count
      trainer_tests {
        id
        title
        duration
        img
      }
      trainer_tests_count
      started_plans {
        id
        taken_plan {
          id
          master_plan {
            id
            title
          }
        }
      }
      favourite_taken_plans_count
      favourite_taken_plans {
        id
        master_plan {
          id
          title
          img
        }
        time
      }
      favourite_tests_count
      favourite_tests {
        id
        test {
          id
          title
          img
          duration
        }
        answered
        favourite
        status
      }
    }
  }
`;

export const FetchCurrentUser = gql`
  query me {
    me {
      id
      locale
      status
      personal {
        name
        surname
        phoneNumber
      }
      email
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }

      role {
        id
        name
      }
      img
      is_admin
      company {
        id
        personal {
          phoneNumber
          name
        }
        address {
          street
        }
        img
        private_info {
          website
        }
      }
    }
  }
`;

export const UsersListQuery = gql`
  query searchUsers(
    $search: String!
    $offset: Int
    $limit: Int
    $status: STATUS
    $order: orderInput
    $assigned: ASSIGNMENT
  ) {
    searchUsers(
      status: $status
      search: $search
      offset: $offset
      limit: $limit
      order: $order
      assigned: $assigned
    ) {
      allUsers {
        id
        img
        status
        personal {
          name
          surname
        }
        email
        address {
          city
        }
        updatedAt
        status
      }
      totalCount
    }
  }
`;

export const UsersWithTrainersQuery = gql`
  query searchUsersWithTrainers($search: String!) {
    searchUsersWithTrainers(search: $search) {
      allUsers {
        id
        img
        status
        personal {
          name
        }
        email
        address {
          city
        }
        updatedAt
        status
      }
      totalCount
    }
  }
`;

export const CompanyListQuery = gql`
  query searchCompanies(
    $search: String!
    $offset: Int
    $status: STATUS
    $order: orderInput
  ) {
    searchCompanies(
      status: $status
      search: $search
      offset: $offset
      order: $order
    ) {
      allUsers {
        id
        img
        status
        personal {
          name
        }
        email
        address {
          city
        }
        updatedAt
        status
        employee_count
      }
      totalCount
    }
  }
`;

export const TrainerListQuery = gql`
  query searchTrainers(
    $search: String!
    $offset: Int
    $status: STATUS
    $order: orderInput
  ) {
    searchTrainers(
      status: $status
      search: $search
      offset: $offset
      order: $order
    ) {
      allUsers {
        id
        img
        status
        personal {
          name
          surname
        }
        email
        address {
          city
        }
        updatedAt
        status
      }
      totalCount
    }
  }
`;

export const FetchUser = gql`
  query GetUser($id: Int!) {
    fetchUser(id: $id, roleId: 4) {
      id
      trainerId
      status
      trainer {
        id
        personal {
          name
          surname
        }
        company {
          id
          personal {
            name
            surname
          }
        }
      }
      company {
        id
        personal {
          name
          surname
        }
      }
      administration {
        id
        PID
        contact_date
        found_from
        referenced_by
        remark_contact
        notify_on
        first_visit
        death_date
        remark_death
        VIP
        comment
        people_group {
          id
          name
        }
        status {
          id
          name
        }
        contact_channel {
          id
          name
        }
      }
       personal {
        name
        surname
        phoneNumber
        birthdate
        gender {
          id
          name
        }
        bussiness_name
        comments
        landline
        remark_communication_channel
        nationality {
          id
          name
        }
      }
      address {
        street
        city
        postal
        country {
          id
          name
        }
        canton {
          id
          name
        }
        additionalAddress
      }
      measurement {
        weight
        height
        bodyFatPt
        belly
        restingHeartRate
      }
      private_info {
        website
        landline_alt
        mail_alt
        tel_alt
        fax
        hometown
        name_unmarried
        number_of_children
        emergency_contact
        emergency_contact_PID
        remark_emergency_contact
        emergency_telephone1
        remark_emergency_telephone1
        emergency_telephone2
        remark_emergency_telephone2
        remark_private_info
        title {
          id
          name
        }
        additional_title {
          id
          name
        }
        civil_status {
          id
          name
        }
      }
      finance {
        id
        IBAN
        account_no
        clearing_no
        BIC
        bank_name
        bank_city
        bank_street
        bank_postal
        bank_country {
          id
          name
        }
        bank_address_addition
        comment
      }
      ahv {
        id
        AHV
        remark_ahv
        comment
      }
      user_sport {
        id
        years
        discipline
        squad
        training_group
        association
        comment
        sport {
          id
          name
        }
        sport_status {
          id
          name
        }
        training_frequency {
          id
          name
        }
        training_status {
          id
          name
        }
        SOCard {
          id
          name
        }
      }
       sport_specialist {
        id
        specialist_PID
        name
        cooperation_since
        email
        mobile
        mobile_alternative
        comments
        specialist_function {
          id
          name
        }
      }
      work {
        id
        PIDcompany
        job_name
        company_name
        department
        position
        street
        roadNo
        additional_address
        postal
        city
        mobile
        landline
        email
        website
        fax
        country {
          id
          name
        }
      }
      medicine {
        id
        KK_KVG
        KK_zusatz
        accident_insurance
        police_no
      }
      med_expert {
        id
        name
        surname
        salutation
        street
        additionalAddress
        postal
        city
        email
        mobile
        med_function {
          id
          name
        }
      }
      problem {
        id
        typ
        description
      }
      diagnose {
        id
        typ
        description
      }
      email
      img
      createdAt
      taken_plans {
        id
        title
        img
        no_of_exercises
      }
      taken_plans_count
      taken_tests_count
      taken_tests {
        id
        test {
          id
          title
          img
          duration
          difficulty {
            id
            name
          }
          description
        }
        answered
        favourite
        status
      }
    }
  }
`;

export const FetchUserByCompleteProfileToken = gql`
  query fetchUserByCompleteProfileToken($token: String!) {
    fetchUserByCompleteProfileToken(token: $token) {
      id
      trainerId
      locale
      complete_profile_token
      status
      administration {
        id
        PID
        contact_date
        found_from
        referenced_by
        remark_contact
        notify_on
        first_visit
        death_date
        remark_death
        VIP
        comment
        people_group {
          id
          name
        }
        status {
          id
          name
        }
        contact_channel {
          id
          name
        }
      }
      personal {
        name
        surname
        phoneNumber
        birthdate
        gender {
          id
          name
        }
        bussiness_name
        comments
        landline
        remark_communication_channel
        nationality {
          id
          name
        }
      }
      address {
        street
        city
        postal
        country {
          id
          name
        }
        canton {
          id
          name
        }
        additionalAddress
      }
      measurement {
        weight
        height
        bodyFatPt
        belly
        restingHeartRate
      }
      private_info {
        website
        landline_alt
        mail_alt
        tel_alt
        fax
        hometown
        name_unmarried
        number_of_children
        emergency_contact
        emergency_contact_PID
        remark_emergency_contact
        emergency_telephone1
        remark_emergency_telephone1
        emergency_telephone2
        remark_emergency_telephone2
        remark_private_info
        title {
          id
          name
        }
        additional_title {
          id
          name
        }
        civil_status {
          id
          name
        }
      }
      finance {
        id
        IBAN
        account_no
        clearing_no
        BIC
        bank_name
        bank_city
        bank_street
        bank_postal
        bank_country {
          id
          name
        }
        bank_address_addition
        comment
      }
      ahv {
        id
        AHV
        remark_ahv
        comment
      }
      user_sport {
        id
        years
        discipline
        squad
        training_group
        association
        comment
        sport {
          id
          name
        }
        sport_status {
          id
          name
        }
        training_frequency {
          id
          name
        }
        training_status {
          id
          name
        }
        SOCard {
          id
          name
        }
      }
      sport_specialist {
        id
        specialist_PID
        name
        cooperation_since
        email
        mobile
        mobile_alternative
        comments
        specialist_function {
          id
          name
        }
      }
      work {
        id
        PIDcompany
        job_name
        company_name
        department
        position
        street
        roadNo
        additional_address
        postal
        city
        mobile
        landline
        email
        website
        fax
        country {
          id
          name
        }
      }
      medicine {
        id
        KK_KVG
        KK_zusatz
        accident_insurance
        police_no
      }
      med_expert {
        id
        name
        surname
        salutation
        street
        additionalAddress
        postal
        city
        email
        mobile
        med_function {
          id
          name
        }
      }
      problem {
        id
        typ
        description
      }
      diagnose {
        id
        typ
        description
      }
      email
      img
      createdAt
    }
  }
`;

export const FetchTrainer = gql`
  query GetTrainer($id: Int!) {
    fetchUser(id: $id, roleId: 3) {
      id
      is_admin
      status
      personal {
        name
        surname
        birthdate
        gender {
          id
          name
        }
      }
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }
      email
      img
      createdAt

      company {
        id
        personal {
          phoneNumber
          name
        }
        address {
          street
        }
        img
        email
        private_info {
          website
        }
        createdAt
      }
    }
  }
`;

export const FetchCompany = gql`
  query GetCompany($id: Int!) {
    fetchUser(id: $id, roleId: 2) {
      id
      status
      personal {
        name
        phoneNumber
      }
      address {
        street
        postal
        city
        country {
          id
          name
        }
      }
      email
      private_info {
        website
      }
      createdAt
      img
    }
  }
`;

export const FetchClientActivitiesQuery = gql`
  query FetchClientActivities($userId: Int!, $date: Date) {
    fetchClientActivities(userId: $userId, date: $date) {
      activities {
        id
        model
        plan {
          id
          taken_plan {
            id
            title
            img
            exercises {
              id
              img
              title
            }
          }
          status
          completed_exercises {
            id
            exercise {
              id
            }
          }
        }
      }
      total
    }
  }
`;

export const FetchClientsMonthlyActivitiesQuery = gql`
  query FetchClientsMonthlyActivities(
    $userId: Int!
    $month: Int!
    $year: Int!
  ) {
    fetchClientsMonthlyActivities(userId: $userId, month: $month, year: $year)
  }
`;

export const SearchTrainersForFilters = gql`
  query SearchTrainersForFilters($search: String!) {
    searchTrainers(search: $search, limit: 20) {
      allUsers {
        id
        status
        personal {
          name
          surname
        }
        company {
          id
          personal {
            name
            surname
          }
        }
      }
    }
  }
`;
// query {
//     fetchUser(id: 120) {
//   id name surname email gender birthDate street city country
//   zip bodyTypeId phoneNumber height weight bodyFatPt belly
//   restingHeartrate maxHeartRate web img status createdAt
//   role { id name } bodyType { id name } goal { id name }
//   Activity{ id name } TraineeCount Trainee {id name email img }
//   Trainer{id name surname email img} Employee{id name surname email img} EmployeeCount
//   Company{id name email img } Gender{id name} TakenPlans {id MasterPlan{id title img} time}
//   TakenPlansCount TakenTestsCount TakenTests{ id  Test{id title img} answered favourite Status{id name}}
//   recentActivities {id plan{id title img} test {id title img} type}
//   TrainerPlans{id title duration img } TrainerPlansCount TrainerTests{ id title duration img} TrainerTestsCount
//   StartedPlans{id TakenPlan{id MasterPlan{id title}}}
//   FavouriteTakenPlansCount FavouriteTakenPlans{id MasterPlan{id title img} time }
//   FavouriteTestsCount FavouriteTests{id  Test{id title img} answered favourite Status{id name}}
//     }
//   }
