import React from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import PropTypes from "prop-types";

import GeneralInfo from "./CreateTrainingplan/GeneralInfo";
import SelectExercises from "./CreateTrainingplan/SelectExercises";
import Overview from "./EditTrainingplan/Overview";

const EditTrainingplan = () => {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route path={match.url + "/overview"} component={Overview} />
            <Route path={match.url + "/select-exercises"} component={SelectExercises} />
            <Route path={match.url} component={GeneralInfo} />
        </Switch>
    );
};

EditTrainingplan.propTypes = {
    match: PropTypes.object
};

export default EditTrainingplan;
