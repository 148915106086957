import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ReportList from "../../../components/Reports/ReportList";
import { withRouter } from "react-router";
import { FetchAllReportsQuery } from "./GraphQLActions";

const AllReports = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const reportState = useSelector(s => s.reportState);
    const filters = reportState.filters;

    useEffect(() => {
        dispatch(FetchAllReportsQuery({ ...filters }));
    }, [dispatch, filters]);

    // if (reportState.loading) return <Loading loading />
    if (reportState.error) return `Error! ${reportState.error}`;

    if (reportState.totalReports > 0) {
        return (
            <table className="table table-priosan">
                <thead>
                    <tr>
                        <th>
                            <div className="head-table">
                                <p>{t("name.label")}</p>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <p>{t("type.label")}</p>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <p>{t("title.label")}</p>
                            </div>
                        </th>
                        <th>
                            <div className="head-table">
                                <p>{t("date_time.label")}</p>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {reportState.allReports.map(report => {
                        return <ReportList key={report.id} report={report} />;
                    })}
                </tbody>
            </table>
        );
    } else {
        return <div></div>;
    }
};

export default withRouter(AllReports);
