import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TestsList from "../../../components/Tests/TestsList";
import { FetchAllTestsQuery } from "./GraphQLActions";
import get from "lodash/get";

const AllTests = () => {
    let dispatch = useDispatch();
    let { testState } = useSelector(s => s);
    let filters = testState.filters;

    useEffect(() => {
        dispatch(
            FetchAllTestsQuery({
                ...filters,
                difficulty: filters.difficulty.id || null,
                creator: filters.creator.id || null
            })
        );
    }, [dispatch, filters]);

    if (testState.error) return `Error! ${testState.error}`;
    return (
        <>
            {get(testState, "allTests", []).map(test => {
                return <TestsList key={test.id} tests={test} />;
            })}
        </>
    );
};

export default AllTests;
