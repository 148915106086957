/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Query } from "react-apollo";
import { Link, useHistory } from "react-router-dom";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import { updateExercise } from "../../../../store/exercise/exercise_actions";
import WizardNav from "../../../../components/UI/WizardNav/WizardNav";
import { searchBaseModels } from "../../../../resolvers/query/base";
import Select from "../../../../components/UI/Select/Select";
import FiltersMultipleSelect from "../../../../components/UI/Select/FiltersMultipleSelect";
import Loading from "../../../../components/UI/Loading";

const Information = () => {
  const { t } = useTranslation();
  const exerciseState = useSelector((s) => s.exerciseState);
  const reduxState = exerciseState.singleExercise;
  const updateState = exerciseState.updateExercise;
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   *
   * @param {Event} e
   * @param {Boolean} number
   */
  let formChangeHandler = (e, number = false) => {
    let value = e.target.value;
    if (number) value = parseInt(e.target.value) || 0;
    let state = updateObject({ name: e.target.name, value });

    dispatch(updateExercise({ name: e.target.name, value }));
  };

  let updateObject = (object) => {
    if (!object.name || object.value === undefined) return {};

    let name = object.name.split(".");
    let firstKey = name[0];
    if (Array.isArray(name) && name.length > 1) {
      name.shift();
      name = name.join(".");
      return { [firstKey]: updateObject({ name, value: object.value }) };
    }
    return { [firstKey]: object.value };
  };

  /**
   *
   * @param {Event} e
   * @param {String} name
   */
  let selectHandlers = (value, name) => {
    dispatch(updateExercise({ name, value }));
  };

  /**
   *
   * @param {Event} e
   * @param {int} index
   */
  let motionsChangedHandler = (e, index) => {
    let updateMotions = [...get(updateState, "motions", [])];
    updateMotions[index] = { motion: e.target.value };
    dispatch(updateExercise({ name: "motions", value: updateMotions }));
  };

  /**
   *
   * @param {Event} e
   * @param {String} name
   */
  let multipleSelectHandler = (value, name) => {
    dispatch(updateExercise({ name, value }));
  };

  const submitHandler = (e) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    if (
      exerciseState.firstStepValidity.isValid &&
      exerciseState.secondStepValidity.isValid
    ) {
      history.push("overview");
    }
    return;
  };

  const terminationCondition = parseInt(
    get(
      updateState,
      "termination_condition.id",
      get(reduxState, "termination_condition.id", "")
    )
  );

  const terminationConditionDefaultValue = parseInt(
    get(
      updateState,
      "termination_condition_value.default",
      get(reduxState, "termination_condition_value.default", 0)
    )
  );

  const defaultWeightItems = () => {
    let items = [];
    let index = 0;

    while (index <= 500) {
      items.push({ id: index, name: index });

      if (index < 100) {
        index++;
      } else {
        index += 5;
      }
    }
    return items;
  };

  return (
    <section className="main-content">
      <WizardNav
        first={t("upload_file.label")}
        firstLink="upload-file"
        second={t("information.label")}
        secondLink="information"
        third={t("overview.label")}
        thirdLink="overview"
        active1={exerciseState.firstStepValidity.isValid}
        active2={
          exerciseState.firstStepValidity.isValid &&
          exerciseState.secondStepValidity.isValid
        }
        active={2}
      />
      <Query
        query={searchBaseModels}
        variables={{
          group: true,
          difficulty: true,
          equipment: true,
          bodypart: true,
          terminationconditions: true,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading loading />;
          if (error) return <div className="container-fluid">`Error! `</div>;
          return (
            <div className="container-fluid">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="training-form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title-form">
                        <h5>{t("general.label")}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("title.label")}
                          <span className="form-required">*</span>
                        </label>
                        <input
                          maxLength={250}
                          value={get(
                            updateState,
                            "title",
                            get(reduxState, "title", "")
                          )}
                          type="text"
                          name="title"
                          onChange={(e) => formChangeHandler(e)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("group.label")}
                          <span className="form-required">*</span>
                        </label>
                        <FiltersMultipleSelect
                          select={(e) => multipleSelectHandler(e, "groups")}
                          selectedItems={get(
                            updateState,
                            "groups",
                            get(reduxState, "groups", [])
                          )}
                          items={data.searchBaseModels.Group}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("difficulty.label")}
                          <span className="form-required">*</span>
                        </label>
                        <Select
                          selectedItem={get(
                            updateState,
                            "difficulty.id",
                            get(reduxState, "difficulty.id", "")
                          )}
                          text={"Please select a Difficulty"}
                          select={(e) => selectHandlers(e, "difficulty")}
                          items={data.searchBaseModels.Difficulty}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("description.label")}</label>
                        <textarea
                          maxLength={250}
                          value={get(
                            updateState,
                            "description",
                            get(reduxState, "description", "")
                          )}
                          onChange={(e) => formChangeHandler(e)}
                          name="description"
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("notes.label")}</label>
                        <textarea
                          maxLength={250}
                          value={get(
                            updateState,
                            "note",
                            get(reduxState, "note", "")
                          )}
                          onChange={(e) => formChangeHandler(e)}
                          name="note"
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("recommendations.label")}</label>
                        <textarea
                          maxLength={250}
                          value={get(
                            updateState,
                            "recommendations",
                            get(reduxState, "recommendations", "")
                          )}
                          name="recommendations"
                          onChange={(e) => formChangeHandler(e)}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title-form">
                        <h5>{t("more_information.label")}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("bodypart.label")}
                          <span className="form-required">*</span>
                        </label>
                        <FiltersMultipleSelect
                          select={(e) => multipleSelectHandler(e, "bodyparts")}
                          selectedItems={get(
                            updateState,
                            "bodyparts",
                            get(reduxState, "bodyparts", [])
                          )}
                          items={data.searchBaseModels.BodyPart}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("equipment.label")}
                          <span className="form-required">*</span>
                        </label>
                        <FiltersMultipleSelect
                          select={(e) => multipleSelectHandler(e, "equipments")}
                          selectedItems={get(
                            updateState,
                            "equipments",
                            get(reduxState, "equipments", [])
                          )}
                          items={data.searchBaseModels.Equipment}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("device_settings.label")}</label>
                        <input
                          maxLength={250}
                          value={get(
                            updateState,
                            "device_settings",
                            get(reduxState, "device_settings", "")
                          )}
                          onChange={(e) => formChangeHandler(e)}
                          type="text"
                          name="device_settings"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("resistance.label")}
                          <span className="form-required">*</span>
                        </label>
                        <input
                          maxLength={250}
                          value={get(
                            updateState,
                            "resistance",
                            get(reduxState, "resistance", "")
                          )}
                          onChange={(e) => formChangeHandler(e)}
                          type="text"
                          name="resistance"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("goal.label")}</label>
                        <input
                          maxLength={250}
                          value={get(
                            updateState,
                            "goal",
                            get(reduxState, "goal", "")
                          )}
                          onChange={(e) => formChangeHandler(e)}
                          type="text"
                          name="goal"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("cave.label")}</label>
                        <input
                          maxLength={250}
                          value={get(
                            updateState,
                            "cave",
                            get(reduxState, "cave", "")
                          )}
                          onChange={(e) => formChangeHandler(e)}
                          type="text"
                          name="cave"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title-form">
                        <h5>{t("execution.label")}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("series.label")}
                          <span className="form-required">*</span>
                        </label>
                        <input
                          maxLength={250}
                          value={get(
                            updateState,
                            "series",
                            get(reduxState, "series", 0)
                          )}
                          onChange={(e) => formChangeHandler(e, true)}
                          type="text"
                          name="series"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("termination_condition.label")}
                          <span className="form-required">*</span>
                        </label>
                        <Select
                          selectedItem={get(
                            updateState,
                            "termination_condition.id",
                            get(reduxState, "termination_condition.id", "")
                          )}
                          text={"Please select a termination condition"}
                          select={(e) =>
                            selectHandlers(e, "termination_condition")
                          }
                          items={data.searchBaseModels.TerminationConditions}
                        />
                      </div>
                    </div>

                    {!terminationCondition ||
                    terminationCondition > 2 ? null : (
                      <>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              {terminationCondition === 1
                                ? t("repetitions.label")
                                : t("time.label")}
                              <span className="form-required">*</span>
                            </label>
                            <input
                              maxLength={250}
                              value={get(
                                updateState,
                                terminationCondition === 1
                                  ? "termination_condition_value.repetitions"
                                  : "termination_condition_value.time",
                                get(
                                  reduxState,
                                  terminationCondition === 1
                                    ? "termination_condition_value.repetitions"
                                    : "termination_condition_value.time",
                                  0
                                )
                              )}
                              onChange={(e) => formChangeHandler(e, true)}
                              type="text"
                              name={
                                terminationCondition === 1
                                  ? "termination_condition_value.repetitions"
                                  : "termination_condition_value.time"
                              }
                              className="form-control"
                            />
                          </div>
                        </div>
                        {terminationCondition === 1 && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                {t("default_weight.label")}
                                <span className="form-required">*</span>
                              </label>
                              <Select
                                selectedItem={terminationConditionDefaultValue}
                                // text={"Please select a termination condition"}
                                select={(e) =>
                                  formChangeHandler(
                                    {
                                      target: {
                                        name:
                                          "termination_condition_value.default",
                                        value: e.id,
                                      },
                                    },
                                    true
                                  )
                                }
                                items={defaultWeightItems()}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {t("motion_execution.label")} ({t("step.label")} 1)
                        </label>
                        <input
                          maxLength={200}
                          value={get(updateState, "motions[0].motion", "")}
                          onChange={(e) => motionsChangedHandler(e, 0)}
                          type="text"
                          name="motion"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("step.label")} 2</label>
                        <input
                          maxLength={200}
                          value={get(updateState, "motions[1].motion", "")}
                          onChange={(e) => motionsChangedHandler(e, 1)}
                          type="text"
                          name="repetitions"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t("step.label")} 3</label>
                        <input
                          maxLength={200}
                          value={get(updateState, "motions[2].motion", "")}
                          onChange={(e) => motionsChangedHandler(e, 2)}
                          type="text"
                          name="repetitions"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                      <div className="btn-right">
                        <Link
                          to="upload-file"
                          className="btn btn-cancel btn-180"
                        >
                          {t("back.label")}
                        </Link>
                        <button
                          disabled={
                            !(
                              exerciseState.firstStepValidity.isValid &&
                              exerciseState.secondStepValidity.isValid
                            )
                          }
                          onClick={submitHandler}
                          to="overview"
                          className="btn btn-primary btn-180"
                        >
                          {t("next.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          );
        }}
      </Query>
    </section>
  );
};

Information.propTypes = {
  history: PropTypes.object,
};

export default Information;
