import gql from "graphql-tag";

export const createExerciseMutation = gql`
  mutation createExercise(
    $title: String!
    $difficulty: Int!
    $bodyparts: [Int!]!
    $groups: [Int!]
    $equipments: [Int!]!
    $device_settings: String
    $resistance: String!
    $goal: String
    $cave: String
    $series: Int!
    $termination_condition_id: Int!
    $termination_condition_value: TerminationConditionInput
    $motions: [String!]
    $img: Upload
    $video: Upload
    $photos: [Upload!]
    $description: String
    $note: String
    $recommendations: String
  ) {
    createExercise(
      title: $title
      description: $description
      note: $note
      recommendations: $recommendations
      device_settings: $device_settings
      resistance: $resistance
      goal: $goal
      cave: $cave
      series: $series
      video: $video
      img: $img
      photos: $photos
      groups: $groups
      difficulty: $difficulty
      termination_condition_id: $termination_condition_id
      termination_condition_value: $termination_condition_value
      bodyparts: $bodyparts
      equipments: $equipments
      motions: $motions
    ) {
      id
      title
      description
      note
      recommendations
      device_settings
      resistance
      cave
      series
      video
      img
      goal
      status
      createdAt
      termination_condition_value
      termination_condition {
        id
        name
      }
      difficulty {
        id
        name
      }
      groups {
        id
        name
      }
      photos {
        id
        path
      }
      equipments {
        id
        name
      }
      bodyparts {
        id
        name
      }
      motions {
        id
        motion
      }
      creator {
        id
      }
    }
  }
`;

export const editExerciseMutation = gql`
  mutation editExerciseMutation(
    $id: Int!
    $title: String
    $difficulty: Int
    $bodyparts: [Int!]
    $groups: [Int!]
    $equipments: [Int!]
    $device_settings: String
    $resistance: String
    $goal: String
    $cave: String
    $series: Int
    $termination_condition_id: Int
    $termination_condition_value: TerminationConditionInput
    $motions: [String!]
    $img: Upload
    $video: Upload
    $deleteVideo: Boolean
    $photos: [Upload!]
    $photosToDelete: [Int!]
    $description: String
    $note: String
    $recommendations: String
  ) {
    editExercise(
      id: $id
      title: $title
      description: $description
      note: $note
      recommendations: $recommendations
      device_settings: $device_settings
      resistance: $resistance
      goal: $goal
      cave: $cave
      series: $series
      video: $video
      deleteVideo: $deleteVideo
      img: $img
      photos: $photos
      photosToDelete: $photosToDelete
      groups: $groups
      difficulty: $difficulty
      termination_condition_id: $termination_condition_id
      termination_condition_value: $termination_condition_value
      bodyparts: $bodyparts
      equipments: $equipments
      motions: $motions
    ) {
      id
      title
      description
      note
      recommendations
      device_settings
      resistance
      cave
      series
      video
      img
      goal
      status
      createdAt
      termination_condition_value
      termination_condition {
        id
        name
      }
      difficulty {
        id
        name
      }
      groups {
        id
        name
      }
      photos {
        id
        path
      }
      equipments {
        id
        name
      }
      bodyparts {
        id
        name
      }
      motions {
        id
        motion
      }
      creator {
        id
      }
    }
  }
`;

export const deletePhotos = gql`
  mutation deletePhotos($ids: [Int!]!) {
    deletePhotos(id: $ids)
  }
`;

export const ToggleExerciseStatus = gql`
  mutation ToggleExerciseStatus($id: Int!, $status: STATUS!) {
    toggleExerciseStatus(id: $id, status: $status)
  }
`;

export const DeleteExercise = gql`
  mutation DeleteExercise($id: Int!) {
    deleteExercise(id: $id)
  }
`;
