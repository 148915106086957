import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";

const ModalComponent = props => (
    <Modal visible={props.show} dialogClassName="modal-dialog modal-dialog-centered" onClick={props.closeModal}>
        <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i onClick={props.closeModal} className="icon-cancel"></i>
            </button>
            {props.error ? (
                <div className="modal-body">
                    <div className="content">
                        {Object.values(props.error).map((val, index) => {
                            return (
                                <p className="text-danger" key={index}>
                                    {val}
                                </p>
                            );
                        })}
                    </div>
                </div>
            ) : props.loading ? (
                <div className="modal-body">
                    <div className="content">
                        <p>Loading</p>
                    </div>
                </div>
            ) : (
                <div className="modal-body">
                    <div className="excercises-created">
                        {props.sendRequest ? null : (
                            <div className="icon">
                                <i className="icon-check"></i>
                            </div>
                        )}
                        <div className="content">
                            <p>{props.text}</p>
                        </div>
                        {!props.oneButton ? (
                            <div className="btn-modal">
                                <a
                                    data-toggle="modal"
                                    data-target="#modal"
                                    onClick={e => {
                                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                        props.closeModal();
                                        props.history.push(`/dashboard/${props.path}/${props.id}`);
                                    }}
                                    href={`/dashboard/${props.path}/${props.id}`}
                                >
                                    See {props.item}
                                </a>
                                <a href="_" className="add">
                                    <i className="icon-add"></i>
                                    Add for client
                                </a>
                            </div>
                        ) : (
                            <div className="btn-modal">
                                <a
                                    className="add"
                                    data-toggle="modal"
                                    data-target="#modal"
                                    onClick={e => {
                                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                        if (props.sendRequest) {
                                            props.sendRequest();
                                        } else {
                                            props.closeModal();
                                        }
                                    }}
                                    href={"_"}
                                >
                                    Ok!
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    </Modal>
);

ModalComponent.propTypes = {
    closeModal: PropTypes.func,
    path: PropTypes.string,
    id: PropTypes.number,
    history: PropTypes.object,
    item: PropTypes.string,
    text: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    show: PropTypes.bool,
    oneButton: PropTypes.bool,
    sendRequest: PropTypes.func
};

export default ModalComponent;
