import React, { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";

import { Loading } from "../../../components/UI";
import { useDispatch } from "react-redux";

const TicketComponent = props => {
    const [filter, setFilter] = useState("DAY");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let vars = {};
        if (props.filterable) vars[props.filterable] = filter;
        dispatch(props.action(vars)).then(data => {
            setData(data);
            setLoading(false);
        });
    }, [dispatch, filter, props, props.action]);

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="n-card">
                <div className="head">
                    <h4>{props.title}</h4>
                    {!props.filterable ? null : (
                        <div className="card-filter">
                            <form>
                                <div className="select-custom">
                                    <select
                                        defaultValue={filter}
                                        className="form-control"
                                        onChange={e => setFilter(e.target.value)}
                                    >
                                        <option value="DAY">Day</option>
                                        <option value="WEEK">Week</option>
                                        <option value="MONTH">Month</option>
                                        <option value="YEAR">Year</option>
                                        <option value="ALL">All</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                {loading ? (
                    <p>Loading</p>
                ) : (
                    <div className="body">
                        {Object.keys(data).map((key, index) => (
                            <div key={index} className="n-card-item">
                                <p>{key}</p>
                                <span>{data[key]}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

TicketComponent.propTypes = {
    action: PropTypes.func,
    filterable: PropTypes.string,
    title: PropTypes.string
};

export default TicketComponent;
