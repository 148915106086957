import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Search from "../../../components/UI/Search/Search";
import Select from "../../../components/UI/Select/Select";

import AllUsers from "./AllUsers";

import {
  resetUserState,
  userFilter,
} from "../../../store/user/user/user_actions";
import { searchBaseModels } from "../../../resolvers/query/base";

const User = () => {
  const { t } = useTranslation();
  const userState = useSelector((s) => s.userState);
  const dispatch = useDispatch();
  const filters = userState.filters;

  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-md-6">
            <div className="training-result">
              <p>
                {t("total.label")}:{" "}
                <span>
                  {userState.totalUsers || 0} {t("users.label")}
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="create-icon">
              <Link
                onClick={() => dispatch(resetUserState())}
                to="users/new-user/administration"
              >
                <i className="icon-add"></i>
                <span>{t("create_user.label")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-between training-filter">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="row">
              <Search
                searchHandler={(e) => {
                  dispatch(userFilter({ search: e.target.value }));
                }}
                querySearch={filters.search}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="row justify-content-end">
              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-4">
                <Select
                  text={"Trainer assignment status"}
                  select={(e) => {
                    dispatch(userFilter({ assigned: e, offset: 0, page: 1 }));
                  }}
                  items={[
                    { id: "ASSIGNED", name: "Has Trainer Assigned" },
                    { id: "NOT_ASSIGNED", name: "No Trainer Assigned" },
                  ]}
                  selectedItem={filters.assigned.id || ""}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-4">
                <Select
                  text={t("filter_status.placeholder")}
                  select={(e) => {
                    dispatch(userFilter({ status: e, offset: 0, page: 1 }));
                  }}
                  items={[
                    { id: "ACTIVE", name: "Active" },
                    { id: "INACTIVE", name: "Inactive" },
                  ]}
                  selectedItem={filters.status.id || ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <AllUsers />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            {userState.totalUsers > 0 ? (
              <ReactPaginate
                previousLabel={<i className="icon-arrow-left"></i>}
                nextLabel={<i className="icon-arrow-right"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(userState.totalUsers / 20) || 0}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  dispatch(
                    userFilter({
                      offset: e.selected * 20,
                      page: parseInt(e.selected) + 1,
                    })
                  );
                }}
                pageClassName={"page-item"}
                forcePage={filters.page - 1}
                pageLinkClassName={"page-link"}
                containerClassName={"pagination"}
                activeClassName={"active"}
                activeLinkClassName={"active"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

User.propTypes = {
  history: PropTypes.object,
};

export default User;
