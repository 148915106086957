import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const WizardNav = props => (
    <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="wizard-nav">
                <ul>
                    <li>
                        <Link to={props.firstLink} className={props.active >= 1 ? "active" : ""}>
                            {props.first}
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={e =>
                                !props.active1
                                    ? e.preventDefault
                                        ? e.preventDefault()
                                        : (e.returnValue = false)
                                    : null
                            }
                            to={props.secondLink}
                            className={props.active >= 2 ? "active" : ""}
                        >
                            {props.second}
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={e =>
                                !props.active2
                                    ? e.preventDefault
                                        ? e.preventDefault()
                                        : (e.returnValue = false)
                                    : null
                            }
                            to={props.thirdLink}
                            className={props.active >= 3 ? "active" : ""}
                        >
                            {props.third}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

WizardNav.propTypes = {
    firstLink: PropTypes.string,
    secondLink: PropTypes.string,
    thirdLink: PropTypes.string,
    first: PropTypes.string,
    second: PropTypes.string,
    third: PropTypes.string,
    active: PropTypes.number,
    active1: PropTypes.bool,
    active2: PropTypes.bool
};

export default WizardNav;
