import React from "react";
import PropTypes from "prop-types";

import { getToken } from "../../helpers";

const Authorization = WrapedComponent => {
    const Auth = props => {
        if (getToken.token) {
            props.history.push("/");
        }
        return <WrapedComponent {...props} />;
    };
    Auth.propTypes = {
        history: PropTypes.object
    };
    return Auth;
};

export default Authorization;
