/**
 *
 * @param {Event} e
 * @param {Object} order Order object
 * @param {String} name Column name
 * @param {Function} dispatch
 * @param {Function} func
 */
export const orderHandler = (e, order, name, dispatch, func) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);

    if (order.name === name) {
        dispatch(func({ order: { ...order, desc: !order.desc }, pageFilter: 1, offsetFilter: 0 }));
    } else {
        dispatch(func({ order: { name: name, desc: false }, pageFilter: 1, offsetFilter: 0 }));
    }
};

/**
 *
 * @param {Object} order Order object
 * @param {String} name Column name
 */
export const classNameGenerator = (order, name) => {
    return order.name === name
        ? `${order.name === name ? "icon-arrow-down" : "icon-arrow-down"} ${order.desc ? "" : "rotate-icon"}`
        : null;
};
