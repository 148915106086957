import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TicketComponent from "./Ticket";
import {
    UserTicketAction,
    LibraryTicketAction,
    LatestRecordTicketAction,
    UserStatusChartDataAction,
    NewTrainersChartDataAction
} from "./GraphQLActions";

import DoughnutChart from "./DoughnutChart";
import LineChartContainer from "./LineChartContainer";

const Home = () => {
    const { t } = useTranslation();
    const authState = useSelector(s => s.authState);

    return (
        <section className="main-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <LineChartContainer />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="chart">
                            <DoughnutChart
                                query={UserStatusChartDataAction}
                                role={4}
                                title={t("user.label")}
                                backgroundColor={["#6c5ce7", "#fe5578"]}
                                hoverBackgroundColor={["#6c5ce7", "#fe5578"]}
                            />
                        </div>
                    </div>
                    {authState.role.id < 3 || (authState.role.id === 3 && authState.is_admin) ? (
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                            <div className="chart">
                                <div className="chart-head">
                                    <h4>{t("trainere_overview.label")}</h4>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                        <DoughnutChart
                                            query={NewTrainersChartDataAction}
                                            backgroundColor={["#1cd1a1", "rgba(28,209,161, 0.1)"]}
                                            hoverBackgroundColor={["#1cd1a1", "rgba(28,209,161, 0.1)"]}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                        <DoughnutChart
                                            query={UserStatusChartDataAction}
                                            variables={{ role: 3 }}
                                            backgroundColor={["#59bcfb", "rgba(89, 188, 251, 0.1)"]}
                                            hoverBackgroundColor={["#59bcfb", "rgba(89, 188, 251, 0.1)"]}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                        <DoughnutChart
                                            query={UserStatusChartDataAction}
                                            variables={{ role: 3 }}
                                            backgroundColor={["#e24049", "rgba(226, 64, 73, 0.1)"]}
                                            hoverBackgroundColor={["#e24049", "rgba(226, 64, 73, 0.1)"]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <TicketComponent title={t("user.label")} filterable="period" action={UserTicketAction} />
                    <TicketComponent title={t("library.label")} filterable="period" action={LibraryTicketAction} />
                    <TicketComponent title={t("feed_ticket.label")} action={LatestRecordTicketAction} />
                </div>
            </div>
        </section>
    );
};
export default Home;
