import React, { useState } from "react";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

const DoughnutChartComponent = props => {
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(props.query(props.variables)).then(resp => {
            if (resp) {
                let labels = [];
                let data = [];
                Object.keys(resp).map(key => {
                    labels.push(key);
                    data.push(resp[key]);
                });
                setData(data);
                setLabels(labels);
            }
        });
    }, [dispatch, props, props.query]);

    return (
        <>
            {props.title ? (
                <div className="chart-head">
                    <h4>{props.title}</h4>
                </div>
            ) : null}
            {data.length > 0 ? (
                <div className="chart-body">
                    <Doughnut
                        options={{
                            legend: { display: false },
                            animation: { duration: 1000, easing: "linear" },
                            title: { text: "Users" },
                            cutoutPercentage: 85
                        }}
                        data={{
                            labels,
                            datasets: [
                                {
                                    backgroundColor: props.backgroundColor,
                                    borderWidth: [0, 0],
                                    hoverBackgroundColor: props.hoverBackgroundColor,
                                    data
                                }
                            ]
                        }}
                    />

                    <div className="chart-footer">
                        {labels.map((label, index) => (
                            <div key={index} className="chart-info">
                                <p>
                                    <span
                                        style={{ backgroundColor: props.backgroundColor[index] }}
                                        className="new-users"
                                    ></span>
                                    {label}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

DoughnutChartComponent.propTypes = {
    title: PropTypes.string,
    variables: PropTypes.object,
    query: PropTypes.func,
    labels: PropTypes.arrayOf(PropTypes.string),
    backgroundColor: PropTypes.arrayOf(PropTypes.string),
    hoverBackgroundColor: PropTypes.arrayOf(PropTypes.string)
};

export default DoughnutChartComponent;
