import { stateValidator } from "../../../helpers/validator";
import { TrainerAddressInfoRules, TrainerInfoRules, TrainerPersonalInfoRules } from "./validation-rules";

export let validateState = (state, isUpdate) => {
    let newState = { ...state };

    let isValidBaseInfo = stateValidator(newState, TrainerInfoRules(isUpdate));
    let isValidPersonalInfo = stateValidator(newState.personal, TrainerPersonalInfoRules(isUpdate));
    let isValidAddress = stateValidator(newState.address, TrainerAddressInfoRules(isUpdate));

    let isAllValid = isValidPersonalInfo.isValid && isValidAddress.isValid && isValidBaseInfo.isValid;

    let completeErrors = {
        ...isValidBaseInfo.errors,
        ...isValidPersonalInfo.errors,
        ...isValidAddress.errors
    };

    newState = {
        ...newState,
        completeErrors,
        isAllValid
    };

    return newState;
};
