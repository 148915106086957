/* eslint-disable default-case */
import * as actionType from "./user_actions";
import * as planActionType from "../../trainingplans/trainingplans_actions";
import * as testActionType from "../../test/test_actions";
import { validateState } from "./validations";
import deepStateMutator from "helpers/deepStateMutator";

const userState = {
  totalUsers: null,
  allUsers: [],
  singleUser: {},
  updateUser: {},
  valid: false,
  errors: { array: [] },
  updateFlag: false,
  filters: {
    search: "",
    offset: 0,
    page: 1,
    status: {},
    assigned: {},
    //Order
    order: { name: "updatedAt", desc: true },
  },
};

const resetState = {
  singleUser: {},
  updateUser: {},
  valid: false,
  errors: { array: [] },
  updateFlag: false,
};

const filterReset = {
  //All Plans Filter
  search: "",
  offset: 0,
  page: 1,
  status: {},
  assigned: {},
  //Order
  order: { name: "updatedAt", desc: true },
};

const reducer = (state = userState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case actionType.USER:
      newState = {
        ...newState,
        ...action.value,
      };
      break;
    case actionType.DEEP_STATE_MUTATOR:
      newState = {
        ...newState,
        updateUser: { ...deepStateMutator(newState.updateUser, action.value) },
      };
      break;
    case actionType.OPEN_MODAL:
      newState = {
        ...newState,
        modal: true,
      };
      break;
    case actionType.SINGLE_USER:
      newState = {
        ...newState,
        singleUser: {
          ...newState.singleUser,
          ...action.value,
        },
        // updateUser: {},
        loading: false,
        forceUpdate: false,
        modal: false,
        errors: { array: [] },
      };
      break;
    case actionType.UPDATE_USER_STATE:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          ...action.value,
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_ADMINISTRATION:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          administration: {
            ...newState.updateUser.administration,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_PERSONAL:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          personal: {
            ...newState.updateUser.personal,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_ADDRESS:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          address: {
            ...newState.updateUser.address,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_PRIVATE_INFO:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          private_info: {
            ...newState.updateUser.private_info,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_WORK:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          work: {
            ...newState.updateUser.work,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_FINANCE:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          finance: {
            ...newState.updateUser.finance,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_AHV:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          ahv: {
            ...newState.updateUser.ahv,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_SPORT:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          sport: {
            ...newState.updateUser.sport,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_SPORT_SPECIALIST:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          sport_specialist: {
            ...newState.updateUser.sport_specialist,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_MEDICINE:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          medicine: {
            ...newState.updateUser.medicine,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_MED_EXPERT:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          med_expert: {
            ...newState.updateUser.med_expert,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_DIAGNOSE:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          diagnose: {
            ...newState.updateUser.diagnose,
            ...action.value,
          },
        },
      };
      break;
    case actionType.UPDATE_USER_STATE_PROBLEM:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          problem: {
            ...newState.updateUser.problem,
            ...action.value,
          },
        },
      };
      break;
    case actionType.USER_FILTERS:
      newState = {
        ...newState,
        filters: {
          ...newState.filters,
          order: { ...newState.filters.order },
          ...action.value,
        },
      };
      break;
    case actionType.RESET_USER_STATE:
      newState = {
        ...newState,
        ...resetState,
        filters: {
          ...filterReset,
          order: { ...filterReset.order },
        },
      };
      break;
    case actionType.PREPARE_USER_UPDATE:
      newState = {
        ...newState,
        updateUser: {
          ...newState.singleUser,
          personal: {
            ...newState.singleUser.personal,
            email: newState.singleUser.email,
          },
        },
        updateFlag: true,
      };
      break;
    case actionType.USER_LOADING:
      return {
        ...state,
        loading: true,
        modal: false,
        errors: { array: [] },
      };
    case actionType.USER_ERRORS:
      newState = {
        ...newState,
        loading: false,
        modal: false,
        errors: {
          ...action.value,
        },
      };
      break;
    case actionType.RESET_USER_FILTER_STATE:
      newState = {
        ...newState,
        filters: {
          ...filterReset,
          order: { ...filterReset.order },
        },
      };
      break;
    case actionType.DISPLAY_ERRORS:
      newState = {
        ...newState,
        updateUser: {
          ...newState.updateUser,
          displayErrors: true,
        },
      };
      break;
    case actionType.DELETE_USER:
    case planActionType.DELETE_TRAINING_PLAN:
    case testActionType.DELETE_TEST:
      newState = { ...state, singleUser: {} };
      break;

    case actionType.USER_STATUS_CHANGED:
      newState = {
        ...state,
        singleUser: { ...state.singleUser, ...action.value },
      };
      break;
  }

  if (!!newState.updateUser)
    newState.updateUser = validateState(newState.updateUser, false);

  return newState;
};

export default reducer;
