/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import ExerciseList from "../../../components/Exercises/ExerciseList";
import { FetchAllExercisesQuery } from "./GraphQLActions";

const AllExercises = props => {
    const dispatch = useDispatch();
    const { exerciseState, planState } = useSelector(s => s);

    const planFilters = planState.filters;
    const exerciseFilters = exerciseState.filters;
    let filters = props.forPlan ? planFilters : exerciseFilters;

    useEffect(() => {
        // let bodyparts = [];
        // if (filters.bodyparts) {
        //     filters.bodyparts.map(e => bodyparts.push(parseInt(e.id)));
        // }
        // let groups = [];
        // if (filters.groups) {
        //     filters.groups.map(e => groups.push(parseInt(e.id)));
        // }

        // searchExercise: "",
        // offsetExercise: 0,
        // pageExercise: 1,
        // bodypartsExercise: [],
        // groupsExercise: [],
        // difficultyExercise: {},
        // creatorExercise: {}

        //     search: "",
        // offset: 0,
        // page: 1,
        // bodyparts: [],
        // groups: [],
        // difficulty: {},
        // creator: {}

        let search = props.forPlan ? filters.searchExercise : filters.search;
        let offset = props.forPlan ? filters.offsetExercise : filters.offset;
        let bodyparts = props.forPlan ? filters.bodypartsExercise : filters.bodyparts;
        let groups = props.forPlan ? filters.groupsExercise : filters.groups;
        let difficulty = props.forPlan ? filters.difficultyExercise : filters.difficulty;
        let creator = props.forPlan ? filters.creatorExercise : filters.creator;

        let bodypartsArr = [];
        if (bodyparts) {
            bodyparts.map(e => bodypartsArr.push(parseInt(e.id)));
        }
        let groupsArr = [];
        if (groups) {
            groups.map(e => groupsArr.push(parseInt(e.id)));
        }
        dispatch(
            FetchAllExercisesQuery(
                {
                    search,
                    offset,
                    bodyparts: bodypartsArr,
                    groups: groupsArr,
                    difficulty: difficulty.id || null,
                    creator: creator.id || null
                },
                props.forPlan
            )
        );
    }, [props.forPlan, filters, dispatch]);

    let selectedState = props.forPlan ? planState : exerciseState;

    if (selectedState.error) return `Error! ${selectedState.error}`;

    return (
        <>
            {selectedState.allExercises.map(exercise => {
                let isChecked = false;
                if (props.forPlan) {
                    isChecked = props.selectedExercises.includes(parseInt(exercise.id));
                }
                return (
                    <ExerciseList
                        isChecked={isChecked}
                        forPlan={props.forPlan}
                        selectExerciseHandler={props.selectExerciseHandler}
                        selectedExercises={props.selectedExercises}
                        key={exercise.id}
                        exercises={exercise}
                        isNew={props.isNew}
                    />
                );
            })}
        </>
    );
};

AllExercises.propTypes = {
    forPlan: PropTypes.bool,
    isNew: PropTypes.bool,
    selectedExercises: PropTypes.array,
    selectExerciseHandler: PropTypes.func
};

export default AllExercises;
