/**
 *
 * @param {String} name
 * @param {Object|Array} value
 * @param {Function} func
 */
export const selectHandler = (name, value, func) => {
    const event = { target: { name, value } };
    formChangeHandler(event, func);
};

/**
 *
 * @param {Event} e
 * @param {Object} componentState
 * @param {Function} func
 */
export const formChangeHandler = (e, componentState, func) => {
    let name = e.target.name.split(".");
    let newState = { ...componentState };
    switch (name[0]) {
        case "select":
            let value = { id: parseInt(e.target.value), name: e.target.options[e.target.selectedIndex].text };
            // if (get(value, "id", false)) {
            newState = { ...newState, [name[1]]: value || null };
            // } else {
            //     delete newState[name[1]];
            // }
            break;
        case "multiple":
            newState = { ...newState, [name[1]]: e.target.value || [] };

            break;
        default:
            newState = { ...newState, [e.target.name]: e.target.value || "" };
    }
    func({ ...newState });
};

/**
 *
 * @param {Event} event
 */
export const formChangeHandlerV2 = event => {
    let name = event.target.name.split(".");
    let newState = {};

    switch (name[0]) {
        case "select":
            let value = {
                id: parseInt(event.target.value),
                name: event.target.options[event.target.selectedIndex].text
            };
            // if (get(value, "id", false)) {
            newState = { [name[1]]: value || null };
            // } else {
            //     delete newState[name[1]];
            // }
            break;
        case "multiple":
            newState = { [name[1]]: event.target.value || [] };

            break;
        default:
            newState = { [event.target.name]: event.target.value || "" };
    }
    // func({ ...newState });
    return newState;
};

/**
 *
 * @param {Event} e
 * @param {String} location
 * @param {String} componentName
 * @param {Object} componentState
 */
export const submitSubFormHandler = (e, props, componentName, componentState, location, final = false) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    props.changeMasterStateHandler(componentName, componentState, final);
    props.history.push(location);
};

/**
 *
 * @param {Event} e
 */
export const valueHandler = e => {
    let name = e.target.name.split(".");
    let value = e.target.value;

    switch (name[0]) {
        case "select":
            name.shift();
            value = e.target.val;
            break;
    }
    name = name.join(".");

    return { name, value };
};
