import get from "lodash/get";
import validator from "validator";

const questionValidator = state => {
    if (!get(state, "type.id", null)) return { isValid: false, error: "Please select a question type" };

    const questions = get(state, "questions", []);
    if (questions.length < 1) return { isValid: false, error: "Please provide one or more questions" };

    switch (state.type.id) {
        case 1:
            return validateQandAQuestions(questions);
        case 2:
            return validateScalaQuestions(questions);
        case 3:
            return validateVariantQuestions(questions);
        case 4:
            return validateMeasurementsQuestions(questions);
    }
};

const validateQandAQuestions = questions => {
    for (const question of questions) {
        let valid =
            validator.isLength(question.content || "", { min: 3, max: 200 }) &&
            validator.isLength(question.correct_answer || "", { min: 0, max: 200 });

        if (!valid) return { isValid: false, error: "Every field should have between 3 and 200 characters" };
    }

    return { isValid: true };
};

const validateMeasurementsQuestions = questions => {
    for (const question of questions) {
        let valid =
            validator.isLength(question.content || "", { min: 3, max: 200 }) &&
            validator.isInt(get(question, "unit.id", "") + "", { gt: 0 });

        if (!valid) return { isValid: false, error: "Every field should have between 3 and 200 characters" };
    }

    return { isValid: true };
};

const validateScalaQuestions = questions => {
    for (const question of questions) {
        let valid =
            validator.isLength(question.content || "", { min: 3, max: 200 }) &&
            validator.isInt(question.correct_answer + "" || "", { min: 1, max: 10 });

        if (!valid) return { isValid: false, error: "Every field should have between 3 and 200 characters" };
    }

    return { isValid: true };
};

const validateVariantQuestions = questions => {
    const response = {
        isValid: false,
        error: "Every field should have between 3 and 200 characters and at least a option"
    };

    for (const question of questions) {
        let valid =
            validator.isLength(question.content || "", { min: 3, max: 200 }) && get(question, "options", []).length > 0;
        if (!valid) return response;

        for (const option of question.options) {
            valid = validator.isLength(option.content || "", { min: 3, max: 200 });
            if (!valid) break;

            if (get(option, "sub_options", []).length > 0) {
                for (let subOption of option.sub_options) {
                    valid = validator.isLength(subOption.content || "", { min: 3, max: 200 });
                    if (!valid) break;
                }
                if (!valid) break;
            }
        }
        if (!valid) return response;
    }
    return { isValid: true };
};

export default questionValidator;
