import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import moment from "moment";
import { useTranslation } from "react-i18next";

import TrainingPlanReportList from "../../../components/Client/User/TrainingPlanReportList";
import { FetchClientActivitiesAction } from "./GraphQLActions";
import { Loading } from "../../../components/UI";

const DisplayReports = ({ date }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const { userId } = useParams();
    useEffect(() => {
        setLoading(true);
        dispatch(
            FetchClientActivitiesAction({
                userId: parseInt(userId),
                date: moment(date, "DD-MM-YYYY")
                    .format("YYYY-MM-DD")
                    .toString()
            })
        )
            .then(result => {
                let trainingPlans = [];
                let tests = [];

                result.activities.map(activity => {
                    if (activity.model === "training") {
                        trainingPlans.push(activity);
                    } else {
                        tests.push(activity);
                    }
                });

                setData({ trainingPlans, tests });
                setLoading(false);
            })
            .catch(() => {});
    }, [date, dispatch, userId]);

    return (
        <div className="col-md-12 col-lg-12 col-xl-7">
            <div className="user-detail-main-title">
                <h1>{t("trainingplans.label")}</h1>
            </div>
            {loading ? (
                <Loading loading />
            ) : get(data, "trainingPlans", []).length > 0 ? (
                get(data, "trainingPlans", []).map(training => (
                    <TrainingPlanReportList key={training.id} data={training.plan} />
                ))
            ) : (
                <h2>{t("no_activity.label")}</h2>
            )}
            {}
        </div>
    );
};

DisplayReports.propTypes = {
    date: PropTypes.string
};

export default DisplayReports;
