import { client } from "../../../helpers/index";
import {
  TrainingPlanListQuery,
  SingleTrainingPlanQuery,
  SingleTakenPlanQuery,
  IsTitleUnique,
} from "../../../resolvers/query/trainingplan";
import {
  plan,
  singleTrainingPlan,
  trainingPlanStatusChanged,
} from "../../../store/trainingplans/trainingplans_actions";
import {
  createPlanMutation,
  DeleteTrainingPlan,
  ToggleTrainingPlanStatus,
  DeleteTakenPlan,
  ToggleTakenPlanStatus,
  updatePlanMutation,
  updateTakenPlanMutation,
} from "../../../resolvers/mutation/trainingplan";
import {
  toggleLoadingOn,
  toggleLoadingOff,
} from "../../../store/global/global_actions";

export const FetchAllTrainingPlansQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: TrainingPlanListQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let {
            searchPlans: { totalCount, allPlans },
          } = data;
          dispatch(
            plan({ allPlans: allPlans, totalPlans: totalCount, loading: false })
          );
          return true;
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const FetchTrainingPlanByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: SingleTrainingPlanQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let { fetchPlan } = data;
          let exercisesId = [];
          let exercises = [];
          fetchPlan.exercises.map((e) => exercisesId.push(parseInt(e.id)));
          fetchPlan.exercises.map((e) =>
            exercises.push({ ...e, exerciseId: e.id })
          );
          dispatch(
            singleTrainingPlan({
              ...fetchPlan,
              exercisesId,
              exercises,
            })
          );
          dispatch(plan({ takenPlanFlag: false }));
          return true;
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const IsTitleUniqueQuery = (variables) => {
  return (dispatch) => {
    return client
      .query({
        query: IsTitleUnique,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          return data.isTitleUnique;
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const FetchTakenPlanByIdQuery = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .query({
        query: SingleTakenPlanQuery,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let { fetchTakenPlan } = data;
          let exercisesId = [];
          let exercises = [];
          fetchTakenPlan.exercises.map((e) => exercisesId.push(parseInt(e.id)));
          fetchTakenPlan.exercises.map((e) =>
            exercises.push({ ...e, exerciseId: e.id })
          );
          dispatch(
            singleTrainingPlan({
              ...fetchTakenPlan,
              exercisesId,
              exercises,
              loading: false,
            })
          );
          dispatch(plan({ takenPlanFlag: true }));
          return true;
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const CreateTrainingPlanMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: createPlanMutation,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let exercisesId = [];
          let exercises = [];
          data.createPlan.exercises.map((e) =>
            exercisesId.push(parseInt(e.id))
          );
          data.createPlan.exercises.map((e) =>
            exercises.push({ ...e, exerciseId: e.id })
          );
          dispatch(
            singleTrainingPlan({
              ...data.createPlan,
              id: parseInt(data.createPlan.id),
              exercisesId,
              exercises,
            })
          );
          return parseInt(data.createPlan.id);
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const UpdateTrainingPlanMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: updatePlanMutation,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          dispatch(
            singleTrainingPlan({
              ...data.editPlan,
              id: parseInt(data.editPlan.id),
              loading: false,
              errors: { array: [] },
              modal: true,
            })
          );
          return parseInt(data.editPlan.id);
        }
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const UpdateTakenPlanMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({
        mutation: updateTakenPlanMutation,
        variables,
      })
      .then(({ data }) => {
        if (data) {
          dispatch(
            singleTrainingPlan({
              ...data.editSubscribePlanDashboard,
              id: parseInt(data.editSubscribePlanDashboard.id),
              loading: false,
              errors: { array: [] },
              modal: true,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(plan({ loading: false, errors: err }));
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const ToggleTrainingPlanStatusMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    client
      .mutate({ mutation: ToggleTrainingPlanStatus, variables })
      .then(() => dispatch(trainingPlanStatusChanged(variables)))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const DeleteTrainingPlanMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: DeleteTrainingPlan, variables })
      .then(() => true)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const ToggleTakenPlanStatusMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    client
      .mutate({ mutation: ToggleTakenPlanStatus, variables })
      .then(() => dispatch(trainingPlanStatusChanged(variables)))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};

export const DeleteTakenPlanMutation = (variables) => {
  return (dispatch) => {
    dispatch(toggleLoadingOn());
    return client
      .mutate({ mutation: DeleteTakenPlan, variables })
      .then(() => true)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors[0]) {
          throw {
            statusCode: err.graphQLErrors[0].statusCode,
            message: err.graphQLErrors[0].message,
          };
        } else {
          throw err;
        }
      })
      .finally(() => dispatch(toggleLoadingOff()));
  };
};
