import gql from "graphql-tag";

export const LoginMutation = gql`
    mutation Login($email: String!, $password: String!, $provider: String!) {
        login(email: $email, password: $password, provider: $provider) {
            token
            refreshtoken
            user {
                id
                locale
                personal {
                    name
                    phoneNumber
                    surname
                }
                email
                address {
                    street
                }
                address {
                    street
                    postal
                    country {
                        id
                        name
                    }
                    city
                }

                role {
                    id
                    name
                }
                img
                is_admin
                company {
                    id
                    personal {
                        phoneNumber
                        name
                    }
                    img
                    address {
                        street
                    }
                    private_info {
                        website
                    }
                }
            }
        }
    }
`;

export const ForgotPasswordMutation = gql`
    mutation ForgotPassword($email: String!) {
        resetPassword(email: $email)
    }
`;

export const ResetFromToken = gql`
    mutation resetPassword($newPassword: String!, $confirmPassword: String!, $token: String!) {
        resetPasswordFromResetRequest(newPassword: $newPassword, confirmPassword: $confirmPassword, token: $token)
    }
`;

export const ChangeLocaleMutation = gql`
    mutation ChangeLocale($locale: LOCALE!) {
        changeLocale(locale: $locale)
    }
`;
