import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const SelectComp = (props) => {
  let [state, setState] = useState({
    elements: [
      {
        id: "",
        text: props.text,
        hidden: props.required,
        selected: !props.selectedItem,
        disabled: !props.selectedItem,
      },
    ],
    selectedItem: "",
  });

  /**
   *
   * @param {Event} e
   */
  let handleSelectItem = (e) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let name = e.target.options[e.target.selectedIndex].text;
    let value = e.target.value;
    e.target.val = { id: value, name };
    return props.select({ id: value, name });
  };

  useEffect(() => {
    let elements = [
      {
        id: "",
        text: props.text,
        hidden: props.required,
      },
    ];
    if (props.items && props.items.length > 0) {
      props.items.map((item) => {
        elements.push({ id: item.id, text: item.name });
      });

      setState({
        elements,
        selectedItem: props.selectedItem,
      });
    }
  }, [props.items, props.text, props.selectedItem, props.required, props]);

  return (
    <div className="select-custom select-max-width form-group">
      <select
        disabled={props.disable}
        name={"select." + props.name}
        value={state.selectedItem || ""}
        onChange={(e) => handleSelectItem(e)}
        className="form-control"
        required={props.required}
      >
        {state.elements.map((e) => (
          <option key={e.id} value={e.id}>
            {e.text}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectComp.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  select: PropTypes.func,
  name: PropTypes.string,
  required: PropTypes.bool,
  disable: PropTypes.bool,
};

export default SelectComp;
