import React, { useState, useEffect } from "react";
import { Loading } from "../../../components/UI";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import uuid from "uuid";

import RowComponent from "./RowComponent";
import { showModal } from "../../../store/global/global_actions";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import { useTranslation } from "react-i18next";

const List = props => {
    const { t } = useTranslation();
    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [confirmModal, setConfirmModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(props.fetch()).then(data => {
            setRecords(data);
            setLoading(false);
        });
        return () => {
            setRecords([]);
        };
    }, [dispatch, props]);

    /**
     *
     * @param {Event} e
     */
    const addMoreHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        setRecords(records => [...records, { id: uuid(), name: "", name_de: "", isNew: true }]);
    };

    /**
     *
     * @param {Event} e
     * @param {Integer} index
     * @param {Boolean} isNew
     */
    const removeHandler = (index, isNew = false) => {
        let newRecords = [...records];
        if (isNew) {
            newRecords.splice(index, 1);
            setRecords(newRecords);
        } else {
            dispatch(props.delete({ id: parseInt(newRecords[index].id) })).then(() => {
                newRecords.splice(index, 1);
                setRecords(newRecords);
                dispatch(showModal({ type: 1, message: t("ca_success_deleted.message"), button: "Ok!" }));
            });
        }
    };

    /**
     *
     * @param {Event} e
     * @param {Boolean} isNew
     * @param {Object} data
     * @param {Integer} index
     */
    const createOrEditRecord = (e, isNew, data, index) => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        setLoading(true);
        let mutatedRecords = [...records];
        if (isNew) {
            dispatch(props.create(data)).then(res => {
                mutatedRecords[index] = { ...res };
                setRecords(mutatedRecords);
                setLoading(false);
                dispatch(showModal({ type: 1, message: t("ca_success_created.message"), button: "Ok!" }));
            });
        } else {
            dispatch(props.edit(data)).then(res => {
                mutatedRecords[index] = { ...res };
                setRecords(mutatedRecords);
                setLoading(false);
                dispatch(showModal({ type: 1, message: t("ca_success_updated.message"), button: "Ok!" }));
            });
        }
    };

    let isRemovable = () => {
        let validRecords = 0;
        for (let i = 0; i < records.length; i++) {
            validRecords += !records[i].isNew ? 1 : 0;
            if (validRecords > 1) return true;
        }
        return false;
    };

    return (
        <div className="col-md-9">
            <div className="settings-form">
                <div className="row justify-content-center">
                    <Loading loading={isLoading} />
                    <div className="col-md-9">
                        {records.map((data, index) => (
                            <RowComponent
                                key={data.id || uuid()}
                                data={data}
                                remove={
                                    (!isRemovable() && !data.isNew) || !props.delete
                                        ? null
                                        : e => {
                                              e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                              if (data.isNew) {
                                                  removeHandler(index, true);
                                              } else {
                                                  setConfirmModal({ show: true, index, isNew: data.isNew });
                                              }
                                          }
                                }
                                mutate={(e, vars) => createOrEditRecord(e, data.isNew, vars, index)}
                                locales={props.locales}
                            />
                        ))}
                    </div>
                    {isLoading || !props.delete ? null : (
                        <div className="col-md-9 d-flex justify-content-start">
                            <div className="settings-button">
                                <a
                                    href="#"
                                    onClick={e => addMoreHandler(e, setRecords)}
                                    className="btn btn-primary btn-180"
                                >
                                    <i className="icon-add"></i>
                                    {t("add_more.label")}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ConfirmationModal
                show={confirmModal.show}
                remove={() => removeHandler(confirmModal.index, confirmModal.isNew)}
                closeModal={() => setConfirmModal({})}
            />
        </div>
    );
};

List.propTypes = {
    fetch: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    locales: PropTypes.object.isRequired
};

export default List;
