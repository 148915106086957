/* eslint-disable default-case */
import get from "lodash/get";
import * as actionType from "./trainingplans_actions";
import { stateValidator } from "../../helpers/validator";
import { GeneralInfoValidations } from "./validation-rules";

const newStateForPlan = {
    totalPlans: null,
    allPlans: [],

    singleTrainingPlan: {},
    updateTrainingPlan: {},
    isValid: true,
    updateFlag: false,

    totalExercise: 0,
    allExercises: [],
    filters: {
        search: "",
        trainingPeriods: [],
        phases: [],
        bodyParts: [],
        equipments: [],
        offset: 0,
        page: 1,
        creator: {},
        difficulty: {},

        //Exercises Filters for Plans

        searchExercise: "",
        offsetExercise: 0,
        pageExercise: 1,
        bodypartsExercise: [],
        groupsExercise: [],
        difficultyExercise: {},
        creatorExercise: {}
    }
    //All Plans Filter
};

const filterReset = {
    search: "",
    trainingPeriods: [],
    phases: [],
    bodyParts: [],
    equipments: [],
    offset: 0,
    page: 1,
    creator: {},
    difficulty: {},

    //Exercises Filters for Plans

    searchExercise: "",
    offsetExercise: 0,
    pageExercise: 1,
    bodypartsExercise: [],
    groupsExercise: [],
    difficultyExercise: {},
    creatorExercise: {}
};

const resetState = {
    singleTrainingPlan: {},
    updateTrainingPlan: {},
    isValid: true,
    updateFlag: false,

    totalExercise: 0,
    allExercises: [],
    takenPlanFlag: false
};

const reducer = (state = newStateForPlan, action) => {
    let newState = { ...state };
    switch (action.type) {
        case actionType.RESET_PLAN_STATE:
            newState = {
                ...state,
                ...resetState,
                filters: { ...filterReset }
            };
            break;
        case actionType.RESET_PLAN_FILTER_STATE:
            newState = {
                ...state,
                filters: { ...filterReset }
            };
            break;
        case actionType.PLAN_FILTERS:
            newState = {
                ...state,
                filters: {
                    ...newState.filters,
                    ...action.value
                }
            };
            break;
        case actionType.PLAN_ERRORS:
            newState = {
                ...newState,
                loading: false,
                modal: false,
                errors: {
                    ...action.value
                }
            };
            break;
        case actionType.PLAN_LOADING:
            return {
                ...state,
                loading: true,
                modal: false,
                errors: { array: [] }
            };
        case actionType.PLAN:
            newState = {
                ...state,
                ...action.value
            };
            break;
        case actionType.SINGLE_TRAINING_PLAN:
            newState = {
                ...state,
                singleTrainingPlan: {
                    ...state.singleTrainingPlan,
                    ...action.value
                }
                // updateTrainingPlan: {}
            };
            break;
        case actionType.UPDATE_TRAINING_PLAN:
            newState = {
                ...state,
                updateTrainingPlan: {
                    ...state.updateTrainingPlan,
                    ...action.value
                }
            };
            break;
        case actionType.PLAN_FILTERS:
            newState = {
                ...state,
                ...action.value
            };
            break;
        case actionType.TRAINING_PLAN_STATUS_CHANGED:
            newState = { ...state, singleTrainingPlan: { ...state.singleTrainingPlan, ...action.value } };
            break;
        case actionType.DELETE_TRAINING_PLAN:
            newState = { ...state, singleTrainingPlan: {} };
            break;
    }

    newState.isValidGeneralInfo = stateValidator(
        newState.updateTrainingPlan,
        GeneralInfoValidations(newState.updateFlag)
    );
    newState.isValidSelectExercises =
        get(newState, "singleTrainingPlan.exercises", []).length +
            get(newState, "updateTrainingPlan.exercises", []).length >
        0;

    return newState;
};

export default reducer;
