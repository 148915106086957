module.exports.UploadFileValidations = () => [
  {
    field: "title",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 200 }, validWhen: true },
    ],
    messages: ["Title can't be empty.", "Input too long. Max 200 characters."],
    variableType: [],
  },
  {
    field: "description",
    rules: [{ name: "isLength", params: { max: 500 }, validWhen: true }],
    messages: ["Input too long. Max 500 characters."],
    variableType: [],
  },
];
module.exports.InformationValidations = (isUpdate) => [
  {
    field: "title",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isLength", params: { max: 200 }, validWhen: true },
    ],
    messages: ["Title can't be empty.", "Input too long. Max 200 characters."],
    required: true,
    variableType: [],
  },
  {
    field: "description",
    rules: [{ name: "isLength", params: { max: 500 }, validWhen: true }],
    messages: ["Input too long. Max 500 characters."],
    variableType: [],
  },
  {
    field: "note",
    rules: [{ name: "isLength", params: { max: 500 }, validWhen: true }],
    messages: ["Input too long. Max 500 characters."],
    variableType: [],
  },
  {
    field: "recommendations",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Input too long. Max 250 characters."],
    variableType: [],
  },
  {
    field: "device_settings",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Input too long. Max 250 characters."],
    variableType: [],
  },
  {
    field: "resistance",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Input too long. Max 250 characters."],
    required: true,
    variableType: [],
  },
  {
    field: "cave",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Input too long. Max 250 characters."],
    variableType: [],
  },
  {
    field: "series",
    rules: [{ name: "isInt", params: { max: 15 }, validWhen: true }],
    messages: ["Please put a number less or equal to 15."],
    required: true,
    variableType: [],
  },
  {
    field: "goal",
    rules: [{ name: "isLength", params: { max: 250 }, validWhen: true }],
    messages: ["Input too long. Max 250 characters."],
    variableType: [],
  },
  {
    field: "termination_condition",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Invalid value"],
    required: true,
    variableType: ["object"],
  },
  {
    field: "difficulty",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Invalid value"],
    required: true,
    variableType: ["object"],
  },
  {
    field: "groups",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Invalid value"],
    variableType: ["array", "object"],
    required: true,
  },
  {
    field: "equipments",
    rules: [{ name: "isInt", params: {}, validWhen: true }],
    messages: ["Invalid value"],
    required: true,
    variableType: ["array", "object"],
  },
  {
    field: "bodyparts",
    rules: [
      { name: "isEmpty", params: {}, validWhen: false },
      { name: "isInt", params: {}, validWhen: true },
    ],
    messages: ["Invalid value"],
    required: true,
    variableType: ["array", "object"],
  },
];
