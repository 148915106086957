export const RESET_PLAN_STATE = `RESET_PLAN_STATE`;
export const resetPlanState = value => {
    return {
        type: RESET_PLAN_STATE,
        value
    };
};

export const PLAN = `PLAN`;
export const plan = value => {
    return {
        type: PLAN,
        value
    };
};

export const SINGLE_TRAINING_PLAN = `SINGLE_TRAINING_PLAN`;
export const singleTrainingPlan = value => {
    return {
        type: SINGLE_TRAINING_PLAN,
        value
    };
};

export const UPDATE_TRAINING_PLAN = `UPDATE_TRAINING_PLAN`;
export const updateTrainingPlan = value => {
    return {
        type: UPDATE_TRAINING_PLAN,
        value
    };
};

export const PLAN_FILTERS = `PLAN_FILTERS`;
export const planFilter = value => {
    return {
        type: PLAN_FILTERS,
        value
    };
};

export const PLAN_LOADING = `PLAN_LOADING`;
export const planLoading = value => {
    return {
        type: PLAN_LOADING,
        value
    };
};

export const PLAN_ERRORS = `PLAN_ERRORS`;
export const planErrors = value => {
    return {
        type: PLAN_ERRORS,
        value
    };
};

export const RESET_PLAN_FILTER_STATE = `RESET_PLAN_FILTER_STATE`;
export const resetPlanFilterState = value => {
    return {
        type: RESET_PLAN_FILTER_STATE,
        value
    };
};

export const TRAINING_PLAN_STATUS_CHANGED = `TRAINING_PLAN_STATUS_CHANGED`;
export const trainingPlanStatusChanged = value => {
    return {
        type: TRAINING_PLAN_STATUS_CHANGED,
        value
    };
};

export const DELETE_TRAINING_PLAN = `DELETE_TRAINING_PLAN`;
export const deleteTrainingPlan = () => {
    return {
        type: DELETE_TRAINING_PLAN
    };
};
