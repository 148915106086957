import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import LogoImage from "../../assets/img/logo_favicon/logo-b.svg";
import LogoImageMobile from "../../assets/img/logo_favicon/logo-mobile.svg";
import { resetExerState } from "../../store/exercise/exercise_actions";
import { resetPlanState } from "../../store/trainingplans/trainingplans_actions";
import { resetTestState } from "../../store/test/test_actions";
import { resetReportState } from "../../store/reports/reports_actions";
import { resetCompanyState } from "../../store/user/company/company_actions";
import { resetTrainerState } from "../../store/user/trainer/trainer_actions";
import { resetUserState } from "../../store/user/user/user_actions";
const SideBar = () => {
    let authState = useSelector(s => s.authState);
    let { menu: isMenuOpen } = useSelector(s => s.globalState);
    let dispatch = useDispatch();

    const { t } = useTranslation();

    if (!authState.role.id) {
        return <aside></aside>;
    }
    return (
        <aside>
            <div className={"sidebar" + (isMenuOpen ? " open-menu" : "")}>
                <div className="logo">
                    <NavLink to="/dashboard" exact className="logo-desktop">
                        <img alt="" src={LogoImage} />
                    </NavLink>
                    <NavLink to="/dashboard" exact className="logo-mobile">
                        <img alt="" src={LogoImageMobile} />
                    </NavLink>
                </div>
                <div className="menu">
                    <ul>
                        <li>
                            <NavLink to="/dashboard" exact>
                                <i className="icon-dashboard"></i>
                                <span>{t("dashboard.label")}</span>
                            </NavLink>
                        </li>
                        <li className="menu-title">{t("libraries.label")}</li>
                        <li>
                            <NavLink
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    dispatch(resetPlanState());
                                    e.defaultPrevented = false;
                                }}
                                to="/dashboard/trainingplans"
                            >
                                <i className="icon-trainingplans"></i>
                                <span>{t("trainingplans.label")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    dispatch(resetExerState());
                                    e.defaultPrevented = false;
                                }}
                                to="/dashboard/exercises"
                            >
                                <i className="icon-exercises"></i>
                                <span>{t("exercises.label")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    dispatch(resetTestState());
                                    e.defaultPrevented = false;
                                }}
                                to="/dashboard/tests"
                            >
                                <i className="icon-tests"></i>
                                <span>{t("tests.label")}</span>
                            </NavLink>
                        </li>
                        <li className="menu-title">{t("clients.label")}</li>
                        {!authState.id || parseInt(authState.role.id) > 1 ? null : (
                            <li>
                                <NavLink
                                    onClick={e => {
                                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                        dispatch(resetCompanyState());
                                        e.defaultPrevented = false;
                                    }}
                                    to="/dashboard/companies"
                                >
                                    <i className="icon-company"></i>
                                    <span>{t("companies.label")}</span>
                                </NavLink>
                            </li>
                        )}
                        {!authState.id || (parseInt(authState.role.id) > 2 && !authState.is_admin) ? null : (
                            <li>
                                <NavLink
                                    onClick={e => {
                                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                        dispatch(resetTrainerState());
                                        e.defaultPrevented = false;
                                    }}
                                    to="/dashboard/trainers"
                                >
                                    <i className="icon-trainers"></i>
                                    <span>{t("trainers.label")}</span>
                                </NavLink>
                            </li>
                        )}

                        <li>
                            <NavLink
                                onClick={e => {
                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                    dispatch(resetUserState());
                                    e.defaultPrevented = false;
                                }}
                                to="/dashboard/users"
                            >
                                <i className="icon-users"></i>
                                <span>{t("users.label")}</span>
                            </NavLink>
                        </li>
                        {parseInt(authState.role.id) < 3 || (authState.role.id === 3 && authState.is_admin) ? (
                            <li>
                                <NavLink
                                    onClick={e => {
                                        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                        dispatch(resetReportState());
                                        e.defaultPrevented = false;
                                    }}
                                    to="/dashboard/reports"
                                >
                                    <i className="icon-reports"></i>
                                    <span>{t("reports.label")}</span>
                                </NavLink>
                            </li>
                        ) : null}
                        {parseInt(authState.role.id) !== 1 ? null : (
                            <li className="menu-settings">
                                <NavLink to="/dashboard/categories">
                                    <i className="icon-category"></i>
                                    <span>{t("categories.label")}</span>
                                </NavLink>
                            </li>
                        )}
                        <li className={parseInt(authState.role.id) !== 1 ? "menu-settings" : ""}>
                            <NavLink to="/dashboard/settings">
                                <i className="icon-settings"></i>
                                <span>{t("settings.label")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    );
};

export default SideBar;
