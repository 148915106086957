import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import SingleTrainer from "../../../components/Client/Trainer/SingleTrainer";
import NewTrainer from "./NewTrainer";
import {
  FetchTrainerByIdQuery,
  ToggleCompanyAdminPrivilegesMutation,
  ToggleTrainerStatusMutation,
} from "./GraphQLActions";

import Loading from "../../../components/UI/Loading";
import { useParams } from "react-router-dom";
import { trainer } from "../../../store/user/trainer/trainer_actions";

let FetchTrainer = (props) => {
  let dispatch = useDispatch();
  let trainerState = useSelector((s) => s.trainerState);
  let { singleTrainer } = trainerState;
  const [error, setError] = useState(null);

  const { trainerId } = useParams();

  useEffect(() => {
    if (!props.fetch) {
      dispatch(trainer({ updateFlag: true }));
    } else {
      dispatch(trainer({ updateTrainer: {} }));
    }
    if (singleTrainer.id !== parseInt(trainerId))
      dispatch(
        FetchTrainerByIdQuery({
          id: parseInt(trainerId),
          roleId: 3,
        })
      ).catch((err) => {
        setError(err);
      });
  }, [trainerId, singleTrainer, dispatch, props.fetch]);

  const toggleAdminPrivilegesHandler = () => {
    dispatch(
      ToggleCompanyAdminPrivilegesMutation(
        { userId: singleTrainer.id },
        singleTrainer.is_admin
      )
    );
  };

  /**
   *
   * @param {Event} e
   */
  const toggleTrainerStatus = () => {
    dispatch(
      ToggleTrainerStatusMutation({
        id: singleTrainer.id,
        status: singleTrainer.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      })
    );
  };

  if (error) throw error;

  if (parseInt(singleTrainer.id) === parseInt(trainerId)) {
    if (props.fetch) {
      return (
        <SingleTrainer
          toggleAdminPrivilegesHandler={toggleAdminPrivilegesHandler}
          toggleTrainerStatus={toggleTrainerStatus}
        />
      );
    } else {
      return <NewTrainer update {...props} />;
    }
  }
  //     }
  return <div></div>;
};

FetchTrainer.propTypes = {
  match: PropTypes.object,
  fetch: PropTypes.bool,
};

export default FetchTrainer;
