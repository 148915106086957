import { client } from "../../helpers";
import { FetchUserByCompleteProfileToken } from "../../resolvers/query/users";
import { singleUser, user, updateUserState } from "../../store/user/user/user_actions";

export const FetchUserByCompleteRegistrationToken = variables => {
    return dispatch => {
        return client
            .query({
                query: FetchUserByCompleteProfileToken,
                variables
            })
            .then(({ data, errors }) => {
                if (errors) throw errors;
                if (data) {
                    let { fetchUserByCompleteProfileToken } = data;
                    dispatch(
                        singleUser({
                            ...fetchUserByCompleteProfileToken,
                            id: parseInt(fetchUserByCompleteProfileToken.id),
                            completeProfileFlag: true
                        })
                    );
                    dispatch(updateUserState({ complete_profile_token: variables.token }));
                    dispatch(user({ updateFlag: true }));
                    return true;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else if (Array.isArray(err)) {
                    throw { statusCode: err[0].statusCode, message: err[0].message };
                } else {
                    throw err;
                }
            });
    };
};
