import React from "react";
import Modal from "react-bootstrap4-modal";
import PropTypes from "prop-types";

const CompanyDisclaimer = props => {
    return (
        <Modal visible={props.show} dialogClassName="modal-dialog modal-dialog-centered" onClick={props.close}>
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.close}>
                    <i className="icon-cancel"></i>
                </button>
                <div className="modal-body">
                    <div className="excercises-created disclaimer">
                        <div className="content">
                            Ich nehme zur Kenntnis, dass wir als Firma/Trainer und nicht Priosan für die Inhalte
                            (Trainingspläne, Übungen, Tests) und deren Folgen verantwortlich ist.
                        </div>

                        <div className="btn-modal">
                            <a href="#" className="add" onClick={props.action}>
                                Confirm
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

CompanyDisclaimer.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired
};

export default CompanyDisclaimer;
