/* eslint-disable default-case */
import * as actionType from "./global_actions";

const initState = {
    loading: false,
    modal: null,
    menu: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.CHANGE_GLOBAL_STATE:
            return {
                ...state,
                ...action.value
            };
        case actionType.TOGGLE_LOADING_ON:
            return {
                ...state,
                loading: true
            };
        case actionType.TOGGLE_LOADING_OFF:
            return {
                ...state,
                loading: false
            };
        case actionType.TOGGLE_MENU:
            return {
                ...state,
                menu: action.value
            };
        case actionType.SHOW_MODAL:
            return {
                ...state,
                loading: false,
                modal: {
                    show: true,
                    ...action.value
                }
            };

        case actionType.SHOW_CONTENT_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    show: false
                },
                contentModal: true
            };
        case actionType.HIDE_CONTENT_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    show: true
                },
                contentModal: false
            };
        case actionType.HIDE_MODAL:
            return {
                ...state,
                modal: null
            };
    }
    return state;
};

export default reducer;
