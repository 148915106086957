import { DEFAULT_LOCALE } from "../constants";

export const changeLocale = locale => {
    if (
        (!localStorage.getItem("locale") && locale !== DEFAULT_LOCALE) ||
        (locale && locale !== localStorage.getItem("locale"))
    ) {
        localStorage.setItem("locale", locale);
        window.location.reload();
    }
};
