import React, { useEffect } from "react";
import { Switch, Route } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import GeneralInfo from "./CreateTestv1/GeneralInfo";
import Questions from "./CreateTestv1/Questions";
import Overview from "./CreateTestv1/Overview";
import { updateTest } from "../../../store/test/test_actions";

const NewTest = () => {
    const { singleTest, updateTest: updateState } = useSelector(s => s.testState);
    const dispatch = useDispatch();
    useEffect(() => {
        if (updateState.id != singleTest.id) dispatch(updateTest({ ...singleTest }));
    }, [dispatch, singleTest, updateState]);

    return (
        <Switch>
            <Route path="/dashboard/tests/:id/edit-test/overview" component={Overview} />
            <Route path="/dashboard/tests/:id/edit-test/questions" component={Questions} />
            <Route path="/dashboard/tests/:id/edit-test" component={GeneralInfo} />
        </Switch>
    );
};

export default NewTest;
