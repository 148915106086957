import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const ScalaQuestions = ({ changeHandler, remove, question }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        content: "",
        correct_answer: 1
    });

    return (
        <div className="col-md-6">
            <div className="form-group form-question">
                <a onClick={e => remove(e)} href="#" className="remove-item">
                    {t("remove.label")} <i className="icon-cancel"></i>
                </a>
                <input
                    onChange={e => changeHandler(e)}
                    type="text"
                    defaultValue={question.content}
                    placeholder={t("write_your_question.label")}
                    name="content"
                    className="form-control"
                />
            </div>
            <div className="form-group custom-slider">
                <input
                    onChange={e => changeHandler({ target: { name: e.target.name, value: parseInt(e.target.value) } })}
                    type="range"
                    min={1}
                    max={10}
                    defaultValue={parseInt(question.correct_answer) || 1}
                    placeholder={""}
                    name="correct_answer"
                    className="form-slider"
                />
                {[...Array(10).keys()].map((el, i) => (
                    <span className={i + 1 <= (+question.correct_answer || 1) ? "active" : ""} key={i}>
                        {i + 1}
                    </span>
                ))}
            </div>
        </div>
    );
};

ScalaQuestions.propTypes = {
    changeHandler: PropTypes.func,
    remove: PropTypes.func,
    question: PropTypes.object
};

export default ScalaQuestions;
