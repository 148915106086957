import gql from "graphql-tag";

export const CreateTestMutation = gql`
  mutation CreateTest(
    $type: Int!
    $title: String!
    $img: Upload
    $video: Upload
    $duration: Int!
    $difficulty: Int!
    $description: String
    $QuestionAndAnswerInput: [QuestionAndAnswerInput!]
    $MeasurementsInput: [MeasurementsInput!]
    $ScalaInput: [ScalaInput!]
    $VariantsInput: [VariantsInput!]
  ) {
    createTest_v1(
      type: $type
      title: $title
      img: $img
      video: $video
      duration: $duration
      difficulty: $difficulty
      description: $description
      QuestionAndAnswerInput: $QuestionAndAnswerInput
      MeasurementsInput: $MeasurementsInput
      ScalaInput: $ScalaInput
      VariantsInput: $VariantsInput
    ) {
      id
      title
      duration
      description
      img
      video
      no_of_questions
      status
      difficulty {
        id
        name
      }
      type {
        id
        name
      }
      questions {
        id
        content
        order
        correct_answer
        unit {
          id
          name
        }
        options {
          id
          content
          isCorrect
          order
          sub_options {
            id
            content
            isCorrect
            order
          }
        }
      }
      creator {
        id
        personal {
          name
        }
      }
    }
  }
`;

export const UpdateTestMutation = gql`
  mutation UpdateTest(
    $id: Int!
    $type: Int!
    $title: String!
    $video: Upload
    $img: Upload
    $duration: Int!
    $difficulty: Int!
    $description: String!
    $QuestionAndAnswerInput: [QuestionAndAnswerInput!]
    $MeasurementsInput: [MeasurementsInput!]
    $ScalaInput: [ScalaInput!]
    $VariantsInput: [VariantsInput!]
  ) {
    updateTest_v1(
      id: $id
      type: $type
      title: $title
      img: $img
      video: $video
      duration: $duration
      difficulty: $difficulty
      description: $description
      QuestionAndAnswerInput: $QuestionAndAnswerInput
      MeasurementsInput: $MeasurementsInput
      ScalaInput: $ScalaInput
      VariantsInput: $VariantsInput
    ) {
      id
      title
      duration
      description
      img
      video
      no_of_questions
      status
      difficulty {
        id
        name
      }
      type {
        id
        name
      }
      questions {
        id
        content
        order
        correct_answer
        unit {
          id
          name
        }
        options {
          id
          content
          isCorrect
          order
          sub_options {
            id
            content
            isCorrect
            order
          }
        }
      }
      creator {
        id
        personal {
          name
        }
      }
    }
  }
`;

export const subscribeTestDashboard = gql`
  mutation subscribeTestDashboard($userIds: [Int!]!, $testIds: [Int!]!) {
    subscribeTestDashboard(userIds: $userIds, testIds: $testIds)
  }
`;

export const ToggleTestStatus = gql`
  mutation ToggleTestStatus($id: Int!, $status: STATUS!) {
    toggleTestStatus(id: $id, status: $status)
  }
`;

export const DeleteTest = gql`
  mutation DeleteTest($id: Int!) {
    deleteTest(id: $id)
  }
`;

export const ToggleTakenTestStatus = gql`
  mutation ToggleTakenTestStatus($id: Int!, $status: STATUS!) {
    toggleTakenTestStatus(id: $id, status: $status)
  }
`;

export const DeleteTakenTest = gql`
  mutation DeleteTakenTest($id: Int!) {
    deleteTakenTest(id: $id)
  }
`;
