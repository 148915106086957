import React from "react";
import PropTypes from "prop-types";

const changeOffsetHandler = (e, pageFilter, offset, handler, next, prev, total) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    if (next) {
        let times = Math.ceil(total / 8);
        if (pageFilter < times) {
            offset += 8;
            pageFilter += 1;

            handler({
                offsetFilter: offset,
                pageFilter: pageFilter
            });
        }
    } else if (prev) {
        if (pageFilter > 1) {
            offset -= 8;
            pageFilter -= 1;

            handler({
                offsetFilter: offset,
                pageFilter: pageFilter
            });
        }
    } else {
        offset = pageFilter * 8;
        pageFilter += 1;
        handler({
            offsetFilter: offset,
            pageFilter: pageFilter
        });
    }
};

const showPagination = (count, handler, page, offset, limit = 8) => {
    let paginations = [];
    let times = Math.ceil(count / limit);
    for (let i = 0; i < times; i++) {
        paginations.push(
            <li key={i} className="page-item">
                <a
                    style={{ cursor: "pointer" }}
                    href="#"
                    onClick={e => changeOffsetHandler(e, i, offset, handler)}
                    className={i + 1 === page ? "page-link active" : "page-link"}
                >
                    {i + 1}
                </a>
            </li>
        );
    }

    return paginations;
};

const Pagination = props => {
    return (
        <ul className="pagination">
            <li className="page-item">
                <a
                    style={{ cursor: "pointer" }}
                    onClick={e =>
                        changeOffsetHandler(e, props.page, props.offset, props.offsetHandler, false, true, null)
                    }
                    href="#"
                    className="page-link"
                >
                    <i className="icon-arrow-left"></i>
                </a>
            </li>
            {showPagination(props.count, props.offsetHandler, props.page, props.offset)}
            <li className="page-item">
                <a
                    style={{ cursor: "pointer" }}
                    onClick={e =>
                        changeOffsetHandler(e, props.page, props.offset, props.offsetHandler, true, false, props.count)
                    }
                    href="#"
                    className="page-link"
                >
                    <i className="icon-arrow-right"></i>
                </a>
            </li>
        </ul>
    );
};

Pagination.propTypes = {
    page: PropTypes.number,
    offset: PropTypes.number,
    offsetHandler: PropTypes.func,
    count: PropTypes.number
};

export default Pagination;
