import React from "react";
import { Switch, Route } from "react-router";
import { useSelector } from "react-redux";

import NewTrainer from "./NewTrainer";
import FetchTrainer from "./fetchTrainer";
import Trainer from "./index";
import { Loading } from "../../../components/UI";

const TrainerRoutes = () => {
    const globalState = useSelector(s => s.globalState);
    return (
        <>
            <Loading loading={globalState.loading} />
            <Switch>
                <Route path="/dashboard/trainers/new-trainer" component={NewTrainer} />
                <Route path="/dashboard/trainers/:trainerId(\d+)/edit-trainer" component={FetchTrainer} />
                <Route path="/dashboard/trainers/:trainerId(\d+)">
                    <FetchTrainer fetch />
                </Route>
                <Route path="/dashboard/trainers/" component={Trainer} />
            </Switch>
        </>
    );
};
export default TrainerRoutes;
