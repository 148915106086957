import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import get from "lodash/get";

const ReportList = props => {
    let report = props.report;
    let date = moment(get(report, "updatedAt", "")).format("DD.MM.YYYY - hh:mm");
    return (
        <tr
            style={{ cursor: "pointer" }}
            onClick={() => {
                props.history.push(`/dashboard/reports/${get(report, "id", "")}`);
            }}
        >
            <td>
                <div className="info">
                    <p>
                        {get(report, "issuer.personal.name", "-") + " " + get(report, "issuer.personal.surname", "-")}
                    </p>
                </div>
            </td>
            <td>
                <div className="info">
                    <p>{parseInt(get(report, "issuer.role.id", "")) === 3 ? "Trainer" : "Client"}</p>
                </div>
            </td>
            <td>
                <div className="info">
                    <p>{get(report, "subject", "-")}</p>
                </div>
            </td>
            <td>
                <div className="info">
                    <p>{date}</p>
                </div>
            </td>
        </tr>
    );
};

ReportList.propTypes = {
    report: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(ReportList);
