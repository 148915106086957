export const RESET_EXERCISE_STATE = `RESET_EXERCISE_STATE`;
export const resetExerState = value => {
    return {
        type: RESET_EXERCISE_STATE,
        value
    };
};

export const EXERCISE = `EXERCISE`;
export const exercise = value => {
    return {
        type: EXERCISE,
        value
    };
};

export const PREPARE_EXERCISE_UPDATE = `PREPARE_EXERCISE_UPDATE`;
export const prepareExerciseUpdate = value => {
    return {
        type: PREPARE_EXERCISE_UPDATE,
        value
    };
};

export const SINGLE_EXERCISE = `SINGLE_EXERCISE`;
export const singleExercise = value => {
    return {
        type: SINGLE_EXERCISE,
        value
    };
};

export const UPDATE_EXERCISE = `UPDATE_EXERCISE`;
export const updateExercise = value => {
    return {
        type: UPDATE_EXERCISE,
        value
    };
};

export const EXERCISE_LOADING = `EXERCISE_LOADING`;
export const exerciseLoading = value => {
    return {
        type: EXERCISE_LOADING,
        value
    };
};

export const EXERCISE_ERRORS = `EXERCISE_ERRORS`;
export const exerciseErrors = value => {
    return {
        type: EXERCISE_ERRORS,
        value
    };
};

export const EXERCISE_FILTERS = `EXERCISE_FILTERS`;
export const exerciseFilter = value => {
    return {
        type: EXERCISE_FILTERS,
        value
    };
};

export const RESET_EXERCISE_FILTER_STATE = `RESET_EXERCISE_FILTER_STATE`;
export const resetExerFilterState = value => {
    return {
        type: RESET_EXERCISE_FILTER_STATE,
        value
    };
};

export const EXERCISE_STATUS_CHANGED = `EXERCISE_STATUS_CHANGED`;
export const exerciseStatusChanged = value => {
    return {
        type: EXERCISE_STATUS_CHANGED,
        value
    };
};

export const DELETE_EXERCISE = `DELETE_EXERCISE`;
export const deleteExercise = () => {
    return {
        type: DELETE_EXERCISE
    };
};
