import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import WizardNav from "../../../../components/UI/WizardNav/WizardNav";

import {
  CreateExerciseMutation,
  UpdateExerciseMutation,
} from "../GraphQLActions";
import { showModal } from "../../../../store/global/global_actions";

import placeholder from "../../../../assets/img/placeholders/400x200.png";
import videoPlaceholder from "../../../../assets/img/placeholders/400x200video.png";

const Overview = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const exerciseState = useSelector((s) => s.exerciseState);
  const reduxState = exerciseState.singleExercise;
  const updateState = exerciseState.updateExercise;
  const dispatch = useDispatch();

  if (!exerciseState.firstStepValidity.isValid) {
    history.push("/dashboard/exercises/new-exercise/upload-file");
    return null;
  }
  if (!exerciseState.secondStepValidity.isValid) {
    history.push("/dashboard/exercises/new-exercise/information");
    return null;
  }

  /**
   *
   * @param {Event} e
   */
  let createExercise = (e) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let motions = [];
    get(reduxState, "motions", []).map((e) => motions.push(e.motion));
    get(updateState, "motions", []).map(
      (e, index) => (motions[index] = e && e.motion ? e.motion : motions[index])
    );

    let vars = {
      ...updateState,
      difficulty: parseInt(get(updateState, "difficulty.id", null)),
      termination_condition_id: parseInt(
        get(updateState, "termination_condition.id", null)
      ),
      bodyparts: get(updateState, "bodyparts", []).map(
        (bodypart) => bodypart.id
      ),
      groups: get(updateState, "groups", []).map((group) => group.id),
      equipments: get(updateState, "equipments", []).map(
        (equipment) => equipment.id
      ),
      motions,
      img: get(updateState, "imgFile", null),
      imgString: get(updateState, "img", null),
      photos: get(updateState, "photosFiles", null),
      video: get(updateState, "videoFile", null),
      photosToDelete: get(updateState, "photosToDelete", null),
    };

    if (vars.termination_condition_id === 1) {
      vars.termination_condition_value = {
        repetitions: vars.termination_condition_value.repetitions,
        default: vars.termination_condition_value.default,
      };
    } else if (vars.termination_condition_id === 2) {
      vars.termination_condition_value = {
        time: vars.termination_condition_value.time,
        default: vars.termination_condition_value.time,
      };
    } else {
      vars.termination_condition_value = {};
    }

    if (!exerciseState.updateFlag) {
      dispatch(CreateExerciseMutation(vars))
        .then((id) =>
          dispatch(
            showModal({
              message: t("ex_success_created.message"),
              button: t("see_it.label"),
              link: "/dashboard/exercises/" + id,
            })
          )
        )
        .catch((err) =>
          dispatch(showModal({ message: err.message, button: "Ok" }))
        );
    } else {
      vars.id = parseInt(updateState.id);
      dispatch(UpdateExerciseMutation(vars))
        .then((id) =>
          dispatch(
            showModal({
              message: t("ex_success_updated.message"),
              button: t("see_it.label"),
              link: "/dashboard/exercises/" + id,
            })
          )
        )
        .catch((err) =>
          dispatch(showModal({ message: err.message, button: "Ok" }))
        );
    }
  };

  return (
    <>
      <section className="main-content">
        <div className="container-fluid">
          <WizardNav
            first={t("upload_file.label")}
            firstLink="upload-file"
            second={t("information.label")}
            secondLink="information"
            third={t("overview.label")}
            thirdLink="overview"
            active1={exerciseState.firstStepValidity.isValid}
            active2={
              exerciseState.firstStepValidity.isValid &&
              exerciseState.secondStepValidity.isValid
            }
            active={3}
          />
          <div className="white-bg">
            <div className="row">
              <div className="col-md-12">
                <div className="single-detail">
                  <div className="thumb-slider">
                    <div className="carousel-priosan">
                      <Carousel
                        infiniteLoop={true}
                        autoPlay={false}
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false}
                      >
                        <div>
                          <img
                            alt="desc"
                            src={
                              get(
                                updateState,
                                "img",
                                get(reduxState, "img", null)
                              ) || placeholder
                            }
                          />
                        </div>
                        {get(updateState, "photos", []).map((el) => {
                          return (
                            <div key={el.id}>
                              <img alt="desc" src={el.id ? el.path : el} />
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                  <div className="content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <h3>
                            {get(
                              updateState,
                              "title",
                              get(reduxState, "title", "-")
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("difficulty.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "difficulty.name",
                              get(reduxState, "difficulty.name", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("bodypart.label")}</span>
                          {get(
                            updateState,
                            "bodyparts",
                            get(reduxState, "bodyparts", [])
                          ).length > 0 ? (
                            get(
                              updateState,
                              "bodyparts",
                              get(reduxState, "bodyparts", [])
                            ).map((e) => <p key={e.id}>{e.name}</p>)
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("group.label")}</span>
                          {get(
                            updateState,
                            "groups",
                            get(reduxState, "groups", [])
                          ).length > 0 ? (
                            get(
                              updateState,
                              "groups",
                              get(reduxState, "groups", [])
                            ).map((e) => <p key={e.id}>{e.name}</p>)
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("equipment.label")}</span>
                          {get(
                            updateState,
                            "equipments",
                            get(reduxState, "equipments", [])
                          ).length > 0 ? (
                            get(
                              updateState,
                              "equipments",
                              get(reduxState, "equipments", [])
                            ).map((e) => <p key={e.id}>{e.name}</p>)
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("device_settings.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "device_settings",
                              get(reduxState, "device_settings", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("resistance.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "resistance",
                              get(reduxState, "resistance", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("goal.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "goal",
                              get(reduxState, "goal", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("cave.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "cave",
                              get(reduxState, "cave", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("series.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "series",
                              get(reduxState, "series", 0)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="info">
                          <span>{t("termination_condition.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "termination_condition.name",
                              get(reduxState, "termination_condition.name", "-")
                            )}
                          </p>
                        </div>
                      </div>

                      {get(
                        updateState,
                        "termination_condition.id",
                        get(reduxState, "termination_condition.id", 3)
                      ) > 2 ? null : (
                        <div className="col-md-4">
                          <div className="info">
                            <span>
                              {t(
                                Object.keys(
                                  get(
                                    updateState,
                                    "termination_condition_value",
                                    get(
                                      reduxState,
                                      "termination_condition_value",
                                      {}
                                    )
                                  )
                                )[0] + ".label"
                              )}
                            </span>
                            <p>
                              {
                                get(
                                  updateState,
                                  "termination_condition_value",
                                  get(
                                    reduxState,
                                    "termination_condition_value",
                                    {}
                                  )
                                )[
                                  Object.keys(
                                    get(
                                      updateState,
                                      "termination_condition_value",
                                      get(
                                        reduxState,
                                        "termination_condition_value",
                                        {}
                                      )
                                    )
                                  )[0]
                                ]
                              }
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="info">
                          <span>{t("motion_execution.label")}</span>
                          {get(
                            updateState,
                            "motions",
                            get(reduxState, "motions", [])
                          ).map((el, index) =>
                            !el || !el.motion ? null : (
                              <div key={index + 1} className="steps">
                                <span>
                                  {t("step.label")} {index + 1}
                                </span>
                                <p>{el.motion}</p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-t-80">
              <div className="col-md-12">
                <div className="single-detail">
                  <div className="video-detail">
                    {get(
                      updateState,
                      "video",
                      get(reduxState, "video", null)
                    ) ? (
                      <ReactPlayer
                        width="100%"
                        url={get(
                          updateState,
                          "video",
                          get(reduxState, "video", null)
                        )}
                        controls={true}
                        playing={false}
                      />
                    ) : (
                      <div>
                        <img alt="desc" src={videoPlaceholder} />
                      </div>
                    )}
                  </div>
                  <div className="content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="info">
                          <span>{t("notes.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "note",
                              get(reduxState, "note", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="info">
                          <span>{t("description.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "description",
                              get(reduxState, "description", "-")
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="info">
                          <span>{t("recommendations.label")}</span>
                          <p>
                            {get(
                              updateState,
                              "recommendations",
                              get(reduxState, "recommendations", "-")
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-end">
                        <div className="btn-right">
                          <Link
                            to="information"
                            className="btn btn-cancel btn-180"
                          >
                            {t("back.label")}
                          </Link>
                          <button
                            disabled={
                              !(
                                exerciseState.firstStepValidity.isValid &&
                                exerciseState.secondStepValidity.isValid
                              )
                            }
                            href="information"
                            onClick={(e) => createExercise(e)}
                            className="btn btn-primary btn-180"
                            data-toggle="modal"
                            data-target="#modal"
                          >
                            {exerciseState.updateFlag
                              ? t("update.label")
                              : t("create.label")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Modal
                            show={exerciseState.modal}
                            loading={exerciseState.loading}
                            error={exerciseState.error}
                            text={`Exercise created successfully.`}
                            history={props.history}
                            path={"exercises"}
                            id={exerciseState.id}
                            item={"exercise"}
                            closeModal={() => dispatch(exercise({ modal: false }))}
                        /> */}
          </div>
        </div>
      </section>
    </>
  );
};

Overview.propTypes = {
  history: PropTypes.object,
};

export default Overview;
