import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { reportFilter } from "../../../store/reports/reports_actions";

import AllReports from "./allReports";
import Search from "../../../components/UI/Search/Search";

const Report = () => {
    const { t } = useTranslation();
    const reportState = useSelector(s => s.reportState);
    const dispatch = useDispatch();
    const filters = reportState.filters;

    let searchHandler = e => {
        dispatch(
            reportFilter({
                search: e.target.value,
                offset: 0,
                page: 1
            })
        );
    };

    // eslint-disable-next-line no-unused-vars
    let statusHandler = e => {
        dispatch(
            reportFilter({
                status: e,
                offset: 0,
                page: 1
            })
        );
    };

    return (
        <section className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        <div className="training-result">
                            <p>
                                {t("total.label")}:{" "}
                                <span>
                                    {reportState.totalReports || 0} {t("reports.label")}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-between training-filter">
                    <div className="col-sm-12 col-md-6 col-lg-5 col-xl-3">
                        <div className="row">
                            <Search searchHandler={searchHandler} querySearch={filters.search} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <AllReports />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        {reportState.totalReports > 0 ? (
                            <ReactPaginate
                                previousLabel={<i className="icon-arrow-left"></i>}
                                nextLabel={<i className="icon-arrow-right"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(reportState.totalReports / 20) || 0}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={e =>
                                    dispatch(
                                        reportFilter({
                                            offset: e.selected * 20,
                                            page: parseInt(e.selected) + 1
                                        })
                                    )
                                }
                                forcePage={filters.page - 1}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                activeLinkClassName={"active"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

Report.propTypes = {
    history: PropTypes.object
};

export default Report;
