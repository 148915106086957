export const TEST = `TEST`;
export const test = value => {
    return {
        type: TEST,
        value
    };
};

export const SINGLE_TEST = `SINGLE_TEST`;
export const singleTest = value => {
    return {
        type: SINGLE_TEST,
        value
    };
};

export const UPDATE_TEST = `UPDATE_TEST`;
export const updateTest = value => {
    return {
        type: UPDATE_TEST,
        value
    };
};

export const TEST_FILTERS = `TEST_FILTERS`;
export const testFilter = value => {
    return {
        type: TEST_FILTERS,
        value
    };
};

export const TEST_LOADING = `TEST_LOADING`;
export const testLoading = value => {
    return {
        type: TEST_LOADING,
        value
    };
};

export const TEST_ERRORS = `TEST_ERRORS`;
export const testErrors = value => {
    return {
        type: TEST_ERRORS,
        value
    };
};

export const RESET_TEST_STATE = `RESET_TEST_STATE`;
export const resetTestState = value => {
    return {
        type: RESET_TEST_STATE,
        value
    };
};

export const RESET_TEST_FILTER_STATE = `RESET_TEST_FILTER_STATE`;
export const resetTestFilterState = value => {
    return {
        type: RESET_TEST_FILTER_STATE,
        value
    };
};

export const TEST_STATUS_CHANGED = `TEST_STATUS_CHANGED`;
export const testStatusChanged = value => {
    return {
        type: TEST_STATUS_CHANGED,
        value
    };
};

export const DELETE_TEST = `DELETE_TEST`;
export const deleteTest = () => {
    return {
        type: DELETE_TEST
    };
};
