export const CHANGE_GLOBAL_STATE = `CHANGE_GLOBAL_STATE`;
export const changeGlobalState = value => {
    return {
        type: CHANGE_GLOBAL_STATE,
        value
    };
};

export const TOGGLE_LOADING_ON = `TOGGLE_LOADING_ON`;
export const toggleLoadingOn = value => {
    return {
        type: TOGGLE_LOADING_ON,
        value
    };
};

export const TOGGLE_MENU = `TOGGLE_MENU`;
export const toggleMenu = value => {
    return {
        type: TOGGLE_MENU,
        value
    };
};

export const SHOW_MODAL = `SHOW_MODAL`;
export const showModal = (value, closeModal) => {
    return {
        type: SHOW_MODAL,
        value,
        closeModal
    };
};

export const SHOW_CONTENT_MODAL = `SHOW_CONTENT_MODAL`;
export const HIDE_CONTENT_MODAL = `HIDE_CONTENT_MODAL`;

export const HIDE_MODAL = `HIDE_MODAL`;
export const hideModal = () => {
    return {
        type: HIDE_MODAL
    };
};

export const TOGGLE_LOADING_OFF = `TOGGLE_LOADING_OFF`;
export const toggleLoadingOff = value => {
    return {
        type: TOGGLE_LOADING_OFF,
        value
    };
};
