import { client } from "../../../helpers/index";
import { company, companyLoading, companyError, singleCompany } from "../../../store/user/company/company_actions";
import { FetchCompany, CompanyListQuery } from "../../../resolvers/query/users";
import { addCompanyMutation, editCompanyMutation } from "../../../resolvers/mutation/user";
import { toggleLoadingOn, toggleLoadingOff } from "../../../store/global/global_actions";

export const FetchAllComapniesQuery = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .query({
                query: CompanyListQuery,
                variables
            })
            .then(({ data, errors }) => {
                if (errors) {
                    let err = [];
                    errors.map(mes => err.push(mes.message));
                    dispatch(companyError({ array: err }));
                }
                if (data) {
                    let {
                        searchCompanies: { totalCount, allUsers }
                    } = data;
                    dispatch(company({ allCompanies: allUsers, totalCompanies: totalCount, loading: false }));
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    dispatch(companyError({ array: errors }));
                } else {
                    dispatch(companyError({ array: err }));
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const FetchCompanyByIdQuery = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .query({
                query: FetchCompany,
                variables
            })
            .then(({ data, errors }) => {
                if (errors) {
                    let err = [];
                    errors.map(mes => err.push(mes.message));
                    dispatch(companyError({ array: err }));
                }
                if (data) {
                    let { fetchUser } = data;
                    dispatch(
                        singleCompany({
                            ...fetchUser,
                            id: parseInt(fetchUser.id),
                            loading: false,
                            errors: { array: [] },
                            modal: false
                        })
                    );
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    const errors = err.graphQLErrors.map(mes => mes.message);
                    dispatch(companyError({ array: errors }));
                } else {
                    dispatch(companyError({ array: err }));
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const CreateCompanyMutation = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: addCompanyMutation,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    dispatch(
                        singleCompany({
                            ...data.addCompany,
                            id: parseInt(data.addCompany.id)
                        })
                    );
                    return parseInt(data.addCompany.id);
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const UpdateCompanyMutation = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: editCompanyMutation,
                variables,
                refetchQueries: []
            })
            .then(({ data, errors }) => {
                if (errors) {
                    let err = [];
                    errors.map(mes => err.push(mes.message));
                    dispatch(companyError({ array: err }));
                }
                if (data) {
                    dispatch(
                        singleCompany({
                            ...data.editUser,
                            id: parseInt(data.editUser.id)
                        })
                    );
                    return parseInt(data.editUser.id);
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};
