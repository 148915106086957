/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import VideoUpload from "./VideoUpload";

import {
  updateExercise,
  exercise,
} from "../../../../store/exercise/exercise_actions";
import WizardNav from "../../../../components/UI/WizardNav/WizardNav";

const UploadFile = () => {
  const { t } = useTranslation();
  const exerciseState = useSelector((s) => s.exerciseState);
  const reduxState = exerciseState.singleExercise;
  const updateState = exerciseState.updateExercise;

  const dispatch = useDispatch();
  const errors = exerciseState.firstStepValidity.errors;

  /**
   *
   * @param {Event} e
   */
  let formChangeHandler = (e) => {
    dispatch(updateExercise({ name: e.target.name, value: e.target.value }));
  };

  /**
   *
   * @param {Event} e
   */
  let uploadMainPhotoHandler = (e) => {
    dispatch(updateExercise({ name: "imgFile", value: e.target.files[0] }));
    dispatch(
      updateExercise({
        name: "img",
        value: URL.createObjectURL(e.target.files[0]),
      })
    );
  };

  /**
   *
   * @param {Event} e
   */
  let uploadMultiplePhotoHandler = (e) => {
    let photos = [...get(updateState, "photos", [])];
    let photosFiles = [...get(updateState, "photosFiles", [])];
    let uploads = [...e.target.files];
    // eslint-disable-next-line array-callback-return
    uploads.map((e) => {
      photos.push(URL.createObjectURL(e));
      photosFiles.push(e);
    });
    dispatch(updateExercise({ name: "photos", value: photos }));
    dispatch(updateExercise({ name: "photosFiles", value: photosFiles }));
  };

  /**
   *
   * @param {Event} e
   * @param {Integer} index
   */
  let removePhotoHandler = (e, index) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let photos = [...get(updateState, "photos", [])];
    let photosFiles = [...get(updateState, "photosFiles", [])];
    let photosToDelete = [...get(updateState, "photosToDelete", [])];

    if (photos[index].id) {
      photosToDelete.push(photos[index].id);
    } else {
      photosFiles.splice(index, 1);
    }
    photos.splice(index, 1);

    dispatch(updateExercise({ name: "photos", value: photos }));
    dispatch(updateExercise({ name: "photosFiles", value: photosFiles }));
    dispatch(updateExercise({ name: "photosToDelete", value: photosToDelete }));
  };

  /**
   *
   * @param {Event} e
   * @param {Integer} index
   */
  let removeExistingPhotoHandler = (e, index) => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let photos = [...get(reduxState, "photos", [])];
    let photosToDelete = [...get(updateState, "photosToDelete", [])];
    let imageToRemove = parseInt(photos[index].id);
    photosToDelete.push(imageToRemove);
    dispatch(updateExercise({ photosToDelete }));
  };

  /**
   *
   * @param {Event} e
   */
  let videoUploadHandler = (e) => {
    console.log({ targetCo: e.target });

    let video = e.target.files[0];

    dispatch(updateExercise({ name: "videoFile", value: video }));
    dispatch(
      updateExercise({
        name: "video",
        value: video ? URL.createObjectURL(video) : null,
      })
    );
    dispatch(updateExercise({ name: "deleteVideo", value: !video }));
  };

  /**
   *
   * @param {Event} e
   */
  let submitHandler = (e) => {
    if (!exerciseState.firstStepValidity.isValid) {
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);
      dispatch(exercise({ displayUploadFileErrors: true }));
    }
    return;
  };

  return (
    <section className="main-content">
      <WizardNav
        first={t("upload_file.label")}
        firstLink="upload-file"
        second={t("information.label")}
        secondLink="information"
        third={t("overview.label")}
        thirdLink="overview"
        active1={exerciseState.firstStepValidity.isValid}
        active2={
          exerciseState.firstStepValidity.isValid &&
          exerciseState.secondStepValidity.isValid
        }
        active={1}
      />
      <div className="container-fluid">
        <form>
          <div className="training-form">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="main-file-upload excercises-upload">
                      <div className="file-upload-title">
                        <h5>{t("main_image.label")}</h5>
                      </div>
                      <div className="file-upload">
                        <div className="image-upload-wrap">
                          <input
                            maxLength={250}
                            id="mainImageInput"
                            type="file"
                            className="file-upload-input"
                            accept="image/*"
                            onChange={(e) => uploadMainPhotoHandler(e)}
                          />
                          {!get(
                            updateState,
                            "img",
                            get(reduxState, "img", null)
                          ) ? (
                            <div className="drag-text">
                              <i className="icon-picture"></i>
                              <span>{t("dragndrop.label")}</span>
                            </div>
                          ) : (
                            <img
                              alt="uploaded img"
                              style={{
                                width: "100%",
                                objectFit: "cover",
                                height: "100%",
                              }}
                              src={get(
                                updateState,
                                "img",
                                get(reduxState, "img", null)
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="button-upload">
                        <a
                          href="/"
                          className="btn btn-upload"
                          onClick={(e) => {
                            e.preventDefault
                              ? e.preventDefault()
                              : (e.returnValue = false);
                            document.getElementById("mainImageInput").click();
                          }}
                        >
                          {t("upload_img.label")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <label>
                        {t("title.label")}
                        <span className="form-required">*</span>
                      </label>
                      <input
                        maxLength={250}
                        onChange={(e) => formChangeHandler(e)}
                        value={get(
                          updateState,
                          "title",
                          get(reduxState, "title", "")
                        )}
                        type="text"
                        name="title"
                        className="form-control"
                      />
                      {!exerciseState.displayUploadFileErrors ? null : (
                        <p style={{ color: "red", fontSize: "15px" }}>
                          {errors.title}
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <label>{t("description.label")}</label>
                      <textarea
                        maxLength={800}
                        value={get(
                          updateState,
                          "description",
                          get(reduxState, "description", "")
                        )}
                        onChange={(e) => formChangeHandler(e)}
                        name="description"
                        className="form-control"
                        rows="3"
                      ></textarea>
                      {!exerciseState.displayUploadFileErrors ? null : (
                        <p style={{ color: "red", fontSize: "15px" }}>
                          {errors.description}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-file-upload excercises-upload">
                      <div className="file-upload-title">
                        <h5>{t("gallery_images.label")}</h5>
                      </div>
                      <div className="file-upload">
                        <div className="image-upload-wrap">
                          <input
                            maxLength={250}
                            id="galleryImageUpload"
                            type="file"
                            className="file-upload-input"
                            accept="image/*"
                            multiple
                            onChange={(e) => uploadMultiplePhotoHandler(e)}
                          />
                          <div className="drag-text">
                            <i className="icon-picture"></i>
                            <span>{t("dragndrop.label")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="button-upload">
                        <a
                          href="/"
                          className="btn btn-upload"
                          onClick={(e) => {
                            e.preventDefault
                              ? e.preventDefault()
                              : (e.returnValue = false);
                            document
                              .getElementById("galleryImageUpload")
                              .click();
                          }}
                        >
                          {t("upload_img.label")}
                        </a>
                      </div>
                    </div>
                    <div className="gallery-upload-image">
                      {get(updateState, "photos", []).map((photo, index) => {
                        return (
                          <div key={index} className="gallery-image">
                            <img
                              alt={index + 1}
                              src={photo.id ? photo.path : photo}
                            />
                            <a
                              onClick={(e) => removePhotoHandler(e, index)}
                              href="/"
                              className="remove-item"
                            >
                              {t("remove.label")}{" "}
                              <i className="icon-cancel"></i>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <VideoUpload
                      src={get(
                        updateState,
                        "video",
                        get(reduxState, "video", "")
                      )}
                      onChange={videoUploadHandler}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-end">
                    <div className="btn-right">
                      <Link
                        to={
                          "/dashboard/exercises" +
                          (reduxState.id ? "/" + reduxState.id : "")
                        }
                        className="btn btn-cancel btn-180"
                      >
                        {t("cancel.label")}
                      </Link>
                      <Link
                        to="information"
                        className="btn btn-primary btn-180"
                        onClick={submitHandler}
                      >
                        {t("next.label")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

UploadFile.propTypes = {
  history: PropTypes.object,
};

export default UploadFile;
