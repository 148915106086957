import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import WizardNav from "../../../../components/UI/WizardNav/WizardNav";
import SingleTrainingplan from "../../../../components/Trainingplan/SingleTrainingplan";
import { UpdateTrainingPlanMutation } from "../GraphQLActions";
import { showModal, HIDE_CONTENT_MODAL } from "../../../../store/global/global_actions";
import ContentModalMiddleware from "../../Middlewares/ContentModalMiddleware";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const Overview = props => {
    const { t } = useTranslation();
    const planState = useSelector(s => s.planState);
    const reduxState = planState.singleTrainingPlan;
    const updateState = planState.updateTrainingPlan;

    const globalState = useSelector(s => s.globalState);
    const dispatch = useDispatch();

    let updateTrainingplanHandler = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        let exercises = [];
        //Existing exercises
        get(reduxState, "exercises", "").map((e, index) =>
            exercises.push({
                master: parseInt(e.id),
                order: index + 1
            })
        );
        //New exercises
        get(updateState, "exercises", []).map((e, index) =>
            exercises.push({
                master: parseInt(e.id),
                order: get(reduxState, "exercises", []).length + index + 1
            })
        );

        let bodyparts = [];
        get(updateState, "bodyparts", []).map(e => bodyparts.push(parseInt(e.id)));
        let trainingperiods = [];
        get(updateState, "trainingperiods", []).map(e => trainingperiods.push(parseInt(e.id)));
        let phases = [];
        get(updateState, "phases", []).map(e => phases.push(parseInt(e.id)));
        let equipments = [];
        get(updateState, "equipments", []).map(e => equipments.push(parseInt(e.id)));

        dispatch(
            UpdateTrainingPlanMutation({
                ...updateState,
                id: parseInt(reduxState.id),
                img: updateState.imgFile,
                bodyparts,
                trainingperiods,
                phases,
                equipments,
                exercises,
                difficulty: parseInt(get(updateState, "difficulty.id", null))
            })
        )
            .then(id =>
                dispatch(
                    showModal({
                        type: 2,
                        message: t("tp_success_updated.label"),
                        button: t("add_for_client.label"),
                        link: "/dashboard/trainingplans/" + id
                    })
                )
            )
            .catch(err =>
                dispatch(
                    showModal({
                        message: err.message,
                        button: "Ok"
                    })
                )
            );
    };

    if (!planState.isValidGeneralInfo.isValid) {
        props.history.push("general-info");
        return null;
    }
    if (!planState.isValidSelectExercises) {
        props.history.push("select-exercises");
        return null;
    }

    return (
        <section className="main-content">
            <WizardNav
                first={t("general_info.label")}
                firstLink="general-info"
                second={t("select_exercises.label")}
                secondLink="select-exercises"
                third={t("overview.label")}
                thirdLink="overview"
                active1
                active={3}
            />
            <ContentModalMiddleware
                parent={"PLAN"}
                parentId={parseInt(reduxState.id)}
                content={"USER_LIST"}
                isMutating
                show={globalState.contentModal}
                closeModal={() => dispatch({ type: HIDE_CONTENT_MODAL })}
            />
            <SingleTrainingplan forOverview={true} update={true} submitHandler={e => updateTrainingplanHandler(e)} />
        </section>
    );
};

Overview.propTypes = {
    history: PropTypes.object
};

export default Overview;
