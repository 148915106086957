import React from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

const VideoUpload = ({
  src,
  hasControls = true,
  isAutoPlayed = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const videoUploadHandler = (e) => {
    console.log({ e });
    onChange(e);
  };

  return (
    <div className="main-file-upload excercises-upload">
      <div className="file-upload-title">
        <h5>{t("video.label")}</h5>
      </div>
      <div style={{ cursor: "default" }} className="file-upload">
        <div className="image-upload-wrap">
          <input
            maxLength={250}
            id="videoUpload"
            type="file"
            className="file-upload-input"
            accept="video/*"
            style={{ display: "none" }}
            onChange={(e) => videoUploadHandler(e)}
          />
          {!src ? (
            <div
              onClick={() => {
                document.getElementById("videoUpload").click();
              }}
              style={{ cursor: "pointer" }}
              className="drag-text"
            >
              <i className="icon-video"></i>
              <span>{t("dragndrop.label")}</span>
            </div>
          ) : (
            <ReactPlayer
              width="100%"
              url={src}
              controls={hasControls}
              playing={isAutoPlayed}
            />
          )}
        </div>
      </div>
      <div className="button-upload">
        <a
          onClick={(e) => {
            e.preventDefault ? e.preventDefault() : (e.returnValue = false);
            src
              ? videoUploadHandler({ target: { files: [null] } })
              : document.getElementById("videoUpload").click();
          }}
          href="/"
          className="btn btn-upload"
        >
          {src ? t("remove_video.label") : t("upload_video.label")}
        </a>
      </div>
    </div>
  );
};

export default VideoUpload;
