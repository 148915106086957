import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { DEFAULT_LOCALE, getLocale } from "./constants";
import en from "./translations/en.json";
import de from "./translations/de.json";

i18n.use(initReactI18next).init({
    lng: getLocale(),
    fallbackLng: DEFAULT_LOCALE,
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: { escapeValue: false, formatSeparator: "," },
    react: { wait: true },
    resources: { EN: { translations: en }, DE: { translations: de } }
});

export default i18n;
