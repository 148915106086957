import { client } from "../../../helpers/index";
import { trainer, singleTrainer, trainerStatusChanged } from "../../../store/user/trainer/trainer_actions";
import { FetchTrainer, TrainerListQuery } from "../../../resolvers/query/users";
import {
    addTrainerMutation,
    editTrainerMutation,
    toggleCompanyAdminPrivileges,
    ToggleTrainerStatus
} from "../../../resolvers/mutation/user";
import { toggleLoadingOn, toggleLoadingOff } from "../../../store/global/global_actions";

export const FetchAllTrainersQuery = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .query({
                query: TrainerListQuery,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let {
                        searchTrainers: { totalCount, allUsers }
                    } = data;
                    dispatch(trainer({ allTrainers: allUsers, totalTrainers: totalCount, loading: false }));
                    return true;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const FetchTrainerByIdQuery = variables => {
    return dispatch => {
        // dispatch(trainer({ loading: true, errors: null }));
        dispatch(toggleLoadingOn());
        return client
            .query({
                query: FetchTrainer,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { fetchUser } = data;
                    dispatch(
                        singleTrainer({
                            ...fetchUser,
                            id: parseInt(fetchUser.id),
                            company: {
                                ...fetchUser.company,
                                id: parseInt(fetchUser.company.id)
                            },
                            gender: fetchUser.gender || {}
                        })
                    );
                    return true;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const CreateTrainerMutation = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: addTrainerMutation,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { addTrainer } = data;
                    dispatch(
                        singleTrainer({
                            ...addTrainer,
                            id: parseInt(addTrainer.id),
                            company: {
                                ...addTrainer.company,
                                id: parseInt(addTrainer.company.id)
                            }
                        })
                    );
                    return parseInt(addTrainer.id);
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const UpdateTrainerMutation = variables => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: editTrainerMutation,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { editUser } = data;
                    dispatch(
                        singleTrainer({
                            ...editUser,
                            id: parseInt(editUser.id),
                            company: {
                                ...editUser.company,
                                id: parseInt(editUser.company.id)
                            }
                        })
                    );
                    return parseInt(editUser.id);
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};

export const ToggleCompanyAdminPrivilegesMutation = (variables, is_admin) => {
    return dispatch => {
        dispatch(toggleLoadingOn());
        return client
            .mutate({
                mutation: toggleCompanyAdminPrivileges,
                variables
            })
            .then(({ data }) => {
                if (data) {
                    let { toggleCompanyAdminPrivileges } = data;
                    dispatch(toggleLoadingOff());
                    dispatch(singleTrainer({ is_admin: !is_admin }));
                    return toggleCompanyAdminPrivileges;
                }
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            })
            .finally(() => dispatch(toggleLoadingOff()));
    };
};


export const ToggleTrainerStatusMutation = (variables) => {
    return (dispatch) => {
      dispatch(toggleLoadingOn());
      client
        .mutate({ mutation: ToggleTrainerStatus, variables })
        .then(() => dispatch(trainerStatusChanged(variables))) 
        .catch((err) => {
          if (err.graphQLErrors && err.graphQLErrors[0]) {
            throw {
              statusCode: err.graphQLErrors[0].statusCode,
              message: err.graphQLErrors[0].message,
            };
          } else {
            throw err;
          }
        })
        .finally(() => dispatch(toggleLoadingOff()));
    };
  };
  