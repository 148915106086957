import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import get from "lodash/get";
import moment from "moment";

import placeholder from "../../assets/img/placeholders/400x200.png";
import { getApiUrl } from "../../constants";
import ContentModalMiddleware from "../../containers/dashboard/Middlewares/ContentModalMiddleware";
import { getToken } from "../../helpers";
import QuestionList from "components/Tests/QuestionList";
import AnswerList from "components/Tests/AnswerList";
import ConfirmModal from "../UI/Modal/ConfirmModal";
import { hasUpdateAccess } from "../../helpers/hasUpdateAccess";

const SingleTest = ({
  test,
  isUpdate,
  submitHandler,
  onToggleTestStatus,
  deleteTestHandler,
}) => {
  const { t } = useTranslation();
  const [modal, toggleModal] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const user = useSelector((state) => state.authState);

  let calculateDuration = (time) => {
    let hours = Math.floor(time / 60);
    let minutes = time % 60;
    return `${hours} h ${minutes} m`;
  };

  let apiUrl = getApiUrl();
  let pdfUrl =
    test.__typename === "Test" ? "test/" + test.id : "taken-test/" + test.id;
  pdfUrl += "?token=" + getToken.token;

  return (
    <>
      <ContentModalMiddleware
        parent={"TEST"}
        parentId={parseInt(test.id)}
        content={"USER_LIST"}
        show={modal}
        closeModal={() => toggleModal(false)}
      />
      <div className="container-fluid">
        <div className="white-bg">
          <div className="row">
            <div className="col-md-12">
              {isUpdate ? null : (
                <div className="single-detail-head single-head-left-right">
                  <div className="single-head-flex">
                    <div className="single-head-left">
                      <div className="form-toggle form-toggle-checked">
                        {hasUpdateAccess(user, test) && (
                          <label
                            className="form-toggle-switch"
                            htmlFor="status"
                          >
                            <input
                              type="checkbox"
                              id="status"
                              defaultChecked={test.status === "ACTIVE"}
                              onChange={onToggleTestStatus}
                            />
                            <div className="form-toggle-switch-slider form-toggle-switch-round"></div>
                          </label>
                        )}
                        <span
                          className={
                            test.status === "ACTIVE" ? "active" : "inactive"
                          }
                        >
                          {test.status === "ACTIVE" ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>
                    <div className="single-head-right">
                      {user.role.id !== 1 && !test.creator ? null : (
                        <Link
                          to={`/dashboard/tests/${get(
                            test,
                            "id",
                            ""
                          )}/edit-test/general-info`}
                        >
                          <i className="icon-edit"></i>
                          <span>{t("edit.label")}</span>
                        </Link>
                      )}
                      <a
                        href={apiUrl + pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-print"></i>
                        <span>{t("print.label")}</span>
                      </a>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleModal(true);
                        }}
                        className="add"
                      >
                        <i className="icon-add"></i>
                        <span>{t("add_for_client.label")}</span>
                      </a>
                      {user.role.id !== 1 && !test.creator ? null : (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeleteModal(true);
                          }}
                        >
                          <i className="icon-cancel"></i>
                          <span>Delete</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="single-detail">
                <div className="img">
                  <img
                    alt="main"
                    src={get(test, "img", false) || placeholder}
                  />
                  <div>
                    <br />
                    <br />
                  </div>
                  {get(test, "video", "") ? (
                    <ReactPlayer
                      width="100%"
                      url={get(test, "video", "")}
                      controls={true}
                      playing={false}
                    />
                  ) : null}
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <h3>{get(test, "title", "-")}</h3>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="head">
                        <div className="icons">
                          <div>
                            <div className="icon">
                              <i className="icon-time"></i>
                            </div>
                            <div className="text">
                              <span>{t("duration.label")}</span>
                              <p>
                                {calculateDuration(
                                  parseInt(get(test, "duration", 0))
                                )}
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="icon">
                              <i className="icon-level"></i>
                            </div>
                            <div className="text">
                              <span>{t("difficulty.label")}</span>
                              <p>{get(test, "difficulty.name", "-")}</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="status">
                                                        <span className="status-active">Active</span>
                                                    </div> */}
                      </div>
                    </div>
                    {test.description && (
                      <div className="col-md-12">
                        <div className="info">
                          <span>{t("description.label")}</span>
                          <p>{test.description}</p>
                        </div>
                      </div>
                    )}
                    {test.answered && (
                      <div className="col-md-12">
                        <div className="info">
                          <span>{t("answered.label")}</span>
                          <p>
                            {moment(test.answered).format("YYYY-MM-DD hh:mm")}
                          </p>
                          <p>{}</p>
                        </div>
                      </div>
                    )}
                    <QuestionList
                      type={get(test, "type", {})}
                      questions={get(test, "questions", [])}
                    />
                    {test.answered && (
                      <AnswerList
                        type={get(test, "type", {})}
                        questions={get(test, "questions", [])}
                        userAnswers={get(test, "user_answers", [])}
                        userOptionAnswers={get(test, "user_option_answers", [])}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!isUpdate ? null : (
                <div className="row d-flex justify-content-end">
                  <div className="col-md-12 d-flex justify-content-end">
                    <div className="btn-right">
                      <Link to={"questions"} className="btn btn-cancel btn-180">
                        {t("back.label")}
                      </Link>
                      <a
                        href="information"
                        onClick={submitHandler}
                        className="btn btn-primary btn-180"
                        data-toggle="modal"
                        data-target="#modal"
                      >
                        {!+test.id ? t("create.label") : t("update.label")}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={deleteModal}
        confirmButton="Delete"
        rejectHandler={() => setDeleteModal(false)}
        confirmHandler={deleteTestHandler}
        message="Are you sure you want to delete this test?"
      />
    </>
  );
};

SingleTest.propTypes = {
  test: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool,
  submitHandler: PropTypes.func,
  onToggleTestStatus: PropTypes.func,
  deleteTestHandler: PropTypes.func,
};

export default SingleTest;
