/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import get from "lodash/get";
import { useQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import uuid from "uuid";
import { useTranslation } from "react-i18next";

import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import { Select } from "../../../../components/UI";
import { searchBaseModels } from "../../../../resolvers/query/base";
import { valueHandler } from "./helpers";
import { updateUser as updateUserAction } from "../../../../store/user/user/user_actions";

const Private = () => {
    const { t } = useTranslation();
    const { updateUser } = useSelector(s => s.userState);
    const dispatch = useDispatch();

    //Redux state
    const componentState = get(updateUser, "private_info", {});

    //Errors for component
    let { displayErrors } = updateUser.private_info;
    displayErrors = displayErrors || updateUser.displayErrors;
    let componentErrors = { ...get(componentState, "errors", {}) };
    /**
     *
     * @param {Event} e
     */
    const displayErrorsFunc = e => {
        let keys = [...Object.keys(componentErrors)];
        if (keys.length > 0) {
            e.preventDefault();
            dispatch(updateUserAction({ name: "private_info.displayErrors", value: true }));
        }
    };

    let PID = get(componentState, "emergency_contact_PID", false);
    useEffect(() => {
        if (!PID) {
            let UUiD = uuid();
            dispatch(updateUserAction({ name: "private_info.emergency_contact_PID", value: UUiD }));
        }
    }, [PID, dispatch]);

    let childrenOptions = [];
    for (let i = 0; i < 15; i++) {
        childrenOptions.push({ id: i + 1, name: i + 1 + "" });
    }

    let selectItems = useQuery(searchBaseModels, {
        variables: {
            civil_status: true,
            title: true,
            additional_title: true
        }
    });

    return (
        <section className="main-content">
            <ClientMenu componentName={"private_info"} componentNumber={3} clickHandler={displayErrorsFunc} />
            <div className="container-fluid">
                <form
                    onChange={e => {
                        let req = valueHandler(e);
                        dispatch(updateUserAction({ name: "private_info." + req.name, value: req.value }));
                    }}
                >
                    <div className="client-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="client-form-title">
                                    <h1>{t("private_info.label")}</h1>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("title.label")}</label>
                                    <Select
                                        name={"title"}
                                        selectedItem={get(componentState, "title.id", "").toString()}
                                        select={e => e}
                                        items={get(selectItems, "data.searchBaseModels.Title", [])}
                                        // text={"Select title"}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.title}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("additional_title.label")}</label>
                                    <Select
                                        name={"additional_title"}
                                        selectedItem={get(componentState, "additional_title.id", "").toString()}
                                        select={e => e}
                                        items={get(selectItems, "data.searchBaseModels.AdditionalTitle", [])}
                                        // text={"Select additional title"}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.additional_title}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("website.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "website", "")}
                                        type="text"
                                        name="website"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.website}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("landline.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "landline_alt", "")}
                                        type="text"
                                        name="landline_alt"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.landline_alt}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("email_alternative.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "mail_alt", "")}
                                        type="text"
                                        name="mail_alt"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.mail_alt}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("phone_alternative.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "tel_alt", "")}
                                        type="text"
                                        name="tel_alt"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.tel_alt}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("fax.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "fax", "")}
                                        type="text"
                                        name="fax"
                                        className="form-control"
                                    />
                                    {displayErrors ? <span style={{ color: "red" }}>{componentErrors.fax}</span> : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("civil_status.label")}</label>
                                    <Select
                                        name={"civil_status"}
                                        selectedItem={get(componentState, "civil_status.id", "").toString()}
                                        select={e => e}
                                        items={get(selectItems, "data.searchBaseModels.CivilStatus", [])}
                                        // text={"Select civil status"}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.civil_status}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("hometown.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "hometown", "")}
                                        type="text"
                                        name="hometown"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.hometown}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("childhood_surname.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "name_unmarried", "")}
                                        type="text"
                                        name="name_unmarried"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.name_unmarried}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("no_of_children.label")}</label>
                                    <Select
                                        name={"number_of_children"}
                                        selectedItem={get(
                                            componentState,
                                            "number_of_children.id",
                                            get(componentState, "number_of_children", "")
                                        )}
                                        select={e => e}
                                        items={childrenOptions}
                                        // text={"Select number of children"}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.number_of_children}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("emergency_contact_name.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "emergency_contact", "")}
                                        type="text"
                                        name="emergency_contact"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.emergency_contact}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("pid_emergency_contact.label")}</label>
                                    <input
                                        disabled
                                        defaultValue={get(componentState, "emergency_contact_PID", "")}
                                        type="text"
                                        name="emergency_contact_PID"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.emergency_contact_PID}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("remark_emergency_contact.label")}</label>
                                    <input
                                        defaultValue={get(componentState, "remark_emergency_contact", "")}
                                        type="text"
                                        name="remark_emergency_contact"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.remark_emergency_contact}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("emergency_tel.label")} 1</label>
                                    <input
                                        defaultValue={get(componentState, "emergency_telephone1", "")}
                                        type="text"
                                        name="emergency_telephone1"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.emergency_telephone1}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("remark_emergency_tel.label")} 1</label>
                                    <input
                                        defaultValue={get(componentState, "remark_emergency_telephone1", "")}
                                        type="text"
                                        name="remark_emergency_telephone1"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>
                                            {componentErrors.remark_emergency_telephone1}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("emergency_tel.label")} 2</label>
                                    <input
                                        defaultValue={get(componentState, "emergency_telephone2", "")}
                                        type="text"
                                        name="emergency_telephone2"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.emergency_telephone2}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("remark_emergency_tel.label")} 2</label>
                                    <input
                                        defaultValue={get(componentState, "remark_emergency_telephone2", "")}
                                        type="text"
                                        name="remark_emergency_telephone2"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>
                                            {componentErrors.remark_emergency_telephone2}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t("remark_private_info.label")}</label>
                                    <textarea
                                        defaultValue={get(componentState, "remark_private_info", "")}
                                        name="remark_private_info"
                                        className="form-control"
                                        rows="5"
                                    ></textarea>
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{componentErrors.remark_private_info}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                                <div className="btn-right">
                                    <Link to="personal" className="btn btn-cancel btn-180">
                                        {t("back.label")}
                                    </Link>
                                    <Link onClick={displayErrorsFunc} to="finance" className="btn btn-primary btn-180">
                                        {t("next.label")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

Private.propTypes = {
    changeMasterStateHandler: PropTypes.func,
    formChangeHandler: PropTypes.func,
    state: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(Private);
