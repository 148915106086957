import { client } from "../../../helpers/index";
import { report } from "../../../store/reports/reports_actions";
import { ReportListQuery } from "../../../resolvers/query/reports";

export const FetchAllReportsQuery = variables => {
    return dispatch => {
        dispatch(report({ loading: true, errors: { array: [] } }));
        return client
            .query({
                query: ReportListQuery,
                variables
            })
            .then(({ data, errors }) => {
                if (errors) {
                    let err = [];
                    errors.map(mes => err.push(mes.message));
                    dispatch(report({ loading: false, errors: err }));
                }
                if (data) {
                    let {
                        searchAllReports: { totalCount, allReports }
                    } = data;
                    dispatch(report({ allReports: allReports, totalReports: totalCount, loading: false }));
                }
            })
            .catch(err => {
                const errors = err.graphQLErrors.map(mes => mes.message);
                dispatch(report({ errors: err, loading: false }));
            });
    };
};

export const FetchReportByIdQuery = variables => {
    // return dispatch => {
    //     return client.query({
    //         query: SingleTestQuery,
    //         variables
    //     }).then(({ data, errors }) => {
    //         if (errors) {
    //             errors = errors.map(mes => mes.message);
    //             dispatch(test({ errors: errors, loading: false }))
    //         }
    //         if (data) {
    //             let { fetchTest } = data;
    //             let questions = []
    //             fetchTest.Questions.map(e =>
    //                 questions.push({
    //                     id: e.id,
    //                     content: e.content,
    //                     unit: e.Unit
    //                 }))
    //             dispatch(test({
    //                 id: fetchTest.id,
    //                 title: fetchTest.title,
    //                 description: fetchTest.description,
    //                 duration: fetchTest.duration,
    //                 noOfQuestions: fetchTest.noOfQuestions,
    //                 img: fetchTest.img,
    //                 video: fetchTest.video,
    //                 questions: questions,
    //                 level: fetchTest.Level
    //             }))
    //         }
    //     }).catch(err => {
    //         const errors = err.graphQLErrors.map(mes => mes.message);
    //         dispatch(test({ errors: errors, loading: false }))
    //     })
    // }
};
