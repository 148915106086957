import gql from "graphql-tag";

export const createPlanMutation = gql`
  mutation CreatePlan(
    $title: String!
    $objective: String
    $trainingperiods: [Int!]!
    $duration: Int!
    $phases: [Int!]!
    $equipments: [Int!]!
    $difficulty: Int!
    $notes: String
    $description: String
    $exercises: [exercise!]!
    $bodyparts: [Int!]!
    $img: Upload
  ) {
    createPlan(
      title: $title
      objective: $objective
      trainingperiods: $trainingperiods
      duration: $duration
      phases: $phases
      img: $img
      bodyparts: $bodyparts
      equipments: $equipments
      difficulty: $difficulty
      notes: $notes
      description: $description
      exercises: $exercises
    ) {
      id
      title
      objective
      status
      trainingperiods {
        id
        name
      }
      duration
      phases {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        personal {
          name
        }
      }
      img
      no_of_exercises
      exercises {
        id
        title
        img
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const updatePlanMutation = gql`
  mutation EditPlan(
    $id: Int!
    $title: String
    $objective: String
    $trainingperiods: [Int]
    $duration: Int
    $phases: [Int!]
    $equipments: [Int!]
    $difficulty: Int
    $notes: String
    $description: String
    $exercises: [exercise!]
    $bodyparts: [Int!]
    $img: Upload
  ) {
    editPlan(
      id: $id
      title: $title
      objective: $objective
      trainingperiods: $trainingperiods
      duration: $duration
      phases: $phases
      img: $img
      bodyparts: $bodyparts
      equipments: $equipments
      difficulty: $difficulty
      notes: $notes
      description: $description
      exercises: $exercises
    ) {
      id
      title
      objective
      status
      trainingperiods {
        id
        name
      }
      duration
      phases {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        personal {
          name
        }
      }
      img
      no_of_exercises
      exercises {
        id
        title
        img
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const updateTakenPlanMutation = gql`
  mutation EditTakenPlan(
    $id: Int!
    $title: String
    $objective: String
    $trainingperiods: [Int]
    $duration: Int
    $phases: [Int!]
    $equipments: [Int!]
    $difficulty: Int
    $notes: String
    $description: String
    $exercises: [exercise!]
    $bodyparts: [Int!]
    $img: Upload
  ) {
    editSubscribePlanDashboard(
      id: $id
      title: $title
      objective: $objective
      trainingperiods: $trainingperiods
      duration: $duration
      phases: $phases
      img: $img
      bodyparts: $bodyparts
      equipments: $equipments
      difficulty: $difficulty
      notes: $notes
      description: $description
      exercises: $exercises
    ) {
      id
      title
      objective
      status
      trainingperiods {
        id
        name
      }
      duration
      phases {
        id
        name
      }
      bodyparts {
        id
        name
      }
      equipments {
        id
        name
      }
      difficulty {
        id
        name
      }
      notes
      description
      creator {
        id
        personal {
          name
        }
      }
      img
      no_of_exercises
      exercises {
        id
        title
        img
        groups {
          id
          name
        }
        bodyparts {
          id
          name
        }
        difficulty {
          id
          name
        }
      }
    }
  }
`;

export const subscribePlanDashboard = gql`
  mutation subscribePlanDashboard($userIds: [Int!]!, $planIds: [Int!]!) {
    subscribePlanDashboard(userIds: $userIds, planIds: $planIds)
  }
`;

export const ToggleTrainingPlanStatus = gql`
  mutation ToggleTrainingPlanStatus($id: Int!, $status: STATUS!) {
    togglePlanStatus(id: $id, status: $status)
  }
`;

export const DeleteTrainingPlan = gql`
  mutation DeleteTrainingPlan($id: Int!) {
    deletePlan(id: $id)
  }
`;

export const ToggleTakenPlanStatus = gql`
  mutation ToggleTakenPlanStatus($id: Int!, $status: STATUS!) {
    toggleTakenPlanStatus(id: $id, status: $status)
  }
`;

export const DeleteTakenPlan = gql`
  mutation DeleteTakenPlan($id: Int!) {
    deleteTakenPlan(id: $id)
  }
`;
