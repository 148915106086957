/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const multipleSelectHandler = e => {
    let items = [];
    if (e !== null) {
        e.map(item => {
            item = {
                ...item,
                name: item.label,
                id: item.value
            };
            delete item.label;
            delete item.value;
            items.push({ ...item });
        });
    }
    return items;
};

const MultipleSelectComp = props => {
    let [state, setState] = useState({
        elements: [],
        selectedItems: []
    });

    useEffect(() => {
        let elements = [];
        let selectedItems = [];
        if (props.items) {
            props.items.map(item => {
                item = {
                    ...item,
                    label: item.name,
                    value: parseInt(item.id)
                };
                delete item.name;
                delete item.id;
                elements.push({ ...item });
            });
            props.selectedItems.map(item => {
                item = {
                    ...item,
                    label: item.name,
                    value: parseInt(item.id)
                };
                delete item.name;
                delete item.id;
                elements.push({ ...item });
                selectedItems.push({ ...item });
            });
            // let filteredElements = elements.filter(e => props.selectedItems.indexOf(e) < 0);
            setState({
                elements: elements,
                selectedItems: selectedItems
            });
        }
    }, [props.items, props.selectedItems]);

    return (
        <div className="select-custom select-max-width">
            <Select
                isMulti
                options={state.elements}
                onChange={e => {
                    let arr = multipleSelectHandler(e);
                    props.select(arr);
                }}
                name={"multiple." + props.name}
                value={state.selectedItems}
                placeholder={props.text}
            />
        </div>
    );
};

MultipleSelectComp.propTypes = {
    items: PropTypes.array,
    selectedItems: PropTypes.array,
    text: PropTypes.string,
    select: PropTypes.func,
    name: PropTypes.string
};

export default MultipleSelectComp;
