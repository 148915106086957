import React from "react";
import { removeToken } from "../../helpers";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import userPlaceholder from "../../assets/img/placeholders/200x200.png";
import { toggleMenu } from "../../store/global/global_actions";

const Header = () => {
    const authState = useSelector(s => s.authState);
    const { menu: isMenuOpen } = useSelector(s => s.globalState);
    const dispatch = useDispatch();
    // const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const [language, setLanguage] = useState("EN");

    let logOut = e => {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        removeToken();
        window.location.reload();
    };
    return (
        <header className="header">
            <div className="toggle-sidebar">
                <a
                    href="#"
                    className={"click-menu" + (isMenuOpen ? " line-animation" : "")}
                    onClick={e => {
                        e.preventDefault();
                        dispatch(toggleMenu(!isMenuOpen));
                    }}
                >
                    <span className="lines line-top"></span>
                    <span className="lines line-mid"></span>
                    <span className="lines line-bottom"></span>
                </a>
            </div>
            <div className="right-content">
                {/* 				
                <div className="notifications">
                    <a href="#">
                        <i className="icon-notification"></i>
                        <span className="number">2</span>
                    </a>
                </div> */}
                <div className="profile">
                    <Link to="/dashboard/settings">
                        <div className="image">
                            <img alt="" src={authState.img || userPlaceholder} />
                        </div>
                        <div className="content" style={{ width: "120px" }}>
                            <p>{authState.personal.name}</p>
                            <span>{authState.role.name}</span>
                        </div>
                    </Link>
                </div>
                <div className="logout">
                    <a href="#">
                        <i onClick={e => logOut(e)} className="icon-logout"></i>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
