import React from "react";
import { Route, Switch, Redirect } from "react-router";

// import "../../assets/css/style.min.css";

import Index from "./index.js";
import CompleteUserRegistration from "./CompleteUserRegistration";
import { Loading } from "../../components/UI/index.js";
import { useSelector } from "react-redux";

const ReportRoutes = () => {
    const globalState = useSelector(s => s.globalState);

    return (
        <section className="container-fluid">
            <Loading loading={globalState.loading} />
            <Switch>
                <Route exact path="/new-user/complete-registration" component={Index} />
                <Route path="/new-user/:token" component={CompleteUserRegistration} />
                <Redirect to="/" />
            </Switch>
        </section>
    );
};

// {
//     /* <>
//             <Loading loading={userState.loading} />
//             <Switch>
//                 <Route path="/new-user/:token" component={CompleteUserRegistartion} />
//                 <Route path="/complete-registartion" component={Index} />
//             </Switch>
//         </> */
// }

export default ReportRoutes;
