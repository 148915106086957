import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import useQueryStrings from "hooks/useQueryStrings";
import { Loading } from "../../../components/UI";
import ReportCalendar from "../../../components/Client/User/ReportCalendar";
import DisplayReports from "./DisplayReports";

const UserReports = () => {
    const [date, setDate] = useState(null);
    const query = useQueryStrings();
    useEffect(() => {
        if (query.date) {
            setDate(query.date);
        }
    }, [query.date]);

    const monthChangeHandler = next => {
        let newDate;
        if (!next) {
            newDate = moment(date, "DD-MM-YYYY")
                .subtract(1, "month")
                .format("DD-MM-YYYY")
                .toString();
        } else {
            newDate = moment(date, "DD-MM-YYYY")
                .add(1, "month")
                .format("DD-MM-YYYY")
                .toString();
        }
        setDate(newDate);
        window.history.pushState(null, "", "?date=" + newDate);
    };

    const dayChangeHandler = date => {
        setDate(date);
        window.history.pushState(null, "", "?date=" + date);
    };

    if (!date) return <Loading loading />;

    return (
        <section className="main-content">
            <div className="container-fluid">
                <div className="white-bg">
                    <div className="row">
                        <ReportCalendar
                            date={date}
                            dayChangeHandler={dayChangeHandler}
                            monthChangeHandler={monthChangeHandler}
                        />
                        <DisplayReports date={date} />
                    </div>
                </div>
            </div>
        </section>
    );
};

UserReports.propTypes = {
    event: PropTypes.string
};

export default UserReports;
