import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

import SingleTest from "../../../components/Tests/SingleTest";
import EditTest from "./EditTest";
import {
  DeleteTestMutation,
  DeleteTakenTestMutation,
  FetchTestByIdQuery,
  FetchTakenTestByIdQuery,
  ToggleTestStatusMutation,
  ToggleTakenTestStatusMutation,
} from "./GraphQLActions";
import { deleteTest } from "../../../store/test/test_actions";

const FetchTest = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const testState = useSelector((s) => s.testState);
  const singleTest = testState.singleTest;
  const { testId, userId } = useParams();

  /**
   *
   * @param {Event} e
   */
  const toggleTestStatus = () => {
    if (userId) {
      dispatch(
        ToggleTakenTestStatusMutation({
          id: singleTest.id,
          status: singleTest.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        })
      );
    } else {
      dispatch(
        ToggleTestStatusMutation({
          id: singleTest.id,
          status: singleTest.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        })
      );
    }
  };

  const deleteTestHandler = () => {
    if (userId) {
      dispatch(DeleteTakenTestMutation({ id: parseInt(testId) })).then(() => {
        history.push(`/dashboard/users/${userId}`);
        dispatch(deleteTest());
      });
    } else {
      dispatch(DeleteTestMutation({ id: parseInt(testId) })).then(() => {
        history.push("/dashboard/tests");
        dispatch(deleteTest());
      });
    }
  };

  useEffect(() => {
    if (userId) {
      if (singleTest.id != testId || singleTest.__typename !== "TakenTest") {
        dispatch(FetchTakenTestByIdQuery({ takenTestId: parseInt(testId) }));
      }
    } else {
      if (singleTest.id != testId || singleTest.__typename !== "Test") {
        dispatch(FetchTestByIdQuery({ id: parseInt(testId) }));
      }
    }
  }, [singleTest.id, dispatch, testId]);

  if (testState.error) return `Error! ${testState.error}`;

  if (singleTest.id == parseInt(testId))
    if (props.fetch) {
      return (
        <SingleTest
          test={testState.singleTest}
          onToggleTestStatus={toggleTestStatus}
          deleteTestHandler={deleteTestHandler}
        />
      );
    } else {
      return <EditTest update {...props} />;
    }

  return <div />;
};

FetchTest.propTypes = {
  fetch: PropTypes.bool,
};

export default FetchTest;
