import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";

import placeholder from "../../assets/img/placeholders/400x200.png";
import { useTranslation } from "react-i18next";

let handleClick = props => {
    if (!props.forPlan) {
        let link = !props.custom
            ? `/dashboard/exercises/${get(props, "exercises.id", "")}`
            : `${props.custom}/custom-exercise/${get(props, "exercises.id", "")}`;

        props.history.push(link);
    }
};

const ExerciseList = props => {
    const { t } = useTranslation();
    let bodyparts = "";
    let groups = "";
    if (get(props, "exercises.bodyparts", []).length > 0) {
        get(props, "exercises.bodyparts", []).map(e => (bodyparts += e.name + ", "));
        if (bodyparts.length > 20) {
            bodyparts = bodyparts.substring(0, 19) + "...";
        } else {
            bodyparts = bodyparts.substring(0, bodyparts.length - 2);
        }
    } else {
        bodyparts = t("without.label");
    }

    if (get(props, "exercises.groups", []).length > 0) {
        get(props, "exercises.groups", []).map(e => (groups += e.name + ", "));
        if (groups.length > 20) {
            groups = groups.substring(0, 19) + "...";
        } else {
            groups = groups.substring(0, groups.length - 2);
        }
    } else {
        groups = t("without.label");
    }

    return (
        <div className={props.changeClassForPlans ? "item" : "col-sm-12 col-md-6 col-lg-4 col-xl-3"}>
            <div
                onClick={() => handleClick(props)}
                style={!props.forPlan ? { cursor: "pointer" } : {}}
                className="excercises-item"
            >
                {props.forPlan ? (
                    <div className="check-item">
                        <input
                            onChange={() => {
                                props.selectExerciseHandler(props.isChecked, props.exercises, props.isNew);
                            }}
                            type="checkbox"
                            id={`${get(props, "exercises.title", "") + get(props, "exercises.id", "")}`}
                            name={`${get(props, "exercises.title", "") + get(props, "exercises.id", "")}`}
                            checked={props.isChecked}
                        />
                        <label
                            htmlFor={`${get(props, "exercises.title", "") + get(props, "exercises.id", "")}`}
                        ></label>
                    </div>
                ) : null}
                <div className="thumb">
                    <img alt="exercise" src={get(props, "exercises.img", false) || placeholder} />
                </div>
                <div className="content">
                    <div className="title">
                        <h5>{get(props, "exercises.title", "")}</h5>
                    </div>
                    <div className="icons-text">
                        <div>
                            <div className="icon">
                                <i className="icon-trainers"></i>
                            </div>
                            <div className="text">
                                <span>{t("bodypart.label")}</span>
                                {/* {get(props, "", "").exercises.bodyparts.length > 0 ? (
									get(props, "", "").exercises.bodyparts.map(e => <p key={e.id}>{e.name}</p>)
								) : (
									<p></p>
								)} */}
                                <p>{bodyparts}</p>
                            </div>
                        </div>
                        <div>
                            <div className="icon">
                                <i className="icon-equipment"></i>
                            </div>
                            <div className="text">
                                <span>{t("group.label")}</span>
                                {/* {get(props, "", "").exercises.groups.length > 0 ? (
									get(props, "", "").exercises.groups.map(e => <p key={e.id}>{e.name}</p>)
								) : (
									<p></p>
								)} */}
                                <p>{groups}</p>
                            </div>
                        </div>
                    </div>
                    <div className="icons-text">
                        <div>
                            <div className="icon">
                                <i className="icon-level"></i>
                            </div>
                            <div className="text">
                                <span>{t("level.label")}</span>
                                <p>{get(props, "exercises.difficulty.name", "")}</p>
                            </div>
                        </div>
                        <div>
                            <div className="text">
                                <div className="view-details">
                                    {props.forPlan ? null : (
                                        <Link
                                            onClick={e =>
                                                e.preventDefault ? e.preventDefault() : (e.returnValue = false)
                                            }
                                            to={
                                                !props.custom
                                                    ? `/dashboard/exercises/${get(props, "exercises.id", "")}`
                                                    : `${props.custom}/custom-exercise/${get(
                                                          props,
                                                          "exercises.id",
                                                          ""
                                                      )}`
                                            }
                                        >
                                            {t("view_details.label")}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ExerciseList.propTypes = {
    exercises: PropTypes.object,
    selectExerciseHandler: PropTypes.func,
    changeClassForPlans: PropTypes.bool,
    forPlan: PropTypes.bool,
    isChecked: PropTypes.bool,
    isNew: PropTypes.bool,
    custom: PropTypes.number
};

export default withRouter(ExerciseList);
