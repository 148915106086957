import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { getApiUrl } from "../../constants";

import placeholder from "../../assets/img/placeholders/400x200.png";
import videoPlaceholder from "../../assets/img/placeholders/400x200video.png";
import { getToken } from "../../helpers";
import { prepareExerciseUpdate } from "../../store/exercise/exercise_actions";

import ConfirmModal from "../UI/Modal/ConfirmModal";
import { hasUpdateAccess } from "../../helpers/hasUpdateAccess";

const SingleExercise = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const exerciseState = useSelector((state) => state.exerciseState);
  const user = useSelector((state) => state.authState);
  const [deleteModal, setDeleteModal] = useState(false);
  const exercise = exerciseState.singleExercise;

  let photos = [];
  if (get(exercise, "img", false)) {
    photos.push({ path: exercise.img, id: 0 });
  }

  get(exercise, "photos", []).map((el) => photos.push(el));
  if (photos.length === 0) photos.push({ path: placeholder, id: 0 });

  let apiUrl = getApiUrl();
  let pdfUrl = !exercise.customExerciseFlag
    ? "exercise/" + exercise.id
    : "custom-exercise/" + exercise.id;
  pdfUrl += "?token=" + getToken.token;

  return (
    <section className="main-content">
      <div className="container-fluid">
        <div className="white-bg">
          <div className="row">
            <div className="col-md-12">
              <div className="single-detail-head single-head-left-right">
                <div className="single-head-flex">
                  <div className="single-head-left">
                    <div className="form-toggle form-toggle-checked">
                      {hasUpdateAccess(user, exercise) && (
                        <label className="form-toggle-switch" htmlFor="status">
                          <input
                            type="checkbox"
                            id="status"
                            defaultChecked={exercise.status === "ACTIVE"}
                            onChange={props.onToggleExerciseStatus}
                          />
                          <div className="form-toggle-switch-slider form-toggle-switch-round"></div>
                        </label>
                      )}
                      <span
                        className={
                          exercise.status === "ACTIVE" ? "active" : "inactive"
                        }
                      >
                        {exercise.status === "ACTIVE" ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                  <div className="single-head-right">
                    {user.role.id !== 1 &&
                    !exercise.creator ? null : exerciseState.customExerciseFlag ? null : (
                      <Link
                        onClick={() => dispatch(prepareExerciseUpdate())}
                        to={`${get(
                          exercise,
                          "id",
                          ""
                        )}/edit-exercise/upload-file`}
                      >
                        <i className="icon-edit"></i>
                        <span>{t("edit.label")}</span>
                      </Link>
                    )}

                    <a
                      href={apiUrl + pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon-print"></i>
                      <span>{t("print.label")}</span>
                    </a>
                    {user.role.id !== 1 && !exercise.creator ? null : (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeleteModal(true);
                        }}
                      >
                        <i className="icon-cancel"></i>
                        <span>Delete</span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="single-detail">
                <div className="thumb-slider">
                  <div className="carousel-priosan">
                    <Carousel
                      infiniteLoop={true}
                      autoPlay={false}
                      showArrows={true}
                      showIndicators={false}
                      showStatus={false}
                    >
                      {photos.map((el) => {
                        return (
                          <div key={el.id}>
                            <img alt="desc" src={el.path} />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <h3>{get(exercise, "title", "")}</h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("difficulty.label")}</span>
                        <p>{get(exercise, "difficulty.name", "")}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("bodypart.label")}</span>
                        {get(exercise, "bodyparts", []).length > 0 ? (
                          get(exercise, "bodyparts", []).map((e) => (
                            <p key={e.id}>{e.name}</p>
                          ))
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("group.label")}</span>
                        {get(exercise, "groups", []).length > 0 ? (
                          get(exercise, "groups", []).map((e) => (
                            <p key={e.id}>{e.name}</p>
                          ))
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("equipment.label")}</span>
                        {get(exercise, "equipments", []).length > 0 ? (
                          get(exercise, "equipments", []).map((e) => (
                            <p key={e.id}>{e.name}</p>
                          ))
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("device_settings.label")}</span>
                        <p>{get(exercise, "device_settings", "-")}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("resistance.label")}</span>
                        <p>{get(exercise, "resistance", "-")}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("goal.label")}</span>
                        <p>{get(exercise, "goal", "-")}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("cave.label")}</span>
                        <p>{get(exercise, "cave", "-")}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("series.label")}</span>
                        <p>{get(exercise, "series", "0")}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <span>{t("termination_condition.label")}</span>
                        <p>
                          {get(exercise, "termination_condition.name", "-")}
                        </p>
                      </div>
                    </div>
                    {get(exercise, "termination_condition.id", 3) > 2 ? null : (
                      <div className="col-md-4">
                        <div className="info">
                          <span>
                            {t(
                              Object.keys(
                                get(exercise, "termination_condition_value", {})
                              )[0] + ".label"
                            )}
                          </span>
                          <p>
                            {
                              get(exercise, "termination_condition_value", {})[
                                Object.keys(
                                  get(
                                    exercise,
                                    "termination_condition_value",
                                    {}
                                  )
                                )[0]
                              ]
                            }
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("motion_execution.label")}</span>
                        {get(exercise, "motions", []).map((el, index) => (
                          <div key={index} className="steps">
                            <span>
                              {t("step.label")} {index + 1}
                            </span>
                            <p>{el.motion}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-t-80">
            <div className="col-md-12">
              <div className="single-detail">
                <div className="video-detail">
                  {get(exercise, "video", false) ? (
                    <ReactPlayer
                      width="100%"
                      url={get(exercise, "video", "")}
                      controls={true}
                      playing={false}
                    />
                  ) : (
                    <img src={videoPlaceholder} />
                  )}
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("notes.label")}</span>
                        <p>{get(exercise, "note", "-")}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("description.label")}</span>
                        <p>{get(exercise, "description", "-")}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="info">
                        <span>{t("recommendations.label")}</span>
                        <p>{get(exercise, "recommendations", "-")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={deleteModal}
        confirmButton="Delete"
        rejectHandler={() => setDeleteModal(false)}
        confirmHandler={props.deleteExerciseHandler}
        message="Are you sure you want to delete this exercise?"
      />
    </section>
  );
};

SingleExercise.propTypes = {
  onToggleExerciseStatus: PropTypes.func.isRequired,
  deleteExerciseHandler: PropTypes.func.isRequired,
};

export default SingleExercise;
