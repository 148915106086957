import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router";
import UploadFile from "./CreateExercise/UploadFile";
import Information from "./CreateExercise/Information";
import Overview from "./CreateExercise/Overview";
import { useSelector, useDispatch } from "react-redux";
import { prepareExerciseUpdate } from "../../../store/exercise/exercise_actions";

const NewExercise = () => {
    const match = useRouteMatch();
    const { exerciseId } = useParams();
    const dispatch = useDispatch();
    const { id } = useSelector(s => s.exerciseState.updateExercise);

    useEffect(() => {
        if (!id && id !== exerciseId) {
            dispatch(prepareExerciseUpdate());
        }
    }, [id, exerciseId]);

    return (
        <Switch>
            <Route path={match.url + "/overview"} component={Overview} />
            <Route path={match.url + "/information"} component={Information} />
            <Route path={match.url + ""} component={UploadFile} />
        </Switch>
    );
};

export default NewExercise;
