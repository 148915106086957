import { client } from "../../../helpers/index";
import {
    LatestRecordTicketQuery,
    UserTicketQuery,
    LibraryTicketQuery,
    GetReportDataForDashboard,
    UserStatusChartDataQuery,
    NewTrainersChartDataQuery
} from "../../../resolvers/query/dashboard";

export const UserTicketAction = variables => {
    return () => {
        return client
            .query({ query: UserTicketQuery, variables })
            .then(({ data }) => {
                if (data) return data.userTicketQuery;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const LibraryTicketAction = variables => {
    return () => {
        return client
            .query({ query: LibraryTicketQuery, variables })
            .then(({ data }) => {
                if (data) return data.libraryTicketQuery;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const LatestRecordTicketAction = variables => {
    return () => {
        return client
            .query({ query: LatestRecordTicketQuery, variables })
            .then(({ data }) => {
                if (data) return data.latestRecordTicketQuery;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const GetReportDataForDashboardAction = variables => {
    return () => {
        return client
            .query({ query: GetReportDataForDashboard, variables })
            .then(({ data }) => {
                if (data) return data.getReportDataForDashboard;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const UserStatusChartDataAction = variables => {
    return () => {
        return client
            .query({ query: UserStatusChartDataQuery, variables })
            .then(({ data }) => {
                if (data) return data.userStatusChartDataQuery;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};

export const NewTrainersChartDataAction = variables => {
    return () => {
        return client
            .query({ query: NewTrainersChartDataQuery, variables })
            .then(({ data }) => {
                if (data) return data.newTrainersChartDataQuery;
            })
            .catch(err => {
                if (err.graphQLErrors && err.graphQLErrors[0]) {
                    throw { statusCode: err.graphQLErrors[0].statusCode, message: err.graphQLErrors[0].message };
                } else {
                    throw err;
                }
            });
    };
};
